import "./dot.scss"

export default function Separators({ title, discription }) {
  return (
    <div className="separation">
      <span className="title">{title}</span>
      <span> : </span>
      <span className="discription">{discription}</span>
    </div>
  )
}
