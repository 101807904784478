import { useState, useEffect } from "react"
// css class
import "../../../styles/profile/product/userproduct.scss"
// mui
import Modal from "@mui/material/Modal"
import { DataGrid } from "@mui/x-data-grid"

import { CircularProgress } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import Alert from "@mui/material/Alert"
// import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline, Search } from "@mui/icons-material"

//
import ProfileSideNav from "../sidenav"
import CreateProduct from "./create"
import UpdateProduct from "./update"

import DeleteItem from "../../../example/deleteitem"

import { userRequest } from "../../../constant/requestMethods"
import { useSelector } from "react-redux"

//
import { useQuery } from "@tanstack/react-query"

// router import
import { useNavigate } from "react-router-dom"

export default function ProfileProject() {
  // for getting the user Id stored when the user logged in
  const { currentUser } = useSelector((state) => state.user)

  // var to navigate to the products oage
  const navigate = useNavigate()

  // state to change the modal from cupdate to create
  const [changeModal, setChangeModal] = useState()

  // state to fetch the sort
  const [sort, setSort] = useState("")

  // state to open create modal when create new products is opened
  const [openModal, setOpenModal] = useState(false)

  // state to hold the item for updating the values
  const [item, setItem] = useState({})

  // state that holds the projects
  const [projects, setProjects] = useState([])
  // modal to appear when edit or create buttons are pressed

  // state to hold the value of the search formthe serach bar
  const [searchedProject, setSearcedProject] = useState("")

  // controllers to show alets
  const [alert, setAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  // modal that appears when either the create or update are pressed
  const renderModal = () => {
    return (
      <>
        <Modal
          style={{
            opacity: "1",
            border: "none",
            borderRadius: "4px",
            overflow: "auto",
            outline: "none",
          }}
          open={openModal}
          onClose={(e) => {
            setOpenModal(!openModal)
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {changeModal === "update" ? (
            <UpdateProduct
              setOpenModal={setOpenModal}
              openModal={openModal}
              product={item}
            />
          ) : changeModal === "delete" ? (
            <DeleteItem
              openModal={openModal}
              setOpenModal={setOpenModal}
              item={item}
              type={"project"}
            />
          ) : (
            <CreateProduct setOpenModal={setOpenModal} openModal={openModal} />
          )}
        </Modal>
      </>
    )
  }
  const columns = [
    { field: "_id", headerName: "ID", width: 90 },
    {
      field: "productName",
      headerName: "item",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellimage">
            <img
              src={params.row.image[0] ? params.row.image[0] : params.row.image}
            />
            <span>
              {params.row.title.length > 10
                ? params.row.title.split("", 10)
                : params.row.title}
              {params.row.title.length > 10 ? "..." : ""}
            </span>
          </div>
        )
      },
    },

    {
      field: "discription",
      headerName: "Discription",
      width: 300,
    },
    {
      field: "catagory",
      headerName: "Catagory",
      width: 150,
      renderCell: (params) => {
        params.row.catagory &&
          params.row.catagory.length > 0 &&
          params.row.catagory.map((item, index) => {
            return <span key={index + 23}>{item}, </span>
          })
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="holeup" id={params.row._id}>
            <button
              onClick={(e) => {
                setChangeModal("update")
                setItem(params.row)
                setOpenModal(!openModal)
                // console.log(item)
              }}
            >
              {" "}
              Edit
            </button>
            <DeleteOutline
              className="userproductddeletebutton"
              style={{ color: "red" }}
              onClick={() => {
                setItem(params.row)
                setChangeModal("delete")
                setOpenModal(!openModal)
              }}
            />
          </div>
        )
      },
    },
  ]

  const { isLoading, error, refetch } = useQuery({
    queryKey: ["findprojects"],
    queryFn: async () => {
      try {
        setErrorAlert(false)
        setAlert(false)
        setErrorMessage("")
        const reply = await userRequest.get(
          `/project/finduserproject/${currentUser.data._id}`
        )
        // console.log(reply)
        setProjects(reply.data.data)
        // console.log(reply)
        return reply
      } catch (e) {
        setErrorAlert(true)
        setAlert(true)
        setErrorMessage(e.message)
        // console.log(e)
        return e
      }
    },

    cacheTime: Infinity,
    staleTime: Infinity,
  })

  //  when the search bar is is inputed the rows change
  const findProject = () => {
    const something = []
    if (searchedProject) {
      projects.map((item, index) => {
        const doe = item.title.toLowerCase()
        if (!doe.indexOf(searchedProject.toLowerCase())) {
          something.push(item)
        }
        return something
      })
    }
    return something
  }
  const newRow = findProject()

  /// sorts the projects
  useEffect(() => {
    if (sort === "Newest to Oldest") {
      setProjects((prev) =>
        [...prev].sort(
          (a, b) =>
            Number(new Date(b.createdAt)) - Number(new Date(a.createdAt))
        )
      )
      // console.log(ProjectsetProjects[4].createdAt)
    } else if (sort === "Oldest to Newest") {
      setProjects((prev) =>
        [...prev].sort(
          (a, b) =>
            Number(new Date(a.createdAt)) - Number(new Date(b.createdAt))
        )
      )
      // console.log(products)
    }
  }, [sort])
  // console.log(projects)
  useEffect(() => {
    refetch()
  }, [openModal])

  return (
    <div className="userproduct">
      <ProfileSideNav page={"project"} />
      {renderModal()}
      <div className="userproductmargin">
        <div className="userproductcontainer">
          {errorAlert && (
            <div className="alert">
              {errorAlert && <Alert severity="error">{errorMessage}</Alert>}
            </div>
          )}
          <div className="userproductheader">
            <span className="userproducttitle">Projects</span>
            <button
              className="userproductcreatebutton"
              onClick={(e) => {
                setChangeModal("create")
                setOpenModal(!openModal)
              }}
            >
              Create Project
            </button>
          </div>
          <div className="userproductsearch">
            <div className="userproductsearchicon">
              <OutlinedInput
                size="small"
                id="outlined-adornment-weight"
                startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                style={{ borderRadius: "none" }}
                onChange={(e) => {
                  setSearcedProject(e.target.value)
                }}
              />

              <button
                style={{
                  width: "30%",
                  height: "40px",
                  backgroundColor: "#00aeff",
                  color: "white",
                  border: "none",
                }}
              >
                {" "}
                Search
              </button>
            </div>
            <div className="userproductsortpage">
              <span>Sort By:</span>
              <FormControl sx={{ m: 1, minWidth: "60%" }}>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  autoWidth
                  value={sort}
                  onChange={(e) => setSort(e.target.value)}
                  size="small"
                >
                  <MenuItem value="">
                    {sort ? <em>{sort}</em> : <em>Newest to Oldest</em>}
                  </MenuItem>
                  <MenuItem
                    value="Newest to Oldest"
                    onClick={(e) => setSort(e.target.value)}
                  >
                    <span
                      style={{ fontSize: "14px", fontFamily: "Bellota-Light" }}
                    >
                      Newest to Oldest
                    </span>
                  </MenuItem>
                  <MenuItem
                    value="Oldest to Newest"
                    onClick={(e) => setSort(e.target.value)}
                  >
                    <span
                      style={{ fontSize: "14px", fontFamily: "Bellota-Light" }}
                    >
                      Oldest to Newest
                    </span>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="userproductgridd">
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <DataGrid
                onCellDoubleClick={(row) => navigate("/project/" + row.row._id)}
                rows={searchedProject ? newRow : projects}
                columns={columns}
                getRowId={(row) => row._id}
                pageSize={5}
                rowsPerPageOptions={[5]}
                // checkboxSelection
                disableSelectionOnClick
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
