/// css class
import "../../../styles/layout/reset/reset.scss"

import { useState } from "react"
// mui
import {
  TextField,
  InputAdornment,
  Button,
  CircularProgress,
  Alert,
} from "@mui/material"
import { Lock } from "@mui/icons-material"
import Footer from "../../display/footer"
import { useLocation } from "react-router"
import { publicRequest } from "../../../constant/requestMethods"
import axios from "axios"

import { useMutation } from "@tanstack/react-query"

export default function Reset() {
  const location = useLocation()
  const token = location.pathname.split("/")[3]
  const id = location.pathname.split("/")[4]
  const [password, setPassword] = useState("")
  const [confirmPass, setConfirm] = useState("")

  // controllers to show alets
  const [alert, setAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  const resetPost = async (data) => {
    const reply = await axios.put(
      process.env.REACT_APP_API_URL + `user/update/${id}`,
      data,
      {
        headers: { token: `Bearer ${token}` },
      }
    )
    return reply.data
  }
  const { mutate, isLoading } = useMutation(resetPost, {
    onSuccess: (data) => {
      setAlert(true)
      setSuccessAlert(true)
      // console.log(data);s
      setSuccessMessage(data.msg)
    },
    onError: (e) => {
      setAlert(true)
      setErrorAlert(true)
      setErrorMessage(e.response.data.msg)
    },
  })
  const updateHandler = () => {
    setAlert(false)
    setErrorAlert(false)
    setSuccessAlert(false)
    setErrorMessage("")
    setSuccessMessage("")
    // console.log(updates)
    mutate({ password: password })
  }
  console.log(token, id)
  return (
    <div className="reset">
      <div className="insidereset">
        <div className="leftsidereset">
          {/* <img
            // src="https://i.pinimg.com/564x/5e/12/53/5e1253874a61533b22301aa60aaf6854.jpg"
            alt=""
          /> */}
        </div>
        <div className="rightsidereset">
          <div className="containers">
            {alert && (
              <div style={{ width: "100%" }}>
                {successAlert && (
                  <Alert severity="success">{successMessage}</Alert>
                )}
                {errorAlert && <Alert severity="error">{errorMessage}</Alert>}
              </div>
            )}
            <span>New Password</span>
            <div className="holders">
              <span>Password</span>
              <TextField
                size="small"
                error={password && password.length < 8}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  ),
                }}
                helperText={
                  password &&
                  password.length < 8 &&
                  "makesure is greater than 8 letters"
                }
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
              ></TextField>
            </div>
            <div className="holders">
              <span>Confirm Password</span>
              <TextField
                size="small"
                variant="outlined"
                error={confirmPass && confirmPass !== password}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  ),
                }}
                type="password"
                onChange={(e) => {
                  setConfirm(e.target.value)
                }}
              ></TextField>
            </div>
            <Button
              className="button"
              disabled={password !== confirmPass || password.length < 8}
              onClick={(e) => {
                updateHandler()
              }}
            >
              {isLoading ? (
                <CircularProgress
                  style={{ color: "white", width: "20px", height: "20px" }}
                />
              ) : (
                <>Register</>
              )}
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
