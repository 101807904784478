import { useEffect, useState } from "react"
/// scss class
import "../../../../styles/profile/tender/update/updatetender.scss"
/// material ui
import {
  Close,
  FormatQuote,
  Info,
  Inventory,
  PriceChange,
} from "@mui/icons-material"
import FormControl from "@mui/material/FormControl"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import { Button, CircularProgress, TextField } from "@mui/material"
import Alert from "@mui/material/Alert"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select, { SelectChangeEvent } from "@mui/material/Select"

import { DeleteOutline } from "@mui/icons-material"

// api call to login the user
import { login } from "../../../../redux/apiCall"
// state dispacther
import { useDispatch, useSelector } from "react-redux"
// router import
import { useNavigate } from "react-router-dom"

// firebase imports to upload the picture
import app from "../../../../constant/fireBase"
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage"

//
import { useQuery, useMutation } from "@tanstack/react-query"

//
import { userRequest } from "../../../../constant/requestMethods"

export default function UpdateTender({ setOpenModal, openModal, tender }) {
  // mui style for the selsector
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 140,
        width: 250,
      },
    },
  }

  let checked = true
  const navigate = useNavigate()
  // getting the state from the website
  const { currentUser } = useSelector((state) => state.user)

  // controllers to show alets
  const [alert, setAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  // error checkers
  const [fieldError, setFieldError] = useState(false)
  const [errorOn, setErrorOn] = useState("")

  // vars for the form
  const [email, setEmail] = useState("")
  const [userName, setUserName] = useState("")
  const [password, setPassWord] = useState("")
  const [file, setFile] = useState()
  const [fileName, setFileName] = useState([...tender.document])
  const [input, setInput] = useState({})
  const [cat, setCat] = useState({})
  const [catagory, setCatagory] = useState([])
  const feature = [
    "Decoration & Accessories",
    "Furniture",
    "Kitchen & Baths",
    "Lighting",
    "Materials",
    "Painting",
    "Roofing & Gutter",
    "Wall & Floor",
  ]

  const [petty, setPetty] = useState([{}])

  //	// trigger for setting if the images failed to load or are success ful
  const [isImageLoading, setIsImageLoading] = useState(false)
  const [hasFailed, setHasFailed] = useState(false)

  // console.lsog(fileName)

  // saves the Edited textfields with their name and values
  const handleInput = (e) => {
    setInput((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

  /// function that saves the inputed properties as arrays
  const handleCat = (e) => {
    setCat((prev) => {
      return { ...prev, [e.target.name]: e.target.value.split(/[\r?\n,]/) }
    })
  }

  const handleDisc = (e) => {
    setCat((prev) => {
      return { ...prev, [e.target.name]: e.target.value.split(/[\r?\n]/) }
    })
  }

  const handleCatagory = (e) => {
    // console.log(e.target.value)
    const {
      target: { value },
    } = e
    setCatagory(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    )
  }

  // endpoint to edit Tender
  const editTender = async (data) => {
    const reply = await userRequest.put(
      `/tender/update/${currentUser.data._id}/${tender._id}`,
      data
    )
    return reply.data
  }
  const { mutate, isLoading } = useMutation(editTender, {
    onSuccess: (data) => {
      setAlert(true)
      setSuccessAlert(true)
      // console.log(data);s
      setSuccessMessage(data.msg)
    },
    onError: (e) => {
      setAlert(true)
      setErrorAlert(true)
      setErrorMessage(e.response.message)
    },
  })
  const updateHandler = () => {
    let { amount, price } = input
    amount = parseInt(amount)
    price = parseInt(price)
    const inStock = { amount }
    const updates = {
      ...input,
      ...cat,
      image: fileName,
      catagory,
      inStock,
    }
    setAlert(false)
    setErrorAlert(false)
    setSuccessAlert(false)
    setErrorMessage("")
    setSuccessMessage("")
    // console.log(updates)
    mutate(updates)
  }

  // chnages eerytime an image is removed from an array which sets the page to relaoad
  const [deleted, setDeleted] = useState(true)

  // useEffect to reload when a pic is deleted
  useEffect(() => {}, [fileName, deleted])

  return (
    <div
      className="renderupdatetender"
      style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        left: "50%",
        top: "60%",
        // height: "90%",
        width: "60.4%",
        transform: "translate(-50%, -50%)",
        outline: "none",
        opacity: "1",
        backgroundColor: "white",
        border: "none",
      }}
    >
      <div className="updatetenderheader">
        <div className="updatetendertext">
          <span>
            Update{" "}
            {tender && tender.title.length > 20
              ? tender.title.split("").map((item, index) => {
                  if (index < 20) {
                    return item
                  }
                })
              : "..."}
          </span>
        </div>

        {/* <div className='verticallines'></div> */}

        {/* <div className='verticallines'></div> */}
        {/* <div className='verticallines'></div> */}

        <button
          onClick={(e) => {
            setOpenModal(!openModal)
          }}
        >
          <Close />
        </button>
      </div>
      {alert && (
        <div className="alert">
          {errorAlert && <Alert severity="error">{errorMessage}</Alert>}
          {successAlert && <Alert severity="success">{successMessage}</Alert>}
        </div>
      )}

      <div className="updatetendermiddle">
        <TextField
          // placeholder={tender.title}
          size="small"
          className="updatetendertextfield"
          label="Title"
          variant="outlined"
          placeholder={tender && tender.title}
          name="title"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FormatQuote />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleInput(e)
          }}
        />

        <TextField
          // placeholder={tender.title}
          size="small"
          className="updatetendertextfield"
          label="TOT Ref.No."
          variant="outlined"
          name="tot"
          placeholder={tender && tender.tot && tender.tot}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FormatQuote />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleInput(e)
          }}
        />
        <TextField
          // placeholder={tender.title}
          size="small"
          className="updatetendertextfield"
          label="Country"
          variant="outlined"
          name="country"
          placeholder={tender && tender.country && tender.country}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FormatQuote />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleInput(e)
          }}
        />
        <TextField
          // placeholder={tender.title}
          size="small"
          className="updatetendertextfield"
          label="City"
          variant="outlined"
          name="city"
          placeholder={
            tender && tender.region && tender.region.city && tender.region.city
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FormatQuote />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleInput(e)
          }}
        />

        <TextField
          size="small"
          className="updatetendertextfield"
          label="Discription"
          variant="outlined"
          // placeholder={tender.discription && tender.discription[0]}
          multiline
          rows={4}
          name="discription"
          placeholder={
            tender &&
            tender.discription &&
            tender.discription.length > 0 &&
            tender.discription.map((item) => {
              return item + ",s"
            })
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Info />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleDisc(e)
          }}
        />
        {/* <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-name-label">Catagory</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={catagory}
            onChange={(e) => {
              handleCatagory(e)
            }}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
          >
            {feature.map((name) => (
              <MenuItem
                key={name}
                value={name}
                // style={getStyles(name, personName, theme)}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
      </div>
      <div className="updatetenderlast">
        <Button
          className="updatetenderbutton"
          onClick={(e) => updateHandler()}
          disabled={isLoading || !input || !cat}
        >
          {isLoading ? (
            <CircularProgress
              style={{ color: "white", width: "20px", height: "20px" }}
            />
          ) : (
            <>Update</>
          )}
        </Button>
        {/* <div className="logingoogle">
        <Google style={{ width: "20%", fontSize: "20px", border: " 1px solid #3c8daa", height: "10%" }} />
        <button className="googlebutton"> Sign In with Google</button>
      </div> */}
      </div>
    </div>
  )
}
