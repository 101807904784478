import { useState, useEffect } from "react"
// css class
import "../../../styles/pages/projects/projectspage.scss"
// page notifiyer
import Locator from "../../../example/locator"
// data for the blogs
// pinterestlayout for the projects
import PinterstLayout from "../../../example/pinterestlayout"

import Border from "../../../example/borderbottom"
import { MinimizeOutlined, Add, Search } from "@mui/icons-material"
//
import FormControl from "@mui/material/FormControl"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"

//
import { useQuery } from "@tanstack/react-query"

// request sender
import { publicRequest } from "../../../constant/requestMethods"

// mui
import { CircularProgress } from "@mui/material"
import Alert from "@mui/material/Alert"

import { useLocation } from "react-router"
import Footer from "../../display/footer"
export default function Projects() {
  const location = useLocation()

  const id = location.pathname.split("/")[1]
  // sort select
  const [sort, setSort] = useState("")
  // checkbox controllers that app
  const catagories = [
    "Engineering and Consultancy",
    "Engineering",
    "Finishing",
    "General Contractors",
    "Graphics & Animation",
    "Landscape Architecture",
    "Photography Studio",
    "Planning",
    "Security",
    "Interior Design",
  ]
  const [cat, setCat] = useState("")
  const [cataPressed, setCataPressed] = useState(true)
  const [conditionPressed, setConditionPressed] = useState(false)
  const [typePressed, setTypePressed] = useState(false)
  const [warrentyPressed, setWarrentyPressed] = useState(false)
  const [searchPressed, setSearchPressed] = useState(true)
  const [search, setSearch] = useState("")

  // shows the items numbers when the filteres are pressed
  const [filterPressed, setFilterPressed] = useState(false)

  const [checked, setChecked] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ])

  //state vars to hold the projects and nexpage if it there
  const [projects, setProjects] = useState([])
  const [nextPage, setNextPage] = useState([])

  // controllers to show alets
  const [alert, setAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  // functions that runs when the page is loaded
  // sends a request to the server for the blog data
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["findprojects"],
    queryFn: async () => {
      setAlert(false)
      setErrorAlert(false)
      setErrorMessage("")
      try {
        // await userRequest.get("/blog/find?page=1&limit=5")
        const reply = await publicRequest.get(
          `/project/find?page=${pageNumber}&limit=10`
        )
        let result = reply.data.data.result
        result.map((item) => {
          projects.push(item)
        })
        let next = reply.data.data.next
        setNextPage(next)
        if (projects && projects.length > 0) {
          setProjects([
            ...new Map(
              projects.map((obj) => [`${obj._id}:${obj.title}`, obj])
            ).values(),
          ])
        }
        return reply
      } catch (e) {
        setAlert(true)
        setErrorAlert(true)
        setErrorMessage(e.message)
        return e
        // console.log(e.message)
      }
    },

    cacheTime: Infinity,
    staleTime: Infinity,
  })

  const handleFilter = () => {
    let something = []
    if (cat) {
      projects.filter(function (node) {
        node.catagory.map((catagory) => {
          if (catagory.toLowerCase() === cat.toLowerCase()) {
            something.push(node)
            // console.log(filterMan)
          }
        })
      })
      return something
    }

    return something
  }

  const foundFilter = handleFilter()

  // changes the checked boxes to the one that is clicked
  const handleChange = (event, index) => {
    setCat(event.target.name)
    setChecked([false, false, false, false, false, false, false])
    checked.map(() => {
      if (index === 0) {
        setChecked([
          true,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ])
      } else if (index === 1) {
        setChecked([
          false,
          true,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ])
      } else if (index === 2) {
        setChecked([
          false,
          false,
          true,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ])
      } else if (index === 3) {
        setChecked([
          false,
          false,
          false,
          true,
          false,
          false,
          false,
          false,
          false,
          false,
        ])
      } else if (index === 4) {
        setChecked([
          false,
          false,
          false,
          false,
          true,
          false,
          false,
          false,
          false,
          false,
        ])
      } else if (index === 5) {
        setChecked([
          false,
          false,
          false,
          false,
          false,
          true,
          false,
          false,
          false,
          false,
        ])
      } else if (index === 6) {
        setChecked([
          false,
          false,
          false,
          false,
          false,
          false,
          true,
          false,
          false,
          false,
        ])
      } else if (index === 7) {
        setChecked([
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          true,
          false,
          false,
        ])
      } else if (index === 8) {
        setChecked([
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          true,
          false,
        ])
      } else if (index === 9) {
        setChecked([
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          true,
        ])
      }
    })

    // console.log(cat)

    // setChecked([event.target.checked, event.target.checked])
  }
  const findProjects = () => {
    const something = []
    if (search) {
      projects.map((item, index) => {
        const doe = item.title.toLowerCase()
        if (!doe.indexOf(search.toLowerCase())) {
          something.push(item)
        }
        return something
      })
    }
    return something
  }

  const foundProjects = findProjects()

  const check = () => {
    if (projects && projects.length > 0) {
      setProjects([
        ...new Map(
          projects.map((obj) => [`${obj._id}:${obj.title}`, obj])
        ).values(),
      ])
    }
    // let set = new Set(projects.map((v) => v._id))
    // console.log(filteredList)
  }

  const { display, pageNumber } = PinterstLayout(
    foundProjects && foundProjects.length > 0
      ? foundProjects
      : foundFilter && foundFilter.length > 0
      ? foundFilter
      : projects && projects,
    "project",
    false,
    true,
    isLoading,
    nextPage
  )

  // refetches the next projects after the user gets to the last one
  useEffect(() => {
    refetch()
    // console.log("the pagenumber funcion is doin this")
  }, [pageNumber])

  useEffect(() => {
    refetch()
    check()
    // console.log("the refethc funcion is doin this")
  }, [id])

  // console.log(foundFilter)
  // console.log(foundProjects)
  // console.log(projects)
  return (
    <div className="projectspage">
      <div className="insideprojectspage">
        {/* <div className='locator'>
          <Locator header={"Home"} icon={"kj"} />
          <Locator span={"Projects"} />
        </div> */}
        <div className="leftsideofthepage">
          {errorAlert && (
            <div className="alert">
              {errorAlert && <Alert severity="error">{errorMessage}</Alert>}
            </div>
          )}
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span className="projectstitleheader">Projects</span>
            {filterPressed && (
              <span style={{ fontSize: "18", fontFamily: "Bellota-Bold" }}>
                Projects Found {foundFilter.length}
              </span>
            )}
          </div>

          {isLoading ? (
            <div
              style={{
                width: "100%",
                height: "70dvh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            display
          )}
        </div>
        <div className="rightsideofthepage">
          <div className="sellersidenav">
            <span className="filterheader">Filter</span>
            <div className="catasidenav">
              <Border
                first={"Catagory"}
                second={
                  cataPressed ? (
                    <Add
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCataPressed(!cataPressed)
                        setCat("")
                        setFilterPressed(false)
                        setChecked([
                          false,
                          false,
                          false,
                          false,
                          false,
                          false,
                          false,
                          false,
                          false,
                          false,
                        ])
                      }}
                    />
                  ) : (
                    <MinimizeOutlined
                      style={{ cursor: "pointer" }}
                      onClick={(e) => setCataPressed(!cataPressed)}
                    />
                  )
                }
              />
              {cataPressed && (
                <FormControl
                  className="close"
                  sx={{ m: 1 }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    {catagories.map((item, index) => (
                      <FormControlLabel
                        key={index + 98676}
                        style={{ fontSize: "12", fontFamily: "Bellota-Light" }}
                        control={
                          <Checkbox
                            checked={checked[index]}
                            onChange={(e) => {
                              setCat(e.target.name)
                              handleChange(e, index)
                              setFilterPressed(true)
                            }}
                            name={item}
                            style={{
                              transform: "scale(0.7)",
                              width: "10px",
                              height: "10px",
                            }}
                            // className="checkbox"
                          />
                        }
                        label={
                          <span
                            style={{
                              fontSize: "12px",
                              fontFamily: "Bellota-Light",
                            }}
                          >
                            {item}
                          </span>
                        }
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              )}
            </div>

            <div className="catasidenav">
              {searchPressed && (
                <FormControl sx={{ m: 1, width: "90%" }} variant="outlined">
                  <OutlinedInput
                    size="small"
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    onChange={(e) => {
                      setSearch(e.target.value)
                      // console.log(search)
                    }}
                  />
                </FormControl>
              )}
            </div>
            <div className="catasidenav">
              <button
                style={{
                  width: "90%",
                  height: "40px",
                  marginBottom: "5%",
                  backgroundColor: "#00aeff",
                  color: "white",
                  border: "none",
                }}
              >
                {" "}
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
