import "./modal.scss"
import { Check, Close } from "@mui/icons-material"
import Rooms from "../rooms"
import { Link } from "react-router-dom"
export default function CartModal({ openModal, setOpenModal, img }) {
  return (
    <div
      className="cartmodal"
      style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        left: "50%",
        top: "35%",
        // height: "60%",
        width: "33.4%",
        transform: "translate(-50%, -50%)",
        outline: "none",
        opacity: "1",
        backgroundColor: "white",
        border: "none",
      }}
    >
      <div className="header">
        <Close
          style={{ color: "white", cursor: "pointer" }}
          onClick={(e) => {
            setOpenModal(!openModal)
          }}
        />
      </div>
      <img src={img} alt="" />
      <Rooms icons={<Check />} number="Added To Cart" />
      <div className="buttons">
        <button
          className="keep"
          onClick={(e) => {
            setOpenModal(!openModal)
          }}
        >
          Keep Shopping
        </button>
        <Link to="/cart">
          <button className="cart"> Check Out Now</button>
        </Link>
      </div>
    </div>
  )
}
