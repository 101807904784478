import { useState, useEffect } from "react"
// css class
import "../../../styles/profile/tender/usertender.scss"
// mui
import Modal from "@mui/material/Modal"
import { DataGrid } from "@mui/x-data-grid"

import { CircularProgress } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import Alert from "@mui/material/Alert"

// import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline, Search } from "@mui/icons-material"
//
import { Link } from "react-router-dom"
//

//
import ProfileSideNav from "../sidenav"
import CreateTender from "./create"
import UpdateTender from "./update"

import { userRequest } from "../../../constant/requestMethods"
import { useSelector } from "react-redux"

//
import { useQuery } from "@tanstack/react-query"

// router import
import { useNavigate } from "react-router-dom"

import DeleteItem from "../../../example/deleteitem"

import { format } from "timeago.js"
export default function TenderProfile() {
  // for getting the user Id stored when the user logged in
  const { currentUser } = useSelector((state) => state.user)

  // var to navigate to the products oage
  const navigate = useNavigate()

  // state to use the user
  const [user, setUser] = useState({})

  // state that holds the tenders
  const [tenders, setTenders] = useState([])

  // state to hold the value of the search formthe serach bar
  const [searchedTender, setSearcedTender] = useState("")

  // controllers to show alets
  const [alert, setAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  // error checkers
  const [fieldError, setFieldError] = useState(false)
  const [errorOn, setErrorOn] = useState("")

  //   account form
  const [account, setAccount] = useState("")

  // state to change the modal from cupdate to create
  const [changeModal, setChangeModal] = useState()

  // state to fetch the sort
  const [sort, setSort] = useState("")

  // state to open create modal when create new tenders is opened
  const [openModal, setOpenModal] = useState(false)

  // state to hold the item for updating the values
  const [item, setItem] = useState({})

  // modal to appear when edit or create buttons are pressed

  //	// trigger for setting if the images failed to load or are success ful
  // const [isLoading, setIsLoading] = useState(false);

  // const [isLoading, setisLoading] = useState(false);
  const { isLoading, error, refetch } = useQuery({
    queryKey: ["findtenders"],
    queryFn: async () => {
      try {
        setErrorAlert(false)
        setAlert(false)
        setErrorMessage("")
        const reply = await userRequest.get(
          `/tender/findusertender/${currentUser.data._id}`
        )
        // console.log(reply)
        setTenders(reply.data.data)
        return reply

        // console.log(reply)
      } catch (e) {
        // console.log("eroor happended" + error)
        setErrorAlert(true)
        setAlert(true)
        setErrorMessage(e.response.data.msg)
        // console.log(e)
        return e
      }
    },

    cacheTime: Infinity,
    staleTime: Infinity,
  })

  //  when the search bar is is inputed the rows change
  const findTender = () => {
    const something = []
    if (searchedTender) {
      tenders.map((item, index) => {
        const doe = item.title.toLowerCase()
        if (!doe.indexOf(searchedTender.toLowerCase())) {
          something.push(item)
        }
        return something
      })
    }
    return something
  }
  const newRow = findTender()

  useEffect(() => {
    if (sort === "Newest to Oldest") {
      setTenders((prev) =>
        [...prev].sort(
          (a, b) =>
            Number(new Date(b.createdAt)) - Number(new Date(a.createdAt))
        )
      )
      // console.log(Tenders[4].createdAt)
    } else if (sort === "Oldest to Newest") {
      setTenders((prev) =>
        [...prev].sort(
          (a, b) =>
            Number(new Date(a.createdAt)) - Number(new Date(b.createdAt))
        )
      )
      // console.log(Tenders)
    }
  }, [sort])

  useEffect(() => {
    refetch()
  }, [openModal])
  const renderModal = () => {
    return (
      <>
        <Modal
          style={{
            opacity: "1",
            border: "none",
            borderRadius: "4px",
            overflow: "auto",
            outline: "none",
          }}
          open={openModal}
          onClose={(e) => {
            setOpenModal(!openModal)
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {changeModal === "update" ? (
            <UpdateTender
              setOpenModal={setOpenModal}
              openModal={openModal}
              tender={item}
            />
          ) : changeModal === "delete" ? (
            <DeleteItem
              openModal={openModal}
              setOpenModal={setOpenModal}
              item={item}
              type={"tender"}
            />
          ) : (
            <CreateTender setOpenModal={setOpenModal} openModal={openModal} />
          )}
        </Modal>
      </>
    )
  }
  const columns = [
    { field: "_id", headerName: "ID", width: 90 },
    {
      field: "tnederName",
      headerName: "item",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellimage">
            <span>
              {params.row.title && params.row.title.length > 10
                ? params.row.title.split("", 10)
                : params.row.title}
              {params.row.title.length > 10 ? "..." : ""}
            </span>
          </div>
        )
      },
    },
    {
      field: "discription",
      headerName: "Discription",
      width: 150,
      editable: true,
      renderCell: (params) => {
        return (
          <span>
            {params.row.discription &&
              params.row.discription.length > 0 &&
              params.row.discription[0].split(20) + "..."}
          </span>
        )
      },
    },
    {
      field: "country",
      headerName: "Country",
      width: 150,
      editable: true,
      renderCell: (params) => {
        return <span>{params.row.country && params.row.country}</span>
      },
    },
    {
      field: "tot",
      headerName: "TOT Ref.No.",
      width: 110,
      editable: true,
      renderCell: (params) => {
        return <span>{params.row.tot && params.row.tot}</span>
      },
    },
    {
      field: "dealline",
      headerName: "Deadline",
      width: 150,
      renderCell: (params) => {
        return <span>{params.row.deadline && format(params.row.deadline)}</span>
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="holeup" id={params.row._id}>
            <button
              onClick={(e) => {
                setChangeModal("update")
                setItem(params.row)
                setOpenModal(!openModal)
                // console.log(item)
              }}
            >
              {" "}
              Edit
            </button>
            <DeleteOutline
              className="userproductddeletebutton"
              style={{ color: "red" }}
              onClick={() => {
                setItem(params.row)
                setChangeModal("delete")
                setOpenModal(!openModal)
              }}
            />
          </div>
        )
      },
    },
  ]
  return (
    <div className="usertender">
      <ProfileSideNav page={"tender"} />
      {renderModal()}
      <div className="usertendermargin">
        <div className="usertendercontainer">
          {errorAlert && (
            <div className="alert">
              {errorAlert && <Alert severity="error">{errorMessage}</Alert>}
            </div>
          )}
          <div className="usertenderheader">
            <span className="usertendertitle">Tenders</span>
            <button
              className="usertendercreatebutton"
              onClick={(e) => {
                setChangeModal("create")
                setOpenModal(!openModal)
              }}
            >
              Create Tender
            </button>
          </div>
          <div className="usertendersearch">
            <div className="usertendersearchicon">
              <OutlinedInput
                size="small"
                id="outlined-adornment-weight"
                startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                style={{ borderRadius: "none" }}
                onChange={(e) => {
                  setSearcedTender(e.target.value)
                }}
              />

              <button
                style={{
                  width: "30%",
                  height: "40px",
                  backgroundColor: "#00aeff",
                  color: "white",
                  border: "none",
                }}
              >
                {" "}
                Search
              </button>
            </div>
            <div className="usertendersortpage">
              <span>Sort By:</span>
              <FormControl sx={{ m: 1, minWidth: "60%" }}>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  autoWidth
                  value={sort}
                  onChange={(e) => setSort(e.target.value)}
                  size="small"
                >
                  <MenuItem value="">
                    {sort ? <em>{sort}</em> : <em>Newest to Oldest</em>}
                  </MenuItem>
                  <MenuItem
                    value="Newest to Oldest"
                    onClick={(e) => setSort(e.target.value)}
                  >
                    <span
                      style={{ fontSize: "14px", fontFamily: "Bellota-Light" }}
                    >
                      Newest to Oldest
                    </span>
                  </MenuItem>
                  <MenuItem
                    value="Oldest to Newest"
                    onClick={(e) => setSort(e.target.value)}
                  >
                    <span
                      style={{ fontSize: "14px", fontFamily: "Bellota-Light" }}
                    >
                      Oldest to Newest
                    </span>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="usertendergridd">
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <DataGrid
                onCellDoubleClick={(row) => navigate("/tender/" + row.row._id)}
                rows={searchedTender ? newRow : tenders}
                columns={columns}
                getRowId={(row) => row._id}
                pageSize={5}
                rowsPerPageOptions={[5]}
                // checkboxSelection
                disableSelectionOnClick
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
