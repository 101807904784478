import "./image.scss"
export default function ImageBox({ src, darker, size }) {
  return (
    <div className="imagebox" style={{ width: "100%", height: "100%" }}>
      <img
        className={size ? "image withborder" : "image"}
        src={src}
        alt=""
        style={{ borderRadiusTop: size, width: "100%", height: "100%" }}
      />
      {darker ? <div className="darker"></div> : <></>}
    </div>
  )
}
