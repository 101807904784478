import { useEffect, useState } from "react"
/// scss class
import "../../../../styles/profile/product/create/createproduct.scss"
/// material ui
import {
  Close,
  FormatQuote,
  Info,
  Inventory,
  PriceChange,
} from "@mui/icons-material"

// border from examples folder
import Border from "../../../../example/borderbottom"

import { MinimizeOutlined, Add, Search } from "@mui/icons-material"
import FormControl from "@mui/material/FormControl"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import { Button, CircularProgress, TextField } from "@mui/material"
import { AccountCircle, Lock, Google } from "@mui/icons-material"
import Alert from "@mui/material/Alert"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { DeleteOutline } from "@mui/icons-material"

//
import { useQuery, useMutation } from "@tanstack/react-query"

//
import { userRequest } from "../../../../constant/requestMethods"

// state dispacther
import { useDispatch, useSelector } from "react-redux"
// router import
import { useNavigate } from "react-router-dom"

// firebase imports to upload the picture
import app from "../../../../constant/fireBase"
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage"

export default function CreateProject({
  setOpenModal,
  openModal,
  setChangeRegister,
}) {
  // mui style for the selsector
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 140,
        width: 250,
      },
    },
  }

  let checked = true
  const navigate = useNavigate()
  // getting the state from the website
  const { isFetching, error, currentUser } = useSelector((state) => state.user)

  // controllers to show alets
  const [alert, setAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  // error checkers
  const [fieldError, setFieldError] = useState(false)
  const [errorOn, setErrorOn] = useState("")

  // vars for the form
  const [input, setInput] = useState({})
  const [cat, setCat] = useState({})
  const [file, setFile] = useState()
  const [fileName, setFileName] = useState([])
  const [details, setDetials] = useState({})
  const [featureName, setFeatureName] = useState([])
  const [features, setFeatures] = useState({})
  const feature = [
    "airConditioning",
    "Balcony",
    "elevator",
    "gym",
    "pool",
    "wifi",
  ]
  const [catagory, setCatagory] = useState([])
  const catagoryfeature = [
    "Engineering and Consultancy",
    "Engineering",
    "Finishing",
    "General Contractors",
    "Graphics & Animation",
    "Landscape Architecture",
    "Photography Studio",
    "Planning",
    "Security",
    "Interior Design",
  ]
  // state to hold the firms after thequery
  const [firm, setFirm] = useState([])

  // state to hold the professionals after thequery
  const [professional, setProfessional] = useState([])

  // state to hold the manufacturer after thequery
  const [manufacturer, setManufacturer] = useState([])

  // holds the userNAMES the
  const [firmName, setFirmName] = useState([])
  const [professionalName, setProfessionalName] = useState([])
  const [manufacturerName, setManufacturerName] = useState([])
  const [manufacturerKey, setManufacturerKey] = useState([])

  //	// trigger for setting if the images failed to load or are success ful
  const [isImageLoading, setIsImageLoading] = useState(false)
  const [hasFailed, setHasFailed] = useState(false)

  // chnages eerytime an image is removed from an array which sets the page to relaoad
  const [deleted, setDeleted] = useState(true)

  // saves the Edited textfields with their name and values
  const handleInput = (e) => {
    setInput((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

  /// function that saves the inputed properties as arrays
  const handleCat = (e) => {
    setCat((prev) => {
      return { ...prev, [e.target.name]: e.target.value.split(/[\r?\n,]/) }
    })
  }

  const handleDisc = (e) => {
    setCat((prev) => {
      return { ...prev, [e.target.name]: e.target.value.split(/[\r?\n]/) }
    })
  }

  const handleDetails = (e) => {
    // console.log(e.target.name, e.target.value)

    setDetials((prev) => {
      {
        return { ...prev, [e.target.name]: e.target.value }
      }
    })
  }

  const handleFeatures = (e) => {
    // console.log(e.target.value)
    e.target.value.map((item) =>
      setFeatures((prev) => {
        return { ...prev, [item]: true }
      })
    )
    const {
      target: { value },
    } = e
    setFeatureName(typeof value === "string" ? value.split(",") : value)
  }

  const handleCatagory = (e) => {
    const {
      target: { value },
    } = e
    setCatagory(typeof value === "string" ? value.split(",") : value)
  }

  const handleFirm = (e) => {
    console.log(e.target.value)
    console.log(e.target.key)
    const {
      target: { value },
    } = e
    setFirmName(typeof value === "string" ? value.split(",") : value)
  }

  const handleManufacturer = (e) => {
    console.log(e.target)
    console.log(e.target.name)

    const {
      target: { value, key, name },
    } = e
    setManufacturerName(typeof value === "string" ? value.split(",") : value)
    setManufacturerKey(typeof name === "string" ? name.split(",") : name)
    // console.log(manufacturerName)
  }

  const handleProfessional = (e) => {
    console.log(e.target.value)
    console.log(e.target.key)

    const {
      target: { value },
    } = e
    setProfessionalName(typeof value === "string" ? value.split(",") : value)
  }

  // function to upload images
  const handleClick = async (e) => {
    // e.prev""entDefault()

    var streetsofafruce = {}

    streetsofafruce = [...e.target.files]
    streetsofafruce.map(async (item, index) => {
      setFile(streetsofafruce)

      const fileName = new Date().getTime() + item.name
      const storage = getStorage(app)
      const storageReff = ref(storage, fileName)
      const uploadTask = uploadBytesResumable(storageReff, item)
      console.log(uploadTask)
      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      await uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log("Upload is " + progress + "% done")
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused")
              break
            case "running":
              console.log("Upload is running")
              break
          }
          setIsImageLoading(true)
        },
        (error) => {
          setIsImageLoading(false)
          setHasFailed(false)
          // Handle unsuccessful uploads
        },
        () => {
          setIsImageLoading(false)
          setHasFailed(false)
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            var lio = downloadURL

            setFileName((oldone) => [...oldone, downloadURL])
          })

          fileName &&
            fileName.map((item, index) => {
              if (!item) {
                fileName.slice(index, 1)
              }
            })
        }
      )
    })
  }
  // functions that runs when the page is loaded
  // sends a request to the server for the firm data
  /* const findFirms = useQuery({
    queryKey: ["findfirms"],
    queryFn: async () => {
      setAlert(false)
      setErrorAlert(false)
      setErrorMessage("")
      try {
        const reply = await userRequest.get(
          `firm/find/firmpage?page=1&limit=100`
        )
        // const reply = await userRequest.get(`firm/find/firmpage`)
        let result = reply.data.data.result
        // console.log(reply)
        result.map((item) => {
          firm.push(item)
        })
        // let next = reply.data.data.next
        // setNextPage(next)

        return reply
      } catch (e) {
        setAlert(true)
        setErrorAlert(true)
        setErrorMessage(e.message)
        console.log(e.message)
        return e
      }
    },
    cacheTime: Infinity,
    staleTime: Infinity,
  })

  // functions that runs when the page is loaded
  // sends a request to the server for the manufacturer data
  const findManufacturers = useQuery({
    queryKey: ["findManufacturers"],
    queryFn: async () => {
      setAlert(false)
      setErrorAlert(false)
      setErrorMessage("")
      try {
        const reply = await userRequest.get(
          `manufacturer/find/manufacturerpage?page=1&limit=100`
        )

        let result = reply.data.data.result
        // console.log(reply)
        result.map((item) => {
          manufacturer.push(item)
        })

        return reply
      } catch (e) {
        setAlert(true)
        setErrorAlert(true)
        setErrorMessage(e.message)
        console.log(e.message)
        return e
      }
    },
    cacheTime: Infinity,
    staleTime: Infinity,
  })

  // functions that runs when the page is loaded
  // sends a request to the server for the Professional data
  const findProfessionals = useQuery({
    queryKey: ["findProfessionals"],
    queryFn: async () => {
      setAlert(false)
      setErrorAlert(false)
      setErrorMessage("")

      try {
        const reply = await userRequest.get(
          `professional/find/professionalpage?page=1&limit=100`
        )
        // const reply = await publicRequest.get(
        //   `professional/find/professionalpage`
        // )
        let result = reply.data.data.result
        // console.log(reply)
        result.map((item) => {
          professional.push(item)
        })

        return reply
      } catch (e) {
        setAlert(true)
        setErrorAlert(true)
        setErrorMessage(e.message)
        console.log(e.message)
        return e
      }
    },
    cacheTime: Infinity,
    staleTime: Infinity,
  }) */

  // endpoint to edit project
  const createProject = async (data) => {
    const reply = await userRequest.post(
      `/project/newproject/${currentUser.data._id}`,
      data
    )
    return reply.data
  }
  const { mutate, isLoading } = useMutation(createProject, {
    onSuccess: (data) => {
      setAlert(true)
      setSuccessAlert(true)
      // console.log(data);
      setSuccessMessage(data.msg)
    },
    onError: (e) => {
      setAlert(true)
      setErrorAlert(true)
      setErrorMessage(e.message)
    },
  })

  const createHandler = () => {
    // console.log(featureName, features)
    const { city } = cat
    const region = { city }
    const updates = {
      ...input,
      ...cat,
      image: fileName,
      details,
      features,
      region,
      catagory,
    }
    setAlert(false)
    setErrorAlert(false)
    setSuccessAlert(false)
    setErrorMessage("")
    setSuccessMessage("")
    // console.log(updates)
    mutate(updates)
  }

  // useEffect to reload when a pic is deleted
  useEffect(() => {}, [fileName, deleted])

  return (
    <div
      className="rendercreateproduct"
      style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        left: "50%",
        top: "50%",
        // height: "90%",
        width: "60.4%",
        transform: "translate(-50%, -50%)",
        outline: "none",
        opacity: "1",
        backgroundColor: "white",
        border: "none",
      }}
    >
      <div className="createproductheader">
        <div className="createproducttext">
          <span>Create New Project</span>
        </div>

        {/* <div className='verticallines'></div> */}

        {/* <div className='verticallines'></div> */}
        {/* <div className='verticallines'></div> */}

        <button
          onClick={(e) => {
            setOpenModal(!openModal)
          }}
        >
          <Close />
        </button>
      </div>
      {alert && (
        <div className="alert">
          {errorAlert && <Alert severity="error">{errorMessage}</Alert>}
          {successAlert && <Alert severity="success">{successMessage}</Alert>}
        </div>
      )}
      <div className="createproductimage">
        <div className="createproductimageplus">
          <div className="laketitle">
            <span>Image</span>
          </div>
          <div className="ollio">
            <input
              type="file"
              multiple="multiple"
              onChange={
                (e) =>
                  handleClick(e) /*paperback(e)setFile(e.target.files[0]) */
              }
            />
          </div>
        </div>
        <div
          className="fetchspinning"
          style={{ display: "flex", alignItems: "center" }}
        >
          {isImageLoading ? (
            <div style={{ marginRight: "1%" }}>
              <CircularProgress />
            </div>
          ) : (
            <></>
          )}
          {hasFailed ? (
            <span style={{ fontSize: "12px", color: "red" }}>
              {" "}
              Error. Try to upload the file again
            </span>
          ) : (
            <></>
          )}
          <div
            className="iplayedmyfair"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {fileName.length >= 1 ? (
              fileName.map((item, index) => (
                <div
                  style={{ display: "flex", flexDirection: "row" }}
                  key={index + 33}
                >
                  <img
                    src={item}
                    alt=""
                    style={{
                      height: "100px",
                      width: "100px",
                      backgroundColor: "#dce0e0",
                      objectFit: "cover",
                      marginRight: "2%",
                    }}
                  />
                  <DeleteOutline
                    style={{
                      position: "relative",
                      color: "red",
                      fontSize: "25px",
                      left: "-10%",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      // console.log(index)
                      fileName.splice(index, 1)
                      setDeleted(!deleted)
                    }}
                  />
                </div>
              ))
            ) : (
              <span></span>
            )}
          </div>
        </div>
      </div>
      <div className="createproductmiddle">
        <TextField
          // placeholder={product.title}
          size="small"
          className="createproducttextfield"
          label="Title"
          variant="outlined"
          name="title"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FormatQuote />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleInput(e)
          }}
        />
        <TextField
          // placeholder={product.title}
          size="small"
          className="createproducttextfield"
          label="Project Complition"
          variant="outlined"
          name="yearBuilt"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FormatQuote />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleDetails(e)
          }}
        />
        <TextField
          // placeholder={product.title}
          size="small"
          className="createproducttextfield"
          label="Client"
          variant="outlined"
          name="client"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FormatQuote />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            // console.log(parseInt(e.target.value))
            handleInput(e)
          }}
        />
        <TextField
          // placeholder={product.title}
          size="small"
          className="createproducttextfield"
          label="Area"
          variant="outlined"
          name="area"
          type="string"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FormatQuote />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleDetails(e)
          }}
        />
        <TextField
          // placeholder={product.title}
          size="small"
          className="createproducttextfield"
          label="City"
          variant="outlined"
          name="city"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FormatQuote />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleCat(e)
          }}
        />
        <TextField
          size="small"
          className="createproducttextfield"
          label="Discription"
          variant="outlined"
          // placeholder={product.discription && product.discription[0]}
          multiline
          rows={4}
          name="discription"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Info />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleDisc(e)
          }}
        />
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-name-label">Catagory</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={catagory}
            onChange={(e) => {
              handleCatagory(e)
            }}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
          >
            {catagoryfeature.map((name) => (
              <MenuItem
                key={name}
                value={name}
                // style={getStyles(name, personName, theme)}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-name-label">Features</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={featureName}
            onChange={(e) => {
              handleFeatures(e)
            }}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
          >
            {feature.map((name) => (
              <MenuItem
                key={name}
                value={name}
                // style={getStyles(name, personName, theme)}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-name-label">Firms</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={firmName}
            onChange={(e) => {
              handleFirm(e)
            }}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
          >
            {firm.map((name) => (
              <MenuItem
                key={name._id}
                value={name.userName}
                // style={getStyles(name, personName, theme)}
              >
                {name.userName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-name-label">Manufacturers</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            name={manufacturerKey}
            value={manufacturerName}
            key={manufacturerKey}
            onChange={(e) => {
              console.log(e.target.value)
              console.log(e.target.name)
              handleManufacturer(e)
            }}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
          >
            {manufacturer.map((name) => (
              <MenuItem
                name={name._id}
                key={name._id}
                value={name.userName}
                onClick={(e) => {
                  setManufacturerKey(
                    typeof e.target.name === "string"
                      ? e.target.name.split(",")
                      : e.target.name
                  )
                }}
                // style={getStyles(name, personName, theme)}
              >
                {name.userName}
              </MenuItem>
            ))}
          </Select>
        </FormControl> 
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-name-label">Professionals</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={professionalName}
            onChange={(e) => {
              handleProfessional(e)
            }}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
          >
            {professional.map((name) => (
              <MenuItem
                key={name._id}
                value={name.userName}
                // style={getStyles(name, personName, theme)}
              >
                {name.userName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        */}
      </div>

      <div className="createproductlast">
        <Button
          className="createproductbutton"
          onClick={(e) => createHandler()}
          disabled={
            isFetching || isLoading || !input.title || fileName.length < 1
          }
          // || fileName.length < 1}
        >
          {isFetching || isImageLoading || isLoading ? (
            <CircularProgress
              style={{ color: "white", width: "20px", height: "20px" }}
            />
          ) : (
            <>Create</>
          )}
        </Button>
        {/* <div className="logingoogle">
          <Google style={{ width: "20%", fontSize: "20px", border: " 1px solid #3c8daa", height: "10%" }} />
          <button className="googlebutton"> Sign In with Google</button>
        </div> */}
      </div>
    </div>
  )
}
