import { useState, useEffect, useCallback, useRef } from "react"

// css class
import "../../../styles/pages/manufacturer/manu.scss"
//mui
import { TextField } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import Alert from "@mui/material/Alert"

import Border from "../../../example/borderbottom"
import { MinimizeOutlined, Add, Search } from "@mui/icons-material"
// Manufacturer outlook
import ManuCard from "./card"
// colored boxes for the catagories
import ColoredBox from "../../../example/coloredBox"

import { useQuery } from "@tanstack/react-query"

// request sender
import { publicRequest } from "../../../constant/requestMethods"

// mui
import { CircularProgress } from "@mui/material"

import { useLocation } from "react-router"
import Footer from "../../display/footer"

export default function Manufacturers() {
  const location = useLocation()

  const id = location.pathname.split("/")[1]

  //state vars to hold the manufacturers and nexpage if it there
  const [manufacturer, setManufacturer] = useState([])
  const [nextPage, setNextPage] = useState([])
  // const [filterMan, setFilterMan] = useState([])
  // controllers to show alets
  const [alert, setAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  // to store the values of the search
  const [search, setSearch] = useState("")
  const [filterMan, setFilterMan] = useState([])

  const [pageNumber, setPageNumber] = useState(1)
  const observer = useRef()

  // catagory for manufacturer
  const manufacturerCatagory = [
    "Decoration & Accessories",
    "Furniture",
    "Kitchen & Baths",
    "Lighting",
    "Material",
    "Painting",
    "Roofing & Gutter",
    "Wall & Floor",
  ]
  const [cat, setCat] = useState("")
  const [cataPressed, setCataPressed] = useState(true)
  const [searchPressed, setSearchPressed] = useState(true)
  const [checked, setChecked] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ])

  // state to change the image of the catagories when pressed
  const [clicked, setOnClicked] = useState("")

  // stores the catagory name
  const [catName, setCatName] = useState("")

  // shows the items numbers when the filteres are pressed
  const [filterPressed, setFilterPressed] = useState(false)

  // functions that runs when the page is loaded
  // sends a request to the server for the manufacturer data
  const findManufacturers = useQuery({
    queryKey: ["findManufacturers"],
    queryFn: async () => {
      try {
        const reply = await publicRequest.get(
          `manufacturer/find/manufacturerpage?page=${pageNumber}&limit=10`
        )

        let result = reply.data.data.result
        // console.log(reply)
        result.map((item) => {
          manufacturer.push(item)
        })
        let next = reply.data.data.next
        setNextPage(next)

        if (manufacturer && manufacturer.length > 0) {
          setManufacturer([
            ...new Map(
              manufacturer.map((obj) => [`${obj._id}:${obj.title}`, obj])
            ).values(),
          ])
        }
        return reply
      } catch (e) {
        setAlert(true)
        setErrorAlert(true)
        setErrorMessage(e.message)
        console.log(e.message)
        return e
      }
    },
    cacheTime: Infinity,
    staleTime: Infinity,
  })

  const lastBlogElement = useCallback(
    (node) => {
      if (findManufacturers.isLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && nextPage) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1)
          console.log(pageNumber)
        }
      })
      if (node) observer.current.observe(node)
    },
    [
      findManufacturers.isLoading,
      nextPage,
      /* Todo setup a state to check it there are any more blogs to be seen
      and make is loading a dependency */
    ]
  )
  // check and remove the duplicate in teh array
  const check = () => {
    if (manufacturer && manufacturer.length > 0) {
      setManufacturer([
        ...new Map(
          manufacturer.map((obj) => [`${obj._id}:${obj.title}`, obj])
        ).values(),
      ])
      // console.log("my eople")
    }
  }
  const handleChange = (event, index) => {
    setCat(event.target.name)
    setChecked([false, false, false, false, false, false, false, false, false])
    checked.map(() => {
      if (index === 0) {
        setChecked([true, false, false, false, false, false, false, false])
      } else if (index === 1) {
        setChecked([false, true, false, false, false, false, false, false])
      } else if (index === 2) {
        setChecked([false, false, true, false, false, false, false, false])
      } else if (index === 3) {
        setChecked([false, false, false, true, false, false, false, false])
      } else if (index === 4) {
        setChecked([false, false, false, false, true, false, false, false])
      } else if (index === 5) {
        setChecked([false, false, false, false, false, true, false, false])
      } else if (index === 6) {
        setChecked([false, false, false, false, false, false, true, false])
      } else if (index === 7) {
        setChecked([false, false, false, false, false, false, false, true])
      }
    })
  }

  //  when the search bar is is inputed the rows change
  const findManufacturer = () => {
    const something = []
    if (search) {
      manufacturer.map((item, index) => {
        const doe = item.userName.toLowerCase()
        if (!doe.indexOf(search.toLowerCase())) {
          something.push(item)
        }
        return something
      })
    }
    return something
  }

  const handleFilter = () => {
    let something = []
    if (clicked) {
      manufacturer.filter(function (node) {
        node.manufacturerData[0] &&
          node.manufacturerData[0].catagory.map((catagory) => {
            if (catagory === clicked.toLowerCase()) {
              something.push(node)
              // console.log(filterMan)
            }
          })
      })
      return something
    }

    return something
  }
  const foundManufacturers = findManufacturer()
  const foundFilter = handleFilter()
  // console.log(filterMan)

  // runs when the ageNumber changes and that happens whtn the user gets to the last item
  useEffect(() => {
    // console.log("page changer")
    findManufacturers.refetch()
    if (manufacturer && manufacturer.length > 0) {
      setManufacturer([
        ...new Map(
          manufacturer.map((obj) => [`${obj._id}:${obj.title}`, obj])
        ).values(),
      ])
    }
  }, [pageNumber])

  // runs to delete duplicated profsesionals

  // here to check if the user got back to the page and the data wont' be lost
  useEffect(() => {
    findManufacturers.refetch()
    check()
  }, [id])

  console.log(manufacturer)
  return (
    <div className="manufacturer">
      <div className="insidemanufacturer">
        {/* <div className='header'>
          <Locator header={"Home"} icon={"kj"} />
          <Locator span={"Manufacturers"} />
        </div> */}
        {errorAlert && (
          <div className="alert">
            {errorAlert && <Alert severity="error">{errorMessage}</Alert>}
          </div>
        )}

        <div className="multiplemanufacturer">
          <div className="leftpartmanufacturer">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <span className="manufacturerttilte">
                {catName && catName + " "}Manufacturers
              </span>
              {filterPressed && (
                <span style={{ fontSize: "18", fontFamily: "Bellota-Bold" }}>
                  Manufacturers Found {foundFilter.length}
                </span>
              )}
            </div>
            <div className="singelmanufacturercontainer">
              {findManufacturers.isLoading ? (
                <div
                  style={{
                    width: "80dvw",
                    height: "100dvh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : // <></>
              foundManufacturers && foundManufacturers.length > 0 ? (
                foundManufacturers.map((item, index) => (
                  <div className="singualrmanufacturer" key={index + 23}>
                    <ManuCard
                      id={item._id}
                      title={item.userName}
                      type={
                        item.catagory && item.catagory.length > 0
                          ? item.catagory.map((item) => {
                              return item + ", "
                            })
                          : ""
                      }
                      image={item.profilepic}
                    />
                  </div>
                ))
              ) : foundFilter && foundFilter.length > 0 ? (
                foundFilter.map((item, index) => (
                  <div className="singualrmanufacturer" key={index + 23}>
                    <ManuCard
                      id={item._id}
                      title={item.userName}
                      type={
                        item.catagory && item.catagory.length > 0
                          ? item.catagory.map((item) => {
                              return item + ", "
                            })
                          : ""
                      }
                      image={item.profilepic}
                    />
                  </div>
                ))
              ) : (
                manufacturer.map((item, index) => {
                  if (manufacturer.length === index + 1) {
                    return (
                      <div
                        className="singualrmanufacturer"
                        ref={lastBlogElement}
                        key={index + 23}
                      >
                        <ManuCard
                          id={item._id}
                          title={item.userName}
                          type={
                            item.catagory && item.catagory.length > 0
                              ? item.catagory.map((item) => {
                                  return item + ", "
                                })
                              : ""
                          }
                          image={item.profilepic}
                        />
                      </div>
                    )
                  } else {
                    return (
                      <div className="singualrmanufacturer" key={index + 23}>
                        <ManuCard
                          id={item._id}
                          title={item.userName}
                          type={
                            item.catagory && item.catagory.length > 0
                              ? item.catagory.map((item) => {
                                  return item + ", "
                                })
                              : ""
                          }
                          image={item.profilepic}
                        />
                      </div>
                    )
                  }
                })
              )}
            </div>
          </div>
          <div className="rightpartmanufacturer">
            <div className="serachmanufacturers"></div>
            <div className="prosidenav">
              <span
                className="serachmanufacturers"
                style={{ fontSize: "23px", fontFamily: "Bellota-Bold" }}
              >
                Filter
              </span>
              <div className="manufacturercatagories">
                <Border
                  first={"Catagory"}
                  second={
                    cataPressed ? (
                      <Add
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          setCataPressed(!cataPressed)
                          setCat("")
                          setOnClicked("")
                          setCatName("")
                          setFilterPressed(false)
                          setChecked([
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                          ])
                        }}
                      />
                    ) : (
                      <MinimizeOutlined
                        style={{ cursor: "pointer" }}
                        onClick={(e) => setCataPressed(!cataPressed)}
                      />
                    )
                  }
                />
                {cataPressed && (
                  <FormControl
                    className="close"
                    sx={{ m: 1 }}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormGroup>
                      {manufacturerCatagory.map((item, index) => (
                        <FormControlLabel
                          key={index + 98676}
                          style={{
                            fontSize: "12",
                            fontFamily: "Bellota-Light",
                          }}
                          control={
                            <Checkbox
                              checked={checked[index]}
                              onChange={(e) => {
                                setOnClicked(e.target.name)
                                setCatName(e.target.name)
                                setFilterPressed(true)
                                handleChange(e, index)
                              }}
                              name={item}
                              style={{
                                transform: "scale(0.7)",
                                width: "10px",
                                height: "10px",
                              }}
                              // className="checkbox"
                            />
                          }
                          label={
                            <span
                              style={{
                                fontSize: "12px",
                                fontFamily: "Bellota-Light",
                              }}
                            >
                              {item}
                            </span>
                          }
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                )}
              </div>
              <div className="catasidenav">
                {searchPressed && (
                  <FormControl sx={{ m: 1, width: "90%" }} variant="outlined">
                    <OutlinedInput
                      size="small"
                      id="outlined-adornment-weight"
                      endAdornment={
                        <InputAdornment position="end">
                          <Search />
                        </InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                      onChange={(e) => {
                        setSearch(e.target.value)
                      }}
                    />
                  </FormControl>
                )}
              </div>
              <div className="catasidenav">
                <button
                  style={{
                    width: "90%",
                    height: "40px",
                    marginBottom: "5%",
                    backgroundColor: "#00aeff",
                    color: "white",
                    border: "none",
                  }}
                >
                  {" "}
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}
