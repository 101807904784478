// csss claass
import "./border.scss";
export default function Border({ first, second, style }) {
  return (
    <div className="border">
      <span className="firstborder" style={style}>
        {first}
      </span>
      <span className="secondborder" style={style}>
        {second}
      </span>
    </div>
  );
}
