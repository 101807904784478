// css class
import "./singletender.scss"

import { Link } from "react-router-dom"
export default function TenderCard({ title, date, country, tot, id }) {
  return (
    <Link to={`/tender/${id}`} className="link">
      {/* <div className="card"> */}
      <div className="cardcontainer">
        <div className="higher">
          <span className="cardtitle">{title}</span>
          <div className="carddate">
            <span className="smaller">Deadline: </span>
            <span className="bolder">{date}</span>
          </div>
        </div>
        <div className="lower">
          <span>{country}</span>
          <span>{tot}</span>
          <Link to={`/tender/${id}`} className="link">
            <button className="linkbutton">View Details</button>
          </Link>
        </div>
      </div>
      {/* </div> */}
    </Link>
  )
}
