import { useEffect, useState } from "react"
/// scss class
import "../../../../styles/profile/tender/create/createtender.scss"
/// material ui
import {
  Close,
  FormatQuote,
  Info,
  Inventory,
  PriceChange,
} from "@mui/icons-material"
import { MinimizeOutlined, Add, Search } from "@mui/icons-material"
import FormControl from "@mui/material/FormControl"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import { Button, CircularProgress, TextField } from "@mui/material"
import Alert from "@mui/material/Alert"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { DeleteOutline } from "@mui/icons-material"

//
import { useQuery, useMutation } from "@tanstack/react-query"

//
import { userRequest } from "../../../../constant/requestMethods"

// state dispacther
import { useDispatch, useSelector } from "react-redux"
// router import
import { useNavigate } from "react-router-dom"

export default function CreateTender({
  setOpenModal,
  openModal,
  setChangeRegister,
}) {
  // mui style for the selsector
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 140,
        width: 250,
      },
    },
  }

  let checked = true
  const navigate = useNavigate()
  // getting the state from the website
  const { isFetching, error, currentUser } = useSelector((state) => state.user)

  // controllers to show alets
  const [alert, setAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  // error checkers
  const [fieldError, setFieldError] = useState(false)
  const [errorOn, setErrorOn] = useState("")

  // vars for the form
  const [input, setInput] = useState({})
  const [cat, setCat] = useState({})
  const [file, setFile] = useState()
  const [fileName, setFileName] = useState([])
  const [catagory, setCatagory] = useState([])
  const feature = [
    "Decoration & Accessories",
    "Furniture",
    "Kitchen & Baths",
    "Lighting",
    "Materials",
    "Painting",
    "Roofing & Gutter",
    "Wall & Floor",
  ]

  //	// trigger for setting if the images failed to load or are success ful
  const [isImageLoading, setIsImageLoading] = useState(false)
  const [hasFailed, setHasFailed] = useState(false)

  // chnages eerytime an image is removed from an array which sets the page to relaoad
  const [deleted, setDeleted] = useState(true)

  // saves the Edited textfields with their name and values
  const handleInput = (e) => {
    setInput((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

  /// function that saves the inputed properties as arrays
  const handleCat = (e) => {
    setCat((prev) => {
      return { ...prev, [e.target.name]: e.target.value.split(/[\r?\n,]/) }
    })
  }

  const handleDisc = (e) => {
    setCat((prev) => {
      return { ...prev, [e.target.name]: e.target.value.split(/[\r?\n]/) }
    })
  }

  const handleCatagory = (e) => {
    // console.log(e.target.value)
    const {
      target: { value },
    } = e
    setCatagory(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    )
  }

  // endpoint to edit tender
  const createtender = async (data) => {
    const reply = await userRequest.post(
      `/tender/newtender/${currentUser.data._id}`,
      data
    )
    return reply.data
  }
  const { mutate, isLoading } = useMutation(createtender, {
    onSuccess: (data) => {
      setAlert(true)
      setSuccessAlert(true)
      // console.log(data);
      setSuccessMessage(data.msg)
    },
    onError: (e) => {
      setAlert(true)
      setErrorAlert(true)
      setErrorMessage(e.message)
    },
  })

  const createHandler = () => {
    // console.log(catagory, features)
    let { city } = input
    let region = { city }

    const updates = {
      ...input,
      ...cat,
      region,
      catagory,
    }
    setAlert(false)
    setErrorAlert(false)
    setSuccessAlert(false)
    setErrorMessage("")
    setSuccessMessage("")
    // console.log(updates)
    mutate(updates)
  }

  // console.log(fileName)
  return (
    <div
      className="rendercreatetender"
      style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        left: "50%",
        top: "50%",
        // height: "",
        width: "60.4%",
        transform: "translate(-50%, -50%)",
        outline: "none",
        opacity: "1",
        backgroundColor: "white",
        border: "none",
        // overflow: "auto",
      }}
    >
      <div className="createtenderheader">
        <div className="createtendertext">
          <span>Create New Tender</span>
        </div>

        {/* <div className='verticallines'></div> */}

        {/* <div className='verticallines'></div> */}
        {/* <div className='verticallines'></div> */}

        <button
          onClick={(e) => {
            setOpenModal(!openModal)
          }}
        >
          <Close />
        </button>
      </div>
      {alert && (
        <div className="alert">
          {errorAlert && <Alert severity="error">{errorMessage}</Alert>}
          {successAlert && <Alert severity="success">{successMessage}</Alert>}
        </div>
      )}

      <div className="createtendermiddle">
        <TextField
          // placeholder={tender.title}
          size="small"
          className="createtendertextfield"
          label="Title"
          variant="outlined"
          name="title"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FormatQuote />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleInput(e)
          }}
        />
        <TextField
          // placeholder={tender.title}
          size="small"
          className="createtendertextfield"
          label="Country"
          variant="outlined"
          name="country"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FormatQuote />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleInput(e)
          }}
        />
        <TextField
          // placeholder={tender.title}
          size="small"
          className="createtendertextfield"
          label="TOT Ref.No."
          variant="outlined"
          name="tot"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FormatQuote />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleInput(e)
          }}
        />
        <TextField
          // placeholder={tender.title}
          size="small"
          className="createtendertextfield"
          label="City"
          variant="outlined"
          name="city"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FormatQuote />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleInput(e)
          }}
        />
        <TextField
          // placeholder={tender.title}
          size="small"
          className="createtendertextfield"
          label="Deadline"
          variant="outlined"
          name="deadline"
          type="date"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FormatQuote />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleInput(e)
          }}
        />

        <TextField
          size="small"
          className="createtendertextfield"
          label="Discription"
          variant="outlined"
          // placeholder={tender.discription && tender.discription[0]}
          multiline
          rows={4}
          name="discription"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Info />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleDisc(e)
          }}
        />

        {/* <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-name-label">Catagory</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={catagory}
            onChange={(e) => {
              handleCatagory(e)
            }}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
          >
            {feature.map((name) => (
              <MenuItem
                key={name}
                value={name}
                // style={getStyles(name, personName, theme)}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
      </div>
      <div className="createtenderlast">
        <Button
          className="createtenderbutton"
          onClick={(e) => createHandler()}
          disabled={isFetching || isLoading || !input.title}
          //
          // }
          // || fileName.length < 1}
        >
          {isFetching || isImageLoading || isLoading ? (
            <CircularProgress
              style={{ color: "white", width: "20px", height: "20px" }}
            />
          ) : (
            <>Create</>
          )}
        </Button>
        {/* <div className="logingoogle">
          <Google style={{ width: "20%", fontSize: "20px", border: " 1px solid #3c8daa", height: "10%" }} />
          <button className="googlebutton"> Sign In with Google</button>
        </div> */}
      </div>
    </div>
  )
}
