import { useState, useEffect } from "react"
/// css Class
import "./productmodal.scss"
// mui icons
import {
  LocationOn,
  FavoriteBorder,
  Close,
  UnfoldMore,
  NavigateBefore,
  NavigateNext,
  SingleBed,
  SquareFoot,
  Shower,
  AttachFile,
  GarageSharp,
} from "@mui/icons-material"
import {
  Star,
  StarOutlineSharp,
  Add,
  MinimizeOutlined,
} from "@mui/icons-material"

//
import { Link } from "react-router-dom"

// mui
import Select, { SelectChangeEvent } from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"

// boderder layout
import Border from "../borderbottom"
import Box from "../box"
import Rooms from "../rooms"

// for add to product function
import { useDispatch, useSelector } from "react-redux"
import { addProduct } from "../../redux/cartRedux"

// another modal that apperas when user successfullly add the product
import CartModal from "../cartmodal"

import { useQuery, useMutation } from "@tanstack/react-query"

// request sender
import { publicRequest, userRequest } from "../../constant/requestMethods"

// mui
import { CircularProgress } from "@mui/material"
import Alert from "@mui/material/Alert"

export default function ItemModal({ open, setOpen, product }) {
  // holds state for the user from the browser storage
  const { currentUser } = useSelector((state) => state.user)

  const dispatch = useDispatch()
  // console.log(product)
  // controllers to show alets
  const [alert, setAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  // const id = product.name.split("/")[2]
  const [item, setItem] = useState()
  // console.log(product)

  // TODO the image swipper should go through an array of images instead of swipping.
  // to fiill the image by hiding the details when true
  const [full, setFull] = useState(false)

  // mekes the discription section bigger
  const [discriptionPressed, setDiscriptionPressed] = useState(false)

  // makes the specificaation section bigger
  const [specificationPressed, setSpecificationPressed] = useState(false)

  const [openCartModal, setOpenCartModal] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const [color, setColor] = useState("")
  const [size, setSize] = useState("")

  // state to get the review value
  const [value, setValue] = useState(0)
  const [comment, setComment] = useState("")

  // state to check if user is a pro, manufacturer,or firm
  const [userType, setUserType] = useState("firm")

  // state to hold the link to get the user
  const [linker, setLinker] = useState("/")

  // holds the average computerd from the third request
  const [reviewAvrg, setReviewAvrg] = useState(0)

  // changes everytime a user reviews a product to load the pages agian
  const [isReviewed, setIsReviewed] = useState(false)

  // state to hold the alue of the image before it slides to the next or prev
  const [count, setCount] = useState(0)

  // state to hold the image link
  const [image, setImage] = useState("")

  // fetches a request to get the specified product sent from the selling products page
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["findSingleProductModal"],
    queryFn: async () => {
      setAlert(false)
      setErrorAlert(false)
      setErrorMessage("")
      try {
        // await userRequest.get("/blog/find?page=1&limit=5")
        const reply = await publicRequest.get(`/product/find/${product}`)
        if (reply.status === 201) {
          // console.log(reply.data.data)
          reply.data.data &&
            reply.data.data.length > 0 &&
            setItem(reply.data.data[0])

          reply.data.data &&
            reply.data.data.length > 0 &&
            reply.data.data[0].avgRating &&
            setReviewAvrg(reply.data.data[0].avgRating)

          reply.data.data &&
            reply.data.data.length > 0 &&
            reply.data.data[0].image &&
            reply.data.data[0].image.length > 0 &&
            setImage(reply.data.data[0].image[0])
          // console.log(reply.data.data[0].image)
          // setImage(reply.data.data[0].image[0])
          if (
            reply.data.data &&
            reply.data.data[0] &&
            reply.data.data[0].userInfo &&
            reply.data.data[0].userInfo.firm &&
            reply.data.data[0].userInfo.firm.length > 0
          ) {
            setUserType("firm")
            setLinker("/firm/" + reply.data.data[0].userInfo.userId)
          } else if (
            reply.data.data &&
            reply.data.data[0] &&
            reply.data.data[0].userInfo &&
            reply.data.data[0].userInfo.manufacturer &&
            reply.data.data[0].userInfo.manufacturer.length > 0
          ) {
            setUserType("manufacturer")
            setLinker("/manufacturer/" + reply.data.data[0].userInfo.userId)
          } else if (
            reply.data.data &&
            reply.data.data[0] &&
            reply.data.data[0].userInfo &&
            reply.data.data[0].userInfo.professional &&
            reply.data.data[0].userInfo.professional.length > 0
          ) {
            setUserType("professional")
            setLinker("/professional/" + reply.data.data[0].userInfo.userId)
          }

          // setNewDate(formatDistance(new Date(blog.createdAt), new Date()))
        } else {
          setAlert(true)
          setErrorAlert(true)
          setErrorMessage("couldn't get product")
        }
        return reply
      } catch (e) {
        setAlert(true)
        setErrorAlert(true)
        setErrorMessage(e.message)
        console.log(e.message)
        return e
      }
    },

    cacheTime: Infinity,
    staleTime: Infinity,
  })

  // endpoint to rate product
  const createReview = async (data) => {
    const reply = await userRequest.put(
      `product/rateproduct/${currentUser.data._id}/${product._id}`,
      data
    )
    return reply.data
  }
  const rateProduct = useMutation(createReview, {
    onSuccess: (data) => {
      setAlert(true)
      setSuccessAlert(true)
      // console.log(data)
      setSuccessMessage(data.msg)
      setIsReviewed(!isReviewed)
    },
    onError: (e) => {
      setAlert(true)
      setErrorAlert(true)
      setErrorMessage(e.message)
    },
  })

  // create or update review
  const handleReview = (number, message) => {
    // console.log("tje off season")
    setAlert(false)
    setErrorAlert(false)
    setSuccessAlert(false)
    setErrorMessage("")
    setSuccessMessage("")
    let reviewed
    if (value && comment) {
      reviewed = { value: number, comment: message }
    } else if (value && !comment) {
      reviewed = { value: number }
    } else if (!value && comment) {
      reviewed = { comment: message }
    }

    if (currentUser && currentUser.data && currentUser.data._id) {
      rateProduct.mutate(reviewed)
    } else {
      setAlert(true)
      setErrorAlert(true)
      setSuccessAlert(false)
      setErrorMessage("login to rate product")
    }
  }

  // runs when user presses the add to cart button on the modal
  const handleClick = async () => {
    item && item.color ? setColor(item.color) : setColor("")
    item && item.size && item.size.length > 0
      ? setSize(item.size[0])
      : setSize("")
    const reply = await dispatch(addProduct({ ...item, quantity, color, size }))
    // console.log(reply)
    if (reply.payload) {
      setOpenCartModal(!openCartModal)
    }

    // setOpen(!open)
    // console.log(usedColor)
  }

  /// movies the box one step to the left
  const handleMoveLeft = (number) => {
    if (count === 0) {
      // console.log("first")
      setCount(0)
    } else if (count >= 1) {
      // console.log(product.image.length, count)

      setCount(number)

      // setPercent(percent+(0.6+count));
      // setCount(0.5-(slider/10));
    }
  }
  // moves the box one item to the right
  const handleMoveRight = (number) => {
    if (count < item.image.length) {
      if (count === 0) {
        setCount(1)
      }
      // console.log(product.image.length, count)
      setCount(number)
    } else {
      // console.log("last")
      setCount(0)

      // setPercent(6);
    }
  }

  // refetches the query when open modal changes
  useEffect(() => {
    // console.log("on open")
    refetch()
    // console.log(item)
  }, [open])
  useEffect(() => {
    // console.log("onproduct")
    refetch()
  }, [product])

  // console.log(item)

  if (openCartModal) {
    return (
      <CartModal
        img={item && item.image[0]}
        openModal={openCartModal}
        setOpenModal={setOpenCartModal}
      />
    )
  } else {
    return (
      <div className="productitemmodal">
        <div className="productheader">
          <div className="producttitle">
            <LocationOn style={{ fontSize: "32px", color: "#2596be" }} />
            <h3>houzez</h3>
          </div>
          <div className="productclose">
            <div className="productfav">
              <FavoriteBorder className="productlike" />
              <span>Favorite</span>
            </div>
            <Close
              style={{ color: "white", cursor: "pointer" }}
              onClick={(e) => {
                setItem({})
                setOpen(!open)
              }}
            />
          </div>
        </div>

        {isLoading ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {errorAlert && (
              <div className="alert">
                {errorAlert && <Alert severity="error">{errorMessage}</Alert>}
              </div>
            )}
            <div className={full ? "productmiddle true" : "productmiddle"}>
              <div className="productimagecontainer">
                <div className="biggerimage">
                  {image ? (
                    <img src={image ? image : item.image[0]} alt="" />
                  ) : (
                    <img
                      src={
                        item && item.image && item.image.length > 0
                          ? item.image[0]
                          : ""
                      }
                      alt=""
                    />
                  )}
                  {/* <img src={image ? image : item.image[0]} alt="" /> */}

                  <div
                    className="productunfold"
                    onClick={(e) => {
                      setFull(!full)
                    }}
                  >
                    <UnfoldMore
                      className="productlike"
                      style={{ transform: "rotate(45deg)" }}
                    />
                  </div>
                  <div
                    className="productleft"
                    value={count}
                    onClick={(e) => {
                      handleMoveLeft(count - 1)
                      setImage(
                        count !== 0 ? item.image[count - 1] : item.image[0]
                      )
                    }}
                  >
                    <NavigateBefore className="productlike" />
                  </div>
                  <div
                    className="productright"
                    value={count}
                    onClick={(e) => {
                      handleMoveRight(count + 1)
                      setImage(
                        count !== item.image.length
                          ? item.image[count + 1]
                          : item.image[item.image.length - 1]
                      )
                    }}
                  >
                    <NavigateNext className="productlike" />
                  </div>
                </div>
                <div className="smallerimagecontainer">
                  <div className="imageslider">
                    {item &&
                      item.image.map((image, index) => (
                        <div className="singleimage" key={index + 7823}>
                          <img
                            src={image}
                            alt=""
                            onClick={(e) => {
                              setCount(index)
                              setImage(image)
                            }}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="productitemdetails">
                <div className="producttop">
                  {/* <div className="productsecond">
                    <span>For Rent</span>
                  </div> */}

                  <h3>{item && item.title} </h3>
                  <span>
                    by{" "}
                    <Link className="link" to={linker}>
                      {item && item.userInfo.userName}
                    </Link>
                  </span>
                </div>
                <div className="productlandstars">
                  <div className="singleproductratingstars">
                    {rateProduct.isLoading ? (
                      <div>
                        <CircularProgress />
                      </div>
                    ) : (
                      <div className="ratingstars">
                        {value && value >= 1 ? (
                          <Star className="star pressed" />
                        ) : reviewAvrg >= 1 ? (
                          <Star className="star pressed" />
                        ) : (
                          <StarOutlineSharp
                            style={{
                              cursor: "pointer",
                            }}
                            className="star"
                          />
                        )}
                        {value >= 2 ? (
                          <Star className="star pressed" />
                        ) : reviewAvrg >= 2 ? (
                          <Star className="star pressed" />
                        ) : (
                          <StarOutlineSharp
                            style={{
                              cursor: "pointer",
                            }}
                            className="star"
                          />
                        )}
                        {value >= 3 ? (
                          <Star className="star pressed" />
                        ) : reviewAvrg >= 3 ? (
                          <Star className="star pressed" />
                        ) : (
                          <StarOutlineSharp
                            style={{
                              cursor: "pointer",
                            }}
                            className="star"
                          />
                        )}
                        {value >= 4 ? (
                          <Star className="star pressed" />
                        ) : reviewAvrg >= 4 ? (
                          <Star className="star pressed" />
                        ) : (
                          <StarOutlineSharp
                            style={{
                              cursor: "pointer",
                            }}
                            className="star"
                          />
                        )}
                        {value >= 5 ? (
                          <Star className="star pressed" />
                        ) : reviewAvrg >= 5 ? (
                          <Star className="star pressed" />
                        ) : (
                          <StarOutlineSharp
                            style={{
                              cursor: "pointer",
                            }}
                            className="star"
                          />
                        )}
                        <span className="ratingspan">
                          {item &&
                            item.reviewRecieved &&
                            item.reviewRecieved.length}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="productland">
                  <span className="productprice">$ {item && item.price}</span>
                  {/* <span>3,500.00/sq ft</span> */}
                </div>
                <div className="productdescription">
                  {/* TODO After some letters it should display ... */}
                  <Border
                    first={"Discription"}
                    second={
                      discriptionPressed ? (
                        <Add
                          style={{ cursor: "pointer" }}
                          onClick={(e) =>
                            setDiscriptionPressed(!discriptionPressed)
                          }
                        />
                      ) : (
                        <MinimizeOutlined
                          style={{ cursor: "pointer" }}
                          onClick={(e) =>
                            setDiscriptionPressed(!discriptionPressed)
                          }
                        />
                      )
                    }
                  />
                  {discriptionPressed &&
                    item &&
                    item.discription.map((disc, index) => (
                      <p key={index + 231} style={{ marginBottom: "10px" }}>
                        {disc}
                      </p>
                    ))}
                </div>
                <div className="productspecification">
                  <Border
                    first={"Product Specification"}
                    second={
                      specificationPressed ? (
                        <Add
                          style={{ cursor: "pointer" }}
                          onClick={(e) =>
                            setSpecificationPressed(!specificationPressed)
                          }
                        />
                      ) : (
                        <MinimizeOutlined
                          style={{ cursor: "pointer" }}
                          onClick={(e) =>
                            setSpecificationPressed(!specificationPressed)
                          }
                        />
                      )
                    }
                  />
                  {specificationPressed && item && (
                    <div className="secificationboxes">
                      <Box left={"Product ID"} right={item._id} />
                      <Box
                        left={"Manufactured By"}
                        right={item.userInfo.name}
                      />
                      {item.weight && (
                        <Box left={"Size/Weight"} right={item.weight} />
                      )}
                      {item.color && <Box left={"Color"} right={item.color} />}
                      {item.materials.length > 0 && (
                        <Box
                          left={"Materials"}
                          right={item.materials.map((material, index) => {
                            return material + ","
                          })}
                        />
                      )}

                      {/* <Box left={"Assembly Required"} right={"No"} /> */}
                      {item.style.length > 0 && (
                        <Box
                          left={"Style"}
                          right={item.style.map((material, index) => {
                            return material + ","
                          })}
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className="productdetail">
                  <div className="productbath">
                    {/* <div className="productIcons">
              <h3>2016</h3>
                <AttachFile style={{ color: "#2F2F2F" }} />
              </div>
              <span>Year Built</span> */}
                  </div>
                </div>

                <div className="productaddtocart">
                  <FormControl sx={{ m: 1, minWidth: "30%" }}>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      autoWidth
                      value={quantity}
                      onChange={(e) => setQuantity(e.target.value)}
                      size="small"
                      // label={`Qty: ${quantity}`}
                      label="Qty"
                    >
                      <MenuItem value="">{<em>{quantity}</em>}</MenuItem>
                      <MenuItem
                        value={1}
                        onClick={(e) => setQuantity(e.target.value)}
                      >
                        <span
                          style={{
                            fontSize: "11px",
                            fontFamily: "Bellota-Light",
                          }}
                        >
                          Qty: 1
                        </span>
                      </MenuItem>
                      <MenuItem
                        value={2}
                        onClick={(e) => setQuantity(e.target.value)}
                      >
                        <span
                          style={{
                            fontSize: "11px",
                            fontFamily: "Bellota-Light",
                          }}
                        >
                          Qty: 2
                        </span>
                      </MenuItem>
                      <MenuItem
                        value={3}
                        onClick={(e) => setQuantity(e.target.value)}
                      >
                        <span
                          style={{
                            fontSize: "11px",
                            fontFamily: "Bellota-Light",
                          }}
                        >
                          Qty: 3
                        </span>
                      </MenuItem>
                      <MenuItem
                        value={4}
                        onClick={(e) => setQuantity(e.target.value)}
                      >
                        <span
                          style={{
                            fontSize: "11px",
                            fontFamily: "Bellota-Light",
                          }}
                        >
                          Qty: 4
                        </span>
                      </MenuItem>
                      <MenuItem
                        value={5}
                        onClick={(e) => setQuantity(e.target.value)}
                      >
                        <span
                          style={{
                            fontSize: "11px",
                            fontFamily: "Bellota-Light",
                          }}
                        >
                          Qty: 5
                        </span>
                      </MenuItem>
                      <MenuItem
                        value={6}
                        onClick={(e) => setQuantity(e.target.value)}
                      >
                        <span
                          style={{
                            fontSize: "11px",
                            fontFamily: "Bellota-Light",
                          }}
                        >
                          Qty: 6
                        </span>
                      </MenuItem>
                      <MenuItem
                        value={7}
                        onClick={(e) => setQuantity(e.target.value)}
                      >
                        <span
                          style={{
                            fontSize: "11px",
                            fontFamily: "Bellota-Light",
                          }}
                        >
                          Qty: 7
                        </span>
                      </MenuItem>
                      <MenuItem
                        value={8}
                        onClick={(e) => setQuantity(e.target.value)}
                      >
                        <span
                          style={{
                            fontSize: "11px",
                            fontFamily: "Bellota-Light",
                          }}
                        >
                          Qty: 8
                        </span>
                      </MenuItem>
                      <MenuItem
                        value={9}
                        onClick={(e) => setQuantity(e.target.value)}
                      >
                        <span
                          style={{
                            fontSize: "11px",
                            fontFamily: "Bellota-Light",
                          }}
                        >
                          Qty: 9
                        </span>
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <button
                    onClick={(e) => {
                      handleClick()
                    }}
                  >
                    Add To Cart
                  </button>
                </div>
              </div>
            </div>
            <div className="productlast">
              {item && (
                <Link to={`/product/${item._id}`}>
                  <Rooms
                    number={<NavigateNext />}
                    icons={<span>View Full Details</span>}
                  />
                </Link>
              )}
            </div>
          </>
        )}
      </div>
    )
  }
}
// <div></div>
// <div className=''> </div>
