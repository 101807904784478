/// css import
import "../../../../styles/pages/blog/blog.scss"
// page notifiyer
import Locator from "../../../../example/locator"
// image for the blogs
import ImageBox from "../../../../example/imagebox"
// icon classes for the blog
import Rooms from "../../../../example/rooms"
import PinterstLayout from "../../../../example/pinterestlayout"
// data for the blogs
// import { blogs } from "../data";
import { Link } from "react-router-dom"
import { useRef, useCallback, useState, useEffect } from "react"
import { useQuery } from "@tanstack/react-query"

// request sender
import { publicRequest } from "../../../../constant/requestMethods"

// mui
import { CircularProgress, TextField } from "@mui/material"
import Alert from "@mui/material/Alert"
import Footer from "../../../display/footer"

export default function Blog() {
  //state vars to hold the blog and nexpage if it there
  const [blog, setBlog] = useState([])
  const [nextPage, setNextPage] = useState([])
  const sort = "Newest to Oldest"
  const [searchedBlog, setSearchedBlog] = useState()
  const [search, setSearch] = useState("")
  const [recentBlog, setRecentBlog] = useState([])

  // controllers to show alets
  const [alert, setAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  // functions that runs when the page is loaded
  // sends a request to the server for the blog data
  const findBlog = useQuery({
    queryKey: ["findblogs"],
    queryFn: async () => {
      setAlert(false)
      setErrorAlert(false)
      setErrorMessage("")
      try {
        // await userRequest.get("/blog/find?page=1&limit=5")
        const reply = await publicRequest.get(
          `/blog/find?page=${pageNumber}&limit=10`
        )
        let result = reply.data.data.result
        // console.log(reply.data.data)
        result.map((item) => {
          blog.push(item)
        })

        if (blog && blog.length > 0) {
          setBlog([
            ...new Map(
              blog.map((obj) => [`${obj._id}:${obj.title}`, obj])
            ).values(),
          ])
          // console.log("my eople")
        }
        // console.log(products[4].createdAt)

        let next = reply.data.data.next
        setNextPage(next)
        return reply
      } catch (e) {
        setAlert(true)
        setErrorAlert(true)
        setErrorMessage(e.message)
        console.log(e)
        return e
      }
    },

    cacheTime: Infinity,
    staleTime: Infinity,
  })

  const searchBlogs = useQuery({
    queryKey: ["blogpagesearchblogs"],
    queryFn: async () => {
      setAlert(false)
      setErrorAlert(false)
      setErrorMessage("")
      setSearchedBlog("")
      try {
        // await userRequest.get("/blog/find?page=1&limit=5")
        if (search) {
          const reply = await publicRequest.get(`/blog/search?q=${search}`)
          // console.log(reply)
          if (reply.data.success) {
            setSearchedBlog(reply.data.data)
            // console.log(searchedBlog)
            // setBlog(reply.data.data)
            // setNewDate(formatDistance(new Date(blog.createdAt), new Date()))
          } else {
            setErrorMessage(reply.data.msg)
          }
          return reply
        }
      } catch (e) {
        setAlert(true)
        setErrorAlert(true)
        setErrorMessage(e.message)
        return e
        // console.log(e.message)
      }
    },
    cacheTime: Infinity,
    staleTime: Infinity,
  })

  // functions that runs when the page is loaded
  // sends a request to the server for the blog data
  const findRecentBlogs = useQuery({
    queryKey: ["findrecentblogs"],
    queryFn: async () => {
      setAlert(false)
      setErrorAlert(false)
      setErrorMessage("")
      try {
        // await userRequest.get("/blog/find?page=1&limit=5")
        const reply = await publicRequest.get(`/blog/find/recent`)
        // console.log(reply)
        if (reply.status === 201) {
          setRecentBlog(reply.data.data)

          // setBlog(reply.data.data)
          // setNewDate(formatDistance(new Date(blog.createdAt), new Date()))
        } else {
          setAlert(true)
          setErrorAlert(true)
          setErrorMessage("couldn't get blog")
        }
        return reply
      } catch (e) {
        setAlert(true)
        setErrorAlert(true)
        setErrorMessage(e.message)
        // console.log(e.message)
      }
    },

    cacheTime: Infinity,
    staleTime: Infinity,
  })

  // console.log(isLoading, blog)
  const { display, pageNumber } = PinterstLayout(
    blog && blog,
    "blog",
    true,
    false,
    findBlog.isLoading,
    nextPage
  )

  useEffect(() => {
    findBlog.refetch()
  }, [pageNumber])

  useEffect(() => {
    searchBlogs.refetch()
  }, [search])

  // useEffect(() => {
  //   if (blog && blog.length > 0) {
  //     setBlog([
  //       ...new Map(
  //         blog.map((obj) => [`${obj._id}:${obj.title}`, obj])
  //       ).values(),
  //     ])
  //   }
  // }, [blog])

  // useEffect(() => {
  //   if (sort === "Newest to Oldest") {
  //     setBlog((prev) =>
  //       [...prev].sort(
  //         (a, b) =>
  //           Number(new Date(b.createdAt)) - Number(new Date(a.createdAt))
  //       )
  //     )
  //     // console.log(Blog[4].createdAt)
  //   }
  // }, [sort, blog])
  // console.log(blog)
  return (
    <div className="blog">
      <div className="insideblog">
        {errorAlert && (
          <div className="alert">
            {errorAlert && <Alert severity="error">{errorMessage}</Alert>}
          </div>
        )}
        {/* <div className='locator'>
          <Locator header={"Home"} icon={"kj"} />
          <Locator span={"Blog"} />
        </div> */}
        <span>Blog</span>
        <div className="blogmiddle">
          <div className="leftsideoftheblogpage">
            {findBlog.isLoading ? (
              <div
                style={{
                  width: "75%",
                  height: "70dvh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              display
            )}
          </div>
          <div className="rightsideblog">
            <div className="blogsearchbox">
              <span>Search</span>
              <div className="searchbox">
                <TextField
                  size="small"
                  className="textfield"
                  label="Blog"
                  variant="outlined"
                  onChange={(e) => {
                    setSearch(e.target.value)
                  }}
                />
                <button>Search</button>
              </div>
              <div className="searchedblog">
                {searchBlogs.isLoading ? (
                  <div
                    style={{
                      width: "100%",
                      minHeight: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : searchedBlog && searchedBlog.length > 0 ? (
                  searchedBlog.map((item, index) => (
                    <Link className="link" to={`/blog/${item._id}`}>
                      <Locator span={item.title} icon={"hellow world"} />
                    </Link>
                  ))
                ) : (
                  <span>{errorMessage}</span>
                )}
              </div>
            </div>
            <div className="recentblogs">
              <h2>Recent Posts</h2>
              {findRecentBlogs.isLoading ? (
                <div
                  style={{
                    width: "100%",
                    minHeight: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <div className="recently">
                  {recentBlog &&
                    recentBlog.length > 0 &&
                    recentBlog.map((item, index) => {
                      if (index <= 5)
                        return (
                          <Link
                            className="link"
                            to={`/blog/${item._id}`}
                            key={index + 2323}
                          >
                            <Locator span={item.title} icon={"hellow world"} />
                          </Link>
                        )
                    })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
