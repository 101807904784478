import { useEffect, useState } from "react"
import { Routes, Route } from "react-router-dom"

import UserProfile from "./layouts/profile"
import UserProduct from "./layouts/profile/product"
import UserMaterial from "./layouts/profile/material"
import UserFavorite from "./layouts/profile/favorite"
import UserProject from "./layouts/profile/project"
import UserBlog from "./layouts/profile/blog"
import Sidebar from "./layouts/header/sidebar"
import NavBar from "./layouts/header/navbar"
import TenderProfile from "./layouts/profile/tender"

// css for fonts
import "./App.css"

// routes
import routes from "./routes"
//
import { useSelector } from "react-redux"
import SingleTenderPage from "./layouts/pages/tender/singelpage"
function App() {
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.route) {
        return (
          <Route path={route.route} element={route.component} key={route.key} />
        )
      }

      return null
    })
  const iColumns = [
    "hme",
    "apartmet",
    "pepoke",
    "heartbreak",
    "my bad",
    "the past is the paset",
    "if i die today",
  ]
  const iRows = ["uncle iroh", "prince zuko", "start lord", "drax", "groot"]
  const [openSidebar, setOpenSidebar] = useState(false)
  // display is for showing the nav bar, isItOpen is for the Sidenav to show it u or not
  const { display, sideSetOpen, isItOpen } = NavBar()
  // trigger to show the nabar
  const [navNeeded, setNavNeeded] = useState(true)
  // getting the user info from the storage
  const { currentUser, isFetching, error } = useSelector((state) => state.user)

  const handleNavChange = () => {
    console.log("ti is false now")
    setNavNeeded(false)
  }

  const { displayPage, yetAnotherNavChanger } = UserProfile(navNeeded)

  return (
    <div
      className="App"
      onClick={(e) => {
        isItOpen && sideSetOpen(false)
      }}
    >
      {/* <Intro /> */}
      {navNeeded && display()}

      <Sidebar open={isItOpen} />
      <Routes>
        {getRoutes(routes)}
        <Route
          path="/tender/:id"
          // component={displayPage}
          element={<SingleTenderPage />}
        />
        {currentUser && (
          <>
            {/* {setNavNeeded(false)} */}
            <Route
              path="/profile"
              // component={displayPage}
              element={displayPage}
              loader={() => {
                console.log("hellow profile")
                setNavNeeded(false)
              }}
            />
            <Route
              path="/profile_products"
              element={<UserProduct />}
              loader={() => {
                console.log("hellow products")
                setNavNeeded(false)
              }}
            />
            <Route
              path="/profile_favorite"
              element={<UserFavorite />}
              loader={() => {
                console.log("hellow favs")
                setNavNeeded(false)
              }}
            />
            <Route
              path="/profile_materials"
              element={<UserMaterial />}
              loader={() => {
                console.log("hellow mats")
                setNavNeeded(false)
              }}
            />
            <Route
              path="/profile_Projects"
              element={<UserProject />}
              loader={() => {
                setNavNeeded(false)
              }}
            />
            <Route
              path="/profile_blog"
              element={<UserBlog />}
              loader={() => {
                console.log("hellow blogs")
                setNavNeeded(false)
              }}
            />
            {currentUser.data.a && (
              <Route
                path="//profile_tender"
                element={<TenderProfile />}
                loader={() => {
                  setNavNeeded(false)
                }}
              />
            )}
          </>
        )}

        {/* <Route path="/profile" element={<UserProfile />} /> */}
      </Routes>
    </div>
  )
}

export default App
