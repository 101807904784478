import { useEffect, useState, useRef, useCallback } from "react"
// css class
import "../../../styles/pages/firms/firms.scss"
// header icons

//
import { TextField } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"

import Border from "../../../example/borderbottom"
import { MinimizeOutlined, Add, Search } from "@mui/icons-material"
// header
import Locator from "../../../example/locator"
// firm outlook
import SingleFirm from "./individual"
// colored boxes for the catagories
import ColoredBox from "../../../example/coloredBox"

import { useQuery } from "@tanstack/react-query"

// request sender
import { publicRequest } from "../../../constant/requestMethods"

// mui
import { CircularProgress } from "@mui/material"
import Alert from "@mui/material/Alert"

//
import { useLocation } from "react-router"
import Footer from "../../display/footer"

export default function Firms() {
  //state vars to hold the firm and nexpage if it there
  const [firm, setFirm] = useState([])
  const [nextPage, setNextPage] = useState([])

  const location = useLocation()

  const id = location.pathname.split("/")[1]

  // controllers to show alets
  const [alert, setAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  // to store the values of the search
  const [search, setSearch] = useState("")
  const [filterPro, setFilterPro] = useState([])
  const [clicked, setOnClicked] = useState("")

  // stores the catagory name
  const [catName, setCatName] = useState("")

  const [pageNumber, setPageNumber] = useState(1)
  const observer = useRef()

  const firmCatagory = [
    "Architecture / Design Firm",
    "Engineering",
    "Finishing",
    "General Contractors",
    "Graphics & Animation",
    "Landscape Architecture",
    "Photography Studio",
    "Planning",
    "Security",
  ]
  const [cat, setCat] = useState("")
  const [cataPressed, setCataPressed] = useState(true)
  const [searchPressed, setSearchPressed] = useState(true)

  // shows the items numbers when the filteres are pressed
  const [filterPressed, setFilterPressed] = useState(false)

  const [checked, setChecked] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ])

  // functions that runs when the page is loaded
  // sends a request to the server for the firm data
  const findFirms = useQuery({
    queryKey: ["findfirms"],
    queryFn: async () => {
      setAlert(false)
      setErrorAlert(false)
      setErrorMessage("")
      try {
        const reply = await publicRequest.get(
          `firm/find/firmpage?page=${pageNumber}&limit=10`
        )
        // const reply = await publicRequest.get(`firm/find/firmpage`)
        let result = reply.data.data.result
        // console.log(reply)
        result.map((item) => {
          firm.push(item)
        })
        let next = reply.data.data.next
        setNextPage(next)
        if (firm && firm.length > 0) {
          setFirm([
            ...new Map(
              firm.map((obj) => [`${obj._id}:${obj.userName}`, obj])
            ).values(),
          ])
        }
        return reply
      } catch (e) {
        setAlert(true)
        setErrorAlert(true)
        setErrorMessage(e.message)
        console.log(e.message)
        return e
      }
    },
    cacheTime: Infinity,
    staleTime: Infinity,
  })

  const lastBlogElement = useCallback(
    (node) => {
      if (findFirms.isLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && nextPage) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1)
          console.log(pageNumber)
        }
      })
      if (node) observer.current.observe(node)
    },
    [
      findFirms.isLoading,
      nextPage,
      /* Todo setup a state to check it there are any more blogs to be seen
      and make is loading a dependency */
    ]
  )

  // check and remove the duplicate in teh array
  const check = () => {
    if (firm && firm.length > 0) {
      setFirm([
        ...new Map(
          firm.map((obj) => [`${obj._id}:${obj.title}`, obj])
        ).values(),
      ])
      // console.log("my eople")
    }
  }

  const handleChange = (event, index) => {
    setCat(event.target.name)
    setChecked([false, false, false, false, false, false, false, false, false])
    checked.map(() => {
      if (index === 0) {
        setChecked([
          true,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ])
      } else if (index === 1) {
        setChecked([
          false,
          true,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ])
      } else if (index === 2) {
        setChecked([
          false,
          false,
          true,
          false,
          false,
          false,
          false,
          false,
          false,
        ])
      } else if (index === 3) {
        setChecked([
          false,
          false,
          false,
          true,
          false,
          false,
          false,
          false,
          false,
        ])
      } else if (index === 4) {
        setChecked([
          false,
          false,
          false,
          false,
          true,
          false,
          false,
          false,
          false,
        ])
      } else if (index === 5) {
        setChecked([
          false,
          false,
          false,
          false,
          false,
          true,
          false,
          false,
          false,
        ])
      } else if (index === 6) {
        setChecked([
          false,
          false,
          false,
          false,
          false,
          false,
          true,
          false,
          false,
        ])
      } else if (index === 7) {
        setChecked([
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          true,
          false,
        ])
      } else if (index === 8) {
        setChecked([
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          true,
        ])
      }
    })
  }

  //  when the search bar is is inputed the rows change
  const findProject = () => {
    const something = []
    if (search) {
      firm.map((item, index) => {
        const doe = item.userName.toLowerCase()
        if (!doe.indexOf(search.toLowerCase())) {
          something.push(item)
        }
        return something
      })
    }
    return something
  }
  const handleFilter = () => {
    let something = []
    if (clicked) {
      firm.filter(function (node) {
        // console.log(node)
        node.firmData[0].catagory &&
          node.firmData[0].catagory.map((cat) => {
            if (cat === clicked.toLowerCase()) {
              something.push(node)
              // console.log(filterMan)
            }
          })
      })
      return something
    }

    return something
  }

  const foundFilter = handleFilter()

  const foundFirm = findProject()

  // runs when teh uset gets to the last item og the page
  useEffect(() => {
    findFirms.refetch()
  }, [pageNumber])

  /* // runs to delete duplicated firms
  useEffect(() => {
    console.log("regfetch on decomposer")
    if (firm && firm.length > 0) {
      setFirm([
        ...new Map(
          firm.map((obj) => [`${obj._id}:${obj.title}`, obj])
        ).values(),
      ])
    }
    // let set = new Set(firm.map((v) => v._id))
    // console.log(filteredList)
  }, [firm]) */

  useEffect(() => {
    findFirms.refetch()
    check()
  }, [id])

  console.log(catName)
  return (
    <div className="firms">
      <div className="insidefirms">
        {/* <div className='header'>
          <Locator header={"Home"} icon={"kj"} />
          <Locator span={"Firms"} />
        </div> */}
        {errorAlert && (
          <div className="alert">
            {errorAlert && <Alert severity="error">{errorMessage}</Alert>}
          </div>
        )}
        <div className="multiplefirms">
          <div className="leftpartfirm">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <span className="firmsttilte">
                {catName && catName + " "}Firms
              </span>
              {filterPressed && (
                <span style={{ fontSize: "22", fontFamily: "Bellota-Bold" }}>
                  {" "}
                  Firms Found {foundFilter.length}
                </span>
              )}
            </div>

            <div className="singelfirmcontainer">
              {findFirms.isLoading ? (
                <div
                  style={{
                    width: "80dvw",
                    height: "100dvh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : // <></>
              foundFirm && foundFirm.length > 0 ? (
                foundFirm.map((item, index) => (
                  <div className="singualrfirm" key={index + 23}>
                    <SingleFirm
                      id={item._id}
                      title={item.userName}
                      type={
                        item.catagory && item.catagory.length > 0
                          ? item.catagory.map((item) => {
                              return item + ", "
                            })
                          : ""
                      }
                      image={item.profilepic}
                    />
                  </div>
                ))
              ) : foundFilter && foundFilter.length > 0 ? (
                foundFilter.map((item, index) => (
                  <div className="singualrfirm" key={index + 23}>
                    <SingleFirm
                      id={item._id}
                      title={item.userName}
                      type={
                        item.catagory && item.catagory.length > 0
                          ? item.catagory.map((item) => {
                              return item + ", "
                            })
                          : ""
                      }
                      image={item.profilepic}
                    />
                  </div>
                ))
              ) : (
                firm.map((item, index) => {
                  if (firm.length === index + 1) {
                    return (
                      <div
                        className="singualrfirm"
                        ref={lastBlogElement}
                        key={index + 23}
                      >
                        <SingleFirm
                          id={item._id}
                          title={item.userName}
                          type={
                            item.catagory && item.catagory.length > 0
                              ? item.catagory.map((item) => {
                                  return item + ", "
                                })
                              : ""
                          }
                          image={item.profilepic}
                        />
                      </div>
                    )
                  } else {
                    return (
                      <div className="singualrfirm" key={index + 23}>
                        <SingleFirm
                          id={item._id}
                          title={item.userName}
                          type={
                            item.catagory && item.catagory.length > 0
                              ? item.catagory.map((item) => {
                                  return item + ", "
                                })
                              : ""
                          }
                          image={item.profilepic}
                        />
                      </div>
                    )
                  }
                })
              )}
            </div>
          </div>

          <div className="rightpartfirm">
            <div className="serachfirms"></div>
            <div className="prosidenav">
              <span
                className="serachfirms"
                style={{ fontSize: "23px", fontFamily: "Bellota-Bold" }}
              >
                Filter
              </span>
              <div className="firmcatagories">
                <Border
                  first={"Catagory"}
                  second={
                    cataPressed ? (
                      <Add
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          setCataPressed(!cataPressed)
                          setCat("")
                          setOnClicked("")
                          setCatName("")
                          setFilterPressed(false)
                          setChecked([
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                          ])
                        }}
                      />
                    ) : (
                      <MinimizeOutlined
                        style={{ cursor: "pointer" }}
                        onClick={(e) => setCataPressed(!cataPressed)}
                      />
                    )
                  }
                />
                {cataPressed && (
                  <FormControl
                    className="close"
                    sx={{ m: 1 }}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormGroup>
                      {firmCatagory.map((item, index) => (
                        <FormControlLabel
                          key={index + 98676}
                          style={{
                            fontSize: "12",
                            fontFamily: "Bellota-Light",
                          }}
                          control={
                            <Checkbox
                              checked={checked[index]}
                              onChange={(e) => {
                                setOnClicked(e.target.name)
                                setCatName(e.target.name)
                                setFilterPressed(true)
                                handleChange(e, index)
                              }}
                              name={item}
                              style={{
                                transform: "scale(0.7)",
                                width: "10px",
                                height: "10px",
                              }}
                              // className="checkbox"
                            />
                          }
                          label={
                            <span
                              style={{
                                fontSize: "12px",
                                fontFamily: "Bellota-Light",
                              }}
                            >
                              {item}
                            </span>
                          }
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                )}
              </div>
              <div className="catasidenav">
                {searchPressed && (
                  <FormControl sx={{ m: 1, width: "90%" }} variant="outlined">
                    <OutlinedInput
                      size="small"
                      id="outlined-adornment-weight"
                      endAdornment={
                        <InputAdornment position="end">
                          <Search />
                        </InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                      onChange={(e) => {
                        setSearch(e.target.value)
                      }}
                    />
                  </FormControl>
                )}
              </div>
              <div className="catasidenav">
                <button
                  style={{
                    width: "90%",
                    height: "40px",
                    marginBottom: "5%",
                    backgroundColor: "#00aeff",
                    color: "white",
                    border: "none",
                  }}
                >
                  {" "}
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}
