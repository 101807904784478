// css classs
import "../../../styles/pages/tender/tender.scss"

import { useState, useEffect } from "react"

import Border from "../../../example/borderbottom"
import Locator from "../../../example/locator"

import { MinimizeOutlined, Add, Search } from "@mui/icons-material"
//
import { Link } from "react-router-dom"

import FormControl from "@mui/material/FormControl"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"

// tender layout
import TenderLayout from "../../../example/tenderlayout"

import Footer from "../../display/footer"

import { useLocation } from "react-router"
//
import { useQuery } from "@tanstack/react-query"

// request sender
import { publicRequest } from "../../../constant/requestMethods"

import { CircularProgress, Modal } from "@mui/material"

export default function Tender() {
  const location = useLocation()
  const id = location.pathname.split("/")[1]
  //state vars to hold the tender and nexpage if it there
  const [nextPage, setNextPage] = useState([])

  // state taht holds the tenders
  const [tenders, setTenders] = useState([])

  // controllers to show alets
  const [alert, setAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  // sort select
  const [sort, setSort] = useState("")

  // state to check if usertenders,or firm
  const [userType, setUserType] = useState("manufacturer")

  // state to hold the link to get the user
  const [linker, setLinker] = useState("/")

  // shows the items numbers when the filteres are pressed
  const [filterPressed, setFilterPressed] = useState(false)

  const [recentTender, setRecentTender] = useState([])

  const [cat, setCat] = useState("")
  const [cataPressed, setCataPressed] = useState(true)
  const [searchPressed, setSearchPressed] = useState(true)
  const [search, setSearch] = useState("")

  // var for the modals to apper
  // const [open, setOpen] = useState(modalOpen);

  ///

  // functions that runs when the page is loaded
  // sends a request to the server for the tender data
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["findtenders"],
    queryFn: async () => {
      try {
        const reply = await publicRequest.get(
          `/tender/tender/find?page=${pageNumber}&limit=10`
        )
        let result = reply.data.data.result
        result.map((item) => {
          tenders.push(item)
        })
        let next = reply.data.data.next
        setNextPage(next)
        if (tenders && tenders.length > 0) {
          setTenders([
            ...new Map(
              tenders.map((obj) => [`${obj._id}:${obj.title}`, obj])
            ).values(),
          ])
          // console.log("my eople")
        }
        // if (tenders && tenders.length > 0) {
        //   setTenders([
        //     ...new Map(
        //       tenders.map((obj) => [`${obj._id}:${obj.title}`, obj])
        //     ).values(),
        //   ])
        //   // console.log("my eople")
        // }
        setAlert(false)
        setErrorAlert(false)
        setErrorMessage("")
        return reply
      } catch (e) {
        setAlert(true)
        setErrorAlert(true)
        setErrorMessage(e.message)
        console.log(e.message)
        return e
      }
    },
    cacheTime: Infinity,
    staleTime: Infinity,
  })

  const findRecentTenders = useQuery({
    queryKey: ["findrecenttenders"],
    queryFn: async () => {
      setAlert(false)
      setErrorAlert(false)
      setErrorMessage("")
      try {
        // await userRequest.get("/tender/find?page=1&limit=5")
        const reply = await publicRequest.get(`/tender/recent`)
        // console.log(reply)
        if (reply.status === 201) {
          setRecentTender(reply.data.data)

          // settender(reply.data.data)
          // setNewDate(formatDistance(new Date(tender.createdAt), new Date()))
        } else {
          setAlert(true)
          setErrorAlert(true)
          setErrorMessage("couldn't get tender")
        }
        return reply
      } catch (e) {
        setAlert(true)
        setErrorAlert(true)
        setErrorMessage(e.response.msg)
        // console.log(e.message)
      }
    },

    cacheTime: Infinity,
    staleTime: Infinity,
  })

  // check and remove the duplicate in teh array
  const check = () => {
    if (tenders && tenders.length > 0) {
      setTenders([
        ...new Map(
          tenders.map((obj) => [`${obj._id}:${obj.title}`, obj])
        ).values(),
      ])
      // console.log("my eople")
    }
  }
  const handleFilter = () => {
    let something = []
    if (cat) {
      tenders.filter(function (node) {
        node.catagory.map((catagory) => {
          if (catagory.toLowerCase() === cat.toLowerCase()) {
            something.push(node)
            // console.log(filterMan)
          }
        })
      })
      return something
    }

    return something
  }

  const foundFilter = handleFilter()

  //  when the search bar is is inputed the rows change
  const searchTender = () => {
    const something = []
    if (search && foundFilter && foundFilter.length > 0) {
      foundFilter.map((item, index) => {
        const doe = item.title.toLowerCase()
        if (!doe.indexOf(search.toLowerCase())) {
          something.push(item)
        }
        return something
      })
    } else if (search && !foundFilter) {
      tenders.map((item, index) => {
        const doe = item.title.toLowerCase()
        if (!doe.indexOf(search.toLowerCase())) {
          something.push(item)
        }
        return something
      })
    }
    return something
  }

  const foundTenders = searchTender()

  //  console.log(cat)

  // setChecked([event.target.checked, event.target.checked])

  const { display, pageNumber } = TenderLayout(
    foundTenders && foundTenders.length > 0
      ? foundTenders
      : foundFilter && foundFilter.length > 0
      ? foundFilter
      : tenders && tenders,
    isLoading,
    nextPage
  )

  useEffect(() => {
    // console.log("leave you")
    refetch()
    check()
    // setPageNumber(1)
  }, [id])
  // refteches the query when the display returns a changed pageNumber
  useEffect(() => {
    refetch()
  }, [pageNumber])

  useEffect(() => {
    findRecentTenders.refetch()
  }, [id])

  useEffect(() => {
    if (sort === "Newest to Oldest") {
      setTenders((prev) =>
        [...prev].sort(
          (a, b) =>
            Number(new Date(b.createdAt)) - Number(new Date(a.createdAt))
        )
      )
      // console.log(Tenders[4].createdAt)
    } else if (sort === "Oldest to Newest") {
      setTenders((prev) =>
        [...prev].sort(
          (a, b) =>
            Number(new Date(a.createdAt)) - Number(new Date(b.createdAt))
        )
      )
    }
  }, [sort])

  // console.log(nextPage)
  return (
    <div className="tender">
      <div className="tendercontainer">
        <div className="toptender">
          <span>Latest Tenders</span>
          <div className="toptenderdescriptions">
            <p>
              Lorem Ipsum texts herer.!!!1Find daily latest Government Tenders
              published in all countries. Tendersontime is the most
              comprehensive tenders information provider, providing B2G sales
              intelligence, bidding and consulting services. Tendersontime
              covers Projects Information, Business News, and Contract Award
              Information from different multilateral and bilateral funding
              agencies like The World Bank tenders, Asian Development Bank
              tenders, IMF, African Development Bank tenders, UN Tenders, UNGM
              Bids, UNGM Tenders, WHO Tenders etc. All queries from suppliers
              like: where to search tenders portal?, which are the famous
              websites for tenders?, best tender service provider?, best tender
              information system?, which are the best tendering websites?, best
              listing of global tenders?, how to search tenders?, where to find
              government leads?, what are the best sources of worldwide tenders?
              etc are best answered if they register and enjoy the free trial of
              the service. Tendersontime provide free trial and send relevant
              sample tenders to judge the seriousness and genuinely of the
              tenders info.
            </p>
          </div>
        </div>
        <Border />
        <div className="bottomtender">
          <div className="tenderlist">
            {/* <div className="listcontainer"> */}
            {isLoading ? (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              display
            )}

            {/* </div> */}
          </div>
          <div className="filter">
            <div className="tendersidenav">
              <div className="catasidenav">
                {searchPressed && (
                  <FormControl sx={{ m: 1, width: "90%" }} variant="outlined">
                    <OutlinedInput
                      size="small"
                      id="outlined-adornment-weight"
                      endAdornment={
                        <InputAdornment position="end">
                          <Search />
                        </InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                      onChange={(e) => {
                        setSearch(e.target.value)
                        // console.log(search)
                      }}
                    />
                  </FormControl>
                )}
              </div>
              <div className="catasidenav">
                <button
                  style={{
                    width: "90%",
                    height: "40px",
                    marginBottom: "5%",
                    backgroundColor: "#00aeff",
                    color: "white",
                    border: "none",
                  }}
                >
                  {" "}
                  Search
                </button>
              </div>
              <div className="recenttenders">
                <h2>Recent Posts</h2>
                {findRecentTenders.isLoading ? (
                  <div
                    style={{
                      width: "100%",
                      minHeight: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <div className="recently">
                    {recentTender &&
                      recentTender.length > 0 &&
                      recentTender.map((item, index) => {
                        if (index <= 5)
                          return (
                            <Link
                              className="link"
                              to={`/tender/${item._id}`}
                              key={index + 2323}
                            >
                              <Locator
                                span={item.title}
                                icon={"hellow world"}
                              />
                            </Link>
                          )
                      })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}
