export const data = [
  {
    img: [
      "https://niddf.com/static/niddf.jpg",
      "https://niddf.com/static/niddf.jpg",
      "https://niddf.com/static/niddf.jpg",
    ],
    name: "PREMIUM ACRYLIC PUTTY",
    passages: "Painting",
    price: "500.0 Birr",
  },
  {
    img: ["https://niddf.com/media/product_images/aa.jpg"],
    name: "Melamine MDF",
    passages: "Material",
    price: "2000.0 Birr",
  },
  {
    img: ["https://niddf.com/media/product_images/3aa.png"],
    name: "Parquet",
    passages: "Wall & Floor",
    price: "800.0 Birr",
  },
  {
    img: ["https://niddf.com/media/product_images/2aa.png"],
    name: "Image Handler Locker",
    passages: "Material",
    price: "4000.0 Birr",
  },
  {
    img: ["https://niddf.com/media/product_images/w1.jpg"],
    name: "Model AF-24",
    passages: "Furniture",
    price: "22400.0 Birr",
  },
  {
    img: ["https://niddf.com/media/product_images/1a.webp"],
    name: "Bass New Bedroom",
    passages: "Furniture",
    price: "10000.0 Birr",
  },
  {
    img: ["https://niddf.com/media/product_images/bass_yatak_02.jpg"],
    name: "Charisma",
    passages: "Furniture",
    price: "None Birr",
  },
  {
    img: ["https://niddf.com/media/product_images/anje4670-800x527-1.jpg"],
    name: "Benchcraft Living Room Traemore Sofa 2740338",
    passages: "Furniture",
    price: "None Birr",
  },
  {
    img: ["https://niddf.com/media/product_images/27403-38-35.jpg"],
    name: "Benchcraft Living Room Allmaxx Sofa 2810538",
    passages: "Furniture",
    price: "None Birr",
  },
  {
    img: ["https://niddf.com/media/product_images/28105-38-set-10x8-crop.jpg"],
    name: "Benchcraft Living Room Allmaxx Sofa 2810538",
    passages: "Furniture",
    price: "None Birr",
  },
  {
    img: ["https://niddf.com/static/niddf.jpg"],
    name: "Benchcraft Living Room Allmaxx Sofa 2810538",
    passages: "Furniture",
    price: "None Birr",
  },
];
