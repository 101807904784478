import "./box.scss";
export default function Box({ left, right }) {
  return (
    <div className="boxspecification">
      <div className="left">
        <span>{left}</span>
      </div>
      <div className="right">
        <span>{right}</span>
      </div>
    </div>
  );
}
