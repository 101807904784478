import { useState, useEffect } from "react"
// css class
import "../../../styles/display/property/property.scss"
// links at the top of the page
import Locator from "../../../example/locator"
// iconBoxes
import IconBox from "../../../example/iconBox"
// property type boxes
import ColoredBox from "../../../example/coloredBox"
// rooms on the overview part
import Rooms from "../../../example/rooms"

// reiveiws layout
import Review from "../../../example/review"
// mui Icons

//
import { useQuery, useMutation } from "@tanstack/react-query"

// request sender
import { publicRequest, userRequest } from "../../../constant/requestMethods"

// mui
import { CircularProgress } from "@mui/material"
import Alert from "@mui/material/Alert"

import {
  AccountCircle,
  CheckCircleOutline,
  Today,
  SingleBed,
  SquareFoot,
  Shower,
  LocationOn,
  FavoriteBorder,
  ShareOutlined,
  NavigateNext,
  PrintOutlined,
  NavigateBefore,
  Garage,
  InsertDriveFileSharp,
  Star,
  StarBorderOutlined,
  StarOutlineSharp,
} from "@mui/icons-material"
///
import { TextField } from "@mui/material"
import Select from "@mui/material/Select"
import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from "@mui/material/InputLabel"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"

//
import { useLocation } from "react-router"
import { Link } from "react-router-dom"
// time formater
import { format } from "timeago.js"

//
import { useSelector } from "react-redux"

export default function Property() {
  // holds state for the user from the browser storage
  const { currentUser } = useSelector((state) => state.user)

  //
  const location = useLocation()
  const id = location.pathname.split("/")[2]
  // opens the dexcriptions for the floors
  const [descOpen, setDescOpen] = useState(false)
  const [image, setImage] = useState("")

  // state to check if user is a pro, manufacturer,or firm
  const [userType, setUserType] = useState("firm")

  // state to hold the link to get the user
  const [linker, setLinker] = useState("/")

  // holds the average computerd from the third request
  const [reviewAvrg, setReviewAvrg] = useState(1)

  // state to get the review value
  const [value, setValue] = useState(0)
  const [comment, setComment] = useState("")

  // changes everytime a user reviews a project to load the pages agian
  const [isReviewed, setIsReviewed] = useState(false)
  // changes the bigger image to which ever was pressed
  // const [biggerImg, setBiggerImg] = useState("")
  // gets the firm fromt eh data file by spliting the urls

  // state to hold the alue of the image before it slides to the next or prev
  const [count, setCount] = useState(0)

  const [item, setItem] = useState()

  //
  const [reviews, setReviews] = useState([])
  const [project, setProject] = useState({})
  const [details, setDetails] = useState({})

  // controllers to show alets
  const [alert, setAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  // fetches the query to get single project
  const { isLoading, refetch } = useQuery({
    queryKey: ["findsingelproject"],
    queryFn: async () => {
      try {
        // await userRequest.get("/blog/find?page=1&limit=5")
        const reply = await publicRequest.get(`/project/find/${id}`)
        // console.log(reply)
        if (reply.status === 201) {
          // console.log(reply.data.data)
          setProject(reply.data.data[0])

          reply.data.data[0].details && setDetails(reply.data.data[0].details)
          reply.data.data[0].reviewRecieved &&
            setReviews(reply.data.data[0].reviewRecieved)
          if (
            reply.data.data &&
            reply.data.data[0] &&
            reply.data.data[0].userInfo &&
            reply.data.data[0].userInfo.firm &&
            reply.data.data[0].userInfo.firm.length > 0
          ) {
            setUserType("firm")
            setLinker("/firm/" + reply.data.data[0].userInfo.userId)
          } else if (
            reply.data.data &&
            reply.data.data[0] &&
            reply.data.data[0].userInfo &&
            reply.data.data[0].userInfo.manufacturer &&
            reply.data.data[0].userInfo.manufacturer.length > 0
          ) {
            setUserType("manufacturer")
            setLinker("/manufacturer/" + reply.data.data[0].userInfo.userId)
          } else if (
            reply.data.data &&
            reply.data.data[0] &&
            reply.data.data[0].userInfo &&
            reply.data.data[0].userInfo.professional &&
            reply.data.data[0].userInfo.professional.length > 0
          ) {
            setUserType("professional")
            setLinker("/professional/" + reply.data.data[0].userInfo.userId)
          }
          // setNewDate(formatDistance(new Date(blog.createdAt), new Date()))
        } else {
          setAlert(true)
          setErrorAlert(true)
          setErrorMessage("couldn't get blog")
        }
        return reply
      } catch (e) {
        setAlert(true)
        setErrorAlert(true)
        setErrorMessage(e.message)
        console.log(e)
      }
    },

    cacheTime: Infinity,
    staleTime: Infinity,
  })

  // endpoint to rate firm
  const createReview = async (data) => {
    const reply = await userRequest.put(
      `project/rateproject/${currentUser.data._id}/${project._id || id}`,
      data
    )
    return reply.data
  }
  const rateProject = useMutation(createReview, {
    onSuccess: (data) => {
      setAlert(true)
      setSuccessAlert(true)
      // console.log(data)
      setSuccessMessage(data.msg)
      setIsReviewed(!isReviewed)
    },
    onError: (e) => {
      setAlert(true)
      setErrorAlert(true)
      setErrorMessage(e.message)
    },
  })

  // create or update review
  const handleReview = (number, message) => {
    // console.log("tje off season")
    setAlert(false)
    setErrorAlert(false)
    setSuccessAlert(false)
    setErrorMessage("")
    setSuccessMessage("")
    let reviewed
    if (value && comment) {
      reviewed = { value: number, comment: message }
    } else if (value && !comment) {
      reviewed = { value: number }
    } else if (!value && comment) {
      reviewed = { comment: message }
    }

    if (currentUser && currentUser.data && currentUser.data._id) {
      rateProject.mutate(reviewed)
    } else {
      setAlert(true)
      setErrorAlert(true)
      setSuccessAlert(false)
      setErrorMessage("login to rate project")
    }
  }

  /// movies the box one step to the left
  const handleMoveLeft = (number) => {
    if (number < 0) {
      setCount(project.image.length - 1)
    } else if (number === 0) {
      console.log("first")
      setCount(project.image.length)
    } else if (number >= 1) {
      setCount(number)
    }
  }
  // moves the box one item to the right
  const handleMoveRight = (number) => {
    if (number === project.image.length - 1) {
      setCount(-1)

      // setPercent(6);
    } else if (number < project.image.length) {
      // console.log(project.image.length, count)
      setCount(number)
    }
  }

  useEffect(() => {
    // console.log("on id")
    refetch()
  }, [id])

  useEffect(() => {
    if (currentUser && currentUser.data && currentUser.data._id) {
      refetch()
      // console.log("refethcing on review")
    }
  }, [isReviewed])

  // console.log(details, reviews)
  // useEffect(() => {
  //   console.log("nas isn good")
  // }, [handleMoveLeft, handleMoveRight, image])
  // console.log(project, details, userType, linker)
  return (
    <div className="property">
      {isLoading ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100dvh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        project && (
          <div className="inside">
            {alert && (
              <div className="alert">
                {errorAlert && <Alert severity="error">{errorMessage}</Alert>}
                {successAlert && (
                  <Alert severity="success">{successMessage}</Alert>
                )}
              </div>
            )}
            <div className="header">
              <div className="title">
                <h3>{item && item.title}</h3>
                <div className="typebox">
                  <ColoredBox span={"For Sell"} />
                  <ColoredBox span={"For Rent"} />
                </div>
              </div>
              <div className="favorite">
                <IconBox
                  icon={<FavoriteBorder style={{ fontSize: "18px" }} />}
                />
                <IconBox
                  icon={<ShareOutlined style={{ fontSize: "18px" }} />}
                />
                <IconBox
                  icon={<PrintOutlined style={{ fontSize: "18px" }} />}
                />
              </div>
              {/* <div className='headerprice'>
            <h3>670,000.00</h3>
            <span>1,300.00/mo</span>
          </div> */}
            </div>
            <div className="gps">
              <LocationOn
                style={{ fontSize: "18px", fontFamily: "Bellota-Light" }}
              />
              <span>3001 W Ainslie St, Chicago, IL 60625, USA</span>
            </div>
            <div className="divided">
              <div className="leftside">
                <div className="imagecontainer">
                  <div className="bigger">
                    {/* TODO after receiveing the images as an array it should show the first unless the bottom images are pressed*/}
                    {/* {image ? ( */}
                    <img src={image ? image : project.image} alt="" />
                    {/* ) : (
                  <></>
                  // <img src={project ? project.image[0] : ""} alt="" />
                )} */}
                    {project && project.image && project.image.length > 1 && (
                      <>
                        <div
                          className="left"
                          onClick={(e) => {
                            if (count === 0) {
                              setImage(project.image[project.image.length - 1])
                            } else {
                              handleMoveLeft(count - 1)
                              setImage(project.image[count - 1])
                            }
                          }}
                        >
                          <NavigateBefore
                            style={{ color: "white", fontSize: "22px" }}
                          />
                        </div>
                        <div
                          className="right"
                          onClick={(e) => {
                            if (count === project.image.length - 1) {
                              setCount(0)
                              setImage(project.image[0])
                            } else {
                              handleMoveRight(count + 1)
                              setImage(project.image[count + 1])
                            }

                            // console.log(count)
                          }}
                        >
                          <NavigateNext
                            style={{ color: "white", fontSize: "22px" }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  {project && project.image && project.image.length > 1 && (
                    <div className="smaller">
                      <div className="swipper">
                        {project ? (
                          project.image &&
                          project.image.map((item, i) => (
                            <div className="individual" key={i + 231}>
                              <img
                                src={item}
                                alt=""
                                onClick={(e) => {
                                  setImage(item)
                                  setCount(i)
                                }}
                              />
                            </div>
                          ))
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="overview">
                  <div className="propertyID">
                    <h3>Overview</h3>
                    <div className="id">
                      <h3>property ID:</h3>
                      <span>{project && project._id}</span>
                    </div>
                  </div>
                  <div className="propertyType">
                    <div className="Ptype">
                      <h3>{project && project.title}</h3>
                      <span>Property Type</span>
                    </div>
                    <div className="roomicons">
                      {details && details.rooms ? (
                        <Rooms
                          icons={<SingleBed />}
                          title={"Bedrooms"}
                          number={details.rooms && details.rooms}
                        />
                      ) : (
                        <></>
                      )}
                      {details && details.toilet ? (
                        <Rooms
                          icons={<Shower />}
                          title={"Toilets"}
                          number={details.toilet && details.toilet}
                        />
                      ) : (
                        <></>
                      )}
                      {details && details.garage ? (
                        <Rooms
                          icons={<Garage />}
                          title={"Garages"}
                          number={details.garage && project.details.garage}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="description">
                  <div className="disc">
                    <h3>Description</h3>
                  </div>
                  {project ? (
                    project.discription &&
                    project.discription.map((item, index) => (
                      <p key={index + 903}>{item}</p>
                    ))
                  ) : (
                    <></>
                  )}

                  <div className="documents">
                    <h3>Property Documents</h3>
                  </div>
                  <div className="downloadable">
                    <div className="plans">
                      <InsertDriveFileSharp style={{ fontSize: "18px" }} />
                      <span>plan-2</span>
                    </div>
                    <span>Downlaod</span>
                  </div>
                </div>
                <div className="details">
                  <div className="detailtitle">
                    <h3>Details</h3>
                    <div className="detailupdated">
                      <Today
                        style={{
                          fontSize: "12px",
                          fontFamily: "Bellota-Light",
                        }}
                      />
                      <span>
                        Updated {project && format(project.updatedAt)}
                      </span>
                    </div>
                  </div>
                  <div className="detailbox">
                    <div className="boxdetails">
                      <div className="singledetail">
                        <h3>Property ID:</h3>
                        <span>{project && project._id}</span>
                      </div>

                      {details.rooms && (
                        <div className="singledetail">
                          <h3>Rooms</h3>
                          <span>{details.rooms && details.rooms}</span>
                        </div>
                      )}
                      {details.garage && (
                        <div className="singledetail">
                          <h3>Garage:</h3>
                          <span>{details.garage && details.garage}</span>
                        </div>
                      )}
                      {details.area && (
                        <div className="singledetail">
                          <h3>Area</h3>
                          <span>{details.area && details.area}</span>
                        </div>
                      )}
                      {details.yearBuilt && (
                        <div className="singledetail">
                          <h3>Year Built:</h3>
                          <span>{details.yearBuilt && details.yearBuilt}</span>
                        </div>
                      )}
                      {details.propertySize && (
                        <div className="singledetail">
                          <h3>Property Size</h3>
                          <span>
                            {details.propertySize && details.propertySize}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className='detailtitle'>
                <h3>Additonal Details</h3>
              </div>
              <div className='aditonal'>
                <div className='singleaditonal'>
                  <h3>Deposit:</h3>
                  <span>20%</span>
                </div>
                <div className='singleaditonal'>
                  <h3>Pool Size:</h3>
                  <span>300 Sqft</span>
                </div>
                <div className='singleaditonal'>
                  <h3>Last remodel year:</h3>
                  <span>1987</span>
                </div>
                <div className='singleaditonal'>
                  <h3>Amenities:</h3>
                  <span>Clubhouse</span>
                </div>
                <div className='singleaditonal'>
                  <h3>Additional Rooms:</h3>
                  <span>Guest Bath</span>
                </div>
                <div className='singleaditonal'>
                  <h3>Equipment:</h3>
                  <span>Grill - Gas</span>
                </div>
              </div> */}
                </div>
                {project && project.features && (
                  <div className="feature">
                    <div className="featuretitle">
                      <h3>Feature</h3>
                    </div>
                    <div className="featureicons">
                      {project && project.features.airConditioning && (
                        <div className="singularfeature">
                          <Rooms
                            icons={
                              <CheckCircleOutline
                                style={{
                                  fontFamily: "Bellota-Light",
                                  fontSize: "13px",
                                }}
                              />
                            }
                            number={" Air Conditioning"}
                          />
                        </div>
                      )}
                      {project && project.features.elevator && (
                        <div className="singularfeature">
                          <Rooms
                            icons={
                              <CheckCircleOutline
                                style={{
                                  fontFamily: "Bellota-Light",
                                  fontSize: "13px",
                                }}
                              />
                            }
                            number={"   Elevator"}
                          />
                        </div>
                      )}
                      {project && project.features.gym && (
                        <div className="singularfeature">
                          <Rooms
                            icons={
                              <CheckCircleOutline
                                style={{
                                  fontFamily: "Bellota-Light",
                                  fontSize: "13px",
                                }}
                              />
                            }
                            number={"   Gym"}
                          />
                        </div>
                      )}
                      {project && project.features.Balcony && (
                        <div className="singularfeature">
                          <Rooms
                            icons={
                              <CheckCircleOutline
                                style={{
                                  fontFamily: "Bellota-Light",
                                  fontSize: "13px",
                                }}
                              />
                            }
                            number={"   Balcony"}
                          />
                        </div>
                      )}
                      {project && project.features.laundary && (
                        <div className="singularfeature">
                          <Rooms
                            icons={
                              <CheckCircleOutline
                                style={{
                                  fontFamily: "Bellota-Light",
                                  fontSize: "13px",
                                }}
                              />
                            }
                            number={"   Laundry"}
                          />
                        </div>
                      )}
                      {project && project.features.pool && (
                        <div className="singularfeature">
                          <Rooms
                            icons={
                              <CheckCircleOutline
                                style={{
                                  fontFamily: "Bellota-Light",
                                  fontSize: "13px",
                                }}
                              />
                            }
                            number={"   Swimming Pool"}
                          />
                        </div>
                      )}
                      {project && project.features.wifi && (
                        <div className="singularfeature">
                          <Rooms
                            icons={
                              <CheckCircleOutline
                                style={{
                                  fontFamily: "Bellota-Light",
                                  fontSize: "13px",
                                }}
                              />
                            }
                            number={"   WiFi"}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="description">
                  <div className="disc">
                    <h3>Reivews</h3>
                  </div>

                  {project ? (
                    <Review
                      review={project && project.reviewRecieved}
                      average={project && Math.round(project.avgRating, 1)}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="rightside">
                <div className="messenger">
                  <div className="topmessenger">
                    <img src="" alt="" />
                    <div className="userinfo">
                      {project && project.userInfo && (
                        <Rooms
                          icons={<AccountCircle />}
                          number={project.userInfo.userName}
                        />
                      )}
                      {linker && (
                        <Link to={linker} className="link">
                          <span className="username">View Listing</span>
                        </Link>
                      )}
                    </div>
                    <div className="rateproject">
                      {rateProject.isLoading ? (
                        <div>
                          <CircularProgress />
                        </div>
                      ) : (
                        <div className="ratingstars">
                          {value && value >= 1 ? (
                            <Star
                              className="star pressed"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(1)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }
                              }}
                            />
                          ) : reviewAvrg >= 1 ? (
                            <Star
                              className="star pressed"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(1)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }

                                // handle
                              }}
                            />
                          ) : (
                            <StarOutlineSharp
                              style={{
                                cursor: "pointer",
                              }}
                              className="star"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(1)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }
                              }}
                            />
                          )}
                          {value >= 2 ? (
                            <Star
                              className="star pressed"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(2)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }
                              }}
                            />
                          ) : reviewAvrg >= 2 ? (
                            <Star
                              className="star pressed"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(2)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }
                              }}
                            />
                          ) : (
                            <StarOutlineSharp
                              style={{
                                cursor: "pointer",
                              }}
                              className="star"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(2)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }
                              }}
                            />
                          )}
                          {value >= 3 ? (
                            <Star
                              className="star pressed"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(3)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }
                              }}
                            />
                          ) : reviewAvrg >= 3 ? (
                            <Star
                              className="star pressed"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(3)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }
                              }}
                            />
                          ) : (
                            <StarOutlineSharp
                              style={{
                                cursor: "pointer",
                              }}
                              className="star"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(3)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }
                              }}
                            />
                          )}
                          {value >= 4 ? (
                            <Star
                              className="star pressed"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(4)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }
                              }}
                            />
                          ) : reviewAvrg >= 4 ? (
                            <Star
                              className="star pressed"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(4)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }
                              }}
                            />
                          ) : (
                            <StarOutlineSharp
                              style={{
                                cursor: "pointer",
                              }}
                              className="star"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(4)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }
                              }}
                            />
                          )}
                          {value >= 5 ? (
                            <Star
                              className="star pressed"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(5)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }
                              }}
                            />
                          ) : reviewAvrg >= 5 ? (
                            <Star
                              className="star pressed"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(5)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }
                              }}
                            />
                          ) : (
                            <StarOutlineSharp
                              style={{
                                cursor: "pointer",
                              }}
                              className="star"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(5)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }
                              }}
                            />
                          )}
                          <span className="ratingspan">
                            {project &&
                              project.reviewRecieved &&
                              project.reviewRecieved.length}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="middle">
                    <TextField
                      style={{
                        // height: "20%",
                        fontSize: "10px",
                      }}
                      // size="small"
                      className="registertextfield"
                      label="Comment"
                      variant="outlined"
                      multiline
                      onChange={(e) => {
                        setComment(e.target.value)
                      }}
                      rows={4}
                    />
                    {/* <FormControl size="small" className="registertextfield">
                      <InputLabel>Select your account type</InputLabel>
                      <Select value="" label="Coffee Profile" required>
                        <MenuItem value="Agent">Agent</MenuItem>
                        <MenuItem value="Agency">Agency</MenuItem>
                        <MenuItem value="Buyer">Buyer</MenuItem>
                        <MenuItem value="Seller">Seller</MenuItem>
                      </Select>
                    </FormControl> */}
                    {/* <div className="messengercheck">
                      <FormControlLabel
                        control={
                          <Checkbox
                            style={{
                              fontSize: "13px",
                              fontFamily: "Bellota-Light",
                            }}
                          />
                        }
                        label="By submitting this form I agree to "
                        style={{ fontSize: 13, fontFamily: "Bellota-Light" }}
                      />
                      <a href="https://realestate.niddf.com/">Terms of Use</a>
                    </div> */}
                  </div>
                  <div className="messengerbottom">
                    <button
                      onClick={(e) => {
                        handleReview(value, comment)
                      }}
                      disabled={!value && !comment}
                    >
                      Upload Review
                    </button>
                    {/* <button>Call</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  )
}
