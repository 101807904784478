import { Link } from "react-router-dom"
// css class
import "../../../styles/layout/side/sidebar.scss"
import Rooms from "../../../example/rooms"
import {
  FavoriteBorderOutlined,
  AccountCircleOutlined,
  Lock,
} from "@mui/icons-material"
// trigger to logout the user
import { logout } from "../../../redux/apiCall"
import { useDispatch } from "react-redux"
export default function Sidebar({ open }) {
  const dispatch = useDispatch()
  return (
    <div className={open ? "sidebar" : "sidebar close"}>
      <div className="catagory">
        <Link
          className="link"
          style={{ width: "100%", height: "100%" }}
          to="/profile_favorite"
        >
          <div className="lists">
            <Rooms
              icons={
                <FavoriteBorderOutlined
                  color="#004274"
                  fontSize="15px"
                  style={{ marginRight: "10px" }}
                />
              }
              number={"Favorites"}
            />
          </div>
        </Link>
        <Link
          className="link"
          style={{ width: "100%", height: "100%" }}
          to="/profile"
        >
          <div className="lists">
            <Rooms
              icons={
                <AccountCircleOutlined
                  color="#004274"
                  fontSize="15px"
                  style={{ marginRight: "10px" }}
                />
              }
              number={"My Profile"}
            />
          </div>
        </Link>
        <Link className="link" style={{ width: "100%", height: "100%" }} to="/">
          <div
            className="lists"
            onClick={(e) => {
              logout(dispatch)
            }}
          >
            <Rooms
              icons={
                <Lock
                  color="#004274"
                  fontSize="15px"
                  style={{ marginRight: "10px" }}
                />
              }
              number={"Logout"}
            />
          </div>
        </Link>
      </div>
    </div>
  )
}
