//css class
import { Link } from "react-router-dom"
import "../../../styles/display/footer/footer.scss"
import {
  Copyright,
  FacebookOutlined,
  Instagram,
  Twitter,
} from "@mui/icons-material"
export default function Footer() {
  return (
    <div className="footer">
      <div className="insidefooter">
        <div className="titlefooter">
          <span>NIDDF</span>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lacus,
            auctor pretium ac ultrices. Dui lacus dignissim tincidunt urna,
          </p>

          <div className="whomadeit">
            <Copyright
              style={{ fontSize: "12", fontFamily: "Bellota-Light" }}
            />{" "}
            <span>M00n-Man92</span>
          </div>
        </div>
        <div className="links">
          <span>Quick Links</span>
          <div className="quicklinks">
            <Link className="link" to={"/blogs"}>
              <span>Blogs</span>
            </Link>
            <Link className="link" to={"/firms"}>
              <span>Firms</span>
            </Link>
            <Link className="link" to={"/manufacturers"}>
              <span>Manufacturers</span>
            </Link>
            <Link className="link" to={"/products"}>
              <span>Products</span>
            </Link>
            <Link className="link" to={"/professionals"}>
              <span>Professionals</span>
            </Link>
            <Link className="link" to={"/projects"}>
              <span>Projects</span>
            </Link>
          </div>
        </div>
        <div className="service">
          <span>Service Policy</span>
          <div className="policies">
            <span>Terms & Conditions</span>
            <span>Privacy Policy</span>
            <span>Shipping & Delivery</span>
            <span>Retruns & Refunds Policy</span>
          </div>
        </div>
        <div className="findus">
          <span>Find US On</span>
          <div className="socials">
            <FacebookOutlined />
            <Instagram />
            <Twitter />
          </div>
        </div>
      </div>
    </div>
  )
}
