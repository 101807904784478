import axios from "axios"

// urls imported from the .env file
const baseUrl = process.env.REACT_APP_API_URL
const otherUrl = process.env.REACT_APP_LOCAL_URL
// getting the elements form the localstorage

export const GetToken = () => {
  var saved = JSON.parse(localStorage.getItem("persist:arch"))
  var token = null
  if (saved) {
    var user = saved.user
    if (user) {
      var currentUser = JSON.parse(user).currentUser
      if (currentUser && currentUser.data) {
        token = currentUser.data.token
      }
    }
  }
  return token
}
export const publicRequest = axios.create({ baseURL: baseUrl })
export const userRequest = axios.create({
  baseURL: baseUrl,
  headers: { token: `Bearer ${GetToken()}` },
})
