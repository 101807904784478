import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
// Icon class
import Rooms from "../../../../example/rooms"
// image container
import ImageBox from "../../../../example/imagebox"
// Items class
import Pictured from "../../../../example/item/pictured"
// csss class
import "../../../../styles/pages/manufacturer/singelpage/singlepage.scss"
// mui
import {
  FacebookOutlined,
  Grade,
  Instagram,
  Google,
  Info,
  LinkedIn,
  LocationOn,
  Pinterest,
  ReportProblem,
  Share,
  Star,
  StarBorderOutlined,
  StarOutlineSharp,
  Twitter,
  YouTube,
  NotificationAdd,
  NotificationsActive,
} from "@mui/icons-material"
import Alert from "@mui/material/Alert"

import Border from "../../../../example/borderbottom"
import { useLocation } from "react-router"

import HeaderNavLink from "../../../../example/headerNavLinks"
import { CircularProgress, Modal } from "@mui/material"

//
import { useQuery, useMutation } from "@tanstack/react-query"

//
import { publicRequest, userRequest } from "../../../../constant/requestMethods"

import { useSelector } from "react-redux"

// modal that appears when unfollow button is cliked
import UnfollowModal from "../../../../example/unfollowmodal"

// review layout
import Review from "../../../../example/review"
import Footer from "../../../display/footer"

export default function ManufacturerPage() {
  // holds state for the user from the browser storage
  const { currentUser } = useSelector((state) => state.user)

  const location = useLocation()

  const id = location.pathname.split("/")[2]

  // store the data of the user
  const [manufacturer, setManufacturer] = useState({})

  // store the manufacturermData variables
  const [manufacturerData, setManufacturerData] = useState({})

  const [manufacturerProduct, setManufacturerProduct] = useState([])

  // stores the projects
  const [manufacturerProject, setManufacturerProject] = useState([])

  // stores the materials
  const [manufacturerMaterial, setManufacturerMaterial] = useState([])

  // hold the linker for the query changes when the buttons are pressed
  const [linker, setLinker] = useState("/product/finduserproduct/" + id)

  // holds the data from the manufacturer product project or materials
  const [dataHolder, setDataHolder] = useState([])

  // holds the average computerd from the third request
  const [reviewAvrg, setReviewAvrg] = useState(1)

  // state to get the review value
  const [value, setValue] = useState(0)

  // state to check if a user is following the firm
  const [isFollowing, setIsFollowing] = useState(false)

  //state to open and close the unfollow modal
  const [modalOpen, setModalOpen] = useState(false)

  // controllers to show alets
  const [alert, setAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  // functions that runs when the page is loaded
  // sends a request to the server for the blog data

  /// just a list im gong to use as a for looop
  const loop = [0, 1, 2, 3, 4]
  const people = 95800
  let peopleholder
  if (people.toString().split("").length > 5) {
    peopleholder =
      people.toString().split("")[0] +
      people.toString().split("")[1] +
      people.toString().split("")[2] +
      "k"
  } else if (people.toString().split("").length > 4) {
    peopleholder =
      people.toString().split("")[0] + people.toString().split("")[1] + "k"
  }

  // vars and functions to use for the stars
  const [isPressed, setMsPressed] = useState(0)
  // Todo A function here to add the review from the user to the manufacturer and save it
  // changes the catagories from roduct, projects ot materials
  const pagechanger = [
    { name: "Product", value: 0, link: "/product/finduserproduct/" + id },
    { name: "Material", value: 1, link: "/material/findusermaterial/" + id },
    { name: "Project", value: 2, link: "/project/finduserproject/" + id },
  ]

  const [projectChanger, setProjectChanger] = useState("Product")

  // query to find the user and the firm
  const findManufacturer = useQuery({
    queryKey: ["findsingelmanufacturer"],
    queryFn: async () => {
      try {
        // await userRequest.get("/blog/find?page=1&limit=5")
        const reply = await publicRequest.get(
          `manufacturer/find/singlepage/${id}`
        )
        // console.log(reply)
        if (reply.status === 201) {
          // console.log(reply.data.data)
          setManufacturer(reply.data.data[0] && reply.data.data[0])
          setManufacturerData(
            reply.data.data[0] &&
              reply.data.data[0].manufacturerData[0] &&
              reply.data.data[0].manufacturerData[0]
          )
          setManufacturerProduct(
            reply.data.data[0] &&
              reply.data.data[0].manufacturerData[0] &&
              reply.data.data[0].manufacturerData[0].products
          )
          setManufacturerProject(
            reply.data.data[0] &&
              reply.data.data[0].manufacturerData[0] &&
              reply.data.data[0].manufacturerData[0].projects
          )
          setManufacturerMaterial(
            reply.data.data[0] &&
              reply.data.data[0].manufacturerData[0] &&
              reply.data.data[0].manufacturerData[0].materials
          )
          // console.log(reply.data.data)
          reply.data.data &&
            reply.data.data[0] &&
            reply.data.data[0].manufacturerData[0] &&
            reply.data.data[0].manufacturerData[0].manufacturerInfo &&
            reply.data.data[0].manufacturerData[0].manufacturerInfo.followers &&
            reply.data.data[0].manufacturerData[0].manufacturerInfo.followers.map(
              (item, index) => {
                if (
                  currentUser &&
                  currentUser.data &&
                  currentUser.data._id &&
                  currentUser.data._id === item
                ) {
                  // console.log("user Follow firm")
                  setIsFollowing(true)
                }
              }
            )
          // return reply
          // setImage(reply.data.data.image[0])
          // setNewDate(formatDistance(new Date(blog.createdAt), new Date()))
        } else {
          setAlert(true)
          setErrorAlert(true)
          setErrorMessage("couldn't get blog")
          // return errorMessage
        }

        return reply
      } catch (e) {
        setAlert(true)
        setErrorAlert(true)
        setErrorMessage(e.message)
        console.log(e)
        return e
        // console.log(e.message)
      }
    },
    cacheTime: Infinity,
    staleTime: Infinity,
  })

  // request to find materials for the manufacturer has made
  const findMaterials = useQuery({
    queryKey: ["findManufacturerMaterials"],
    queryFn: async () => {
      // console.log(linker)

      try {
        // console.log("here")
        // await userRequest.get("/blog/find?page=1&limit=5")
        const reply = await publicRequest.get(linker)
        // console.log(reply)
        if (reply.status === 201) {
          // console.log(reply.data.data)
          // console.log(reply)
          setDataHolder(reply.data.data)

          // setImage(reply.data.data.image[0])
          // setNewDate(formatDistance(new Date(blog.createdAt), new Date()))
        } else {
          setAlert(true)
          setErrorAlert(true)
          setErrorMessage("couldn't get materials")
        }

        return reply
      } catch (e) {
        setAlert(true)
        setErrorAlert(true)
        setErrorMessage(e.message)
        // console.log(e)
        return e
      }
    },
    enabled: successAlert,
    cacheTime: Infinity,
    staleTime: Infinity,
  })

  // request to find reviews for the manufacturer has made
  const FindReview = useQuery({
    queryKey: ["findManufacturerReview"],
    queryFn: async () => {
      try {
        // await userRequest.get("/blog/find?page=1&limit=5")
        if (manufacturerData._id) {
          const reply = await publicRequest.get(
            `manufacturer/review/${manufacturerData._id}`
          )
          // console.log()
          if (reply.status === 201) {
            // console.log(reply.data.data)
            setReviewAvrg(
              reply.data.data[0] && Math.round(reply.data.data[0].avgRating)
            )
            return reply

            // setImage(reply.data.data.image[0])
            // setNewDate(formatDistance(new Date(blog.createdAt), new Date()))
          } else {
            setAlert(true)
            setErrorAlert(true)
            setErrorMessage("review")
            return reply
          }
          return reply
        }
        return "no user reviews found"
      } catch (e) {
        setAlert(true)
        setErrorAlert(true)
        setErrorMessage(e.message)
        return e
        // console.log(e.message)
      }
    },
    enabled: successAlert,
    cacheTime: Infinity,
    staleTime: Infinity,
  })

  // endpoint to rate manufacturer
  const createReview = async (data) => {
    const reply = await userRequest.put(
      `manufacturer/ratemanufacturer/${currentUser.data._id}/${manufacturerData._id}`,
      data
    )
    return reply.data
  }
  const rateManufacturer = useMutation(createReview, {
    onSuccess: (data) => {
      setAlert(true)
      setSuccessAlert(true)
      // console.log(data)
      setSuccessMessage(data.msg)
    },
    onError: (e) => {
      setAlert(true)
      setErrorAlert(true)
      setErrorMessage(e.message)
    },
  })

  // create or update review
  const handleReview = (num) => {
    setAlert(false)
    setErrorAlert(false)
    setSuccessAlert(false)
    setErrorMessage("")
    setSuccessMessage("")
    let reviewed = { value: num }
    // console.log(reviewed)
    setAlert(false)
    setErrorAlert(false)
    setSuccessAlert(false)
    setErrorMessage("")
    setSuccessMessage("")
    if (currentUser && currentUser.data && currentUser.data._id) {
      rateManufacturer.mutate(reviewed)
    } else {
      setAlert(true)
      setErrorAlert(true)
      setSuccessAlert(false)
      setErrorMessage("login to rate manufacturer")
    }
  }

  // end point to follow manufacturer
  const followOrg = async () => {
    const reply = await userRequest.put(
      `manufacturer/followmanufacturer/${currentUser.data._id}/${manufacturerData._id}`
    )
    return reply.data
  }
  const sendFollow = useMutation(followOrg, {
    onSuccess: (data) => {
      setAlert(true)
      setSuccessAlert(true)
      // console.log(data)
      setSuccessMessage(data.msg)
    },
    onError: (e) => {
      setAlert(true)
      setErrorAlert(true)
      setErrorMessage(e.message)
    },
  })

  const handleFollow = () => {
    setAlert(false)
    setErrorAlert(false)
    setSuccessAlert(false)
    setErrorMessage("")
    setSuccessMessage("")
    if (currentUser && currentUser.data && currentUser.data._id) {
      sendFollow.mutate()
    } else {
      setAlert(true)
      setErrorAlert(true)
      setSuccessAlert(false)
      setErrorMessage("login to follow manufacturer")
    }
  }

  const { display, unFollowed } = UnfollowModal(
    modalOpen,
    setModalOpen,
    manufacturer && manufacturer.userName,
    "manufacturer",
    currentUser && currentUser.data && currentUser.data._id,
    manufacturerData && manufacturerData._id,
    setIsFollowing
  )
  const renderModal = () => {
    return (
      <Modal
        sx={{ overflow: "auto" }}
        open={modalOpen}
        onClose={() => {
          setModalOpen(!modalOpen)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {display}
      </Modal>
    )
  }

  useEffect(() => {
    findMaterials.refetch()
  }, [projectChanger, linker])

  useEffect(() => {
    findManufacturer.refetch()
  }, [sendFollow.isSuccess, rateManufacturer.isSuccess, unFollowed])

  useEffect(() => {
    FindReview.refetch()
  }, [handleReview, value])
  // console.log(manufacturer);
  const style = {
    width: "30%",
    height: "28%",
    display: "flex",
    backgroundColor: "blue",
    flexDirection: "column",
  }
  return (
    <div className="individualmanufacturerpage">
      {findManufacturer.isLoading ? (
        <div
          style={{
            width: "100dvw",
            height: "100dvh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {alert && (
            <div className="alert">
              {errorAlert && <Alert severity="error">{errorMessage}</Alert>}
              {successAlert && (
                <Alert severity="success">{successMessage}</Alert>
              )}
            </div>
          )}
          {renderModal()}
          <div className="manufacturerheader">
            <img src={manufacturer.logo && manufacturer.logo} alt="" />

            <div className="manufacturerinforight">
              <div className="manufacturerimagecontainer">
                <ImageBox
                  src={
                    manufacturer &&
                    manufacturer.profilepic &&
                    manufacturer.profilepic
                  }
                />
              </div>
              <div className="imageheaders">
                <div className="insideheaders">
                  <div className="tilteandlinktoprofile">
                    <span className="titlemanufacturer">
                      {manufacturer.name && manufacturer.name}
                    </span>
                    {currentUser &&
                      currentUser.data &&
                      currentUser.data._id &&
                      currentUser.data._id === id && (
                        <Link to={`/profile`}>
                          <button className="linker">Go To Your Profile</button>
                        </Link>
                      )}
                  </div>
                  <Border
                    first={"Professional type General Contractors"}
                    style={{ color: "white", fontFamily: "Bellota-Light" }}
                  />
                  {/* <Rooms icons={<LocationOn style={{ fontSize: "12px" }} />} number={"1611 Michigan Ave, Miami Beach, FL 33139"} /> */}
                </div>
                <div className="lowerdetails">
                  <div className="llicence">
                    <span className="bolder">License:</span>
                    <span> US-123-456-5463</span>
                  </div>
                  <div className="llicence">
                    <span className="bolder">License:</span>
                    <span> US-123-456-5463</span>
                  </div>
                  <div className="buttons">
                    <button>Send email</button>
                    <button>Call</button>
                  </div>
                  {/* <div className='follow'>
                <button >
                  Follow
                </button>
              </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="amnufacturerinsideindiv">
            {/* hellow oweld */}
            {/* <div className='manufacturernavigation'>

          <HeaderNavLink text={"About us"} idlink={"about"} icon={<Info style={{ color: "#999999", fontSize: "16px" }} />} />
          <HeaderNavLink text={"Rating"} idlink={"rating"} icon={<Grade style={{ color: "#999999", fontSize: "16px" }} />} />
          <HeaderNavLink text={"Products"} idlink={"products"} icon={<Info style={{ color: "#999999", fontSize: "16px" }} />} />
          <HeaderNavLink text={"Projects"} idlink={"projects"} icon={<Info style={{ color: "#999999", fontSize: "16px" }} />} />
          <HeaderNavLink text={"Share"} idlink={"share"} icon={<Share style={{ color: "#999999", fontSize: "16px" }} />} />
          <HeaderNavLink text={"Report"} idlink={"report"} icon={<ReportProblem style={{ color: "#999999", fontSize: "16px" }} />} />

        </div> */}

            <div className="manufacturerprojectslists" id="projects">
              <div className="manufacturerlistscontainer">
                <div className="listingbuttons">
                  {pagechanger.map((item, index) => (
                    <button
                      key={index + 23}
                      className={
                        projectChanger === item.name
                          ? "productsbutton on"
                          : "productsbutton"
                      }
                      value={0}
                      onClick={(e) => {
                        setProjectChanger(item.name)
                        setLinker(item.link)
                      }}
                    >
                      {item.name}({" "}
                      {item.name === "Product"
                        ? manufacturerProduct.length
                        : item.name === "Project"
                        ? manufacturerProject.length
                        : manufacturerMaterial.length}{" "}
                      )
                    </button>
                  ))}
                </div>
                <span className="manufacturerprojectsheadertitle">
                  {projectChanger}
                </span>
                <div className="manufacturerprojectslayout">
                  {findMaterials.isLoading ? (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    dataHolder.map((item, index) => (
                      <Link
                        to={
                          projectChanger === "Project"
                            ? `/project/${item._id}`
                            : projectChanger === "Product"
                            ? `/product/${item._id}`
                            : `/material/${item._id}`
                        }
                        className="link"
                        key={index + 231}
                      >
                        <div className="individualmanufacturerprojects">
                          <div className="imageanimation">
                            <img src={item.image[0] && item.image[0]} alt="" />
                          </div>

                          <div className="manufacturerindivinfo">
                            <span>{item.title}</span>
                            {/* <span>details</span> */}
                          </div>
                        </div>
                      </Link>
                    ))
                  )}
                </div>
              </div>
              <div className="separeate">
                <div className="manufacturerprojecstsisde">
                  <div className="manufacturerratingstars">
                    <div className="manufacturercircularpic">
                      <img
                        className="circularimage"
                        src={manufacturer.profilepic && manufacturer.profilepic}
                        alt=""
                      />
                    </div>
                    <div className="ratingstars">
                      {/* Todo  server should compute if the user has a new vote or is updating an older vote 
                    and for the manufacturer sake the comupted votes should be 4 untill the voters reach atleast a 100
                */}
                      {rateManufacturer.isLoading ? (
                        <div>
                          <CircularProgress />
                        </div>
                      ) : (
                        <div className="ratingstars">
                          {value && value >= 1 ? (
                            <Star
                              className="star pressed"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(1)
                                  handleReview(1)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }
                              }}
                            />
                          ) : reviewAvrg >= 1 ? (
                            <Star
                              className="star pressed"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(1)
                                  handleReview(1)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }

                                // handle
                              }}
                            />
                          ) : (
                            <StarOutlineSharp
                              style={{
                                cursor: "pointer",
                              }}
                              className="star"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(1)
                                  handleReview(1)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }
                              }}
                            />
                          )}
                          {value >= 2 ? (
                            <Star
                              className="star pressed"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(2)
                                  handleReview(2)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }
                              }}
                            />
                          ) : reviewAvrg >= 2 ? (
                            <Star
                              className="star pressed"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(2)
                                  handleReview(2)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }
                              }}
                            />
                          ) : (
                            <StarOutlineSharp
                              style={{
                                cursor: "pointer",
                              }}
                              className="star"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(2)
                                  handleReview(2)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }
                              }}
                            />
                          )}
                          {value >= 3 ? (
                            <Star
                              className="star pressed"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(3)
                                  handleReview(3)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }
                              }}
                            />
                          ) : reviewAvrg >= 3 ? (
                            <Star
                              className="star pressed"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(3)
                                  handleReview(3)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }
                              }}
                            />
                          ) : (
                            <StarOutlineSharp
                              style={{
                                cursor: "pointer",
                              }}
                              className="star"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(3)
                                  handleReview(3)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }
                              }}
                            />
                          )}
                          {value >= 4 ? (
                            <Star
                              className="star pressed"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(4)
                                  handleReview(4)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }
                              }}
                            />
                          ) : reviewAvrg >= 4 ? (
                            <Star
                              className="star pressed"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(4)
                                  handleReview(4)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }
                              }}
                            />
                          ) : (
                            <StarOutlineSharp
                              style={{
                                cursor: "pointer",
                              }}
                              className="star"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(4)
                                  handleReview(4)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }
                              }}
                            />
                          )}
                          {value >= 5 ? (
                            <Star
                              className="star pressed"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(5)
                                  handleReview(5)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }
                              }}
                            />
                          ) : reviewAvrg >= 5 ? (
                            <Star
                              className="star pressed"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(5)
                                  handleReview(5)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }
                              }}
                            />
                          ) : (
                            <StarOutlineSharp
                              style={{
                                cursor: "pointer",
                              }}
                              className="star"
                              onClick={(e) => {
                                if (
                                  currentUser &&
                                  currentUser.data &&
                                  currentUser.data._id &&
                                  currentUser.data._id
                                ) {
                                  setValue(5)
                                  handleReview(5)
                                } else {
                                  setAlert(true)
                                  setErrorAlert(true)
                                  setErrorMessage("please login to add review")
                                }
                              }}
                            />
                          )}
                          <span className="ratingspan">
                            {manufacturerData &&
                              manufacturerData.reviewRecieved &&
                              manufacturerData.reviewRecieved.length}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="followingsection">
                    <span className="manufacturertitle">
                      {manufacturer.name}
                    </span>
                    {/* Todo it should be known as the user logs in that whether he/she follows the manufacturer maybe on serverside or */}

                    {currentUser &&
                    currentUser.data &&
                    currentUser.data._id &&
                    currentUser.data._id === id ? (
                      <></>
                    ) : false ? (
                      <button className="followmanufacturer">Follow</button>
                    ) : (
                      <div className="useralreadyfollows">
                        {!isFollowing ? (
                          sendFollow.isLoading ? (
                            <div>
                              <CircularProgress />
                            </div>
                          ) : (
                            <NotificationAdd
                              className="following"
                              onClick={(e) => {
                                handleFollow()
                              }}
                            />
                          )
                        ) : (
                          <>
                            <NotificationsActive
                              className="following pressed"
                              style={{ color: "goldenrod" }}
                            />
                            <button
                              className="unfollowmanufacturer"
                              onClick={(e) => {
                                setModalOpen(true)
                              }}
                            >
                              UnFollow
                            </button>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="listofdetails">
                    {/* <Border first={"Projects"} second={"73"} />
              <Border first={"Products"} second={"2"} />
              <Border first={"Materials"} second={"23"} /> */}
                    <div className="spacebetween">
                      <Border
                        first={"Followers"}
                        second={
                          manufacturerData &&
                          manufacturerData.manufacturerInfo &&
                          manufacturerData.manufacturerInfo.followers &&
                          manufacturerData.manufacturerInfo.followers.length
                        }
                      />
                    </div>
                    <div className="spacebetween">
                      <Border
                        first={"Following"}
                        second={
                          manufacturer &&
                          manufacturer.following &&
                          manufacturer.following.length
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="contactmanufacturer">
                  <div className="containing">
                    <span className="contacttilte">Contact</span>
                    <div className="contactimage">
                      <ImageBox src={manufacturer.img} />
                    </div>
                    <div className="spacebetween">
                      <Rooms
                        icons={<LocationOn style={{ fontSize: "12px" }} />}
                        number={"1611 Michigan Ave, Miami Beach, FL 33139"}
                      />
                    </div>
                    <div className="website">
                      <div className="spacebetween">
                        {manufacturerData && manufacturerData.officeCell && (
                          <Border first={"Office"} second={"098 307 8383"} />
                        )}
                      </div>
                      <div className="spacebetween">
                        {manufacturer && manufacturer.cellPhone && (
                          <Border
                            first={"Cell"}
                            second={manufacturer.cellPhone}
                          />
                        )}
                      </div>
                      <div className="spacebetween">
                        {manufacturer && manufacturer.email && (
                          <Border first={"Email"} second={manufacturer.email} />
                        )}
                      </div>
                    </div>
                    <div className="findonsocials">
                      <span className="manufacturersocialstitlte">
                        you can find {manufacturer.name} on
                      </span>
                      <div className="manufacturersocialhandles">
                        {manufacturerData &&
                          manufacturerData.manufacturerInfo &&
                          manufacturerData.manufacturerInfo.socialLinks &&
                          manufacturerData.manufacturerInfo.socialLinks &&
                          manufacturerData.manufacturerInfo.socialLinks
                            .facebook &&
                          manufacturerData.manufacturerInfo.socialLinks
                            .facebook[0] && (
                            <a
                              href={
                                manufacturerData.manufacturerInfo.socialLinks
                                  .facebook[0]
                              }
                            >
                              <FacebookOutlined className="facebook" />
                            </a>
                          )}

                        {manufacturerData &&
                          manufacturerData.manufacturerInfo &&
                          manufacturerData.manufacturerInfo.socialLinks &&
                          manufacturerData.manufacturerInfo.socialLinks &&
                          manufacturerData.manufacturerInfo.socialLinks
                            .instagram &&
                          manufacturerData.manufacturerInfo.socialLinks
                            .instagram[0] && (
                            <a
                              href={
                                manufacturerData.manufacturerInfo.socialLinks
                                  .instagram[0]
                              }
                            >
                              <Instagram className="insta" />
                            </a>
                          )}
                        {manufacturerData &&
                          manufacturerData.manufacturerInfo &&
                          manufacturerData.manufacturerInfo.socialLinks &&
                          manufacturerData.manufacturerInfo.socialLinks &&
                          manufacturerData.manufacturerInfo.socialLinks
                            .twitter &&
                          manufacturerData.manufacturerInfo.socialLinks
                            .twitter[0] && (
                            <a
                              href={
                                manufacturerData.manufacturerInfo.socialLinks
                                  .twitter[0]
                              }
                            >
                              <Twitter className="twitter" />
                            </a>
                          )}
                        {manufacturerData &&
                          manufacturerData.manufacturerInfo &&
                          manufacturerData.manufacturerInfo.socialLinks &&
                          manufacturerData.manufacturerInfo.socialLinks &&
                          manufacturerData.manufacturerInfo.socialLinks
                            .linkedIn &&
                          manufacturerData.manufacturerInfo.socialLinks
                            .linkedIn[0] && (
                            <a
                              href={
                                manufacturerData.manufacturerInfo.socialLinks
                                  .linkedIn[0]
                              }
                            >
                              <LinkedIn className="linkedin" />
                            </a>
                          )}
                        {manufacturerData &&
                          manufacturerData.manufacturerInfo &&
                          manufacturerData.manufacturerInfo.socialLinks &&
                          manufacturerData.manufacturerInfo.socialLinks &&
                          manufacturerData.manufacturerInfo.socialLinks
                            .google &&
                          manufacturerData.manufacturerInfo.socialLinks
                            .google[0] && (
                            <a
                              href={
                                manufacturerData.manufacturerInfo.socialLinks
                                  .google[0]
                              }
                            >
                              <Google className="googs" />
                            </a>
                          )}
                        {manufacturerData &&
                          manufacturerData.manufacturerInfo &&
                          manufacturerData.manufacturerInfo.socialLinks &&
                          manufacturerData.manufacturerInfo.socialLinks &&
                          manufacturerData.manufacturerInfo.socialLinks
                            .youtube &&
                          manufacturerData.manufacturerInfo.socialLinks
                            .youtube[0] && (
                            <a
                              href={
                                manufacturerData.manufacturerInfo.socialLinks
                                  .youtube[0]
                              }
                            >
                              <YouTube className="yt" />
                            </a>
                          )}

                        {manufacturerData &&
                          manufacturerData.manufacturerInfo &&
                          manufacturerData.manufacturerInfo.socialLinks &&
                          manufacturerData.manufacturerInfo.socialLinks &&
                          manufacturerData.manufacturerInfo.socialLinks
                            .pinterest &&
                          manufacturerData.manufacturerInfo.socialLinks
                            .pinterest[0] && (
                            <a
                              href={
                                manufacturerData.manufacturerInfo.socialLinks
                                  .pinterest[0]
                              }
                            >
                              <Pinterest className="pindabest" />
                            </a>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {manufacturerData &&
              manufacturerData.discription &&
              manufacturerData.discription.length > 0 && (
                <div className="manufacturermiddle">
                  <div className="aboutmanufacturer" id="about">
                    <div className="infocontainer">
                      <span className="infotilte">
                        {" "}
                        About {manufacturer.name}
                      </span>
                      {manufacturerData &&
                        manufacturerData.discription &&
                        manufacturerData.discription.length > 0 &&
                        manufacturerData.discription.map((item, index) => (
                          <p key={45 + index}>{item}</p>
                        ))}
                    </div>
                  </div>
                </div>
              )}
            <div className="manufacturermiddle">
              <div className="aboutmanufacturer" id="review">
                <div className="infocontainer">
                  <span className="infotilte"> Reviews</span>
                  {manufacturerData &&
                  manufacturerData.reviewRecieved &&
                  manufacturerData.reviewRecieved.length > 0 ? (
                    <Review
                      review={
                        manufacturerData &&
                        manufacturerData.reviewRecieved &&
                        manufacturerData.reviewRecieved
                      }
                      average={reviewAvrg && reviewAvrg}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            {/* <div className='manufacturerreport'>
          <div className='leftsidereport'>
            <div className='emailer'>
              <span>Send a message to {manufacturer.name}</span>
              <div className='messagesendingfields'>
                <div className='singlefield'>
                  <span>your Email</span>
                  <TextField variant="standard" />


                </div>
                <div className='singlefield'>
                  <span>Subject</span>
                  <TextField variant="standard" />


                </div>
                <div className='singlefield'>
                  <span>Message</span>
                  <TextField
                    multiline
                    rows={4} />

                </div>
                <button className='emailerbutton'> Send Message</button>
              </div>
            </div>
          </div>
          <div className='rightsidereport'></div>
        </div>
 */}
          </div>
        </>
      )}
      <Footer />
    </div>
  )
}
