import { useState, useEffect } from "react"
// css class
import "../../../styles/pages/blog/single/single.scss"
// page notifiyer
import Locator from "../../../example/locator"
// Icon plus span
import Rooms from "../../../example/rooms"

import { Today } from "@mui/icons-material"
import { CircularProgress, TextField } from "@mui/material"
import { useLocation } from "react-router"
import { NavigateNext, NavigateBefore } from "@mui/icons-material"

//
import { Link } from "react-router-dom"
//
import { useQuery } from "@tanstack/react-query"

//
import { publicRequest } from "../../../constant/requestMethods"

//
import Alert from "@mui/material/Alert"
import Footer from "../../display/footer"

export default function SingleBlog() {
  const location = useLocation()
  const blogLocation = location.pathname.split("/")[1]
  const id = location.pathname.split("/")[2]

  //state vars to hold the blog and nexpage if it there
  const [blog, setBlog] = useState({})
  const [newDate, setNewDate] = useState()
  const [recentBlog, setRecentBlog] = useState([])
  const [search, setSearch] = useState("")
  const [searchedBlog, setSearchedBlog] = useState()
  // controllers to show alets
  const [alert, setAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  const [image, setImage] = useState("")

  // state to hold the alue of the image before it slides to the next or prev
  const [count, setCount] = useState(0)
  // functions that runs when the page is loaded
  // sends a request to the server for the blog data
  const findSingleBlog = useQuery({
    queryKey: ["findsingelblogs"],
    queryFn: async () => {
      setAlert(false)
      setErrorAlert(false)
      setErrorMessage("")
      try {
        // await userRequest.get("/blog/find?page=1&limit=5")
        if (id) {
          const reply = await publicRequest.get(`/blog/find/${id}`)
          // console.log(reply)
          if (reply.status === 201) {
            // console.log(reply.data.data)
            reply.data.data && setBlog(reply.data.data)
            // setNewDate(formatDistance(new Date(blog.createdAt), new Date()))
          } else {
            setAlert(true)
            setErrorAlert(true)
            setErrorMessage("couldn't get blog")
          }
          return reply
        }
      } catch (e) {
        setAlert(true)
        setErrorAlert(true)
        setErrorMessage(e.message)
        console.log(e)
        return e
        // console.log(e.message)
      }
    },

    cacheTime: Infinity,
    staleTime: Infinity,
  })
  // functions that runs when the page is loaded
  // sends a request to the server for the blog data
  /* const findRecentBlogs = useQuery({
    queryKey: ["findrecentblogs"],
    queryFn: async () => {
      setAlert(false)
      setErrorAlert(false)
      setErrorMessage("")
      try {
        // await userRequest.get("/blog/find?page=1&limit=5")
        const reply = await publicRequest.get(`/blog/find/recent`)
        // console.log(reply)
        if (reply.status === 201) {
          setRecentBlog(reply.data.data)

          // setBlog(reply.data.data)
          // setNewDate(formatDistance(new Date(blog.createdAt), new Date()))
        } else {
          setAlert(true)
          setErrorAlert(true)
          setErrorMessage("couldn't get blog")
        }
        return reply
      } catch (e) {
        setAlert(true)
        setErrorAlert(true)
        setErrorMessage(e.message)
        console.log(e.message)
        return e
      }
    },

    cacheTime: Infinity,
    staleTime: Infinity,
  }) */

  /* const searchBlogs = useQuery({
    queryKey: ["searchblogs"],
    queryFn: async () => {
      setAlert(false)
      setErrorAlert(false)
      setErrorMessage("")
      setSearchedBlog("")
      try {
        // await userRequest.get("/blog/find?page=1&limit=5")
        if (search) {
          const reply = await publicRequest.get(`/blog/search?q=${search}`)
          // console.log(reply)
          if (reply.data.success) {
            setSearchedBlog(reply.data.data)
            // console.log(searchedBlog)
            // setBlog(reply.data.data)
            // setNewDate(formatDistance(new Date(blog.createdAt), new Date()))
          } else {
            setErrorMessage(reply.data.msg)
          }
          return reply
        }
      } catch (e) {
        setAlert(true)
        setErrorAlert(true)
        setErrorMessage(e.message)
        console.log(e)
        return e
        // console.log(e.message)
      }
    },
    cacheTime: Infinity,
    staleTime: Infinity,
  }) */

  /// movies the box one step to the left
  const handleMoveLeft = (number) => {
    if (number < 0) {
      setCount(blog.image.length - 1)
    } else if (number === 0) {
      console.log("first")
      setCount(blog.image.length)
    } else if (number >= 1) {
      setCount(number)
    }
  }
  // moves the box one item to the right
  const handleMoveRight = (number) => {
    if (number === blog.image.length - 1) {
      setCount(-1)

      // setPercent(6);
    } else if (number < blog.image.length) {
      // console.log(blog.image.length, count)
      setCount(number)
    }
  }

  /* useEffect(() => {
    searchBlogs.refetch()
  }, [search]) */

  useEffect(() => {
    findSingleBlog.refetch()
  }, [blogLocation])
  /* useEffect(() => {
    findRecentBlogs.refetch()
  }, [id]) */
  // console.log(blog)
  return (
    <div className="singleblog">
      <div className="insideblog">
        {errorAlert && (
          <div className="alert">
            {errorAlert && <Alert severity="error">{errorMessage} </Alert>}
          </div>
        )}
        <div className="blogcontainer">
          {findSingleBlog.isLoading ? (
            <div
              style={{
                width: "100%",
                minHeight: "100dvh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            blog && (
              <>
                <div className="leftsideblog">
                  <div className="blogheader">
                    <h3>{blog && blog.title}</h3>
                    <Rooms
                      icons={<Today style={{ fontSize: "14px" }} />}
                      number={newDate}
                    />
                  </div>
                  {/* {blog&&blog.image&&blog.image.length>0&& <img src={b} alt="" />} */}

                  <div className="singleblogimagecontainer">
                    <div className="singleblogbigger">
                      {/* TODO after receiveing the images as an array it should show the first unless the bottom images are pressed*/}
                      {blog && <img src={image ? image : blog.image} alt="" />}
                      {blog && blog.image && blog.image.length > 1 && (
                        <>
                          <div
                            className="singleblogleft"
                            onClick={(e) => {
                              if (count === 0) {
                                setImage(blog.image[blog.image.length - 1])
                              } else {
                                handleMoveLeft(count - 1)
                                setImage(blog.image[count - 1])
                              }
                            }}
                          >
                            <NavigateBefore
                              style={{ color: "white", fontSize: "22px" }}
                            />
                          </div>
                          <div
                            className="singleblogright"
                            onClick={(e) => {
                              if (count === blog.image.length - 1) {
                                setCount(0)
                                setImage(blog.image[0])
                              } else {
                                handleMoveRight(count + 1)
                                setImage(blog.image[count + 1])
                              }

                              // console.log(count)
                            }}
                          >
                            <NavigateNext
                              style={{ color: "white", fontSize: "22px" }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    {blog && blog.image && blog.image.length > 1 && (
                      <div className="singleblogsmaller">
                        <div className="singleblogswipper">
                          {blog &&
                            blog.image &&
                            blog.image.length > 0 &&
                            blog.image.map((item, index) => (
                              <div
                                key={index + 23134}
                                className="singleblogindividual"
                                onClick={(e) => {
                                  setCount(index)
                                  setImage(item)
                                }}
                              >
                                <img src={item} alt="" />
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                  {blog &&
                    blog.discription &&
                    blog.discription.length > 0 &&
                    blog.discription.map((item, index) => {
                      return <p key={index + 4}>{blog.discription[index]}</p>
                    })}

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    {blog && blog.source && <span>Source {blog.source}</span>}
                  </div>
                </div>
                {/* <div className="rightsideblog">
                  <div className="blogsearchbox">
                    <span>Search</span>
                    <div className="searchbox">
                      <TextField
                        size="small"
                        className="textfield"
                        label="Blog"
                        variant="outlined"
                        onChange={(e) => {
                          setSearch(e.target.value)
                        }}
                      />
                      <button>Search</button>
                    </div>
                    <div className="searchedblog">
                      {searchBlogs.isLoading ? (
                        <div
                          style={{
                            width: "100%",
                            minHeight: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <CircularProgress />
                        </div>
                      ) : searchedBlog && searchedBlog.length > 0 ? (
                        searchedBlog.map((item, index) => (
                          <Link className="link" to={`/blog/${item._id}`}>
                            <span
                              onClick={(e) => {
                                console.log(item._id)
                              }}
                            >
                              {item && item.title}
                            </span>
                          </Link>
                        ))
                      ) : (
                        <span>{errorMessage}</span>
                      )}
                    </div>
                  </div>
                  <div className="recentblogs">
                    <h2>Recent Posts</h2>
                    {findRecentBlogs.isLoading ? (
                      <div
                        style={{
                          width: "100%",
                          minHeight: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    ) : (
                      <div className="recently">
                        {recentBlog &&
                          recentBlog.length > 0 &&
                          recentBlog.map((item, index) => {
                            if (index <= 5)
                              return (
                                <Link
                                  className="link"
                                  to={`/blog/${item._id}`}
                                  key={index + 2323}
                                >
                                  <Locator
                                    span={item.title}
                                    icon={"hellow world"}
                                  />
                                </Link>
                              )
                          })}
                      </div>
                    )}
                  </div>
                </div> */}
              </>
            )
          )}
        </div>

        {/* <div className='locator'>
          <Locator header={"Home"} icon={"kj"} />
          <Locator header={"Blog"} icon={"kj"} />
          <Locator span={blogs[id].name} />
        </div> */}
      </div>
      <Footer />
    </div>
  )
}
