import { useState, useEffect } from "react"
// css class
import "./review.scss"

// mui
import {
  Star,
  StarOutlineSharp,
  Add,
  MinimizeOutlined,
} from "@mui/icons-material"

import BorderBottom from "../borderbottom"
import ColoredBox from "../coloredBox"

export default function Review({ review, average }) {
  const [people, setPeople] = useState(0)
  // console.log(review)
  //TODO
  // how many likes imean i shold make a function that computes the average value and assign it to this var
  // const isLiked = 4
  // how many likes imean i shold make a function that computes the average value and assign it to this var

  // var peopleholder
  // if (review.length&&review.length.toString().split("").length > 5) {
  //   peopleholder =
  //     review.length.toString().split("")[0] +
  //     review.length.toString().split("")[1] +
  //     review.length.toString().split("")[2] +
  //     "k"
  // } else if (review.length.toString().split("").length > 4) {
  //   peopleholder =
  //     review.length.toString().split("")[0] +
  //     review.length.toString().split("")[1] +
  //     "k"
  // }
  // console.log("heoolow ", review)

  return (
    <div className="review">
      <div className="reviewstandards">
        <div className="reviewstars">
          {average && average >= 1 ? (
            <Star className="customerreviewstar pressed" />
          ) : (
            <StarOutlineSharp className="customerreviewstar" />
          )}
          {average && average >= 2 ? (
            <Star className="customerreviewstar pressed" />
          ) : (
            <StarOutlineSharp className="customerreviewstar" />
          )}
          {average && average >= 3 ? (
            <Star className="customerreviewstar pressed" />
          ) : (
            <StarOutlineSharp className="customerreviewstar" />
          )}
          {average && average >= 4 ? (
            <Star className="customerreviewstar pressed" />
          ) : (
            <StarOutlineSharp className="customerreviewstar" />
          )}
          {average && average >= 5 ? (
            <Star className="customerreviewstar pressed" />
          ) : (
            <StarOutlineSharp className="customerreviewstar" />
          )}
        </div>
        <span className="reviewrating">{average && average}</span>
        <span className="customercount">
          <span className="customercount">
            {review && review.length} Reviews
          </span>

          {/* {people > 999 ? peopleholder : review && review.length} Reviews */}
        </span>
      </div>
      {/* <div className="reviewperecet">
        <div className="singlepercet">
          <span className="percentspan">5 Stars</span>
          <div className="lined">
            <div className="percentline" style={{ width: "70%" }}></div>
            <div className="line" style={{ width: "30%" }}></div>
          </div>
          <span className="percentspan">70%</span>
        </div>
        <div className="singlepercet">
          <span className="percentspan">4 Stars</span>
          <div className="lined">
            <div className="percentline" style={{ width: "20%" }}></div>
            <div className="line" style={{ width: "80%" }}></div>
          </div>
          <span className="percentspan">20%</span>
        </div>
        <div className="singlepercet">
          <span className="percentspan">3 Stars</span>
          <div className="lined">
            <div className="percentline" style={{ width: "10%" }}></div>
            <div className="line" style={{ width: "90%" }}></div>
          </div>
          <span className="percentspan">10%</span>
        </div>
        <div className="singlepercet">
          <span className="percentspan">2 Stars</span>
          <div className="lined">
            <div className="percentline" style={{ width: "3%" }}></div>
            <div className="line" style={{ width: "97%" }}></div>
          </div>
          <span className="percentspan">3%</span>
        </div>
        <div className="singlepercet">
          <span className="percentspan">1 Stars</span>
          <div className="lined">
            <div className="percentline" style={{ width: "1%" }}></div>
            <div className="line" style={{ width: "99%" }}></div>
          </div>
          <span className="percentspan">1%</span>
        </div>
      </div> */}
      {/* Todo spread the comments and show them off */}
      <BorderBottom />
      <div className="reviewscommentssection">
        {review &&
          review.length > 0 &&
          review.map((item, index) => (
            <div className="singelrevierscomments" key={index + 2323}>
              <div className="stars">
                {item.value >= 1 ? (
                  <Star className="customerreviewstar pressed" />
                ) : (
                  <StarOutlineSharp className="customerreviewstar" />
                )}
                {item.value >= 2 ? (
                  <Star className="customerreviewstar pressed" />
                ) : (
                  <StarOutlineSharp className="customerreviewstar" />
                )}
                {item.value >= 3 ? (
                  <Star className="customerreviewstar pressed" />
                ) : (
                  <StarOutlineSharp className="customerreviewstar" />
                )}
                {item.value >= 4 ? (
                  <Star className="customerreviewstar pressed" />
                ) : (
                  <StarOutlineSharp className="customerreviewstar" />
                )}
                {item.value >= 5 ? (
                  <Star className="customerreviewstar pressed" />
                ) : (
                  <StarOutlineSharp className="customerreviewstar" />
                )}
              </div>
              <div className="usersection">
                <div className="imagesection">
                  <img src={item && item.userImage} alt="" />
                </div>
                <div className="infosection">
                  <div className="titleholders">
                    <span className="username">{item && item.userName}</span>
                    <span className="date">Date here</span>
                  </div>
                  <div className="isverfired">
                    <ColoredBox span={"Verified"} />
                  </div>
                </div>
              </div>
              <div className="commentsection">
                <span>{item && item.comment}</span>
              </div>

              <BorderBottom />
            </div>
          ))}
      </div>
    </div>
  )
}
