import { Navigate } from "react-router-dom"

import Display from "./layouts/display"
// import Products from "./layouts/display/products/item";

import ProductList from "./layouts/display/productList"
import Property from "./layouts/display/property"
import Blog from "./layouts/pages/blog/item"
import SingleBlog from "./layouts/pages/blog"
import Firms from "./layouts/pages/firms"
import Firm from "./layouts/pages/firms/firm"
import Products from "./layouts/pages/products"
import Projects from "./layouts/pages/projects"
import Manufacturers from "./layouts/pages/manufacturer"
import ManufacturerPage from "./layouts/pages/manufacturer/singelpage"
import Professionals from "./layouts/pages/professionals"
import ProfessionalPage from "./layouts/pages/professionals/singelpage"
import Cart from "./layouts/display/cart"
import Material from "./layouts/pages/material"
import SingleProduct from "./layouts/pages/products/singlepage"
import Reset from "./layouts/authentication/reset"
import Tender from "./layouts/pages/tender"
// @mui icons
import Icon from "@mui/material/Icon"
import SingleTenderPage from "./layouts/pages/tender/singelpage"

const routes = [
  {
    name: "SingleBlog",
    key: "SingleBlog",
    route: "/blog/:blogname",
    component: <SingleBlog />,
  },
  {
    name: "Blog",
    key: "Blog",
    route: "/blogs",
    component: <Blog />,
  },
  {
    name: "ProductList",
    key: "ProductList",
    route: "/search/:token",
    component: <ProductList />,
  },
  {
    name: "Home",
    key: "Home",
    route: "/",
    component: <Display />,
  },
  {
    name: "NotFOund404",
    key: "NotFOund404",
    route: "*",
    component: <Navigate to="/" />,
  },
  {
    name: "Firms",
    key: "Firms",
    route: "/firms",
    component: <Firms />,
  },
  {
    name: "SingleFirms",
    key: "SingleFirms",
    route: "/firm/:firmnameorid",
    component: <Firm />,
  },
  {
    name: "Property",
    key: "Property",
    route: "/project/:projectnameorid",
    component: <Property />,
  },

  {
    name: "Products",
    key: "Products",
    route: "/products",
    component: <Products />,
  },
  {
    name: "Item",
    key: "Item",
    route: "/product/:productnameorid",
    component: <SingleProduct />,
  },
  {
    name: "Projects",
    key: "Projects",
    route: "/projects",
    component: <Projects />,
  },
  {
    name: "Manufacturers",
    key: "Manufacturers",

    route: "/manufacturers",
    component: <Manufacturers />,
  },
  {
    name: "SingleManufacturer",
    key: "SingleManufacturer",
    route: "/manufacturer/:nameorid",
    component: <ManufacturerPage />,
  },
  {
    name: "Professionals",
    key: "Professionals",
    route: "/professionals",
    component: <Professionals />,
  },
  {
    name: "SingleProfessional",
    key: "SingleProfessional",
    route: "/professional/:nameorid",
    component: <ProfessionalPage />,
  },
  {
    name: "Cart",
    key: "Cart",
    route: "/cart",
    component: <Cart />,
  },
  {
    name: "Material",
    key: "Material",
    route: "/material",
    component: <Material />,
  },
  {
    name: "reset",
    key: "reset",
    route: "authentication/change/:token/:id",
    component: <Reset />,
  },
  {
    name: "Tender",
    key: "tender",
    route: "/tenders",
    component: <Tender />,
  },
  {
    name: "Tender",
    key: "tender",
    route: "/tenders",
    component: <Tender />,
  },
  {
    name: "tenderpage",
    key: "tenderpage",
    route: "/tender/:id",
    component: <SingleTenderPage />,
  },
]

export default routes
