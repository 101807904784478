import Separators from "../../../../example/dotseparators"

import { Article } from "@mui/icons-material"

import "../../../../styles/pages/tender/singlepage/singeltender.scss"
import { useState, useEffect } from "react"
// css class
import "../../../../styles/pages/products/singleproduct/singleproduct.scss"
import ColoredBox from "../../../../example/coloredBox"

import Box from "../../../../example/box"
// mui Icons
import {
  NavigateNext,
  NavigateBefore,
  Star,
  StarOutlineSharp,
} from "@mui/icons-material"
///
import { Link } from "react-router-dom"

///
import { useLocation } from "react-router"

///
import { TextField } from "@mui/material"
import Select from "@mui/material/Select"
import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from "@mui/material/InputLabel"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import { Modal } from "@mui/material"
//
import SellingProductsCard from "../../../../example/sellingproducts"
//
import RelatedProducts from "../../../../example/realtedproducts"
import Review from "../../../../example/review"
import CartModal from "../../../../example/cartmodal"

// procut actionhandelr
import { addProduct } from "../../../../redux/cartRedux"

//
import { useDispatch } from "react-redux"

//
import { useQuery, useMutation } from "@tanstack/react-query"

// request sender
import { publicRequest, userRequest } from "../../../../constant/requestMethods"

// mui
import { CircularProgress } from "@mui/material"
import Alert from "@mui/material/Alert"

//
import { useSelector } from "react-redux"
import Footer from "../../../display/footer"

import { format } from "timeago.js"

export default function SingleTenderPage() {
  const location = useLocation()
  const tenderLocation = location.pathname.split("/")[1]
  const id = location.pathname.split("/")[2]

  //state vars to hold the tender and nexpage if it there
  const [tender, setTender] = useState({})
  const [newDate, setNewDate] = useState()
  const [recentTender, setRecentTender] = useState([])
  const [search, setSearch] = useState("")
  const [searchedTender, setSearchedTender] = useState()
  // controllers to show alets
  const [alert, setAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  const [image, setImage] = useState("")

  // state to hold the alue of the image before it slides to the next or prev
  const [count, setCount] = useState(0)
  // functions that runs when the page is loaded
  // sends a request to the server for the Tender data
  const findSingleTender = useQuery({
    queryKey: ["findsingeltenders"],
    queryFn: async () => {
      setAlert(false)
      setErrorAlert(false)
      setErrorMessage("")
      try {
        // await userRequest.get("/tender/find?page=1&limit=5")
        if (id) {
          const reply = await publicRequest.get(`/tender/find/${id}`)
          // console.log(reply)
          if (reply.status === 201) {
            // console.log(reply.data.data)
            reply.data.data && setTender(reply.data.data)
            // setNewDate(formatDistance(new Date(Tender.createdAt), new Date()))
          } else {
            setAlert(true)
            setErrorAlert(true)
            setErrorMessage("couldn't get Tender")
          }
          return reply
        }
      } catch (e) {
        setAlert(true)
        setErrorAlert(true)
        setErrorMessage(e.message)
        console.log(e)
        return e
        // console.log(e.message)
      }
    },

    cacheTime: Infinity,
    staleTime: Infinity,
  })

  useEffect(() => {
    findSingleTender.refetch()
  }, [id])

  return (
    <div className="single">
      <div className="singlecontainers">
        <div className="toppersingle">
          <span>{tender && tender.title && tender.title}</span>
          {/* <button>Download File</button> */}
        </div>
        <div className="descriptionsingle">
          {tender &&
            tender.discription &&
            tender.discription.length > 0 &&
            tender.discription.map((item, index) => (
              <p key={982 + index}>{item}</p>
            ))}
        </div>
        <div className="lastsingle">
          <div className="summary">
            <span className="header">Procurement Summary</span>
            {tender && tender.country && (
              <Separators title={"Country"} discription={tender.country} />
            )}
            {tender && tender._id && (
              <Separators title={"Id"} discription={tender._id} />
            )}
            {tender && tender.title && (
              <Separators title={"Title"} discription={tender.title} />
            )}
            {tender && tender.deadline && (
              <Separators
                title={"Deadline"}
                discription={format(tender.deadline)}
              />
            )}
          </div>
          <div className="summary">
            <span className="header">Other Information</span>
            {tender && tender.country && (
              <Separators title={"Notice Type"} discription={tender.country} />
            )}
            {tender && tender.tot && (
              <Separators title={"TOT Ref.No."} discription={tender.tot} />
            )}
            {/* <Separators
              title={"Document Ref. No."}
              discription={"Hellow Wordl"}
            /> */}
            {/* <Separators title={"Tender Value"} discription={"Refer Document"} /> */}
            <Separators title={"Purchaser Ownership"} discription={"Public"} />
          </div>
          {/* <div className="summary">
            <span className="header">Documents</span>
            <div className="fileicon">
              <Article style={{ fill: "#00aeff" }} />
              <span>Tender Notice</span>
            </div>
          </div> */}
        </div>
      </div>
      <Footer />
    </div>
  )
}
