import { useState } from "react"
// css class
import "./colored.scss"
export default function ColoredBox({ color, span, width, margin, height }) {
  const [clicked, setOnClicked] = useState(false)
  return (
    <div
      name={span}
      value={span}
      onClick={(e) => {
        console.log(span.toLowerCase())
        setOnClicked(!clicked)
      }}
      className="colored"
      style={
        color
          ? { backgroundColor: { color } }
          : clicked
          ? {
              backgroundColor: "#00aeff",
              width: width,
              margin: margin,
              height: height,
              cursor: "pointer",
            }
          : {
              backgroundColor: "#585757",
              width: width,
              margin: margin,
              height: height,
              cursor: "pointer",
            }
      }
    >
      <span className="coloredspan">{span}</span>
    </div>
  )
}
