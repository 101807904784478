import { useState, useEffect } from "react"
// css class
import "../../../styles/profile/product/userproduct.scss"
// mui
import Modal from "@mui/material/Modal"
import { DataGrid } from "@mui/x-data-grid"

import { CircularProgress } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import Alert from "@mui/material/Alert"

// import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline, Search } from "@mui/icons-material"

//
import ProfileSideNav from "../sidenav"
import CreateBlog from "./create"
import UpdateBlog from "./update"

import { userRequest } from "../../../constant/requestMethods"
import { useSelector } from "react-redux"

//
import { useQuery } from "@tanstack/react-query"

// import to navigate to the blog page
import { useNavigate } from "react-router-dom"
import DeleteItem from "../../../example/deleteitem"

export default function ProfileBlog() {
  // for getting the user Id stored when the user logged in
  const { currentUser } = useSelector((state) => state.user)

  // var to hold the address
  const navigate = useNavigate()
  // state to use the user
  const [user, setUser] = useState({})

  // state that holds the blogs
  const [blogs, setBlogs] = useState([])

  // state to hold the value of the search formthe serach bar
  const [searched, setSearced] = useState("")

  // controllers to show alets
  const [alert, setAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  // state to change the modal from cupdate to create
  const [changeModal, setChangeModal] = useState()

  // state to fetch the sort
  const [sort, setSort] = useState("")
  const [filter, setFilter] = useState([])
  // state to open create modal when create new blogs is opened
  const [openModal, setOpenModal] = useState(false)

  // state to hold the item for updating the values
  const [item, setItem] = useState({})

  // modal to appear when edit or create buttons are pressed

  //	// trigger for setting if the images failed to load or are success ful
  // const [isLoading, setIsLoading] = useState(false);

  // const [isLoading, setisLoading] = useState(false);
  const { isLoading, refetch } = useQuery({
    queryKey: ["findblogs"],
    queryFn: async () => {
      try {
        setErrorAlert(false)
        setAlert(false)
        setErrorMessage("")
        const reply = await userRequest.get(
          `/blog//finduserblog/${currentUser.data._id}`
        )
        // console.log(reply)
        setBlogs(reply.data.data)
        // console.log(reply)
        return reply
      } catch (e) {
        // console.log("eroor happended" + error)
        setErrorAlert(true)
        setAlert(true)
        setErrorMessage(e.message)
        // console.log(e)
        return e
      }
    },

    cacheTime: Infinity,
    staleTime: Infinity,
  })

  //  when the search bar is is inputed the rows change
  const findProduct = () => {
    const something = []
    if (searched) {
      blogs.map((item, index) => {
        const doe = item.title.toLowerCase()
        if (!doe.indexOf(searched.toLowerCase())) {
          something.push(item)
        }
        return something
      })
    }
    return something
  }
  const newRow = findProduct()

  useEffect(() => {
    refetch()
  }, [openModal])

  // // filteer function
  // useEffect(() => {
  //   sort &&
  //     setFilter(
  //       blogs.filter((item) =>
  //         Object.entries(filter).every(([key, value]) =>
  //           item[key].includes(value)
  //         )
  //       )
  //     )
  // }, [filter, blogs, sort])

  useEffect(() => {
    if (sort === "newest") {
      setBlogs((prev) =>
        [...prev].sort(
          (a, b) =>
            Number(new Date(b.createdAt)) - Number(new Date(a.createdAt))
        )
      )
    } else if (sort === "oldest") {
      setFilter((prev) =>
        [...prev].sort(
          (a, b) =>
            Number(new Date(a.createdAt)) - Number(new Date(b.createdAt))
        )
      )
    }
  }, [sort])

  // console.log(filter)
  const renderModal = () => {
    return (
      <>
        <Modal
          style={{
            opacity: "1",
            border: "none",
            borderRadius: "4px",
            overflow: "auto",
            outline: "none",
          }}
          open={openModal}
          onClose={(e) => {
            setOpenModal(!openModal)
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {changeModal === "update" ? (
            <UpdateBlog
              setOpenModal={setOpenModal}
              openModal={openModal}
              blog={item}
            />
          ) : changeModal === "delete" ? (
            <DeleteItem
              openModal={openModal}
              setOpenModal={setOpenModal}
              item={item}
              type={"blog"}
            />
          ) : (
            <CreateBlog setOpenModal={setOpenModal} openModal={openModal} />
          )}
        </Modal>
      </>
    )
  }
  const columns = [
    { field: "_id", headerName: "ID", width: 90 },
    {
      field: "productName",
      headerName: "item",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellimage">
            <img
              src={params.row.image ? params.row.image[0] : params.row.image}
            />
            <span>
              {params.row.title && params.row.title.length > 10
                ? params.row.title.split("", 10)
                : params.row.title}
              {params.row.title.length > 10 ? "..." : ""}
            </span>
          </div>
        )
      },
    },
    {
      field: "discription",
      headerName: "Discription",
      width: 300,
    },
    {
      field: "source",
      headerName: "Source",
      width: 150,
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="holeup" id={params.row._id}>
            <button
              onClick={(e) => {
                setChangeModal("update")
                setItem(params.row)
                setOpenModal(!openModal)
                // console.log(item)
              }}
            >
              {" "}
              Edit
            </button>
            <DeleteOutline
              className="userproductddeletebutton"
              style={{ color: "red" }}
              onClick={() => {
                setItem(params.row)
                setChangeModal("delete")
                setOpenModal(!openModal)
              }}
            />
          </div>
        )
      },
    },
  ]
  return (
    <div className="userproduct">
      <ProfileSideNav page={"blog"} />
      {renderModal()}
      <div className="userproductmargin">
        <div className="userproductcontainer">
          {errorAlert && (
            <div className="alert">
              {errorAlert && <Alert severity="error">{errorMessage}</Alert>}
            </div>
          )}
          <div className="userproductheader">
            <span className="userproducttitle">Blogs</span>
            <button
              className="userproductcreatebutton"
              onClick={(e) => {
                setChangeModal("create")
                setOpenModal(!openModal)
              }}
            >
              Create Blog
            </button>
          </div>
          <div className="userproductsearch">
            <div className="userproductsearchicon">
              <OutlinedInput
                size="small"
                id="outlined-adornment-weight"
                startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                style={{ borderRadius: "none" }}
                onChange={(e) => {
                  setSearced(e.target.value)
                }}
              />

              <button
                style={{
                  width: "30%",
                  height: "40px",
                  backgroundColor: "#00aeff",
                  color: "white",
                  border: "none",
                }}
              >
                {" "}
                Search
              </button>
            </div>
            <div className="userproductsortpage">
              <span>Sort By:</span>
              <FormControl sx={{ m: 1, minWidth: "60%" }}>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  autoWidth
                  value={sort}
                  onChange={(e) => setSort(e.target.value)}
                  size="small"
                >
                  <MenuItem value="">
                    {sort ? <em>{sort}</em> : <em>Newest to Oldest</em>}
                  </MenuItem>
                  <MenuItem
                    value="newest"
                    onClick={(e) => setSort(e.target.value)}
                  >
                    <span
                      style={{ fontSize: "14px", fontFamily: "Bellota-Light" }}
                    >
                      Newest to Oldest
                    </span>
                  </MenuItem>
                  <MenuItem
                    value="oldest"
                    onClick={(e) => setSort(e.target.value)}
                  >
                    <span
                      style={{ fontSize: "14px", fontFamily: "Bellota-Light" }}
                    >
                      Oldest to Newest
                    </span>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="userproductgridd">
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <DataGrid
                onCellDoubleClick={(row) => navigate("/blog/" + row.row._id)}
                rows={searched ? newRow : blogs}
                columns={columns}
                getRowId={(row) => row._id}
                pageSize={5}
                rowsPerPageOptions={[5]}
                // checkboxSelection
                disableSelectionOnClick
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
