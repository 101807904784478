import { useRef, useCallback, useState } from "react"
import "./layout.scss"
import ImageBox from "../imagebox"
import Rooms from "../rooms"
import { AttachFile, NavigateNext } from "@mui/icons-material"
import { Link } from "react-router-dom"

// time converter
import { format } from "timeago.js"

export default function PinterstLayout(
  cards,
  linker,
  blog,
  project,
  isLoading,
  nextPage
) {
  const [pageNumber, setPageNumber] = useState(1)
  const observer = useRef()
  const lastBlogElement = useCallback(
    (node) => {
      if (isLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && nextPage) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1)
          // console.log(pageNumber)
        }
      })
      if (node) observer.current.observe(node)
    },
    [
      isLoading,
      nextPage,
      /* Todo setup a state to check it there are any more blogs to be seen
      and make is loading a dependency */
    ]
  )
  // console.log(pageNumber)
  // console.log("carsd", cards)
  return {
    pageNumber: pageNumber,
    display: (
      <div className={linker === "project" ? "layout project" : "layout"}>
        {cards &&
          cards.map((item, index) => {
            if (cards.length === index + 1) {
              return (
                <Link
                  to={`/${linker}/${item._id}`}
                  className="link"
                  key={index + 103}
                >
                  <div className="card" key={index + 23}>
                    {/* <Link to={blog ? `/blog/${item.name}` : `/project/${item.name}`} className="link"> */}
                    <div className="cardimage">
                      <ImageBox
                        src={
                          item && item.image.length > 0
                            ? item.image[0]
                            : item.image
                        }
                        size="16px"
                      />
                    </div>

                    <div className="cardbottom">
                      <div className="cardbottomtop">
                        <Rooms
                          icons={
                            <AttachFile
                              style={{ fontSize: "14px", color: "black" }}
                            />
                          }
                          number={format(item.createdAt)}
                        />
                        {/* <Rooms icons={<NavigateNext style={{ fontSize: "14px" }} />} number={"March 7, 2014"} /> */}
                      </div>
                      <span ref={lastBlogElement}>{item && item.title}</span>
                      {linker === "blog" ? (
                        <span className="carddec">
                          {" "}
                          {item &&
                            item.discription &&
                            item.discription.length > 0 &&
                            item.discription[0].split("", 40)}{" "}
                          ...
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </Link>
              )
            } else {
              return (
                // <Link to={`/${linker}/${index}`} className="link">

                <div className="card" key={index + 23}>
                  <Link to={`/${linker}/${item._id}`} className="link">
                    {/* <Link to={blog ? `/blog/${item.name}` : `/project/${item.name}`} className="link"> */}

                    <div className="cardimage">
                      <ImageBox
                        src={
                          item && item.image.length > 0
                            ? item.image[0]
                            : item.image
                        }
                        size="16px"
                      />
                    </div>

                    <div className="cardbottom">
                      <div className="cardbottomtop">
                        <Rooms
                          icons={
                            <AttachFile
                              style={{ fontSize: "14px", color: "black" }}
                            />
                          }
                          number={format(item.createdAt)}
                        />
                      </div>
                      <span>{item && item.title}</span>
                      {linker === "blog" ? (
                        <span className="carddec">
                          {" "}
                          {item &&
                            item.discription &&
                            item.discription.length > 0 &&
                            item.discription[0].split("", 40)}{" "}
                          ...
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Link>
                </div>
              )
            }
          })}
      </div>
    ),
  }
}
