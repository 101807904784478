import { Link } from "react-router-dom"

import "../../../../styles/pages/professional/card/card.scss"
export default function ProfessionalCard({ id, title, type, image }) {
  return (
    <div className="individualprofessional">
      <Link to={`/professional/${id}`} className="link">
        <div className="imagecontanier">
          <img src={image} alt="" />
          <div className="darkerart">
            <span className="professionalholdertitle">{title}</span>
            <span className="work">{type}</span>
          </div>
        </div>
      </Link>
    </div>
  )
}
