import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
// Icon class
import Rooms from "../../../../example/rooms"
// image container
import ImageBox from "../../../../example/imagebox"
// Items class
import Pictured from "../../../../example/item/pictured"
// review layout
import Review from "../../../../example/review"
// csss class
import "../../../../styles/pages/firms/firm/indivfirm.scss"
// mui
import {
  FacebookOutlined,
  NotificationAdd,
  NotificationsActive,
  Grade,
  Instagram,
  Google,
  Info,
  LinkedIn,
  LocationOn,
  Pinterest,
  ReportProblem,
  Share,
  Star,
  StarBorderOutlined,
  StarOutlineSharp,
  Twitter,
  YouTube,
} from "@mui/icons-material"
import Alert from "@mui/material/Alert"
import { CircularProgress, Modal } from "@mui/material"

import Border from "../../../../example/borderbottom"
import { useLocation } from "react-router"

import HeaderNavLink from "../../../../example/headerNavLinks"

//
import { useQuery, useMutation } from "@tanstack/react-query"

//
import { publicRequest, userRequest } from "../../../../constant/requestMethods"

import { useSelector } from "react-redux"
import UnfollowModal from "../../../../example/unfollowmodal"
import Footer from "../../../display/footer"

export default function Firm() {
  // holds state for the user from the browser storage
  const { currentUser } = useSelector((state) => state.user)

  const location = useLocation()

  const id = location.pathname.split("/")[2]
  // console.log(id)

  //state vars to hold the blog and nexpage if it there

  // store the data of the user
  const [firm, setFirm] = useState({})
  // store the firmData variables
  const [firmData, setFirmData] = useState({})

  const [firmProduct, setFirmProduct] = useState([])

  // stores the projects
  const [firmProject, setFirmProject] = useState([])

  // stores the materials
  const [firmMaterial, setFirmMaterial] = useState([])

  // hold the linker for the query changes when the buttons are pressed
  const [linker, setLinker] = useState("/project/finduserproject/" + id)

  // holds the data from the firm product project or materials
  const [dataHolder, setDataHolder] = useState([])

  // holds the average computerd from the third request
  const [reviewAvrg, setReviewAvrg] = useState(1)

  // state to get the review value
  const [value, setValue] = useState(0)

  // state to check if a user is following the firm
  const [isFollowing, setIsFollowing] = useState(false)

  //state to open and close the unfollow modal
  const [modalOpen, setModalOpen] = useState(false)

  // controllers to show alets
  const [alert, setAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  const [image, setImage] = useState("")
  // functions that runs when the page is loaded
  // sends a request to the server for the blog data

  const loop = [0, 1, 2, 3, 4]
  const people = 95800
  let peopleholder
  if (people.toString().split("").length > 5) {
    peopleholder =
      people.toString().split("")[0] +
      people.toString().split("")[1] +
      people.toString().split("")[2] +
      "k"
  } else if (people.toString().split("").length > 4) {
    peopleholder =
      people.toString().split("")[0] + people.toString().split("")[1] + "k"
  }

  const pagechanger = [
    { name: "Project", value: 0, link: "/project/finduserproject/" + id },
    { name: "Product", value: 1, link: "/product/finduserproduct/" + id },
    { name: "Material", value: 2, link: "/material/findusermaterial/" + id },
  ]
  const [projectChanger, setProjectChanger] = useState("Project")

  // query to find the user and the firm
  const findUser = useQuery({
    queryKey: ["findsingelfirm"],
    queryFn: async () => {
      try {
        // await userRequest.get("/blog/find?page=1&limit=5")
        const reply = await publicRequest.get(`firm/find/singlepage/${id}`)
        // console.log(reply)
        if (reply.status === 201) {
          // console.log(reply.data.data)
          setFirm(reply.data.data[0] && reply.data.data[0])
          setFirmData(
            reply.data.data[0] &&
              reply.data.data[0].firmData[0] &&
              reply.data.data[0].firmData[0]
          )
          setFirmProduct(
            reply.data.data[0] &&
              reply.data.data[0].firmData[0] &&
              reply.data.data[0].firmData[0].products
          )
          setFirmProject(
            reply.data.data[0] &&
              reply.data.data[0].firmData[0] &&
              reply.data.data[0].firmData[0].projects
          )
          setFirmMaterial(
            reply.data.data[0] &&
              reply.data.data[0].firmData[0] &&
              reply.data.data[0].firmData[0].materials
          )
          // console.log(reply.data.data[0].firmData[0])
          reply.data.data &&
            reply.data.data[0] &&
            reply.data.data[0].firmData[0] &&
            reply.data.data[0].firmData[0].firmInfo &&
            reply.data.data[0].firmData[0].firmInfo.followers &&
            reply.data.data[0].firmData[0].firmInfo.followers.map(
              (item, index) => {
                console.log(item)
                if (
                  currentUser &&
                  currentUser.data &&
                  currentUser.data._id &&
                  currentUser.data._id === item
                ) {
                  // console.log("user Follow firm")
                  setIsFollowing(true)
                }
              }
            )
          // return reply
          // setImage(reply.data.data.image[0])
          // setNewDate(formatDistance(new Date(blog.createdAt), new Date()))
        } else {
          setAlert(true)
          setErrorAlert(true)
          setErrorMessage("couldn't get blog")
          // return errorMessage
        }

        return reply
      } catch (e) {
        setAlert(true)
        setErrorAlert(true)
        setErrorMessage(e.message)
        // console.log(e)

        return e
      }
    },
    cacheTime: Infinity,
    staleTime: Infinity,
  })

  // request to find materials for the firm has made
  const findMaterials = useQuery({
    queryKey: ["findMaterials"],
    queryFn: async () => {
      try {
        // await userRequest.get("/blog/find?page=1&limit=5")
        const reply = await publicRequest.get(linker)
        // console.log(reply)
        if (reply.status === 201) {
          // console.log(reply.data.data)
          // console.log(reply)
          setDataHolder(reply.data.data)

          // setImage(reply.data.data.image[0])
          // setNewDate(formatDistance(new Date(blog.createdAt), new Date()))
        } else {
          setAlert(true)
          setErrorAlert(true)
          setErrorMessage("couldn't get blog")
        }
        return reply
      } catch (e) {
        setAlert(true)
        setErrorAlert(true)
        setErrorMessage(e.message)
        // console.log(e)
        return e
      }
    },
    enabled: successAlert,
    cacheTime: Infinity,
    staleTime: Infinity,
  })

  // request to find materials for the firm has made
  const FindReview = useQuery({
    queryKey: ["findReview"],
    queryFn: async () => {
      try {
        // await userRequest.get("/blog/find?page=1&limit=5")
        if (firmData._id) {
          const reply = await publicRequest.get(`firm/review/${firmData._id}`)
          // console.log()
          if (reply.status === 201) {
            // console.log(reply.data.data)
            setReviewAvrg(
              reply.data.data[0] && Math.round(reply.data.data[0].avgRating)
            )

            return reply
            // setImage(reply.data.data.image[0])
            // setNewDate(formatDistance(new Date(blog.createdAt), new Date()))
          } else {
            setAlert(true)
            setErrorAlert(true)
            setErrorMessage("review")
            return reply
          }
          return reply
        }
        return "no user review found"
      } catch (e) {
        setAlert(true)
        setErrorAlert(true)
        setErrorMessage(e.message)
        // console.log(e)
        return e
      }
    },
    enabled: successAlert,
    cacheTime: Infinity,
    staleTime: Infinity,
  })

  // endpoint to rate firm
  const createReview = async (data) => {
    const reply = await userRequest.put(
      `firm/ratefirm/${currentUser.data._id}/${firmData._id}`,
      data
    )
    return reply.data
  }
  const rateFirm = useMutation(createReview, {
    onSuccess: (data) => {
      setAlert(true)
      setSuccessAlert(true)
      // console.log(data)
      setSuccessMessage(data.msg)
    },
    onError: (e) => {
      setAlert(true)
      setErrorAlert(true)
      setErrorMessage(e.message)
    },
  })

  // create or update review
  const handleReview = (number) => {
    // console.log("tje off season")
    setAlert(false)
    setErrorAlert(false)
    setSuccessAlert(false)
    setErrorMessage("")
    setSuccessMessage("")
    let reviewed = { value: number }

    if (currentUser && currentUser.data && currentUser.data._id) {
      rateFirm.mutate(reviewed)
    } else {
      setAlert(true)
      setErrorAlert(true)
      setSuccessAlert(false)
      setErrorMessage("login to rate professional")
    }
  }

  const followOrg = async () => {
    const reply = await userRequest.put(
      `firm/followfirm/${currentUser.data._id}/${firmData._id}`
    )
    return reply.data
  }
  const sendFollow = useMutation(followOrg, {
    onSuccess: (data) => {
      setAlert(true)
      setSuccessAlert(true)
      // console.log(data)
      setSuccessMessage(data.msg)
    },
    onError: (e) => {
      setAlert(true)
      setErrorAlert(true)
      setErrorMessage(e.message)
    },
  })

  const handleFollow = () => {
    setAlert(false)
    setErrorAlert(false)
    setSuccessAlert(false)
    setErrorMessage("")
    setSuccessMessage("")
    if (currentUser && currentUser.data && currentUser.data._id) {
      sendFollow.mutate()
    } else {
      setAlert(true)
      setErrorAlert(true)
      setSuccessAlert(false)
      setErrorMessage("login to follow firm")
    }
  }

  const style = {
    width: "30%",
    height: "28%",
    display: "flex",
    backgroundColor: "blue",
    flexDirection: "column",
  }

  const { display, unFollowed } = UnfollowModal(
    modalOpen,
    setModalOpen,
    firm && firm.userName,
    "firm",
    currentUser &&
      currentUser.data &&
      currentUser.data._id &&
      currentUser.data._id,
    firmData && firmData._id,
    setIsFollowing
  )

  // runs after the user has been fetched succssfully and every time the user presses the product/material or project buttons
  useEffect(() => {
    findMaterials.refetch()
  }, [projectChanger, linker])
  // var to check if a person is following the manufacturere

  // refetches the firm data after the user followed or unflollowed them or rates them
  useEffect(() => {
    findUser.refetch()
  }, [sendFollow.isSuccess, rateFirm.isSuccess, unFollowed])

  useEffect(() => {
    if (currentUser && currentUser.data && currentUser.data._id) {
      FindReview.refetch()
    }
  }, [handleReview, value])

  const renderModal = () => {
    return (
      <Modal
        sx={{ overflow: "auto" }}
        open={modalOpen}
        onClose={() => {
          setModalOpen(!modalOpen)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {display}
      </Modal>
    )
  }
  // console.log(firmData, firm)
  return (
    <div className="individualfirmpage">
      {findUser.isLoading ? (
        <div
          style={{
            width: "100dvw",
            height: "100dvh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {alert && (
            <div className="alert">
              {errorAlert && <Alert severity="error">{errorMessage}</Alert>}
              {successAlert && (
                <Alert severity="success">{successMessage}</Alert>
              )}
            </div>
          )}
          {renderModal()}
          <div className="firmheader">
            <img src={firmData && firmData.logo && firmData.logo} alt="" />
            <div className="firminforight">
              <div className="firmimagecontainer">
                <ImageBox src={firm && firm.profilepic && firm.profilepic} />
              </div>
              <div className="imageheaders">
                <div className="insideheaders">
                  <div className="tilteandlinktoprofile">
                    <span className="titlefirm">{firm.userName}</span>
                    {currentUser &&
                      currentUser.data &&
                      currentUser.data._id &&
                      currentUser.data._id === id && (
                        <Link to={`/profile`}>
                          <button className="linker">Go To Your Profile</button>
                        </Link>
                      )}
                  </div>
                  <Border
                    first={"firm type General Contractors"}
                    style={{ color: "white", fontFamily: "Bellota-Light" }}
                  />
                  {/* <span className='link'>firm type General Contractors</span> */}
                  {/* <Rooms icons={<LocationOn style={{ fontSize: "12px" }} />} number={"1611 Michigan Ave, Miami Beach, FL 33139"} /> */}
                </div>
                <div className="lowerdetails">
                  <div className="llicence">
                    <span className="bolder">License:</span>
                    <span> US-123-456-5463</span>
                  </div>
                  <div className="llicence">
                    <span className="bolder">License:</span>
                    <span> US-123-456-5463</span>
                  </div>
                  <div className="buttons">
                    <button>Send email</button>
                    <button>Call</button>
                  </div>
                  {/* <div className='follow'>
                <button >
                  Follow
                </button>
              </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="amnufacturerinsideindiv">
            <div className="firmprojectslists" id="projects">
              <div className="firmlistscontainer">
                <div className="listingbuttons">
                  {pagechanger.map((item, index) => (
                    <button
                      key={index + 23}
                      className={
                        projectChanger === item.name
                          ? "productsbutton on"
                          : "productsbutton"
                      }
                      value={0}
                      onClick={(e) => {
                        setProjectChanger(item.name)
                        setLinker(item.link)
                      }}
                    >
                      {item.name}({" "}
                      {item.name === "Product"
                        ? firmProduct.length
                        : item.name === "Project"
                        ? firmProject.length
                        : firmMaterial.length}{" "}
                      )
                    </button>
                  ))}
                </div>
                <span className="firmprojectsheadertitle">
                  {projectChanger}
                </span>
                <div className="firmprojectslayout">
                  {findMaterials.isLoading ? (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    dataHolder &&
                    dataHolder.length > 0 &&
                    dataHolder.map((item, index) => (
                      <Link
                        to={
                          projectChanger === "Project"
                            ? `/project/${item._id}`
                            : projectChanger === "Product"
                            ? `/product/${item._id}`
                            : `/material/${item._id}`
                        }
                        className="link"
                        key={index + 231}
                      >
                        <div className="individualfirmprojects">
                          <div className="imageanimation">
                            <img src={item.image[0] && item.image[0]} alt="" />
                          </div>

                          <div className="firmindivinfo">
                            <span>{item.title}</span>
                            {/* <span>details</span> */}
                          </div>
                        </div>
                      </Link>
                    ))
                  )}
                  {/* */}
                </div>
              </div>
              <div className="separeate">
                <div className="firmprojecstsisde">
                  <div className="firmratingstars">
                    <div className="firmcircularpic">
                      <img
                        className="circularimage"
                        src={firm.profilepic && firm.profilepic}
                        alt=""
                      />
                    </div>

                    {/* Todo  server should compute if the user has a new vote or is updating an older vote 
                    and for the firm sake the comupted votes should be 4 untill the voters reach atleast a 100
                */}
                    {rateFirm.isLoading ? (
                      <div>
                        <CircularProgress />
                      </div>
                    ) : (
                      <div className="ratingstars">
                        {value && value >= 1 ? (
                          <Star
                            className="star pressed"
                            onClick={(e) => {
                              if (
                                currentUser &&
                                currentUser.data &&
                                currentUser.data._id &&
                                currentUser.data._id
                              ) {
                                setValue(1)
                                handleReview(1)
                              } else {
                                setAlert(true)
                                setErrorAlert(true)
                                setErrorMessage("please login to add review")
                              }
                            }}
                          />
                        ) : reviewAvrg >= 1 ? (
                          <Star
                            className="star pressed"
                            onClick={(e) => {
                              if (
                                currentUser &&
                                currentUser.data &&
                                currentUser.data._id &&
                                currentUser.data._id
                              ) {
                                setValue(1)
                                handleReview(1)
                              } else {
                                setAlert(true)
                                setErrorAlert(true)
                                setErrorMessage("please login to add review")
                              }

                              // handle
                            }}
                          />
                        ) : (
                          <StarOutlineSharp
                            style={{
                              cursor: "pointer",
                            }}
                            className="star"
                            onClick={(e) => {
                              if (
                                currentUser &&
                                currentUser.data &&
                                currentUser.data._id &&
                                currentUser.data._id
                              ) {
                                setValue(1)
                                handleReview(1)
                              } else {
                                setAlert(true)
                                setErrorAlert(true)
                                setErrorMessage("please login to add review")
                              }
                            }}
                          />
                        )}
                        {value >= 2 ? (
                          <Star
                            className="star pressed"
                            onClick={(e) => {
                              if (
                                currentUser &&
                                currentUser.data &&
                                currentUser.data._id &&
                                currentUser.data._id
                              ) {
                                setValue(2)
                                handleReview(2)
                              } else {
                                setAlert(true)
                                setErrorAlert(true)
                                setErrorMessage("please login to add review")
                              }
                            }}
                          />
                        ) : reviewAvrg >= 2 ? (
                          <Star
                            className="star pressed"
                            onClick={(e) => {
                              if (
                                currentUser &&
                                currentUser.data &&
                                currentUser.data._id &&
                                currentUser.data._id
                              ) {
                                setValue(2)
                                handleReview(2)
                              } else {
                                setAlert(true)
                                setErrorAlert(true)
                                setErrorMessage("please login to add review")
                              }
                            }}
                          />
                        ) : (
                          <StarOutlineSharp
                            style={{
                              cursor: "pointer",
                            }}
                            className="star"
                            onClick={(e) => {
                              if (
                                currentUser &&
                                currentUser.data &&
                                currentUser.data._id &&
                                currentUser.data._id
                              ) {
                                setValue(2)
                                handleReview(2)
                              } else {
                                setAlert(true)
                                setErrorAlert(true)
                                setErrorMessage("please login to add review")
                              }
                            }}
                          />
                        )}
                        {value >= 3 ? (
                          <Star
                            className="star pressed"
                            onClick={(e) => {
                              if (
                                currentUser &&
                                currentUser.data &&
                                currentUser.data._id &&
                                currentUser.data._id
                              ) {
                                setValue(3)
                                handleReview(3)
                              } else {
                                setAlert(true)
                                setErrorAlert(true)
                                setErrorMessage("please login to add review")
                              }
                            }}
                          />
                        ) : reviewAvrg >= 3 ? (
                          <Star
                            className="star pressed"
                            onClick={(e) => {
                              if (
                                currentUser &&
                                currentUser.data &&
                                currentUser.data._id &&
                                currentUser.data._id
                              ) {
                                setValue(3)
                                handleReview(3)
                              } else {
                                setAlert(true)
                                setErrorAlert(true)
                                setErrorMessage("please login to add review")
                              }
                            }}
                          />
                        ) : (
                          <StarOutlineSharp
                            style={{
                              cursor: "pointer",
                            }}
                            className="star"
                            onClick={(e) => {
                              if (
                                currentUser &&
                                currentUser.data &&
                                currentUser.data._id &&
                                currentUser.data._id
                              ) {
                                setValue(3)
                                handleReview(3)
                              } else {
                                setAlert(true)
                                setErrorAlert(true)
                                setErrorMessage("please login to add review")
                              }
                            }}
                          />
                        )}
                        {value >= 4 ? (
                          <Star
                            className="star pressed"
                            onClick={(e) => {
                              if (
                                currentUser &&
                                currentUser.data &&
                                currentUser.data._id &&
                                currentUser.data._id
                              ) {
                                setValue(4)
                                handleReview(4)
                              } else {
                                setAlert(true)
                                setErrorAlert(true)
                                setErrorMessage("please login to add review")
                              }
                            }}
                          />
                        ) : reviewAvrg >= 4 ? (
                          <Star
                            className="star pressed"
                            onClick={(e) => {
                              if (
                                currentUser &&
                                currentUser.data &&
                                currentUser.data._id &&
                                currentUser.data._id
                              ) {
                                setValue(4)
                                handleReview(4)
                              } else {
                                setAlert(true)
                                setErrorAlert(true)
                                setErrorMessage("please login to add review")
                              }
                            }}
                          />
                        ) : (
                          <StarOutlineSharp
                            style={{
                              cursor: "pointer",
                            }}
                            className="star"
                            onClick={(e) => {
                              if (
                                currentUser &&
                                currentUser.data &&
                                currentUser.data._id &&
                                currentUser.data._id
                              ) {
                                setValue(4)
                                handleReview(4)
                              } else {
                                setAlert(true)
                                setErrorAlert(true)
                                setErrorMessage("please login to add review")
                              }
                            }}
                          />
                        )}
                        {value >= 5 ? (
                          <Star
                            className="star pressed"
                            onClick={(e) => {
                              if (
                                currentUser &&
                                currentUser.data &&
                                currentUser.data._id &&
                                currentUser.data._id
                              ) {
                                setValue(5)
                                handleReview(5)
                              } else {
                                setAlert(true)
                                setErrorAlert(true)
                                setErrorMessage("please login to add review")
                              }
                            }}
                          />
                        ) : reviewAvrg >= 5 ? (
                          <Star
                            className="star pressed"
                            onClick={(e) => {
                              if (
                                currentUser &&
                                currentUser.data &&
                                currentUser.data._id &&
                                currentUser.data._id
                              ) {
                                setValue(5)
                                handleReview(5)
                              } else {
                                setAlert(true)
                                setErrorAlert(true)
                                setErrorMessage("please login to add review")
                              }
                            }}
                          />
                        ) : (
                          <StarOutlineSharp
                            style={{
                              cursor: "pointer",
                            }}
                            className="star"
                            onClick={(e) => {
                              if (
                                currentUser &&
                                currentUser.data &&
                                currentUser.data._id &&
                                currentUser.data._id
                              ) {
                                setValue(5)
                                handleReview(5)
                              } else {
                                setAlert(true)
                                setErrorAlert(true)
                                setErrorMessage("please login to add review")
                              }
                            }}
                          />
                        )}
                        <span className="ratingspan">
                          {firmData &&
                            firmData.reviewRecieved &&
                            firmData.reviewRecieved.length}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="followingsection">
                    <span className="firmtitle">{firm.name}</span>
                    {/* Todo it should be known as the user logs in that whether he/she follows the firm maybe on serverside or */}

                    {currentUser &&
                    currentUser.data &&
                    currentUser.data._id &&
                    currentUser.data._id === id ? (
                      <></>
                    ) : false ? (
                      <button className="followfirm">Follow</button>
                    ) : (
                      <div className="useralreadyfollows">
                        {!isFollowing ? (
                          sendFollow.isLoading ? (
                            <div>
                              <CircularProgress />
                            </div>
                          ) : (
                            <NotificationAdd
                              className="following"
                              onClick={(e) => {
                                handleFollow()
                              }}
                            />
                          )
                        ) : (
                          <>
                            <NotificationsActive
                              className="following pressed"
                              style={{ color: "goldenrod" }}
                            />
                            <button
                              className="unfollowfirm"
                              onClick={(e) => {
                                setModalOpen(true)
                              }}
                            >
                              UnFollow
                            </button>
                          </>
                        )}

                        {/*  <button className='followingfirm'>Following</button>
                <button className='unfollowfirm'>UnFollow</button> */}
                      </div>
                    )}
                  </div>
                  <div className="listofdetails">
                    <div className="spacebetween">
                      <Border
                        first={"Followers"}
                        second={
                          firmData &&
                          firmData.firmInfo &&
                          firmData.firmInfo.followers &&
                          firmData.firmInfo.followers.length
                        }
                      />
                    </div>
                    <div className="spacebetween">
                      <Border
                        first={"Following"}
                        second={firm && firm.following && firm.following.length}
                      />
                    </div>
                  </div>
                </div>
                <div className="contactfirm">
                  <div className="containing">
                    <span className="contacttilte">Contact</span>
                    <div className="contactimage">
                      <ImageBox
                        src={
                          firmData && firmData.logo
                            ? firmData.logo
                            : firm && firm.profilepic
                        }
                      />
                    </div>
                    <div className="spacebetween">
                      <Rooms
                        icons={<LocationOn style={{ fontSize: "12px" }} />}
                        number={"1611 Michigan Ave, Miami Beach, FL 33139"}
                      />
                    </div>
                    {/* <Rooms icons={<LocationOn style={{ fontSize: "12px" }} />} number={"1611 Michigan Ave, Miami Beach, FL 33139"} /> */}
                    <div className="website">
                      <div className="spacebetween">
                        {firmData && firmData.officeCell && (
                          <Border first={"Office"} second={"098 307 8383"} />
                        )}
                      </div>
                      <div className="spacebetween">
                        {firm && firm.cellPhone && (
                          <Border first={"Cell"} second={firm.cellPhone} />
                        )}
                      </div>
                      <div className="spacebetween">
                        {firm && firm.email && (
                          <Border first={"Email"} second={firm.email} />
                        )}
                      </div>
                      <div className="spacebetween">
                        {firmData &&
                          firmData.firmInfo &&
                          firmData.firmInfo.socialLinks &&
                          firmData.firmInfo.socialLinks &&
                          firmData.firmInfo.socialLinks.websiteLink &&
                          firmData.firmInfo.socialLinks.websiteLink[0] && (
                            <Border
                              first={"Website"}
                              second={
                                firmData.firmInfo.socialLinks.websiteLink[0]
                              }
                            />
                          )}
                      </div>
                    </div>
                    <div className="findonsocials">
                      <span className="firmsocialstitlte">
                        you can find {firm.name} on
                      </span>
                      <div className="firmsocialhandles">
                        {firmData &&
                          firmData.firmInfo &&
                          firmData.firmInfo.socialLinks &&
                          firmData.firmInfo.socialLinks &&
                          firmData.firmInfo.socialLinks.facebook &&
                          firmData.firmInfo.socialLinks.facebook[0] && (
                            <a href={firmData.firmInfo.socialLinks.facebook[0]}>
                              <FacebookOutlined className="facebook" />
                            </a>
                          )}

                        {firmData &&
                          firmData.firmInfo &&
                          firmData.firmInfo.socialLinks &&
                          firmData.firmInfo.socialLinks &&
                          firmData.firmInfo.socialLinks.instagram &&
                          firmData.firmInfo.socialLinks.instagram[0] && (
                            <a
                              href={firmData.firmInfo.socialLinks.instagram[0]}
                            >
                              <Instagram className="insta" />
                            </a>
                          )}
                        {firmData &&
                          firmData.firmInfo &&
                          firmData.firmInfo.socialLinks &&
                          firmData.firmInfo.socialLinks &&
                          firmData.firmInfo.socialLinks.twitter &&
                          firmData.firmInfo.socialLinks.twitter[0] && (
                            <a href={firmData.firmInfo.socialLinks.twitter[0]}>
                              <Twitter className="twitter" />
                            </a>
                          )}
                        {firmData &&
                          firmData.firmInfo &&
                          firmData.firmInfo.socialLinks &&
                          firmData.firmInfo.socialLinks &&
                          firmData.firmInfo.socialLinks.linkedIn &&
                          firmData.firmInfo.socialLinks.linkedIn[0] && (
                            <a href={firmData.firmInfo.socialLinks.linkedIn[0]}>
                              <LinkedIn className="linkedin" />
                            </a>
                          )}
                        {firmData &&
                          firmData.firmInfo &&
                          firmData.firmInfo.socialLinks &&
                          firmData.firmInfo.socialLinks &&
                          firmData.firmInfo.socialLinks.google &&
                          firmData.firmInfo.socialLinks.google[0] && (
                            <a href={firmData.firmInfo.socialLinks.google[0]}>
                              <Google className="googs" />
                            </a>
                          )}
                        {firmData &&
                          firmData.firmInfo &&
                          firmData.firmInfo.socialLinks &&
                          firmData.firmInfo.socialLinks &&
                          firmData.firmInfo.socialLinks.youtube &&
                          firmData.firmInfo.socialLinks.youtube[0] && (
                            <a href={firmData.firmInfo.socialLinks.youtube[0]}>
                              <YouTube className="yt" />
                            </a>
                          )}

                        {firmData &&
                          firmData.firmInfo &&
                          firmData.firmInfo.socialLinks &&
                          firmData.firmInfo.socialLinks &&
                          firmData.firmInfo.socialLinks.pinterest &&
                          firmData.firmInfo.socialLinks.pinterest[0] && (
                            <a
                              href={firmData.firmInfo.socialLinks.pinterest[0]}
                            >
                              <Pinterest className="pindabest" />
                            </a>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {firmData &&
              firmData.discription &&
              firmData.discription.length > 0 && (
                <div className="firmmiddle">
                  <div className="aboutfirm" id="about">
                    <div className="infocontainer">
                      <span className="infotilte"> About {firm.name}</span>
                      {firmData &&
                        firmData.discription &&
                        firmData.discription.length > 0 &&
                        firmData.discription.map((item, index) => (
                          <p key={45 + index}>{item}</p>
                        ))}
                    </div>
                  </div>
                </div>
              )}

            <div className="firmmiddle">
              <div className="aboutfirm" id="review">
                <div className="infocontainer">
                  <span className="infotilte"> Reviews</span>
                  {firmData &&
                  firmData.reviewRecieved &&
                  firmData.reviewRecieved.length > 0 ? (
                    <Review
                      review={
                        firmData &&
                        firmData.reviewRecieved &&
                        firmData.reviewRecieved
                      }
                      average={reviewAvrg && reviewAvrg}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            {/* <div className='firmreport'>
          <div className='leftsidereport'>
            <div className='emailer'>
              <span>Send a message to {firm.name}</span>
              <div className='messagesendingfields'>
                <div className='singlefield'>
                  <span>your Email</span>
                  <TextField variant="standard" />


                </div>
                <div className='singlefield'>
                  <span>Subject</span>
                  <TextField variant="standard" />


                </div>
                <div className='singlefield'>
                  <span>Message</span>
                  <TextField
                    multiline
                    rows={4} />

                </div>
                <button className='emailerbutton'> Send Message</button>
              </div>
            </div>
          </div>
          <div className='rightsidereport'></div>
        </div> */}
          </div>
        </>
      )}
      <Footer />
    </div>
  )
}
