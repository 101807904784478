export const data = [
  {
    item_no: "1.1",
    discription:
      "Load and cart away ali demolishing & dismantling material to an appropriate tip.",
    unit: "0",
    price: "0",
  },
  { item_no: "0", discription: "Manuall", unit: "0", price: "0" },
  {
    item_no: "1.1.1",
    discription: "Demolishing the lamera wall",
    unit: "m",
    price: "56.51",
  },
  {
    item_no: "1.1.2",
    discription: "Demolishing the existing CIS wall",
    unit: "m",
    price: "1 1.31",
  },
  {
    item_no: "1.1.3",
    discription: "Demolishing of wooden wall",
    unit: "m",
    price: "64.92",
  },
  {
    item_no: "1.1.4",
    discription: "Demolishing of gypsum wall",
    unit: "m",
    price: "32,46",
  },
  {
    item_no: "1.1.5",
    discription: "Demolishing (he CIS roof",
    unit: "m",
    price: "18.1",
  },
  {
    item_no: "1.1 .6",
    discription: "Demolishing of 7x5 cm zi ba purline",
    unit: "ml",
    price: "9.05",
  },
  {
    item_no: "1.1.7",
    discription: "Demolishing of eucalyptus up er & Iower chords",
    unit: "ml",
    price: "29.54",
  },
  {
    item_no: "1 .1.8",
    discription: "Dimolish RHS purline",
    unit: "ml",
    price: "14.13",
  },
  {
    item_no: "1.1 .9",
    discription: "Dimolishing Ihe RHS truss,",
    unit: "ml",
    price: "35.87",
  },
  {
    item_no: "1 .1.10",
    discription: "Desmantling Gutter",
    unit: "ml",
    price: "12.23",
  },
  {
    item_no: "1.1.11",
    discription: "Desmanlling down pipe",
    unit: "ml",
    price: "23.34",
  },
  {
    item_no: "1.1.12",
    discription: "Demolishing the chipwood ceiling",
    unit: "m",
    price: "36.83",
  },
  {
    item_no: "1.1.13",
    discription: "Demolishing (he tember celing",
    unit: "m",
    price: "55.25",
  },
  {
    item_no: "1 M M4",
    discription: "Demolishing the abujedid celin",
    unit: "m",
    price: "13.81",
  },
  {
    item_no: "1.1.15",
    discription: "Dismanlle the metal doors from the Wall.",
    unit: "m",
    price: "67.02",
  },
  {
    item_no: "1.1.16",
    discription: "Dismantle the metal windows from the wall.",
    unit: "m",
    price: "60.32",
  },
  {
    item_no: "1.1.17",
    discription: "Desmantling fascia board",
    unit: "ml",
    price: "16.62",
  },
  {
    item_no: "1.1,18",
    discription: "Desmanlling ridge & copping",
    unit: "ml",
    price: "24.46",
  },
  {
    item_no: "1.1.19",
    discription: "Demolishing Ihe PVC floor tile",
    unit: "m",
    price: "16,92",
  },
  {
    item_no: "1.1.20",
    discription: "Demolishing kitchen sink",
    unit: "CS",
    price: "24.21",
  },
  {
    item_no: "1.1.21",
    discription: "Demolishing toilet WC,HW,turkish seat",
    unit: "CS",
    price: "72.64",
  },
  {
    item_no: "1.1.22",
    discription: "Desmantling of wooden door & window",
    unit: "m",
    price: "52.11",
  },
  {
    item_no: "1.2",
    discription:
      "Demolishing Work of different structures N.B. price include clearing the working area &",
    unit: "0",
    price: "0",
  },
  {
    item_no: "0",
    discription: "disose debri as indicated the suervisour",
    unit: "0",
    price: "0",
  },
  {
    item_no: "\u0131 .2.1",
    discription: "Masonay Struclure (BGL)",
    unit: "m",
    price: "299.19",
  },
  {
    item_no: "\u0131 .2.2",
    discription: "Masonary Structure (AGL)",
    unit: "m",
    price: "398.92",
  },
  {
    item_no: "\u0131 .2.3",
    discription: "Demolishing of 25cm thick hardcore",
    unit: "m",
    price: "26.36",
  },
  {
    item_no: "1.2.4",
    discription: "Concrete Structures",
    unit: "m",
    price: "1 ,667.89",
  },
  {
    item_no: "\u0131.2.5",
    discription: "10cm thick HCB Structure",
    unit: "m",
    price: "27.56",
  },
  {
    item_no: "\u0131 .2.6",
    discription: "15cm thick HCB Structure",
    unit: "m",
    price: "36.74",
  },
  {
    item_no: "\u0131 .2.7",
    discription: "20cm thick HCB Structure",
    unit: "m",
    price: "51.44",
  },
  {
    item_no: "\u0131 .2,8",
    discription: "Terrazzo floor",
    unit: "m",
    price: "57.15",
  },
  {
    item_no: "1.2.9",
    discription: "Cement screed floor",
    unit: "m",
    price: "12.86",
  },
  {
    item_no: "1.2.10",
    discription: "8cm thick Mass concrete floor",
    unit: "m",
    price: "17.15",
  },
  {
    item_no: "1.2.11",
    discription: "30x120x3cm thick marble Iread",
    unit: "CS",
    price: "106.79",
  },
  {
    item_no: "1.2.12",
    discription: "15x120x3cm thick marble riser",
    unit: "CS",
    price: "80.09",
  },
  {
    item_no: "1.2.13",
    discription: "Steel Struclure",
    unit: "Kg",
    price: "3.66",
  },
  {
    item_no: "1.2.14",
    discription: "Demolishing the ceramic wall tile",
    unit: "m",
    price: "57.15",
  },
  {
    item_no: "1.2.15",
    discription: "Demolishing the ceramic floor tile (6 & 8 mm thick)",
    unit: "m",
    price: "64.3",
  },
  { item_no: "0", discription: "(From Ist -3rd floor)", unit: "0", price: "0" },
  {
    item_no: "1.2.14",
    discription: "Concrete Structures",
    unit: "m",
    price: "2227.89",
  },
  {
    item_no: "1.2.15",
    discription: "1 Ocm thick HCB Structure",
    unit: "m",
    price: "38.8",
  },
  {
    item_no: "1.2.16",
    discription: "15cm thick HCB Structure",
    unit: "m",
    price: "49.88",
  },
  {
    item_no: "1.2.17",
    discription: "20cm thick HCB Structure",
    unit: "m",
    price: "69.84",
  },
  {
    item_no: "1.2.18",
    discription: "Terrazzo floor",
    unit: "m",
    price: "76.71",
  },
  {
    item_no: "1.2.19",
    discription: "Cement screed floor",
    unit: "m",
    price: "17.26",
  },
  {
    item_no: "\u0131 .2.20",
    discription: "30x120x3cm thick marble tread",
    unit: "m",
    price: "136.12",
  },
  {
    item_no: "\u0131 .2.21",
    discription: "15x120x3cm Ihick marble riser",
    unit: "m",
    price: "102,09",
  },
  {
    item_no: "1.2.22",
    discription: "Steel Structure",
    unit: "k",
    price: "4.76",
  },
  {
    item_no: "\u0131 .2.23",
    discription: "Demolishing the ceramic wall tile",
    unit: "m",
    price: "76.71",
  },
  {
    item_no: "\u0131 .2.24",
    discription: "Demolishing the ceramic floor tile (6 & 8 mm thick)",
    unit: "m",
    price: "86.3",
  },
  { item_no: "0", discription: "(From 4th -7th floor)", unit: "0", price: "0" },
  {
    item_no: "\u0131 .2.23",
    discription: "Concrete Structures",
    unit: "m",
    price: "2814.55",
  },
  {
    item_no: "\u0131 .2.24",
    discription: "10cm thick HCB Structure",
    unit: "m",
    price: "42.02",
  },
  {
    item_no: "1.2.25",
    discription: "15cm thick HCB Slructure",
    unit: "m",
    price: "54.03",
  },
  {
    item_no: "\u0131 .2.26",
    discription: "20cm thick HCB Struclure",
    unit: "m",
    price: "75.64",
  },
  {
    item_no: "\u0131 .227",
    discription: "Terrazzo floor",
    unit: "m",
    price: "84.04",
  },
  {
    item_no: "12.28",
    discription: "Cement screed floor",
    unit: "m",
    price: "18.91",
  },
  {
    item_no: "\u0131 .2,29",
    discription: "30x120x3cm thick marble tread",
    unit: "m",
    price: "164.12",
  },
  {
    item_no: "1.2.30",
    discription: "15x120x3cm thick marble riser",
    unit: "m",
    price: "123.09",
  },
  {
    item_no: "\u0131 .2.31",
    discription: "Steel Structure",
    unit: "k",
    price: "6.98",
  },
  {
    item_no: "\u0131 .2.32",
    discription: "Demolishing the ceramic wall tile",
    unit: "m",
    price: "96,26",
  },
  {
    item_no: "1.2.33",
    discription: "Demolishing the ceramic floor tile (6 & 8 mm thick)",
    unit: "m",
    price: "108.3",
  },
  { item_no: "0", discription: "(From 8th 12th floor)", unit: "0", price: "0" },
  {
    item_no: "1 .2.32",
    discription: "Concrete Structures",
    unit: "m",
    price: "3401.22",
  },
  {
    item_no: "1 .2.33",
    discription: "10cm thick HCB Structure",
    unit: "m",
    price: "60.6",
  },
  {
    item_no: "1.2.34",
    discription: "15cm thick HCB Structure",
    unit: "m",
    price: "66.6",
  },
  {
    item_no: "1.2.35",
    discription: "20cm thick HCB Structure",
    unit: "m",
    price: "93.24",
  },
  {
    item_no: "112.36",
    discription: "Terrazzo floor",
    unit: "m",
    price: "103.6",
  },
  {
    item_no: "1.2.37",
    discription: "Cement screed floor",
    unit: "m",
    price: "23.31",
  },
  {
    item_no: "1.2.38",
    discription: "30x120x3cm thick marble tread",
    unit: "m",
    price: "193.46",
  },
  {
    item_no: "1 .2.39",
    discription: "15x120x3cm thick marble riser",
    unit: "cs",
    price: "145.09",
  },
  {
    item_no: "1.2.40",
    discription: "Steel Structure",
    unit: "k",
    price: "7.06",
  },
  {
    item_no: "1.2.41",
    discription: "Demolishing the ceramic wall tile",
    unit: "m",
    price: "115.82",
  },
  {
    item_no: "1 .2.42",
    discription: "Demolishin the ceramic floor tile (6 & 8 mm thick)",
    unit: "m",
    price: "130.3",
  },
  {
    item_no: "0",
    discription: "(From 13th -20th floor)",
    unit: "0",
    price: "0",
  },
  {
    item_no: "12.41",
    discription: "Concrete Structures",
    unit: "m",
    price: "3987.89",
  },
  {
    item_no: "1 .2.42",
    discription: "1 Ocm thick HCB Structure",
    unit: "m",
    price: "61.58",
  },
  {
    item_no: "1.243",
    discription: "15cm thick HCB Structure",
    unit: "m",
    price: "79.17",
  },
  {
    item_no: "12.44",
    discription: "20cm thick HCB Structure",
    unit: "m",
    price: "110.84",
  },
  {
    item_no: "1.2.45",
    discription: "Terrazzo floor",
    unit: "m",
    price: "123.15",
  },
  {
    item_no: "1 .2.46",
    discription: "Cement screed floor",
    unit: "m",
    price: "27.71",
  },
  {
    item_no: "1 .2.47",
    discription: "30x120x3cm thick marble tread",
    unit: "m",
    price: "222.79",
  },
  {
    item_no: "1.2.48",
    discription: "15x120x3cm thick marble riser",
    unit: "cs",
    price: "167.09",
  },
  {
    item_no: "1,2.49",
    discription: "Steel Structure",
    unit: "k",
    price: "8.16",
  },
  {
    item_no: "1 .2.50",
    discription: "Demolishing the ceramic wall tile",
    unit: "m",
    price: "135.38",
  },
  {
    item_no: "1.2.51",
    discription: "Demolishin the ceramic floor tile (6 & 8 mm thick)",
    unit: "m",
    price: "152.3",
  },
  {
    item_no: "2",
    discription: "Excavation and earth work (mechanized)",
    unit: "0",
    price: "0",
  },
  {
    item_no: "2.1",
    discription: "Site clearance and excavation",
    unit: "0",
    price: "0",
  },
  {
    item_no: "2.1.1",
    discription: "20cm. Clearing on wet & black soil.",
    unit: "m",
    price: "49.99",
  },
  {
    item_no: "2.2",
    discription: "Bulk excavation in loose & d",
    unit: "0",
    price: "0",
  },
  {
    item_no: "2.2.1",
    discription: "to a depth not exceeding 1.5m",
    unit: "m",
    price: "151.5",
  },
  {
    item_no: "2.2.2",
    discription: "to a depth exceeding 1.50m but not exceeding 3.00m",
    unit: "m",
    price: "185.72",
  },
  {
    item_no: "2.2.3",
    discription: "toa de th exceeding 3.00m but not exceedin4.50m",
    unit: "m",
    price: "224.89",
  },
  {
    item_no: "2.2.4",
    discription: "to a depth exceeding 4.50m but not exceeding 6.00m",
    unit: "m",
    price: "257.02",
  },
  {
    item_no: "2.2.5",
    discription: "to a depth exceeding 6.00m but not exceeding 7.50m",
    unit: "m",
    price: "299.85",
  },
  {
    item_no: "2.2.6",
    discription: "to a depth exceeding 7.50m but not exceeding 9.00m",
    unit: "m",
    price: "359.82",
  },
  {
    item_no: "2.2.7",
    discription: "to a de th exceeding 9.00m but not exceeding 10.50m",
    unit: "m",
    price: "399,80",
  },
  {
    item_no: "2.3",
    discription: "Pit excavation in loose & dry soil",
    unit: "0",
    price: "0",
  },
  {
    item_no: "2.3.1",
    discription: "to a de th not exceeding 1500cm loose and dry soii.",
    unit: "m",
    price: "171.34",
  },
  {
    item_no: "2.3.2",
    discription: "to a depth exceeding 1500cm but not exceeding 3000mm",
    unit: "m",
    price: "195.82",
  },
  {
    item_no: "23.3",
    discription: "to a de th exceeding 3000cm but not exceeding 4500mm",
    unit: "m",
    price: "228.46",
  },
  {
    item_no: "2.3.4",
    discription: "to a depth exceedin4500cm but not exceeding 6000mm",
    unit: "m",
    price: "274.15",
  },
  {
    item_no: "2.3.5",
    discription: "to a depth exceeding 6000cm but not exceedin 7500mm",
    unit: "m",
    price: "342.69",
  },
  {
    item_no: "2.3.6",
    discription: "to a de th exceeding 7500cm but not exceeding 9000mm",
    unit: "m",
    price: "432.87",
  },
  {
    item_no: "2.3.7",
    discription: "to a de th exceeding 9000cm but not exceeding 10500mm",
    unit: "m",
    price: "587.47",
  },
  {
    item_no: "2.4",
    discription: "Bulk excavation in soft rock",
    unit: "0",
    price: "0",
  },
  {
    item_no: "2.4.1",
    discription: "not exceeding 115m",
    unit: "m",
    price: "258.45",
  },
  {
    item_no: "2.4.2",
    discription: "Over 1.5.0m,not exceeding 3m",
    unit: "m",
    price: "359.85",
  },
  {
    item_no: "2.4.3",
    discription: "Over 3.0m,not exceeding 4.5m",
    unit: "m",
    price: "436.06",
  },
  {
    item_no: "2.44",
    discription: "Over 4.5m,not exceeding 6.0m",
    unit: "m",
    price: "622.94",
  },
  {
    item_no: "2.4.5",
    discription: "Over 6.0m,not exceeding 7.5m",
    unit: "m",
    price: "889.91",
  },
  {
    item_no: "2.4.6",
    discription: "Over 7.5m,not exceeding 9.0m",
    unit: "m",
    price: "1271.3",
  },
  {
    item_no: "2.4.7",
    discription: "Over 9.0m,not exceeding 10.5m",
    unit: "m",
    price: "1816.15",
  },
  {
    item_no: "2.5",
    discription: "Pit excavation in soft rock",
    unit: "0",
    price: "0",
  },
  {
    item_no: "2.5.1",
    discription: "not exceedin 1.50m",
    unit: "m",
    price: "321.63",
  },
  {
    item_no: "2.5.2",
    discription: "Over 1.5.0m,not exceeding 3m",
    unit: "m",
    price: "353.43",
  },
  {
    item_no: "2.5.3",
    discription: "Over 3.0m,not exceeding 4.5m",
    unit: "m",
    price: "437.59",
  },
  {
    item_no: "2.5.4",
    discription: "Over 4.5.0m,not exceeding 6m",
    unit: "m",
    price: "625.12",
  },
  {
    item_no: "2.5.5",
    discription: "Over 6m,not exceeding 7.5m",
    unit: "m",
    price: "893.03",
  },
  {
    item_no: "2.5.6",
    discription: "Over 7.5m,not exceeding 9m",
    unit: "m",
    price: "1275.76",
  },
  {
    item_no: "2.5.7",
    discription: "Over 9m.not exceeding 10.5m",
    unit: "m",
    price: "1822.51",
  },
  {
    item_no: "2.6",
    discription: "Bulk excavation in hard rock",
    unit: "0",
    price: "0",
  },
  {
    item_no: "2.6.1",
    discription: "not exceedin 1.50m",
    unit: "m",
    price: "647.73",
  },
  {
    item_no: "2.6.2",
    discription: "Over 1.5m,not exceeding 3m",
    unit: "m",
    price: "807.59",
  },
  {
    item_no: "2.6.3",
    discription: "Over 3.0m,not exceeding 4.5m",
    unit: "m",
    price: "970.94",
  },
  {
    item_no: "2.6.4",
    discription: "Over 4.5m,not exceeding 6,0m",
    unit: "m",
    price: "1321.9",
  },
  {
    item_no: "2.6.5",
    discription: "Over 6.0m\u0142not exceeding 7.5m",
    unit: "m",
    price: "1652.38",
  },
  {
    item_no: "2.6.6",
    discription: "Over 7.5m,not exceeding 9.0m",
    unit: "m",
    price: "2065.47",
  },
  {
    item_no: "2.6.7",
    discription: "Over 9.0m.not exceeding 10.5m",
    unit: "m",
    price: "2429.96",
  },
  {
    item_no: "2.7",
    discription: "Pit excavation in hard rock",
    unit: "0",
    price: "0",
  },
  {
    item_no: "2.7.1",
    discription: "not exceedin 1.50m",
    unit: "m",
    price: "643.25",
  },
  {
    item_no: "2.7.2",
    discription: "Over 1.5m,not exceeding 3m",
    unit: "m",
    price: "795.23",
  },
  {
    item_no: "2.7.3",
    discription: "Over 3.0m,not exceeding 4.5m",
    unit: "m",
    price: "1033.8",
  },
  {
    item_no: "2.7.4",
    discription: "Over 4.5m,not exceeding 6m",
    unit: "m",
    price: "1406.52",
  },
  {
    item_no: "2.7.5",
    discription: "Over 6.0m.not exceeding 7.5m",
    unit: "m",
    price: "2009.32",
  },
  {
    item_no: "2.7.6",
    discription: "Over 7.5m,not exceeding 9m",
    unit: "m",
    price: "2870.46",
  },
  {
    item_no: "2.7.7",
    discription: "Over 9m,not exceeding 10.5m",
    unit: "m",
    price: "4100.65",
  },
  { item_no: "2.8", discription: "Back fill", unit: "0", price: "0" },
  {
    item_no: "28.1",
    discription:
      "Back fill selected excavated mats. from the site.(mechanized)",
    unit: "m",
    price: "645.87",
  },
  {
    item_no: "2.8.2",
    discription:
      "Back fill selected excavated mats. from out side the site. Mechanized)",
    unit: "m",
    price: "1777.75",
  },
  {
    item_no: "2.8.4",
    discription: "25cm. thick basaltic hard core. (Mechanized)",
    unit: "m",
    price: "558",
  },
  {
    item_no: "0",
    discription: "EXCAVATION AND EARTH WORK. (manual)",
    unit: "0",
    price: "0",
  },
  {
    item_no: "0",
    discription: "20cm. Clearing on wet & black soil.",
    unit: "m",
    price: "49.99",
  },
  {
    item_no: "3.1",
    discription: "Bulk excav.in loose and dry soil.",
    unit: "0",
    price: "0",
  },
  {
    item_no: "3.1.1",
    discription: "to a depth not exceeding 1500mm",
    unit: "m",
    price: "367.26",
  },
  {
    item_no: "3.1.2",
    discription: "to a depth exceeding 1500mm but not exceeding 3000mm",
    unit: "m",
    price: "423.76",
  },
  {
    item_no: "3.1.3",
    discription: "to a depth exceeding 3000mm but not exceeding 4500mm",
    unit: "m",
    price: "479.03",
  },
  {
    item_no: "3.2",
    discription: "Bulk excav.in soft rock.",
    unit: "0",
    price: "0",
  },
  {
    item_no: "3.2.1",
    discription: "not exceeding 1 .5m",
    unit: "m",
    price: "748.66",
  },
  {
    item_no: "3.22",
    discription: "Over 1.5.Om,not exceeding 3m",
    unit: "m",
    price: "842.24",
  },
  {
    item_no: "3.2.3",
    discription: "Over 3.0m,not exceeding 4.5m",
    unit: "m",
    price: "1036.6",
  },
  {
    item_no: "3.3",
    discription: "Bulk excav.in hard rock.",
    unit: "0",
    price: "0",
  },
  {
    item_no: "3.3.1",
    discription: "not exceedingl .50m",
    unit: "m",
    price: "1347.58",
  },
  {
    item_no: "3.3.2",
    discription: "Over 1.5m,not exceeding 3m",
    unit: "m",
    price: "1574.84",
  },
  {
    item_no: "33.3",
    discription: "Over 3.0m,not exceeding 4.5m",
    unit: "m",
    price: "2422.82",
  },
  {
    item_no: "3.4",
    discription: "Pit & Trench excav. in loose and dry soil.",
    unit: "0",
    price: "0",
  },
  {
    item_no: "3.4.1",
    discription: "not exceeding 1500cm",
    unit: "m",
    price: "408.06",
  },
  {
    item_no: "3.4.2",
    discription: "to a depth exceeding 1500cm but not exceeding 3000mm",
    unit: "m",
    price: "500.81",
  },
  {
    item_no: "3.4.3",
    discription: "to a depth exceeding 3000cm but not exceeding 4500mm",
    unit: "m",
    price: "688.61",
  },
  {
    item_no: "3.5",
    discription: "Pit & Trench excav. in soft rock",
    unit: "0",
    price: "0",
  },
  {
    item_no: "3.5.1",
    discription: "not exceeding 1.5m",
    unit: "m",
    price: "898.39",
  },
  {
    item_no: "3.5.2",
    discription: "Over 1.5.Om,not exceeding 3m",
    unit: "m",
    price: "1122.99",
  },
  {
    item_no: "3.5.3",
    discription: "Over 3.0m,not exceeding 4.5m",
    unit: "m",
    price: "1347.58",
  },
  {
    item_no: "3.6",
    discription: "Pit Excavation in hard rock",
    unit: "0",
    price: "0",
  },
  {
    item_no: "3.6.1",
    discription: "not exceeding 1.5m",
    unit: "m",
    price: "1497.31",
  },
  {
    item_no: "3.6.2",
    discription: "Over 1.5m,not exceeding 3m",
    unit: "m",
    price: "1776.95",
  },
  {
    item_no: "3.63",
    discription: "Over 3m,not exceeding 4.5m",
    unit: "m",
    price: "2999.69",
  },
  {
    item_no: "3.6",
    discription: "Trench Excavation in hard rock",
    unit: "0",
    price: "0",
  },
  {
    item_no: "3.6.1",
    discription: "not exceeding 1.5m",
    unit: "m",
    price: "1727.67",
  },
  {
    item_no: "3.6.2",
    discription: "Over 1.5m,not exceeding 3m",
    unit: "m",
    price: "2323.42",
  },
  {
    item_no: "3.6.3",
    discription: "Over 3m,not exceeding 4.5m",
    unit: "m",
    price: "3963.48",
  },
  { item_no: "3.7", discription: "Back fill", unit: "0", price: "0" },
  {
    item_no: "3.7.1",
    discription: "Back fill selected excavated mats. from the site. Manual",
    unit: "m",
    price: "561.63",
  },
  {
    item_no: "3.7.2",
    discription:
      "Back fill selected excavated mats. from out side the site. Manual",
    unit: "m",
    price: "1761.7",
  },
  {
    item_no: "3.7.3",
    discription: "Cart away surplus excavated materials to 5km",
    unit: "m",
    price: "156.2",
  },
  {
    item_no: "3.7.4",
    discription: "Cart away surplus excavated materials to 10km",
    unit: "m",
    price: "181.67",
  },
  {
    item_no: "3.7.5",
    discription: "Cart away surplus excavated materials to 15km",
    unit: "m",
    price: "235.54",
  },
  {
    item_no: "3.7.6",
    discription: "Cart away surplus excavated materials to 20km",
    unit: "m",
    price: "298.07",
  },
  {
    item_no: "3.7.6",
    discription: "25cm. thick basaltic hard core. basaltic stone Manual",
    unit: "m",
    price: "565.38",
  },
  {
    item_no: "3.7.7",
    discription: "Red Ash material delivered b contractor.",
    unit: "m",
    price: "1746.07",
  },
  { item_no: "4", discription: "CONCRETE WORK", unit: "0", price: "0" },
  { item_no: "0", discription: "SUB STRUCTURE", unit: "0", price: "0" },
  {
    item_no: "a",
    discription: "C-7 Lean Concrete (Hand mix) (C-7)",
    unit: "m",
    price: "323.97",
  },
  {
    item_no: "b",
    discription: "C-5 Lean Concrete (Hand mix) (C-5)",
    unit: "m",
    price: "307.84",
  },
  {
    item_no: "c",
    discription:
      "C-15 10cm thick mass concrete pavement cost include hard core & 7cm selected material.",
    unit: "m",
    price: "1647.73",
  },
  {
    item_no: "d",
    discription: "Mortar production (1:3)",
    unit: "m",
    price: "7551.59",
  },
  {
    item_no: "e",
    discription: "Light Weight Concrete 1:2:5",
    unit: "m",
    price: "6741.18",
  },
  {
    item_no: "f",
    discription: "C-15 mass Concrete",
    unit: "m",
    price: "765.78",
  },
  {
    item_no: "g",
    discription: "4mm thick approved quality water proof membrane",
    unit: "m",
    price: "1164.14",
  },
  {
    item_no: "h",
    discription:
      "10mm thick sta roroam expansion joint between ground floor slab & beam.",
    unit: "ml",
    price: "36.11",
  },
  {
    item_no: "I",
    discription:
      "20mm thick stayroroam ex ansion \u2022oint between round floor slab & beam",
    unit: "ml",
    price: "75.67",
  },
  { item_no: "0", discription: "SUB STRUCTURE", unit: "0", price: "0" },
  {
    item_no: "4.1",
    discription: "R.C. C-15 280 kg of cement per m3 with drum mixer",
    unit: "0",
    price: "0",
  },
  {
    item_no: "4.1.1",
    discription: "C-15 Concrete (Mechanical Mix) 1:2:4 Wilh admixture",
    unit: "m",
    price: "7977.82",
  },
  {
    item_no: "4.1.2",
    discription:
      "C-15 Concrete (Mechanical Mix) 1:2:4 for Footing Beam and slab",
    unit: "m",
    price: "7657.82",
  },
  {
    item_no: "4.1.3",
    discription: "C-15 Concrete (Mechanical Mix) 1:2:4 for Column ,shear wall",
    unit: "m",
    price: "7956.87",
  },
  {
    item_no: "4.2",
    discription: "R.C. C-20 336 kg of cement per m3 with drum mixer",
    unit: "0",
    price: "0",
  },
  {
    item_no: "4.2.1",
    discription: "C-20 Concrete (Mechanical Mix) 1:2:3 with admixture",
    unit: "m",
    price: "8523.88",
  },
  {
    item_no: "4.2.2",
    discription:
      "C-20 Concrete (Mechanical Mix) 1:2:3 for Footing ,Beam and slab",
    unit: "m",
    price: "8203.88",
  },
  {
    item_no: "4.2.3",
    discription: "C-20 Concrete (Mechanical Mix) 1:2:3 for Column ,shear wall",
    unit: "m",
    price: "8502.87",
  },
  {
    item_no: "4.3",
    discription: "R.C. C-25 378 kg of cement per m3 with drum mixer",
    unit: "0",
    price: "0",
  },
  {
    item_no: "4.3.1",
    discription: "C-25 Concrete (Mechanical Mix) 1:2:3 with admixture",
    unit: "m",
    price: "8983.21",
  },
  {
    item_no: "43.2",
    discription:
      "C-25 Concrete (Mechanical Mix) 1 :2:3 for Footing ,Beam and slab",
    unit: "0",
    price: "8663.21",
  },
  {
    item_no: "4.3.3",
    discription: "C-25 Concrete (Mechanical Mix) 1:2:3 for Column ,shear wall",
    unit: "m3",
    price: "8962.19",
  },
  {
    item_no: "4,3.4",
    discription: "C-25 Concrete (Mechanical Mix) 1:2:3 for 10cm thick slab",
    unit: "m2",
    price: "866.32",
  },
  {
    item_no: "4.4",
    discription: "R.C. C-30 420 kg of cement per m3 with drum mixer",
    unit: "0",
    price: "0",
  },
  {
    item_no: "4.4.1",
    discription: "C-30 Concrete (Mechanical Mix) 1:2:3 Wilh admixture",
    unit: "m",
    price: "9529.21",
  },
  {
    item_no: "4.4.2",
    discription:
      "C-30 Concrete (Mechanical Mix) 1:2:3 for Fooling ,Beam and slab",
    unit: "m3",
    price: "9209.21",
  },
  {
    item_no: "4.4.3",
    discription: "C-30 Concrete (Mechanical Mix) 1:2:3 for Column shear wall",
    unit: "m3",
    price: "9508.19",
  },
  {
    item_no: "4.5",
    discription: "R.C. C-25 378 kg of cement per m3 with truck mixer",
    unit: "0",
    price: "0",
  },
  {
    item_no: "4.5.1",
    discription: "C-25 Concrete (Mechanical Mix) 1:2:3 with admixture",
    unit: "m",
    price: "9830.02",
  },
  {
    item_no: "4.5.2",
    discription:
      "C-25 Concrete (Mechanical Mix) 1:2:3 for Footing ,Beam and slab",
    unit: "m",
    price: "9509.98",
  },
  {
    item_no: "4.5.3",
    discription: "C-25 Concrete (Mechanical Mix) 1 :2:3 for Column ,shear wall",
    unit: "m",
    price: "9872.96",
  },
  {
    item_no: "4.6",
    discription: "R.C. C-30 420 kg of cement per m3 with truck mixer",
    unit: "0",
    price: "0",
  },
  {
    item_no: "4.61",
    discription: "C-30 Concrete (Mechanical Mix) 1:2:3 with admixture",
    unit: "m",
    price: "10376.26",
  },
  {
    item_no: "4.6.2",
    discription:
      "C-30 Concrete (Mechanical Mix) 1:2:3 for Footing ,Beam and slab",
    unit: "m",
    price: "10056.26",
  },
  {
    item_no: "4.6.3",
    discription: "C-30 Concrete (Mechanical Mix) 1:2:3 for Column shear wall",
    unit: "m",
    price: "10419.2",
  },
  {
    item_no: "0",
    discription: "R.C. C-40 504 kg of cement per m3 with truck mixer",
    unit: "0",
    price: "0",
  },
  {
    item_no: "4.6.4",
    discription:
      "C-40 Concrete (Mechanical Mix) 1 :2:3 for Footing Beam and slab Wilh admixture",
    unit: "m",
    price: "10987.45",
  },
  {
    item_no: "4.6.5",
    discription:
      "C-40 Concrete (Mechanical Mix) 1:2:3 for Column ,shear wall with admixture",
    unit: "m",
    price: "11303.99",
  },
  {
    item_no: "0",
    discription: "R.C. C-50 520 kg of cement per m3 with truck mixer",
    unit: "0",
    price: "0",
  },
  {
    item_no: "4.6.6",
    discription:
      "C-50 Concrete (Mechanical Mix) 1 :2:3 for Footing Beam and slab with admixture",
    unit: "m",
    price: "11806.45",
  },
  {
    item_no: "4.6.7",
    discription:
      "C-50 Concrete (Mechanical Mix) 1:2:3 for Column Shear wall with admixture",
    unit: "m",
    price: "12122.99",
  },
  { item_no: "0", discription: "SUPER STRUCTURE", unit: "0", price: "0" },
  {
    item_no: "4.7",
    discription: "R.C. C-25 378 kg of cement per m3 with drum mixer",
    unit: "0",
    price: "0",
  },
  {
    item_no: "4.7.1",
    discription: "C-25 Concrete (Mechanical Mix) 1:2:3 with admixture",
    unit: "m",
    price: "9256.25",
  },
  {
    item_no: "47.2",
    discription: "C-25 Concrete (Mechanical Mix) 1 :2:3 for Beam and slab",
    unit: "m",
    price: "8936.25",
  },
  {
    item_no: "47.3",
    discription: "C-25 Concrete (Mechanical Mix) 1:2:3 for Column shear wall",
    unit: "m",
    price: "9278.72",
  },
  {
    item_no: "47.4",
    discription: "C-25 Concrete (Mechanical Mix) 1:2:3 for Stair case",
    unit: "m",
    price: "9523.34",
  },
  {
    item_no: "47.5",
    discription: "C-25 Concrete (Mechanical Mix) 1:2:3 for 15cm thick slab",
    unit: "m",
    price: "1299.48",
  },
  {
    item_no: "4.7.6",
    discription: "C-25 Concrete (Mechanical Mix) 1:2:3 for 18cm thick slab",
    unit: "m",
    price: "1559.38",
  },
  {
    item_no: "4.8",
    discription: "R.C. C-30 420 kg of cement per m3 with drum mixer",
    unit: "0",
    price: "0",
  },
  {
    item_no: "4.8.1",
    discription: "C-30 Concrete (Mechanical Mix) 1:2:3 with admixture",
    unit: "m",
    price: "9802.25",
  },
  {
    item_no: "4.8.2",
    discription: "C-30 Concrete (Mechanical Mix) 1:2:3 for Beam and slab",
    unit: "m",
    price: "9482.25",
  },
  {
    item_no: "4.8.3",
    discription: "C-30 Concrete (Mechanical Mix) 1 :2:3 for Column ,shear wall",
    unit: "m",
    price: "9824.72",
  },
  {
    item_no: "4.8.4",
    discription: "C-30 Concrete (Mechanical Mix) 1 :2:3 for Stair case",
    unit: "m",
    price: "10069.34",
  },
  {
    item_no: "4.9",
    discription: "R.C. C- 40 504 kg of cement per m3 with drum mixer",
    unit: "0",
    price: "0",
  },
  {
    item_no: "49.1",
    discription: "C-40 Concrete (Mechanical Mix) 1:2:3 with admixture",
    unit: "m",
    price: "10894.25",
  },
  {
    item_no: "4.9.2",
    discription: "C-40 Concrete (Mechanical Mix) 1 :2:3 for Beam and slab",
    unit: "m",
    price: "10574.25",
  },
  {
    item_no: "4.9.3",
    discription: "C-40 Concrete (Mechanical Mix) 1:2:3 for Column ,shear wall",
    unit: "m",
    price: "10916.72",
  },
  {
    item_no: "4.9.4",
    discription: "C-40 Concrete (Mechanical Mix) 1 :2:3 for Stair case",
    unit: "m",
    price: "11060.77",
  },
  {
    item_no: "4.1",
    discription: "R.C. C-20 336 kg of cement per m3 with truck mixer",
    unit: "0",
    price: "0",
  },
  {
    item_no: "4.10.1",
    discription: "C-20 Concrete (Mechanical Mix) 1 :2:3 with admixture",
    unit: "m",
    price: "7973.99",
  },
  {
    item_no: "4.10.2",
    discription: "C-20 Concrete (Mechanical Mix) 1 :2:3 for Beam and slab",
    unit: "m",
    price: "9003.63",
  },
  {
    item_no: "4.10.3",
    discription: "C-20 Concrete (Mechanical Mix) 1 :2:3 for Column shear wall",
    unit: "m",
    price: "9366.57",
  },
  {
    item_no: "4.10.4",
    discription: "C-20 Concrete (Mechanical Mix) 1:2:3 for Stair case",
    unit: "m",
    price: "9519.81",
  },
  {
    item_no: "4.11",
    discription: "R.C. C-25 378 kg of cement per m3 with truck mixer",
    unit: "0",
    price: "0",
  },
  {
    item_no: "4.11.1",
    discription: "C-25 Concrete (Mechanical Mix) 1:2:3 with admixture",
    unit: "m",
    price: "8498.85",
  },
  {
    item_no: "4.11.2",
    discription:
      "C-25 Concrete (Mechanical Mix) 1:2:3 for Footing ,Beam and slab",
    unit: "m",
    price: "9509.98",
  },
  {
    item_no: "4.11.3",
    discription: "C-25 Concrete (Mechanical Mix) 1:2:3 for Column ,shear wall",
    unit: "m",
    price: "9872.96",
  },
  {
    item_no: "4.11.4",
    discription: "C-25 Concrete (Mechanical Mix) 1 :2:3 for Stair case",
    unit: "m",
    price: "10039.57",
  },
  {
    item_no: "4.12",
    discription: "R.C. C-30 420 kg of cement per m3 with truck mixer",
    unit: "0",
    price: "0",
  },
  {
    item_no: "4.12.1",
    discription: "C-30 Concrete (Mechanical Mix) 1:2:3 with admixture",
    unit: "m",
    price: "10437.09",
  },
  {
    item_no: "4.12.2",
    discription: "C-30 Concrete (Mechanical Mix) 1:2:3 for Beam and slab",
    unit: "m",
    price: "10095.91",
  },
  {
    item_no: "4.12.3",
    discription: "C-30 Concrete (Mechanical Mix) 1:2:3 for Column shear wall",
    unit: "m",
    price: "10458.85",
  },
  {
    item_no: "4.12.4",
    discription: "C-30 Concrete (Mechanical Mix) 1 :2:3 for stair cases",
    unit: "m",
    price: "10612.09",
  },
  {
    item_no: "4.13",
    discription: "R.C. C-40 504 kg of cement per m3 with truck mixer",
    unit: "0",
    price: "0",
  },
  {
    item_no: "4.13.1",
    discription: "C-40 Concrete (Mechanical Mix) 1:2:3 with admixture",
    unit: "m",
    price: "11507.91",
  },
  {
    item_no: "4.13.2",
    discription:
      "C-40 Concrete (Mechanical Mix) 1:2:3 for Footing .Beam and slab",
    unit: "m",
    price: "11187.91",
  },
  {
    item_no: "4.13.3",
    discription: "C-40 Concrete (Mechanical Mix) 1:2:3 for Column ,shear wall",
    unit: "m",
    price: "11550.85",
  },
  {
    item_no: "4.13.4",
    discription: "C-40 Concrete (Mechanical Mix) 1:2:3 for stair cases",
    unit: "m",
    price: "11704.09",
  },
  {
    item_no: "0",
    discription: "R.C. C-50 520 kg of cement per m3 with truck mixer",
    unit: "0",
    price: "0",
  },
  {
    item_no: "4.13.5",
    discription:
      "C-50 Concrete (Mechanical Mix) 1:2:3 for Column ,shear wall with admixture",
    unit: "m",
    price: "12817.32",
  },
  { item_no: "4.14", discription: "Form work", unit: "0", price: "0" },
  {
    item_no: "4.14.1",
    discription: "Wooden form work For Beam, Footing & Column.sub structure",
    unit: "m",
    price: "687.65",
  },
  {
    item_no: "4.14.2",
    discription:
      "Wooden form work For Super structure column Floor beam & slab",
    unit: "m",
    price: "698.84",
  },
  {
    item_no: "4.14.3",
    discription: "1.8mm thick Fair Faced Form work (high quality play wood)",
    unit: "m",
    price: "1160.73",
  },
  {
    item_no: "4.15",
    discription: "STEEL REINFORCEMENT",
    unit: "0",
    price: "0",
  },
  {
    item_no: "4.16.1",
    discription: "dia. 6 mm. Bars Sub structure (Ethiopia) Grade- 40",
    unit: "kg",
    price: "187.35",
  },
  {
    item_no: "4.16.2",
    discription: "dia. 8 mm deformed Bars Sub structure (Ethiopia) Grade- 40",
    unit: "kg",
    price: "139.48",
  },
  {
    item_no: "416.3",
    discription: "dia 10 mm deformed Bars Sub structure (Ethiopia) Grade- 40",
    unit: "kg",
    price: "139.48",
  },
  {
    item_no: "416.4",
    discription: "dia. 12 mm deformed Bars Sub structure (Ethiopia) Grade- 40",
    unit: "kg",
    price: "135.03",
  },
  {
    item_no: "4.16.5",
    discription: "dia. 14 mm deformed Bars Sub structure (Ethiopia) Grade- 40",
    unit: "kg",
    price: "135.03",
  },
  {
    item_no: "4.16.6",
    discription: "dia. 16 mm deformed Bars Sub structure (Ethiopia) Grade- 40",
    unit: "kg",
    price: "135.03",
  },
  {
    item_no: "4.16.7",
    discription: "dia. 20 mm deformed Bars Sub structure (Ethiopia) Grade- 40",
    unit: "kg",
    price: "135.03",
  },
  {
    item_no: "4.16.8",
    discription: "dia. 24 mm deformed Bars Sub structure (Ethiopia) Grade- 40",
    unit: "kg",
    price: "135.03",
  },
  {
    item_no: "416.2",
    discription: "dia. 32 mm deformed Bars Sub structure (Ethiopia) Grade- 40",
    unit: "kg",
    price: "135.03",
  },
  {
    item_no: "4.16.3",
    discription: "dia. 6 mm. plan Bars Super structure (Ethiopia) Grade- 40",
    unit: "kg",
    price: "191.85",
  },
  {
    item_no: "4.16.4",
    discription: "dia. 8 mm deformed Bars Super structure (Ethiopia) Grade- 40",
    unit: "kg",
    price: "143.97",
  },
  {
    item_no: "4.16.5",
    discription:
      "dia. 10 mm deformed Bars Super structure (Ethiopia) Grade- 40",
    unit: "kg",
    price: "143.97",
  },
  {
    item_no: "4.16.6",
    discription:
      "dia. 12 mm deformed Bars Super structure (Ethiopia) Grade- 40",
    unit: "kg",
    price: "136.76",
  },
  {
    item_no: "4.16.7",
    discription:
      "dia. 14 mm deformed Bars Super structure (Ethiopia) Grade- 40",
    unit: "kg",
    price: "136.76",
  },
  {
    item_no: "4.16.8",
    discription:
      "dia. 16 mm deformed Bars Super structure (Ethiopia) Grade- 40",
    unit: "kg",
    price: "136.76",
  },
  {
    item_no: "4.16.9",
    discription:
      "dia. 20 mm deformed Bars Super structure (Ethiopia) Grade- 40",
    unit: "kg",
    price: "136.76",
  },
  {
    item_no: "4.16.10",
    discription:
      "dia. 24 mm deformed Bars Super structure (Ethiopia) Grade- 40",
    unit: "kg",
    price: "136.76",
  },
  {
    item_no: "4.16.12",
    discription:
      "dia. 32 mm deformed Bars Super structure (Ethiopia) Grade- 40",
    unit: "kg",
    price: "136.76",
  },
  {
    item_no: "4.16.14",
    discription: "dia. 8 mm deformed Bars Sub structure (Imported) Grade- 60",
    unit: "k",
    price: "148.17",
  },
  {
    item_no: "4.16.15",
    discription: "dia. 10 mm deformed Bars Sub structure (Imported) Grade- 60",
    unit: "k",
    price: "148.17",
  },
  {
    item_no: "4.16.16",
    discription: "dia. 12 mm deformed Bars Sub structure (Imported) Grade- 60",
    unit: "0",
    price: "143.72",
  },
  {
    item_no: "4.16.17",
    discription: "dia. 14 mm deformed Bars Sub structure (Imported) Grade- 60",
    unit: "k",
    price: "143.72",
  },
  {
    item_no: "4.16.18",
    discription: "dia. 16 mm deformed Bars Sub structure (Imported) Grade- 60",
    unit: "k",
    price: "143.72",
  },
  {
    item_no: "4.16.19",
    discription: "dia. 20 mm deformed Bars Sub structure (Imported) Grade- 60",
    unit: "kg",
    price: "143.72",
  },
  {
    item_no: "4.16.20",
    discription: "dia. 24 mm deformed Bars Sub structure (Imported) Grade- 60",
    unit: "kg",
    price: "143.72",
  },
  {
    item_no: "4.16.22",
    discription: "dia. 32 mm deformed Bars Sub structure (Imported) Grade- 60",
    unit: "kg",
    price: "143.72",
  },
  {
    item_no: "4.16.24",
    discription: "dia. 8 mm deformed Bars Super structure (Imported) Grade- 60",
    unit: "k",
    price: "152.67",
  },
  {
    item_no: "4.16.25",
    discription:
      "dia. 10 mm deformed Bars Super structure (Imported) Grade- 60",
    unit: "kg",
    price: "152.67",
  },
  {
    item_no: "4.16.26",
    discription:
      "dia. 12 mm deformed Bars Super structure (Imported) Grade- 60",
    unit: "k",
    price: "145.46",
  },
  {
    item_no: "4.16.27",
    discription:
      "dia. 14 mm deformed Bars Super structure (Imported) Grade- 60",
    unit: "kg",
    price: "145.46",
  },
  {
    item_no: "4.16.28",
    discription:
      "dia. 16 mm deformed Bars Super structure (Imported) Grade- 60",
    unit: "k",
    price: "145.46",
  },
  {
    item_no: "4.16.29",
    discription:
      "dia. 20 mm deformed Bars Super structure (Imported) Grade- 60",
    unit: "k",
    price: "145.46",
  },
  {
    item_no: "4.16.30",
    discription:
      "dia. 24 mm deformed Bars Super structure (Imported) Grade- 60",
    unit: "kg",
    price: "145.46",
  },
  {
    item_no: "4.16.32",
    discription: "dia. 32 mm deformed Bars Super structure (mported) Grade- 60",
    unit: "kg",
    price: "145.46",
  },
  { item_no: "5", discription: "Masonary & block work", unit: "0", price: "0" },
  {
    item_no: "5.1",
    discription: "40cm. thick masonry foundation (A.G.L).",
    unit: "m",
    price: "4494.46",
  },
  {
    item_no: "5.2",
    discription: "50cm. thick masonry foundation (A.G.L).",
    unit: "m",
    price: "4439.9",
  },
  {
    item_no: "5.3",
    discription: "60cm. thick masonry foundation (B.G.L).",
    unit: "m",
    price: "4399.34",
  },
  {
    item_no: "5.4",
    discription: "40cm. thickOne Side Roughly Dressed Stone masonry (A.G.L).",
    unit: "m",
    price: "4534.1",
  },
  {
    item_no: "5.5",
    discription: "50cm. thickOne Side Roughly Dressed Stone masonry (A.G. L).",
    unit: "m",
    price: "4468.3",
  },
  {
    item_no: "5.6",
    discription: "60cm. thickOne Side Roughly Dressed Stone masonry (A.G.).",
    unit: "m",
    price: "4420.68",
  },
  {
    item_no: "5.7",
    discription: "50cm, thick masonry foundation (B.G.L).",
    unit: "m",
    price: "3983.52",
  },
  {
    item_no: "5.8",
    discription: "40cm. thick masonry foundation (B.G.L).",
    unit: "m",
    price: "3928",
  },
  {
    item_no: "5.9",
    discription: "400mm thick Finely dressed elevation wall",
    unit: "m",
    price: "1867.79",
  },
  {
    item_no: "5.1",
    discription: "500mm thick Finely dressed elevation wall",
    unit: "m",
    price: "2603.16",
  },
  {
    item_no: "5.13",
    discription:
      "IO cm. thick class-c HCB Wall Both Sides Left For Plastering.",
    unit: "m",
    price: "633.93",
  },
  {
    item_no: "5.14",
    discription:
      "10 cm. thick class-c Solid CB Wall Both Sides Left For Plastering.",
    unit: "m",
    price: "684.81",
  },
  {
    item_no: "5.15",
    discription:
      "15 cm. thick class-c HCB Wall Both Sides Left For Plastering.",
    unit: "m",
    price: "786.46",
  },
  {
    item_no: "5.16",
    discription:
      "15 cm. thick class-c Solid CB Wall Both Sides Left For Plastering.",
    unit: "m",
    price: "829.67",
  },
  {
    item_no: "5.17",
    discription: "20 cm.thick class-c HCB Wall Both Sides Left For Pointing",
    unit: "m",
    price: "914.96",
  },
  {
    item_no: "5.18",
    discription:
      "20 cm. thick class-c Solid CB Wall Both Sides Left For Plastering.",
    unit: "m",
    price: "982,27",
  },
  {
    item_no: "5.19",
    discription:
      "10 cm. thick class-b HCB Wall Both Sides Left For Plastering.",
    unit: "m",
    price: "738.28",
  },
  {
    item_no: "5.2",
    discription:
      "10 cm. thick class-b Solid CB Wall Both Sides Left For Plastering.",
    unit: "m",
    price: "834.54",
  },
  {
    item_no: "5.21",
    discription:
      "15 cm. thick class-b HCB Wall Both Sides Left For Plastering.",
    unit: "m",
    price: "904.33",
  },
  {
    item_no: "5.22",
    discription:
      "15 cm. thick class-b Solid CB Wall Both Sides Left For Plastering.",
    unit: "m",
    price: "1105.52",
  },
  {
    item_no: "5.23",
    discription: "20 cm.thick class-b HCB Wall Both Sides Left For Pointing",
    unit: "m",
    price: "1231.17",
  },
  {
    item_no: "5.24",
    discription:
      "20 cm. thick class-b Solid CB Wall Both Sides Left For Plastering.",
    unit: "m",
    price: "1425",
  },
  {
    item_no: "5.25",
    discription:
      "10 cm. thick class-a HCB Wall Both Sides Left For Plastering.",
    unit: "m",
    price: "939.12",
  },
  {
    item_no: "5.26",
    discription:
      "10 cm. thick class-a Solid CB Wall Both Sides Left For Plastering.",
    unit: "m",
    price: "1132.64",
  },
  {
    item_no: "5.27",
    discription:
      "15 cm. thick class-a HCB Wall Both Sides Left For Plastering.",
    unit: "m",
    price: "1205.99",
  },
  {
    item_no: "5.28",
    discription:
      "15 cm. thick class-a Solid CB Wall Both Sides Left For Plastering.",
    unit: "m",
    price: "1473.57",
  },
  {
    item_no: "5.29",
    discription: "20 cm.thick class-a HCB Wall Both Sides Left For Pointing",
    unit: "m",
    price: "1283.06",
  },
  {
    item_no: "5.3",
    discription:
      "20 cm. thick class-a Solid CB Wall Both Sides Left For Plastering.",
    unit: "m",
    price: "1831.76",
  },
  {
    item_no: "5.31",
    discription:
      "40 cm.thick Dressed Stone Masonry Wall one Side well dressed & the Other plastered",
    unit: "m",
    price: "3277.44",
  },
  {
    item_no: "5.32",
    discription: "250mm .thick Double Brick Wall",
    unit: "m",
    price: "4051.23",
  },
  {
    item_no: "5.33",
    discription: "120mm .thick Brick Wall",
    unit: "m",
    price: "2068.84",
  },
  {
    item_no: "5.34",
    discription:
      "20 x40cm Inclined type louver wall with 1.5mm thick wire mesh",
    unit: "m",
    price: "2348.53",
  },
  {
    item_no: "5.37",
    discription: "Chika wall with all wood work 11361+ nm) 'I?\u0110\u0118 1",
    unit: "m",
    price: "802.41",
  },
  {
    item_no: "5.38",
    discription: "Eucaly tus wood wall with all wood work 11%hD+ 9?'18 1",
    unit: "m",
    price: "524.65",
  },
  {
    item_no: "5.39",
    discription:
      "2.5cm thick Timberwall with 10cm & 6cm dia. Eucalyptus and all wood work/ \u00e2')) (Tl(l)\u0219\u00e2 dlk\"181",
    unit: "m",
    price: "1032.32",
  },
  {
    item_no: "5.4",
    discription:
      "G32 CIS with Euculy tus lihnc pali-P\u021a \u00f4C\u00f4C F,Gb+ 9?'181",
    unit: "m",
    price: "1396.85",
  },
  {
    item_no: "5.46",
    discription:
      "8mm thick Chipwood wall with e uilaptuss all wood work with Eucalyptus diaMOcm and 5x7cm batten",
    unit: "m",
    price: "1120.25",
  },
  {
    item_no: "5.47",
    discription:
      "1.8cm thick Playwood wall with all wood work with Eucaly tus dia.10cm and 5x7cm batten",
    unit: "m",
    price: "953.37",
  },
  {
    item_no: "5.49",
    discription:
      "1.8 thick MDF Wall with 5x7cm batten wood work and all necessary work.",
    unit: "m",
    price: "1054.61",
  },
  {
    item_no: "5.5",
    discription: "38x1.5mm LTZ metal profile wall with 4mm clear lass",
    unit: "m",
    price: "3127.17",
  },
  {
    item_no: "5.51",
    discription: "30x30x1.5mm RHS wall with 4mm clear lass",
    unit: "m",
    price: "2873.18",
  },
  {
    item_no: "5.52",
    discription: "20x20mm and 1.5mm thick Wire mesh wall with frame",
    unit: "m",
    price: "1109.46",
  },
  {
    item_no: "5.53",
    discription: "Barbed wire fence with 40x40x2mm An le Iron Frame",
    unit: "m",
    price: "947.66",
  },
  { item_no: "6", discription: "Roof work", unit: "0", price: "0" },
  {
    item_no: "6.1",
    discription: "EGA roof coover the price includes J-bolt",
    unit: "0",
    price: "0",
  },
  {
    item_no: "6.1.1",
    discription: "Galvanized EGA 300 roof cover",
    unit: "0",
    price: "0",
  },
  { item_no: "1", discription: "0.30 mm thick", unit: "m", price: "1029.85" },
  { item_no: "2", discription: "0.35 mm thick", unit: "m", price: "1206.36" },
  { item_no: "3", discription: "0.40 mm thick", unit: "m", price: "1256.54" },
  { item_no: "4", discription: "0.50 mm thick", unit: "m", price: "1390.83" },
  { item_no: "5", discription: "0.60 mm thick", unit: "m", price: "1779.07" },
  { item_no: "6", discription: "0.70 mm thick", unit: "m", price: "2037.9" },
  { item_no: "7", discription: "0.80 mm thick", unit: "m", price: "2296.72" },
  { item_no: "8", discription: "1.00 mm thick", unit: "m", price: "2814.37" },
  {
    item_no: "6.1.2",
    discription: "GalvanizedEGA 400 roof cover",
    unit: "m",
    price: "0",
  },
  { item_no: "1", discription: "0.30 mm thick", unit: "m", price: "1057.59" },
  { item_no: "2", discription: "0.35 mm thick", unit: "m", price: "1261.42" },
  { item_no: "3", discription: "0.40 mm thick", unit: "m", price: "1272.42" },
  { item_no: "4", discription: "0.50 mm thick", unit: "m", price: "1313.19" },
  { item_no: "5", discription: "0.60 mm thick", unit: "m", price: "2000.92" },
  { item_no: "6", discription: "0.70 mm thick", unit: "m", price: "2296.72" },
  { item_no: "7", discription: "0.80 mm thick", unit: "m", price: "2592.52" },
  { item_no: "8", discription: "1.00 mm thick", unit: "m", price: "3184.13" },
  {
    item_no: "6.1.3",
    discription: "Galvanized EGA 500 roof cover",
    unit: "m",
    price: "0",
  },
  { item_no: "1", discription: "0.30 mm thick", unit: "m", price: "1245.64" },
  { item_no: "2", discription: "0.35 mm thick", unit: "m", price: "1261.42" },
  { item_no: "3", discription: "0.40 mm thick", unit: "m", price: "1352.01" },
  { item_no: "4", discription: "0.50 mm thick", unit: "m", price: "1495.01" },
  { item_no: "5", discription: "0.60 mm thick", unit: "m", price: "2265.15" },
  { item_no: "6", discription: "0.70 mm thick", unit: "m", price: "2604.99" },
  { item_no: "7", discription: "0.80 mm thick", unit: "m", price: "2944.83" },
  { item_no: "8", discription: "1.00 mm thick", unit: "m", price: "3624.5" },
  {
    item_no: "6.1.4",
    discription: "Galvanized EGA 600 roof cover",
    unit: "m",
    price: "0",
  },
  { item_no: "1", discription: "0.30 mm thick", unit: "m", price: "1271.13" },
  { item_no: "2", discription: "0.35 mm thick", unit: "m", price: "1282.13" },
  { item_no: "3", discription: "0.40 mm thick", unit: "m", price: "1293.13" },
  { item_no: "4", discription: "0.50 mm thick", unit: "m", price: "1455.55" },
  { item_no: "5", discription: "0.60 mm thick", unit: "m", price: "1701.43" },
  { item_no: "6", discription: "0.70 mm thick", unit: "m", price: "1947.31" },
  { item_no: "7", discription: "0.80 mm thick", unit: "m", price: "2193.19" },
  { item_no: "8", discription: "1.00 mm thick", unit: "m", price: "2684.96" },
  {
    item_no: "6.1.5",
    discription: "Galvanized EGA 700 roof cover",
    unit: "m",
    price: "0",
  },
  { item_no: "1", discription: "0.30 mm thick", unit: "m", price: "1326.13" },
  { item_no: "2", discription: "0.35 mm thick", unit: "m", price: "1491.61" },
  { item_no: "3", discription: "0.40 mm thick", unit: "m", price: "1601.74" },
  { item_no: "4", discription: "0.50 mm thick", unit: "m", price: "1711.85" },
  { item_no: "5", discription: "0.60 mm thick", unit: "m", price: "2151.96" },
  { item_no: "6", discription: "0.70 mm thick", unit: "m", price: "2449.82" },
  { item_no: "7", discription: "0.80 mm thick", unit: "m", price: "2646.99" },
  { item_no: "8", discription: "1.00 mm thick", unit: "m", price: "3307.01" },
  {
    item_no: "9",
    discription: "Coated EGA 500 with 0.4mm roof cover",
    unit: "m",
    price: "1666.54",
  },
  {
    item_no: "10",
    discription: "Coated EGA 500 with 0.5mm roof cover",
    unit: "m",
    price: "2026.64",
  },
  {
    item_no: "11",
    discription: "Coated EGA 400 with 0.4mm roof cover",
    unit: "m",
    price: "1517.47",
  },
  {
    item_no: "12",
    discription: "Coated EGA 400 with 0.5mm roof cover",
    unit: "m",
    price: "1650.21",
  },
  {
    item_no: "13",
    discription: "Coated EGA 300 with 0.4mm roof cover",
    unit: "m",
    price: "1517.47",
  },
  { item_no: "6.2", discription: "Gutter", unit: "0", price: "0" },
  {
    item_no: "0",
    discription: "Galvanized metal sheet gutter",
    unit: "0",
    price: "0",
  },
  {
    item_no: "6.2.1",
    discription: "G- 28 of den. Length 33 cm",
    unit: "ml",
    price: "626.96",
  },
  {
    item_no: "6.2.2",
    discription: "G- 30 of devt. Length 33 cm",
    unit: "ml",
    price: "524.96",
  },
  {
    item_no: "6.2.3",
    discription: "G- 28 of devt. Length 40 cm",
    unit: "ml",
    price: "763.81",
  },
  {
    item_no: "6.2.4",
    discription: "G- 30 of devt. Length 40 cm",
    unit: "ml",
    price: "692.41",
  },
  {
    item_no: "6.25",
    discription: "G- 28 of devt. Length 50 cm",
    unit: "ml",
    price: "847.96",
  },
  {
    item_no: "6.2.6",
    discription: "G- 30 of devt. Length 50 cm",
    unit: "ml",
    price: "664.36",
  },
  {
    item_no: "6.2.7",
    discription: "G- 28 of dept. Length 67 cm",
    unit: "ml",
    price: "1092.76",
  },
  {
    item_no: "6.2.8",
    discription: "G- 30 of devt. Length 67 cm",
    unit: "ml",
    price: "1092.76",
  },
  {
    item_no: "62.9",
    discription: "G- 28 of devt. Length 700 cm",
    unit: "ml",
    price: "1265.77",
  },
  {
    item_no: "6.2.10",
    discription: "G- 30 of devt Length 100 cm",
    unit: "ml",
    price: "1097.01",
  },
  {
    item_no: "6.3",
    discription: "Galvanized steel rectangular down",
    unit: "0",
    price: "0",
  },
  {
    item_no: "6.3.1",
    discription: "G- 28 of devt Length 33 cm",
    unit: "ml",
    price: "623.71",
  },
  {
    item_no: "63.2",
    discription: "G- 30 of devt. Length 33 cm",
    unit: "ml",
    price: "521.71",
  },
  {
    item_no: "6.3.3",
    discription: "G- 28 of devt. Length 40 cm",
    unit: "ml",
    price: "773.97",
  },
  {
    item_no: "6.34",
    discription: "G- 30 of dew. Length 40 cm",
    unit: "ml",
    price: "731.82",
  },
  {
    item_no: "6.3.5",
    discription: "G- 28 of devt Length 50 cm",
    unit: "ml",
    price: "808.61",
  },
  {
    item_no: "6.3.6",
    discription: "G- 30 of devt. Length 50 cm",
    unit: "ml",
    price: "735.86",
  },
  { item_no: "6.4", discription: "PVC down pipe", unit: "0", price: "0" },
  {
    item_no: "6.4.1",
    discription: "PVC down pipe Dia.75mm",
    unit: "ml",
    price: "280.39",
  },
  {
    item_no: "6.4.2",
    discription: "PVC down pipe Dia.710mm",
    unit: "ml",
    price: "482.66",
  },
  {
    item_no: "64.3",
    discription: "PVC down pipe Dia.160mm",
    unit: "ml",
    price: "776.85",
  },
  { item_no: "6.5", discription: "UPVC down pipe ", unit: "0", price: "0" },
  {
    item_no: "6.5.1",
    discription: "Down pipe,UPVC,Dia.110mm PN 6",
    unit: "ml",
    price: "613.91",
  },
  {
    item_no: "6.5.2",
    discription: "Down pipe,UPVC,Dia.200mm PN 6",
    unit: "ml",
    price: "1650.26",
  },
  {
    item_no: "6.6",
    discription: "Galvanized steel lashing",
    unit: "0",
    price: "0",
  },
  {
    item_no: "6.6.1",
    discription: "G-30  deve./ength 50cm",
    unit: "ml",
    price: "761.31",
  },
  {
    item_no: "66.2",
    discription: "G-30  deve,/ength 67cm",
    unit: "ml",
    price: "1137.7",
  },
  {
    item_no: "6.6.3",
    discription: "G-30  deve./ength 700cm",
    unit: "ml",
    price: "1266.95",
  },
  {
    item_no: "6.6.4",
    discription: "G-28 deve./ength 50cm",
    unit: "ml",
    price: "1382.22",
  },
  {
    item_no: "6.6.5",
    discription: "G-28  deve./ength 67cm",
    unit: "ml",
    price: "1444.81",
  },
  {
    item_no: "6.6.6",
    discription: "G-28  deve./ength 700cm",
    unit: "ml",
    price: "1561.47",
  },
  {
    item_no: "6.7",
    discription: "Galvanized steel copping",
    unit: "0",
    price: "0",
  },
  {
    item_no: "6.7.1",
    discription: "G-28  deve./ength 25cm",
    unit: "ml",
    price: "495.04",
  },
  {
    item_no: "6.7.2",
    discription: "G-30  deve./ength 25cm",
    unit: "ml",
    price: "454.24",
  },
  {
    item_no: "6.7.3",
    discription: "G-28  deve./ength 33cm",
    unit: "ml",
    price: "617.44",
  },
  {
    item_no: "6.7.4",
    discription: "G-30  deve.length 33cm",
    unit: "ml",
    price: "515.44",
  },
  {
    item_no: "6.7.5",
    discription: "G-28  deve.length 50cm",
    unit: "ml",
    price: "770.44",
  },
  {
    item_no: "6.7.6",
    discription: "G-30  deve,length 50cm",
    unit: "ml",
    price: "668.44",
  },
  {
    item_no: "6.8",
    discription: "Galvanized steel roof ridge",
    unit: "0",
    price: "0",
  },
  {
    item_no: "6.8.1",
    discription: "G-28  deve.length 33cm",
    unit: "ml",
    price: "613.71",
  },
  {
    item_no: "6.8.2",
    discription: "G-30  deve./ength 33cm",
    unit: "ml",
    price: "499.41",
  },
  {
    item_no: "6.8.3",
    discription: "G-28  deve.length 50cm",
    unit: "ml",
    price: "754.41",
  },
  {
    item_no: "6.8.4",
    discription: "G-30  deve./ength 50cm",
    unit: "ml",
    price: "632.01",
  },
  { item_no: "6.9", discription: "CIS Roofing", unit: "0", price: "0" },
  {
    item_no: "6.9.1",
    discription: "G28 C S Roofing ( Without Truss & Purlin )",
    unit: "m",
    price: "2318.04",
  },
  {
    item_no: "6.9.2",
    discription: "G30 CIS Roofing ( Without Truss & Purlin )",
    unit: "m",
    price: "2138.88",
  },
  {
    item_no: "6.9.3",
    discription: "G32 CIS Roofing ( Without Truss & Purlin )",
    unit: "m",
    price: "1510.98",
  },
  {
    item_no: "6.9.4",
    discription: "G35 CIS Roofing ( Without Truss & Purlin )",
    unit: "m",
    price: "997.39",
  },
  {
    item_no: "6.9.5",
    discription: "Aspesto too/ cover",
    unit: "m",
    price: "445.79",
  },
  {
    item_no: "6.9.6",
    discription: "Euro tile roof cover",
    unit: "m",
    price: "393.79",
  },
  {
    item_no: "6.9.7",
    discription: "Grass roof cover",
    unit: "m",
    price: "359.77",
  },
  {
    item_no: "6.9.8",
    discription: "Mica roof cover",
    unit: "m2",
    price: "362.06",
  },
  {
    item_no: "6.9.9",
    discription: "1mm thick Sheet metal roof cover",
    unit: "m",
    price: "1653.49",
  },
  {
    item_no: "6.9.10",
    discription: "CIS cladding for decorating roof prlin",
    unit: "m",
    price: "1199.02",
  },
  {
    item_no: "6.9.11",
    discription:
      "Tsid Timber claddinglcm for decorating roof purlin ,/?'TI& max\"' T Ch.'} IT/",
    unit: "m",
    price: "1144.07",
  },
  {
    item_no: "6.9.12",
    discription: "Light Weight Concrete 1:2:5 for roof",
    unit: "m",
    price: "6404.73",
  },
  {
    item_no: "6.9.18",
    discription: "Ribbed sheet eave",
    unit: "m",
    price: "1050.56",
  },
  {
    item_no: "6.9.19",
    discription:
      "Supply and Install Security Electronic Fencing With all accessory energizer system.",
    unit: "ml",
    price: "920.96",
  },
  {
    item_no: "6.9.20",
    discription: "Supply and Install Security Fence Wiring With all accessory.",
    unit: "ml",
    price: "755.96",
  },
  {
    item_no: "6.9.21",
    discription: "Decra Roof Tiles-Garnet ( Best quality 1st class).",
    unit: "m",
    price: "1945.02",
  },
  { item_no: "7", discription: "CARPENTRY AND JOINERY", unit: "0", price: "0" },
  { item_no: "0", discription: "Ceiling", unit: "0", price: "0" },
  {
    item_no: "7.1",
    discription: "Abujedy ceiling",
    unit: "m",
    price: "2048.13",
  },
  { item_no: "7.2", discription: "Fyzit ceiling", unit: "m", price: "1805.17" },
  {
    item_no: "7.3",
    discription: "Parquet ceiling",
    unit: "m",
    price: "3107.27",
  },
  {
    item_no: "7.4",
    discription: "Timber celing ",
    unit: "m",
    price: "1843.52",
  },
  {
    item_no: "7.5",
    discription: "Purline ceiling ",
    unit: "m",
    price: "1546.52",
  },
  {
    item_no: "7.6",
    discription: "Playwood ceiling /Compersato /",
    unit: "m",
    price: "1513.52",
  },
  { item_no: "7.7", discription: "Rush ceiling ", unit: "m", price: "1370.52" },
  {
    item_no: "7.8",
    discription: "Ceiling made with plastic sack",
    unit: "m",
    price: "1338.63",
  },
  {
    item_no: "7.9",
    discription: "Ceiling made with sack ",
    unit: "m",
    price: "1338.63",
  },
  {
    item_no: "7.1",
    discription: "8mm. thick chipwood Ceiling.",
    unit: "m",
    price: "2244.26",
  },
  {
    item_no: "7.11",
    discription:
      "Armstrong acoustical ceiling With Suspender and al' accessories",
    unit: "m",
    price: "1688.33",
  },
  {
    item_no: "7.12",
    discription: "Mineral fiber accoustic ceiling",
    unit: "m",
    price: "1231.8",
  },
  {
    item_no: "7.13",
    discription: "PVC ceiling (laminated/imported)",
    unit: "m",
    price: "1788.45",
  },
  {
    item_no: "7.15",
    discription: "Built shelves made of HPL wood",
    unit: "m",
    price: "3783.41",
  },
  {
    item_no: "7.16",
    discription: "Flush type door",
    unit: "m",
    price: "4342.32",
  },
  {
    item_no: "7.17",
    discription: "25 cm. Wide Kerrero Facia Board",
    unit: "ml",
    price: "475.2",
  },
  {
    item_no: "7.18",
    discription: "30 cm, Wide Kerrero Facia Board",
    unit: "ml",
    price: "519.2",
  },
  { item_no: "0", discription: "Wooden truss & purlin", unit: "0", price: "0" },
  {
    item_no: "7.19",
    discription: "10-12 cm. dia Upper & Lower Chords Eucaliptus Wood.",
    unit: "ml",
    price: "309.22",
  },
  {
    item_no: "7.2",
    discription: "8-10 cm. dia. Vertical & Diagonal member Eucaliptus Wood.",
    unit: "ml",
    price: "310.22",
  },
  {
    item_no: "7.21",
    discription: "Diam. 6 cm thick eucalyptus purlin",
    unit: "ml",
    price: "138.56",
  },
  {
    item_no: "7.22",
    discription: "50 X 70mm. Tid Wood Roof Purlin",
    unit: "ml",
    price: "228.07",
  },
  { item_no: "0", discription: "Doors", unit: "0", price: "0" },
  {
    item_no: "7.22",
    discription:
      "Mahogany ply wood smooth finish flush wooden door /4\u2022rn\u2022Z\u20224\u2022 (IC /",
    unit: "m",
    price: "4818.38",
  },
  {
    item_no: "7.23",
    discription:
      "Woyra ply wood smooth finish flush wooden door 140900\u20221\u2022;\u2022\u2022 QC/ (best quality)",
    unit: "m",
    price: "6140.12",
  },
  {
    item_no: "7.24",
    discription: "Smooth MDF board made imported wooden door (best quality)",
    unit: "m",
    price: "8879.29",
  },
  {
    item_no: "7.25",
    discription: "Antique MDF board wooden door",
    unit: "m",
    price: "6787.94",
  },
  {
    item_no: "7.26",
    discription: "Solide wooden door (best quality)",
    unit: "m",
    price: "10520.55",
  },
  {
    item_no: "7.27",
    discription: "lcm thick timber door",
    unit: "m",
    price: "2797.9",
  },
  {
    item_no: "7.28",
    discription: "3cm thick timber door",
    unit: "m",
    price: "2797.6",
  },
  {
    item_no: "7.29",
    discription: "lcm thick timber window",
    unit: "m",
    price: "1766.43",
  },
  {
    item_no: "7.3",
    discription: "3cm thick timber window",
    unit: "m",
    price: "1786.43",
  },
  {
    item_no: "7.31",
    discription: "3cm thick timber window with glass ",
    unit: "m",
    price: "4223.38",
  },
  {
    item_no: "7.32",
    discription: "G-32 CIS door with all wood work",
    unit: "m",
    price: "3239.03",
  },
  {
    item_no: "7.33",
    discription: "G-32 CIS window with all wood work",
    unit: "m",
    price: "3239.03",
  },
  {
    item_no: "7.34",
    discription: "Timber /Tsid/ door",
    unit: "m",
    price: "2579.9",
  },
  {
    item_no: "7.35",
    discription: "Timber ITsid/ window",
    unit: "m",
    price: "2579.9",
  },
  {
    item_no: "7.36",
    discription: "G-32 CIS wall with all wood work",
    unit: "m",
    price: "1968.37",
  },
  {
    item_no: "7.37",
    discription: "8 mm thick chip wood wall",
    unit: "m",
    price: "2728.52",
  },
  {
    item_no: "7.44",
    discription:
      "Woyra ply wood flush wooden built in cupboard (standared version)",
    unit: "m",
    price: "6256.09",
  },
  {
    item_no: "7.45",
    discription: "Antique built in cupboard (best quality)",
    unit: "m",
    price: "8456.09",
  },
  { item_no: "8", discription: "METAL WORK", unit: "0", price: "0" },
  {
    item_no: "8,1",
    discription: "Fully metal doors of 38mm LTZ without grill (1 .5mm thick)",
    unit: "m",
    price: "4631.84",
  },
  {
    item_no: "8.2",
    discription: "Partially glazed metal doors of 38LTZ without grill",
    unit: "m",
    price: "4302.74",
  },
  {
    item_no: "8.3",
    discription:
      "Partially glazed metal doors of 38mm LTZ with grill (1.5mm thick)",
    unit: "m",
    price: "4916.72",
  },
  {
    item_no: "8.4",
    discription: "Metal Windows of 38LTZ without grill (1.5mm thick)",
    unit: "m",
    price: "3929.18",
  },
  {
    item_no: "8.5",
    discription: "Metal Windows of 38LTZ (1.5mm thick) with grill",
    unit: "m",
    price: "8180.16",
  },
  {
    item_no: "8.6",
    discription:
      "Double leaf metal doors of 38mmLTZ withoutgrill (1.5mm thick)",
    unit: "m",
    price: "4387.23",
  },
  {
    item_no: "8.7",
    discription: "Double leaf metal doors of 38mmLTZ (1.5mm thick)",
    unit: "m",
    price: "5214.71",
  },
  {
    item_no: "8.8",
    discription: "Fully metal doors of 28mm LTZ without grill (1.2mm thick)",
    unit: "m",
    price: "4345.05",
  },
  {
    item_no: "8.9",
    discription:
      "Partially glazed metal doors of 28mm LTZ without grill (1.2mm thick)",
    unit: "m",
    price: "3709.26",
  },
  {
    item_no: "8.1",
    discription:
      "Partially glazed metal doors of 28mm LTZ with grill (1.2mm thick)",
    unit: "m",
    price: "4460.74",
  },
  {
    item_no: "8.11",
    discription: "Metal Windows of 28LTZ without grill (1.2mm thick)",
    unit: "m",
    price: "3187.12",
  },
  {
    item_no: "8.12",
    discription: "Metal Windows of 28LTZ (1.2mm thick)",
    unit: "m",
    price: "4313.87",
  },
  {
    item_no: "8.13",
    discription:
      "Double leaf metal doors of 28mmLTZ withoutgrill (1.2mm thick)",
    unit: "m",
    price: "3670.75",
  },
  {
    item_no: "8.14",
    discription: "Double leaf metal doors of 28mmLTZ (1.2mm thick)",
    unit: "m",
    price: "4498.23",
  },
  {
    item_no: "8.16",
    discription: "Main gate door covered with sheet metal /96 PIn.. nc na CO/",
    unit: "m",
    price: "11257.6",
  },
  {
    item_no: "8.17",
    discription: "Sliding door & window",
    unit: "m",
    price: "4384.96",
  },
  {
    item_no: "8.18",
    discription: "Sheet metal partition wall (1 mm thick) with RHS 30x30x2.5",
    unit: "m",
    price: "4910.33",
  },
  {
    item_no: "8.18",
    discription: "Only Imm thick Sheet metal (Lamera) partition wall",
    unit: "m",
    price: "2855.77",
  },
  {
    item_no: "8.19",
    discription: "50mm LTZ limitation metal half glazed door with out grill",
    unit: "m",
    price: "2592.85",
  },
  {
    item_no: "8.2",
    discription: "50mm LTZ limitation metal half glazed door with grill",
    unit: "m",
    price: "3769.82",
  },
  {
    item_no: "8.21",
    discription: "Accustic suspended Ceiling.",
    unit: "m",
    price: "1850.31",
  },
  {
    item_no: "8.22",
    discription: "Supply & fix Aluminium window with 6mm glazed",
    unit: "m",
    price: "11284.06",
  },
  {
    item_no: "8.23",
    discription: "Supply & fix Aluminium window with 6mm double glazed",
    unit: "m",
    price: "11684.06",
  },
  {
    item_no: "8.24",
    discription: "Aluminium window with 8mm glazed",
    unit: "m",
    price: "11984.06",
  },
  {
    item_no: "8.25",
    discription: "Aluminium window with 8mm double glazed",
    unit: "m",
    price: "12434.06",
  },
  {
    item_no: "8.26",
    discription: "Aluminium sun breaker",
    unit: "m",
    price: "4692.38",
  },
  {
    item_no: "8.27",
    discription: "Aluminium Curtain wall 6mm glass",
    unit: "m",
    price: "13684.06",
  },
  {
    item_no: "8.28",
    discription: "Aluminium Curtain wall 8mm glass",
    unit: "m",
    price: "16684.06",
  },
  {
    item_no: "8.29",
    discription: "2mm Aluminium partition wall",
    unit: "m",
    price: "13684.06",
  },
  {
    item_no: "8.3",
    discription: "1.6mm thick Aluminium door with 6mm glass",
    unit: "m",
    price: "11684.06",
  },
  {
    item_no: "8.301",
    discription: "2mm thick Aluminium door with 8mm glass",
    unit: "m",
    price: "13684.06",
  },
  {
    item_no: "8.3.8",
    discription:
      "Rolling shutter door shall be built up from interlocking lath sections of aluminum alloy conforming to 53000 (NE5)or 64430 (HE30) of IS : 733. the lath sections shall be from 18 gauge (1.25 mm) section with 78mm profile height for curtains. The lath section shall be rolled so as to have interlocking curls at both edges and a deep corrugation at the centre with a bridge depth of 16 mm to provide sufficient curtain stiffnes for resisting manual pressure & normal wind pressure.",
    unit: "m",
    price: "5819.59",
  },
  { item_no: "0", discription: "0", unit: "0", price: "0" },
  { item_no: "0", discription: "REINFORCEMENT BAR", unit: "0", price: "0" },
  {
    item_no: "8.38",
    discription: "Steel Truss & Purlin With RHS",
    unit: "kg",
    price: "288.2",
  },
  {
    item_no: "8.38",
    discription:
      "Supply and fix Different size and thickness Metal hand rail made of RHS, CHS and SHS . price shall include three coat antirust and the necessory works and materials to full fill the job.",
    unit: "kg",
    price: "275.89",
  },
  {
    item_no: "8.39",
    discription: "dia. 16 mm. deformed bars dev. Length 400mm (J-bolt)",
    unit: "kg",
    price: "185.75",
  },
  {
    item_no: "8.4",
    discription: "dia. 12 mm. deformed bars dev. Length 100mm(J-bolt)",
    unit: "kg",
    price: "129.08",
  },
  {
    item_no: "8.41",
    discription: "dia. 14 mm. deformed bars dev. Length 160mm(J-bolt)",
    unit: "kg",
    price: "185.75",
  },
  {
    item_no: "8.42",
    discription: "dia. 20 mm. Anchor bolt dev. Length 400mm",
    unit: "kg",
    price: "209.63",
  },
  { item_no: "9", discription: "FINISHING WORKS", unit: "0", price: "0" },
  { item_no: "9.1", discription: "PLASTERING", unit: "0", price: "0" },
  {
    item_no: "91.2",
    discription: "3 Coats of cement plastering ( 1:3 ) (To internal wall)",
    unit: "m2",
    price: "472.13",
  },
  {
    item_no: "9.1.3",
    discription: "3 Coats of cement plastering ( 1:3 ) (To external wall)",
    unit: "m2",
    price: "472.13",
  },
  {
    item_no: "9.1.4",
    discription: "3 Coats of cement plastering to exposed beams and columns.",
    unit: "m2",
    price: "508.6",
  },
  {
    item_no: "9.1.5",
    discription: "3 Coats of cement plastering to Slab soffit beams soffit",
    unit: "m2",
    price: "578.38",
  },
  {
    item_no: "9.1.6",
    discription: "2 Coats of cement plastering intenal vertical surface.",
    unit: "m2",
    price: "369.36",
  },
  {
    item_no: "9.1.7",
    discription: "2 Coats of cement plastering External vertical surface.",
    unit: "m2",
    price: "369.36",
  },
  {
    item_no: "9.1.8",
    discription: "cement wash vertical surface.",
    unit: "m2",
    price: "162.29",
  },
  {
    item_no: "9.1.9",
    discription: "2 Coats of cement plastering Exposed column and beam",
    unit: "m2",
    price: "369.62",
  },
  {
    item_no: "9.1.10",
    discription: "2 Coats of cement plastering concrete soffit",
    unit: "m2",
    price: "456.95",
  },
  {
    item_no: "9.1.11",
    discription: "Final Coat of cement Rendering",
    unit: "m2",
    price: "107.49",
  },
  {
    item_no: "9.1.12",
    discription:
      "Final Coat of cement plastering vetical and exposed column and beam",
    unit: "m2",
    price: "369.62",
  },
  {
    item_no: "9.1.13",
    discription: "Final Coat of cement plastering concrete soffit",
    unit: "m2",
    price: "139.08",
  },
  {
    item_no: "9.1.14",
    discription: "Final Coat of Gypsum plastering Soffit",
    unit: "m2",
    price: "143.57",
  },
  {
    item_no: "9.1.15",
    discription:
      "Final Coat of Gypsum plastering Vetical wall and Exposed column and beam",
    unit: "m2",
    price: "117.64",
  },
  {
    item_no: "91.16",
    discription:
      "Final Coat of Gypsum plastering external Vetical wall and Exposed column and",
    unit: "m2",
    price: "106.12",
  },
  { item_no: "9.2", discription: "POINTING", unit: "0", price: "0" },
  {
    item_no: "9.2.1",
    discription: "Cement pointing to H.C.B.wall surface (1:3).",
    unit: "m2",
    price: "118.5",
  },
  {
    item_no: "9.2.2",
    discription: "Cement pointing to Brick.wall surface (1:3).",
    unit: "m2",
    price: "189.92",
  },
  {
    item_no: "9.2.3",
    discription: "Cement pointing to stonemasonry.wall surface (1:3).",
    unit: "m2",
    price: "210.26",
  },
  { item_no: "9.3", discription: "FLOORING", unit: "0", price: "0" },
  {
    item_no: "93.1",
    discription: "2mm. thick PVC flooring.",
    unit: "m2",
    price: "1015.03",
  },
  {
    item_no: "93.2",
    discription: "2mm thick monolotic PVC tile",
    unit: "m2",
    price: "0",
  },
  {
    item_no: "9.3.3",
    discription: "10cmx10cmx10cm Coble Stone",
    unit: "m2",
    price: "929.17",
  },
  {
    item_no: "9.3.4",
    discription: "3cm. Thick cement screed flooring (with out glass).",
    unit: "m2",
    price: "403.39",
  },
  {
    item_no: "9.3.5",
    discription: "5cm. Thick cement screed flooring (with out glass).",
    unit: "m2",
    price: "592.68",
  },
  {
    item_no: "9.3.6",
    discription: "10mm thick Ceramic tile flooring",
    unit: "m2",
    price: "2375.05",
  },
  {
    item_no: "9.3.7",
    discription: "9mm thick Ceramic tile flooring",
    unit: "m2",
    price: "2383.92",
  },
  {
    item_no: "9.3.8",
    discription: "33cmx33xcm8mm thick Ceramic tile flooring",
    unit: "m2",
    price: "2383.92",
  },
  {
    item_no: "9.3.9",
    discription: "33cmx33xcm7mm thick Ceramic tile flooring",
    unit: "m2",
    price: "2383.92",
  },
  {
    item_no: "9.31",
    discription: "6mm thick Ceramic tile flooring",
    unit: "m2",
    price: "2339.57",
  },
  {
    item_no: "9.3.12",
    discription: "60cmx60cmx8mm thick Porcelain floor tile",
    unit: "m2",
    price: "3131.59",
  },
  {
    item_no: "9.3.10",
    discription: "60cmx60cmx9mm thick Porcelain floor tile",
    unit: "m2",
    price: "3344.83",
  },
  {
    item_no: "9.3.13",
    discription: "60cmx60cmx10mm thick Porcelain floor tile",
    unit: "m2",
    price: "3383.39",
  },
  {
    item_no: "9.3.14",
    discription:
      "supply and apply 2mm thick Epoxy floor topping with one coat primer coating.",
    unit: "m2",
    price: "2402.88",
  },
  {
    item_no: "9.3.15",
    discription:
      "supply and apply 3mm thick Epoxy floor topping with one coat primer coating.",
    unit: "m2",
    price: "3604.33",
  },
  {
    item_no: "9.3.16",
    discription:
      "Terrazzo tile flooring size 40cm x 40cmx40mm thick for pedestrian (1:3)",
    unit: "m2",
    price: "1393.29",
  },
  {
    item_no: "9.3.17",
    discription: "Terrazzo tile flooring(1:3) 30mm thick",
    unit: "m2",
    price: "1371.11",
  },
  {
    item_no: "9.3.18",
    discription: "Terrazzo tile flooring(l :3) 20X20mm",
    unit: "m2",
    price: "1437.63",
  },
  {
    item_no: "9.3.20",
    discription: "1 cmthick Granite flooring",
    unit: "m2",
    price: "4410.06",
  },
  {
    item_no: "9.3.21",
    discription: "2cm thick Granite flooring (1:3).",
    unit: "m2",
    price: "6368.7",
  },
  {
    item_no: "9.3.22",
    discription: "3cm thick Granite flooring (1:3).",
    unit: "m2",
    price: "6561.13",
  },
  {
    item_no: "9.3.26",
    discription: "2cm thick Marble flooring (1:3).",
    unit: "m2",
    price: "5480.73",
  },
  {
    item_no: "9.3.27",
    discription: "3cm thick Marble flooring (1:3).",
    unit: "m2",
    price: "6522.34",
  },
  { item_no: "9.4", discription: "WALL TILE CLADING", unit: "0", price: "0" },
  {
    item_no: "9.4.1",
    discription: "15cmx15cmx6mm white glazed Ceramic wall tile.(1:2).",
    unit: "2 m",
    price: "1277.1",
  },
  {
    item_no: "94.2",
    discription: "Ceramic wall tiles (200x300x8mm)",
    unit: "2 m",
    price: "2681.45",
  },
  {
    item_no: "9.4.3",
    discription:
      "Supply and fix lay glass mosaic to external wall bendded & jointed in cement sand",
    unit: "0",
    price: "0",
  },
  {
    item_no: "0",
    discription:
      "mortar (1 : 1). Price includes cement sand mortar bedding & white cement grouting.",
    unit: "m",
    price: "4925.45",
  },
  {
    item_no: "9.4.4",
    discription: "20mm thick Marble wall Cladding",
    unit: "2 m",
    price: "6685.08",
  },
  {
    item_no: "9.4.5",
    discription: "10mm thick Granite wall Cladding",
    unit: "2 m",
    price: "5863.35",
  },
  {
    item_no: "9.4.6",
    discription: "20mm thick Granite wall Cladding",
    unit: "2 m",
    price: "7599.17",
  },
  {
    item_no: "9.4.15",
    discription: "2mm PVC wall tile type cladding",
    unit: "2 m",
    price: "843.01",
  },
  {
    item_no: "9.4.16",
    discription: "Aluminium cladding 4mm thick by adhesive",
    unit: "2 m",
    price: "2411.76",
  },
  { item_no: "9.5", discription: "WINDOW SILL", unit: "0", price: "0" },
  {
    item_no: "9.5.1",
    discription: "30mm. Thick & 220mm width Marble window sill (1:3) on HCB.",
    unit: "ml",
    price: "1601.95",
  },
  {
    item_no: "9.5.2",
    discription: "30mm. Thick & 280mm width Marble window sill (1:3) on HCB.",
    unit: "ml",
    price: "1955.95",
  },
  {
    item_no: "9.5.3",
    discription: "30mm. Thick & 300mm width Marble window sill (1:3) on HCB.",
    unit: "ml",
    price: "2132.95",
  },
  {
    item_no: "9.5.4",
    discription: "30mm. Thick & 470mm width Marble window sill (1:3) on HCB.",
    unit: "ml",
    price: "3135.95",
  },
  {
    item_no: "9.5.5",
    discription: "30mm. Thick & 520mm width Marble window sill (1:3) on HCB.",
    unit: "ml",
    price: "3253.95",
  },
  { item_no: "9.6", discription: "THREAD", unit: "0", price: "0" },
  { item_no: "9.6.1", discription: "MARBLE THREAD", unit: "0", price: "0" },
  {
    item_no: "9.6.1.1",
    discription: "280mm wide & 30mm. thick marble threads.(l :3)",
    unit: "ml",
    price: "1973.13",
  },
  {
    item_no: "9.6.1.2",
    discription: "300mm wide & 30mm. thick marble threads. (1:3)",
    unit: "ml",
    price: "2093.49",
  },
  {
    item_no: "9.6.1.3",
    discription: "330mm wide & 30mm. thick marble threads.(l :3)",
    unit: "ml",
    price: "2274.03",
  },
  {
    item_no: "9.6.1.4",
    discription: "280mm wide & 20mm. thick marble threads.(l :3)",
    unit: "ml",
    price: "1654",
  },
  {
    item_no: "9.6.1.5",
    discription: "300mm wide & 20mm. thick marble threads.(l :3)",
    unit: "ml",
    price: "1751.57",
  },
  {
    item_no: "9.6.1.6",
    discription: "330mm wide & 20mm. thick marble threads.(l :3)",
    unit: "ml",
    price: "1897.92",
  },
  { item_no: "9.62", discription: "GRANITE THREAD", unit: "0", price: "0" },
  {
    item_no: "9.6.2.1",
    discription: "280mm.wide & 30mm thick Granite Treads .(1:3)",
    unit: "ml",
    price: "2073.08",
  },
  {
    item_no: "9.6.2.2",
    discription: "300mm.wide & 30mm thick Granite Treads .(1:3)",
    unit: "ml",
    price: "2203.16",
  },
  {
    item_no: "9.6.2.3",
    discription: "330mm.wide & 30mm thick Granite Tread .(1:3)",
    unit: "ml",
    price: "2398.29",
  },
  {
    item_no: "9.6.2.4",
    discription: "280mm.wide & 20mm. thick Granite Tread (1 :3)",
    unit: "ml",
    price: "1993.04",
  },
  {
    item_no: "9.6.2.5",
    discription: "300mm.wide & 20mm. thick Granite Tread .(1:3)",
    unit: "ml",
    price: "2117.41",
  },
  {
    item_no: "9.6.2.6",
    discription: "330mm.wide & 20mm. thick Granite Tread .(1:3)",
    unit: "ml",
    price: "2303.96",
  },
  { item_no: "9.6.3", discription: "TERRAZZO THREAD", unit: "0", price: "0" },
  {
    item_no: "9.6.3.1",
    discription: "280mm.wide & 30mm. thick Terrazo threads.(l :3)",
    unit: "ml",
    price: "461.22",
  },
  {
    item_no: "9.6.3.2",
    discription: "300mm.wide & 30mm. thick Terrazo threads.(l :3)",
    unit: "ml",
    price: "475.37",
  },
  {
    item_no: "9.6.3.3",
    discription: "340mm.wide & 30mm. thick Terrazo threads.(l :3)",
    unit: "ml",
    price: "496.6",
  },
  {
    item_no: "9.6.3.4",
    discription: "280mm.wide & 20mm. thick Terrazo threads.(l :3)",
    unit: "ml",
    price: "449.35",
  },
  {
    item_no: "9.6.3.5",
    discription: "300mm.wide & 20mm. thick Terrazo threads.(l :3)",
    unit: "ml",
    price: "462.66",
  },
  {
    item_no: "9.6.3.6",
    discription: "330mm.wide & 20mm. thick Terrazo threads.(l :3)",
    unit: "ml",
    price: "482.61",
  },
  { item_no: "9.7", discription: "RISER", unit: "0", price: "0" },
  { item_no: "9.7.1", discription: "MARBLE RISER", unit: "0", price: "0" },
  {
    item_no: "9.7.1.1",
    discription: "130mm wide & 20mm. thick marble riser. (1:3).",
    unit: "ml",
    price: "804",
  },
  {
    item_no: "9.7.1.2",
    discription: "150mm wide & 20mm. thick marble riser. (1:3).",
    unit: "ml",
    price: "904.43",
  },
  { item_no: "9.7.2", discription: "GRANITE RISER", unit: "0", price: "0" },
  {
    item_no: "9.7.2.1",
    discription: "130mm wide & 20mm. thick Granite riser .(1:3)",
    unit: "ml",
    price: "1097.01",
  },
  {
    item_no: "9.7.2.2",
    discription: "150mm wide & 20mm. thick Granite riser .(1:3)",
    unit: "ml",
    price: "1184.63",
  },
  { item_no: "9.7.3", discription: "TERRAZZO RISER", unit: "0", price: "0" },
  {
    item_no: "9.7.3.1",
    discription: "130mm wide & 30mm. Thick Terrazo riser. (1:3).",
    unit: "ml",
    price: "424.17",
  },
  {
    item_no: "9.7.3.2",
    discription: "130mm wide & 20mm. Thick Terrazo riser. (1:3).",
    unit: "ml",
    price: "416.37",
  },
  {
    item_no: "9.7.3.3",
    discription: "150mm wide & 20mm. Thick Terrazo riser. (1:3).",
    unit: "ml",
    price: "424.17",
  },
  { item_no: "9.8", discription: "Threshold", unit: "0", price: "0" },
  { item_no: "9.8.1", discription: "Marble threshold", unit: "0", price: "0" },
  {
    item_no: "9.8.1.1",
    discription: "20mm. Thick & 200mm wide Marble threshold.(l :3)",
    unit: "ml",
    price: "1181.89",
  },
  {
    item_no: "9.8.1.2",
    discription: "20mm. thick & 250mm wide Marble threshold.(l :3)",
    unit: "ml",
    price: "1496.75",
  },
  { item_no: "9.8.2", discription: "Granite threshold", unit: "0", price: "0" },
  {
    item_no: "9.8.2.1",
    discription: "20mm. thick & 200mm wide Granite threshold.(l :3)",
    unit: "ml",
    price: "1409.56",
  },
  {
    item_no: "9.8.2.2",
    discription: "20mm. thick & 250mm wide Granite threshold.(l :3)",
    unit: "ml",
    price: "1682.88",
  },
  { item_no: "9.8.3", discription: "Terrazo threshold", unit: "0", price: "0" },
  {
    item_no: "9.8.3.1",
    discription: "20mm. thick & 200mm w. Terrazo threshold. (1:3).",
    unit: "ml",
    price: "585.54",
  },
  {
    item_no: "9.8.3.2",
    discription: "20mm. thick & 250mm w. terrazo threshold.(1:3)",
    unit: "ml",
    price: "751.32",
  },
  { item_no: "9.9", discription: "Skirtin", unit: "0", price: "0" },
  {
    item_no: "9.9.1",
    discription: "10cm. high PVC skirting 2mm thick",
    unit: "ml",
    price: "479.82",
  },
  {
    item_no: "9.9.2",
    discription: "20mm. thick terrazo skirting.",
    unit: "ml",
    price: "268.58",
  },
  {
    item_no: "9.9.5",
    discription: "10cm high 10mm thick Porcelain skirting",
    unit: "ml",
    price: "320.47",
  },
  {
    item_no: "9.9.7",
    discription: "100mm. high wooden skirting.",
    unit: "ml",
    price: "591.67",
  },
  {
    item_no: "9.9.8",
    discription: "10cm high 2cm thick granite skerting",
    unit: "ml",
    price: "710.37",
  },
  {
    item_no: "9.9.10",
    discription: "20mm Thick, 10cm high Marble skirting",
    unit: "ml",
    price: "663.12",
  },
  { item_no: "9.1", discription: "Copping", unit: "0", price: "0" },
  { item_no: "0", discription: "Marble copping", unit: "0", price: "0" },
  {
    item_no: "9.10.1",
    discription: "300mm wide & 30mm. thick marble copping.(l :3)",
    unit: "ml",
    price: "2068.49",
  },
  {
    item_no: "9.10.2",
    discription: "450mm wide & 30mm. thick marble copping.(1:3)",
    unit: "ml",
    price: "2971.19",
  },
  {
    item_no: "9.10.3",
    discription: "300mm wide & 20mm. thick marble copping.(l :3)",
    unit: "ml",
    price: "1726.57",
  },
  {
    item_no: "9.10.4",
    discription: "450mm wide & 20mm. thick marble copping.(1:3)",
    unit: "ml",
    price: "2458.31",
  },
  { item_no: "9.11", discription: "Terrazzo copping", unit: "0", price: "0" },
  {
    item_no: "9.11.1",
    discription: "300mm wide & 30mm. thick terrazzo copping.(1:3)",
    unit: "ml",
    price: "485.15",
  },
  {
    item_no: "9.11.2",
    discription: "450mm wide & 30mm. thick terrazzo copping.(l :3)",
    unit: "ml",
    price: "596.35",
  },
  {
    item_no: "9.11.3",
    discription: "300mm wide & 20mm. thick terrazzo copping.(l :3)",
    unit: "ml",
    price: "475.04",
  },
  {
    item_no: "9.11.4",
    discription: "450mm wide & 20mm. thick terrazzo copping.(l :3)",
    unit: "ml",
    price: "581.18",
  },
]
