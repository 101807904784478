import { useState, useEffect } from "react"
// css class
import "../../../styles/pages/products/pageproducts.scss"

// items layout form display page
import ProductsItem from "../../../example/productitem"

/// modal layout
import ProductItemModal from "../../../example/productmodal"

// location identifier
import Locator from "../../../example/locator"
// cards layout
import SellingProductsCard from "../../../example/sellingproducts"
// data
import Border from "../../../example/borderbottom"
import { MinimizeOutlined, Add, Search } from "@mui/icons-material"
//
import FormControl from "@mui/material/FormControl"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Alert from "@mui/material/Alert"
import { CircularProgress, Modal } from "@mui/material"
//
import { useQuery } from "@tanstack/react-query"

// request sender
import { publicRequest } from "../../../constant/requestMethods"

import { useLocation } from "react-router"
import Footer from "../../display/footer"

export default function Products() {
  const location = useLocation()
  const id = location.pathname.split("/")[1]
  //state vars to hold the blog and nexpage if it there
  const [nextPage, setNextPage] = useState([])

  // state taht holds the products
  const [products, setProducts] = useState([])

  // controllers to show alets
  const [alert, setAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  // sort select
  const [sort, setSort] = useState("")

  // state to check if userproducts,or firm
  const [userType, setUserType] = useState("manufacturer")

  // state to hold the link to get the user
  const [linker, setLinker] = useState("/")

  // shows the items numbers when the filteres are pressed
  const [filterPressed, setFilterPressed] = useState(false)

  // checkbox controllers that app
  const catagories = [
    "Decoration & Accessories",
    "Furniture",
    "Kitchen & Baths",
    "Lighting",
    "Materials",
    "Painting",
    "Roofing & Gutter",
    "Wall & Floor",
  ]
  const [cat, setCat] = useState("")
  const [cataPressed, setCataPressed] = useState(true)
  const [searchPressed, setSearchPressed] = useState(true)
  const [search, setSearch] = useState("")
  const [checked, setChecked] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ])

  // var for the modals to apper
  // const [open, setOpen] = useState(modalOpen);

  ///

  // functions that runs when the page is loaded
  // sends a request to the server for the blog data
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["findproducts"],
    queryFn: async () => {
      try {
        // await userRequest.get("/blog/find?page=1&limit=5")
        const reply = await publicRequest.get(
          `/product/find?page=${pageNumber}&limit=10`
        )
        let result = reply.data.data.result
        result.map((item) => {
          products.push(item)
        })
        let next = reply.data.data.next
        setNextPage(next)
        if (products && products.length > 0) {
          setProducts([
            ...new Map(
              products.map((obj) => [`${obj._id}:${obj.title}`, obj])
            ).values(),
          ])
          // console.log("my eople")
        }
        setAlert(false)
        setErrorAlert(false)
        setErrorMessage("")
        return reply
      } catch (e) {
        setAlert(true)
        setErrorAlert(true)
        setErrorMessage(e.message)
        console.log(e.message)
        return e
      }
    },
    cacheTime: Infinity,
    staleTime: Infinity,
  })

  // console.log(sellingItem)
  const renderModal = () => {
    return (
      <Modal
        sx={{ overflow: "auto" }}
        open={modalOpen}
        onClose={() => {
          setModalOpen(!modalOpen)
          setItem("")
          console.log(sellingItem)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ProductItemModal
          open={modalOpen}
          setOpen={setModalOpen}
          product={sellingItem}
        />
      </Modal>
    )
  }

  // check and remove the duplicate in teh array
  const check = () => {
    if (products && products.length > 0) {
      setProducts([
        ...new Map(
          products.map((obj) => [`${obj._id}:${obj.title}`, obj])
        ).values(),
      ])
      // console.log("my eople")
    }
  }
  const handleFilter = () => {
    let something = []
    if (cat) {
      products.filter(function (node) {
        node.catagory.map((catagory) => {
          if (catagory.toLowerCase() === cat.toLowerCase()) {
            something.push(node)
            // console.log(filterMan)
          }
        })
      })
      return something
    }

    return something
  }

  const foundFilter = handleFilter()

  //  when the search bar is is inputed the rows change
  const searchProduct = () => {
    const something = []
    if (search && foundFilter && foundFilter.length > 0) {
      foundFilter.map((item, index) => {
        const doe = item.title.toLowerCase()
        if (!doe.indexOf(search.toLowerCase())) {
          something.push(item)
        }
        return something
      })
    } else if (search && !foundFilter) {
      products.map((item, index) => {
        const doe = item.title.toLowerCase()
        if (!doe.indexOf(search.toLowerCase())) {
          something.push(item)
        }
        return something
      })
    }
    return something
  }

  const foundProducts = searchProduct()

  // changes the checked boxes to the one that is clicked
  const handleChange = (event, index) => {
    setCat(event.target.name)
    setChecked([false, false, false, false, false, false, false])
    checked.map(() => {
      if (index === 0) {
        setChecked([true, false, false, false, false, false, false, false])
      } else if (index === 1) {
        setChecked([false, true, false, false, false, false, false, false])
      } else if (index === 2) {
        setChecked([false, false, true, false, false, false, false, false])
      } else if (index === 3) {
        setChecked([false, false, false, true, false, false, false, false])
      } else if (index === 4) {
        setChecked([false, false, false, false, true, false, false, false])
      } else if (index === 5) {
        setChecked([false, false, false, false, false, true, false, false])
      } else if (index === 6) {
        setChecked([false, false, false, false, false, false, true, false])
      } else if (index === 7) {
        setChecked([false, false, false, false, false, false, false, true])
      }
    })

    // console.log(cat)

    // setChecked([event.target.checked, event.target.checked])
  }

  // products layout and item getter
  const {
    pageNumber,
    display,
    modalItem,
    modalOpen,
    setModalOpen,
    sellingItem,
    setItem,
  } = SellingProductsCard(
    foundProducts && foundProducts.length > 0
      ? foundProducts
      : foundFilter && foundFilter.length > 0
      ? foundFilter
      : products && products,
    isLoading,
    nextPage
  )

  // refteches the query when the display returns a changed pageNumber
  useEffect(() => {
    refetch()
  }, [pageNumber])

  useEffect(() => {
    if (sort === "Newest to Oldest") {
      setProducts((prev) =>
        [...prev].sort(
          (a, b) =>
            Number(new Date(b.createdAt)) - Number(new Date(a.createdAt))
        )
      )
      // console.log(products[4].createdAt)
    } else if (sort === "Oldest to Newest") {
      setProducts((prev) =>
        [...prev].sort(
          (a, b) =>
            Number(new Date(a.createdAt)) - Number(new Date(b.createdAt))
        )
      )
    } else if (sort === "Price. Low to High") {
      setProducts((prev) => [...prev].sort((a, b) => a.price - b.price))
    } else if (sort === "Price. High to Low") {
      setProducts((prev) => [...prev].sort((a, b) => b.price - a.price))
    }
  }, [sort])

  // here to check if the user got back to the page and the data wont' be lost
  useEffect(() => {
    // console.log("leave you")
    refetch()
    check()
  }, [id])

  // console.log(foundFilter)
  // console.log(foundProducts)
  return (
    <div className="pageproducts">
      {renderModal()}

      <div className="insidepageproducts">
        <div className="leftsideofthepage">
          {alert && (
            <div className="alert">
              {errorAlert && <Alert severity="error">{errorMessage}</Alert>}
              {successAlert && (
                <Alert severity="success">{successMessage}</Alert>
              )}
            </div>
          )}
          {/* <div className='pagelocator'>
            <Locator header={"Home"} icon={"kj"} />
            <Locator span={"Products"} />
          </div> */}
          <div className="pageheaderandsort">
            <span className="productstitle">Products</span>
            {filterPressed && (
              <span style={{ fontSize: "18", fontFamily: "Bellota-Bold" }}>
                Products Found {foundFilter.length}
              </span>
            )}
            <div className="sortpage">
              <span>Sort By:</span>
              <FormControl sx={{ m: 1, minWidth: "60%" }}>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  autoWidth
                  value={sort}
                  onChange={(e) => setSort(e.target.value)}
                  size="small"
                >
                  <MenuItem value="">
                    {sort ? <em>{sort}</em> : <em>Oldest to Newest</em>}
                  </MenuItem>
                  <MenuItem
                    value="Newest to Oldest"
                    onClick={(e) => setSort(e.target.value)}
                  >
                    <span
                      style={{ fontSize: "14px", fontFamily: "Bellota-Light" }}
                    >
                      Newest to Oldest
                    </span>
                  </MenuItem>
                  <MenuItem
                    value="Oldest to Newest"
                    onClick={(e) => setSort(e.target.value)}
                  >
                    <span
                      style={{ fontSize: "14px", fontFamily: "Bellota-Light" }}
                    >
                      Oldest to Newest
                    </span>
                  </MenuItem>
                  <MenuItem
                    value="Price. High to Low"
                    onClick={(e) => setSort(e.target.value)}
                  >
                    <span
                      style={{ fontSize: "14px", fontFamily: "Bellota-Light" }}
                    >
                      Price. High to Low
                    </span>
                  </MenuItem>
                  <MenuItem
                    value="Price. Low to High"
                    onClick={(e) => setSort(e.target.value)}
                  >
                    <span
                      style={{ fontSize: "14px", fontFamily: "Bellota-Light" }}
                    >
                      Price. Low to High
                    </span>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          {/* <div className='productscomponenets'>
            {data.map(() => (<ProductsItem open={open} setOpen={setOpen} style={"smaller"} />))}
          </div> */}
          {isLoading ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            display
          )}
        </div>
        <div className="rightsideofthepage">
          <div className="sellersidenav">
            <span className="filterheader">Filter</span>
            <div className="catasidenav">
              <Border
                first={"Catagory"}
                second={
                  cataPressed ? (
                    <Add
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setCataPressed(!cataPressed)
                        setCat("")
                        setFilterPressed(false)
                        setChecked([
                          false,
                          false,
                          false,
                          false,
                          false,
                          false,
                          false,
                          false,
                        ])
                      }}
                    />
                  ) : (
                    <MinimizeOutlined
                      style={{ cursor: "pointer" }}
                      onClick={(e) => setCataPressed(!cataPressed)}
                    />
                  )
                }
              />
              {cataPressed && (
                <FormControl
                  className="close"
                  sx={{ m: 1 }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    {catagories.map((item, index) => (
                      <FormControlLabel
                        key={index + 98676}
                        style={{ fontSize: "12", fontFamily: "Bellota-Light" }}
                        control={
                          <Checkbox
                            checked={checked[index]}
                            onChange={(e) => {
                              setCat(e.target.name)
                              handleChange(e, index)
                              setFilterPressed(true)
                            }}
                            name={item}
                            style={{
                              transform: "scale(0.7)",
                              width: "10px",
                              height: "10px",
                            }}
                            // className="checkbox"
                          />
                        }
                        label={
                          <span
                            style={{
                              fontSize: "12px",
                              fontFamily: "Bellota-Light",
                            }}
                          >
                            {item}
                          </span>
                        }
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              )}
            </div>
            <div className="catasidenav">
              {searchPressed && (
                <FormControl sx={{ m: 1, width: "90%" }} variant="outlined">
                  <OutlinedInput
                    size="small"
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    onChange={(e) => {
                      setSearch(e.target.value)
                    }}
                  />
                </FormControl>
              )}
            </div>
            <div className="catasidenav">
              <button
                style={{
                  width: "90%",
                  height: "40px",
                  marginBottom: "5%",
                  backgroundColor: "#00aeff",
                  color: "white",
                  border: "none",
                }}
              >
                {" "}
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
