import { useState, useEffect } from "react"
// css class
import "../../../../styles/pages/products/singleproduct/singleproduct.scss"
import ColoredBox from "../../../../example/coloredBox"

import Box from "../../../../example/box"
// mui Icons
import {
  NavigateNext,
  NavigateBefore,
  Star,
  StarOutlineSharp,
} from "@mui/icons-material"
///
import { Link } from "react-router-dom"

///
import { useLocation } from "react-router"

///
import { TextField } from "@mui/material"
import Select from "@mui/material/Select"
import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from "@mui/material/InputLabel"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import { Modal } from "@mui/material"
//
import SellingProductsCard from "../../../../example/sellingproducts"
//
import RelatedProducts from "../../../../example/realtedproducts"
import Review from "../../../../example/review"
import CartModal from "../../../../example/cartmodal"

// procut actionhandelr
import { addProduct } from "../../../../redux/cartRedux"

//
import { useDispatch } from "react-redux"

//
import { useQuery, useMutation } from "@tanstack/react-query"

// request sender
import { publicRequest, userRequest } from "../../../../constant/requestMethods"

// mui
import { CircularProgress } from "@mui/material"
import Alert from "@mui/material/Alert"

//
import { useSelector } from "react-redux"
import Footer from "../../../display/footer"

export default function ProductPage() {
  // holds state for the user from the browser storage
  const { currentUser } = useSelector((state) => state.user)

  // const action dispathcer
  const dispatch = useDispatch()

  // state to hold the image link
  const [image, setImage] = useState("")

  // opens the dexcriptions for the floors
  const [descOpen, setDescOpen] = useState(false)
  // changes the bigger image to which ever was pressed
  // const [biggerImg, setBiggerImg] = useState("")
  // setting the quantity adn color fo rthe cart
  const [quantity, setQuantity] = useState(1)
  const [color, setColor] = useState("")
  const [size, setSize] = useState("")
  // TODO should have a function that askes the api
  // this is for the liked and peopel
  const isLiked = 4
  const people = 103
  var peopleholder

  // state to get the review value
  const [value, setValue] = useState(0)
  const [comment, setComment] = useState("")

  // state to hold the alue of the image before it slides to the next or prev
  const [count, setCount] = useState(0)

  // mekes the discription section bigger
  const [discriptionPressed, setDiscriptionPressed] = useState(false)

  // makes the specificaation section bigger
  const [specificationPressed, setSpecificationPressed] = useState(false)

  // state to check if user is a pro, manufacturer,or firm
  const [userType, setUserType] = useState("firm")

  // state to hold the link to get the user
  const [linker, setLinker] = useState("/")

  // holds the average computerd from the third request
  const [reviewAvrg, setReviewAvrg] = useState(0)

  // changes everytime a user reviews a product to load the pages agian
  const [isReviewed, setIsReviewed] = useState(false)

  // holds the first catagory of the element to run the fetch request to get realted producst
  const [catagory, setCatagory] = useState("")

  // discription section slider from disctription to specification
  const nav = ["Discription", "Specification"]
  const [slider, setSlider] = useState("Discription")
  const [isLined, setIsLined] = useState(0)

  //
  const style = {
    gridTemplateColumns: "repeat(auto-fill, 180px)",
    height: "280px",
  }
  // const { display } = SellingProductsCard(data,style)

  //relative products slideree
  const [relatedSlider, setRelatedSlider] = useState(0)

  //
  const [reviews, setReviews] = useState([])
  const [product, setProduct] = useState({})
  const [details, setDetails] = useState({})
  const [related, setRelated] = useState([])
  const [fetchRelated, setFetchRelated] = useState(false)

  // controllers to show alets
  const [alert, setAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  //
  const location = useLocation()
  const id = location.pathname.split("/")[2]
  const findSingleProduct = useQuery({
    queryKey: ["findsingelproduct"],
    queryFn: async () => {
      try {
        setFetchRelated(false)
        // await userRequest.get("/blog/find?page=1&limit=5")
        const reply = await publicRequest.get(`/product/find/${id}`)
        // console.log(reply)
        if (reply.status === 201) {
          // console.log(reply.data.data)
          setProduct(reply.data.data[0])
          reply.data.data &&
            reply.data.data.length > 0 &&
            reply.data.data[0].reviewRecieved &&
            setReviews(reply.data.data[0].reviewRecieved)

          reply.data.data &&
            reply.data.data.length > 0 &&
            reply.data.data[0].avgRating &&
            setReviewAvrg(reply.data.data[0].avgRating)
          reply.data.data &&
            reply.data.data.length > 0 &&
            reply.data.data[0].catagory &&
            reply.data.data[0].catagory.length > 0 &&
            setCatagory(reply.data.data[0].catagory[0])
          reply.data.data &&
            reply.data.data.length > 0 &&
            reply.data.data[0] &&
            setFetchRelated(true)
          if (
            reply.data.data &&
            reply.data.data[0] &&
            reply.data.data[0].userInfo &&
            reply.data.data[0].userInfo.firm &&
            reply.data.data[0].userInfo.firm.length > 0
          ) {
            setUserType("firm")
            setLinker("/firm/" + reply.data.data[0].userInfo.userId)
          } else if (
            reply.data.data &&
            reply.data.data[0] &&
            reply.data.data[0].userInfo &&
            reply.data.data[0].userInfo.manufacturer &&
            reply.data.data[0].userInfo.manufacturer.length > 0
          ) {
            setUserType("manufacturer")
            setLinker("/manufacturer/" + reply.data.data[0].userInfo.userId)
          } else if (
            reply.data.data &&
            reply.data.data[0] &&
            reply.data.data[0].userInfo &&
            reply.data.data[0].userInfo.professional &&
            reply.data.data[0].userInfo.professional.length > 0
          ) {
            setUserType("professional")
            setLinker("/professional/" + reply.data.data[0].userInfo.userId)
          }

          // setAlert(false)
          // setErrorAlert(false)
          // setErrorMessage("")
          // setNewDate(formatDistance(new Date(blog.createdAt), new Date()))
        } else {
          setAlert(true)
          setErrorAlert(true)
          setErrorMessage("couldn't get product")
        }
        return reply
      } catch (e) {
        setAlert(true)
        setErrorAlert(true)
        setErrorMessage(e.message)

        console.log(e)
        return e
      }
    },

    cacheTime: Infinity,
    staleTime: Infinity,
  })

  // query for the related items section
  const findRelatedProduct = useQuery({
    queryKey: ["findrealtedproducts"],
    queryFn: async () => {
      try {
        // await userRequest.get("/blog/find?page=1&limit=5")
        const reply = await publicRequest.get(
          `/product/findrecent?catagoryone=${catagory}`
        )
        // console.log(reply)
        if (reply.status === 201) {
          // console.log(reply.data.data)
          reply.data.data.map((item, index) => {
            if (item._id !== product._id) {
              related.push(item)
            }
          })
          if (related && related.length > 0) {
            setRelated([
              ...new Map(
                related.map((obj) => [`${obj._id}:${obj.title}`, obj])
              ).values(),
            ])
            // console.log("my eople")
          }

          setFetchRelated(false)
          // console.log(fetchRelated)
          // console.log("still fetching")
          // setAlert(false)
          // setErrorAlert(false)
          // setErrorMessage("")
        } else {
          setAlert(true)
          setErrorAlert(true)
          setErrorMessage("couldn't get realted product")
        }
        return reply
      } catch (e) {
        setAlert(true)
        setErrorAlert(true)
        setErrorMessage(e.message)
        console.log(e)
        return e
      }
    },

    enabled: fetchRelated,
    cacheTime: Infinity,
    staleTime: Infinity,
  })

  // endpoint to rate product
  const createReview = async (data) => {
    const reply = await userRequest.put(
      `product/rateproduct/${currentUser.data._id}/${product._id || id}`,
      data
    )
    return reply.data
  }
  const rateProduct = useMutation(createReview, {
    onSuccess: (data) => {
      setAlert(true)
      setSuccessAlert(true)
      // console.log(data)
      setSuccessMessage(data.msg)
      setIsReviewed(!isReviewed)
    },
    onError: (e) => {
      setAlert(true)
      setErrorAlert(true)
      setErrorMessage(e.message)
    },
  })

  // create or update review
  const handleReview = (number, message) => {
    // console.log("tje off season")
    setAlert(false)
    setErrorAlert(false)
    setSuccessAlert(false)
    setErrorMessage("")
    setSuccessMessage("")
    let reviewed
    if (value && comment) {
      reviewed = { value: number, comment: message }
    } else if (value && !comment) {
      reviewed = { value: number }
    } else if (!value && comment) {
      reviewed = { comment: message }
    }

    if (currentUser && currentUser.data && currentUser.data._id) {
      rateProduct.mutate(reviewed)
    } else {
      setAlert(true)
      setErrorAlert(true)
      setSuccessAlert(false)
      setErrorMessage("login to rate product")
    }
  }

  /// movies the box one step to the left
  const handleMoveLeft = (number) => {
    if (number < 0) {
      setCount(product.image.length - 1)
    } else if (number === 0) {
      console.log("first")
      setCount(product.image.length)
    } else if (number >= 1) {
      setCount(number)
    }
  }
  // moves the box one item to the right
  const handleMoveRight = (number) => {
    if (number === product.image.length - 1) {
      setCount(-1)

      // setPercent(6);
    } else if (number < product.image.length) {
      // console.log(product.image.length, count)
      setCount(number)
    }
  }
  // console.log(related)
  // modal that appears after the the add to cart button is pressed
  const renderModal = () => {
    return (
      <>
        <Modal
          style={{
            opacity: "1",
            border: "none",
            borderRadius: "4px",
            overflow: "auto",
            outline: "none",
          }}
          open={openCartModal}
          onClose={(e) => {
            setOpenCartModal(!openCartModal)
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <CartModal
            openModal={openCartModal}
            setOpenModal={setOpenCartModal}
          />
        </Modal>
      </>
    )
  }
  // var that open the cart modal
  const [openCartModal, setOpenCartModal] = useState(false)

  // function to add the profuct into tht cart
  const handleClick = async () => {
    product && product.color ? setColor(product.color) : setColor("")

    // console.log("here")
    const reply = await dispatch(
      addProduct({ ...product, quantity, color, size })
    )
    if (reply.payload) {
      // console.log(reply.payload)
      setOpenCartModal(!openCartModal)
    }
    // console.log("after")
    // console.log(reply)
    // console.log(usedColor)
  }

  useEffect(() => {
    // console.log("id")
    findSingleProduct.refetch()
    fetchRelated && catagory && findRelatedProduct.refetch()
  }, [id])

  useEffect(() => {
    if (currentUser && currentUser.data && currentUser.data._id) {
      findSingleProduct.refetch()
      // console.log("refethcing on review")
    }
  }, [isReviewed])

  // console.log(product, linker, userType, reviewAvrg, fetchRelated)
  return (
    <div className="singleproduct">
      {errorAlert && (
        <div className="alert">
          {errorAlert && <Alert severity="error">{errorMessage}</Alert>}
        </div>
      )}
      {findSingleProduct.isLoading ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100dvh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div className="singleproductinside">
          {renderModal()}
          <div className="singleproductlocator">
            {/* <div className='singleproductmultipleloc'>
            <Locator header={"Home"} icon={<NavigateNext style={{ fontSize: "14px", color: "#00aeff" }} />} />
            <Locator header={"Home"} icon={<NavigateNext style={{ fontSize: "14px", color: "#00aeff" }} />} />
            <Locator header={"Home"} icon={<NavigateNext style={{ fontSize: "14px", color: "#00aeff" }} />} />
            <Locator header={"Home"} icon={<NavigateNext style={{ fontSize: "14px", color: "#00aeff" }} />} />
          </div> */}
            {/* <div className="singleproductfavorite">
              <IconBox icon={<FavoriteBorder style={{ fontSize: "18px" }} />} />
              <IconBox icon={<ShareOutlined style={{ fontSize: "18px" }} />} />
              <IconBox icon={<PrintOutlined style={{ fontSize: "18px" }} />} />
            </div> */}
          </div>
          <div className="singleproductdivided">
            <div className="singleproductleftside">
              <div className="singleproductimagecontainer">
                <div className="singleproductbigger">
                  {/* TODO after receiveing the images as an array it should show the first unless the bottom images are pressed*/}
                  {product && (
                    <img src={image ? image : product.image} alt="" />
                  )}
                  {product && product.image && product.image.length > 1 && (
                    <>
                      <div
                        className="singleproductleft"
                        onClick={(e) => {
                          if (count === 0) {
                            setImage(product.image[product.image.length - 1])
                          } else {
                            handleMoveLeft(count - 1)
                            setImage(product.image[count - 1])
                          }
                        }}
                      >
                        <NavigateBefore
                          style={{ color: "white", fontSize: "22px" }}
                        />
                      </div>
                      <div
                        className="singleproductright"
                        onClick={(e) => {
                          if (count === product.image.length - 1) {
                            setCount(0)
                            setImage(product.image[0])
                          } else {
                            handleMoveRight(count + 1)
                            setImage(product.image[count + 1])
                          }

                          // console.log(count)
                        }}
                      >
                        <NavigateNext
                          style={{ color: "white", fontSize: "22px" }}
                        />
                      </div>
                    </>
                  )}
                </div>
                {product && product.image && product.image.length > 1 && (
                  <div className="singleproductsmaller">
                    <div className="singleproductswipper">
                      {product &&
                        product.image &&
                        product.image.length > 0 &&
                        product.image.map((item, index) => (
                          <div
                            key={index + 23134}
                            className="singleproductindividual"
                          >
                            <img
                              src={item}
                              alt=""
                              onClick={(e) => {
                                setCount(index)
                                setImage(item)
                              }}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="singleproductoverview"></div>
            </div>
            <div className="singleproductproductitemdetails">
              <div className="singleproductproducttop">
                <ColoredBox span={"For Sell"} />

                <span
                  className="singleproductproductitemdetailsheader"
                  style={{ marginBottom: "10px" }}
                >
                  {product && product.title}{" "}
                </span>
                <span>
                  by{" "}
                  <Link className="link" to={linker}>
                    {product && product.userInfo && product.userInfo.userName}
                  </Link>
                </span>
              </div>
              <div
                className="singleproductproductlandstars"
                // style={{ marginTop: "70px" }}
              >
                <div className="singleproductratingstars">
                  {rateProduct.isLoading ? (
                    <div>
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="ratingstars">
                      {value && value >= 1 ? (
                        <Star
                          className="star pressed"
                          onClick={(e) => {
                            if (
                              currentUser &&
                              currentUser.data &&
                              currentUser.data._id &&
                              currentUser.data._id
                            ) {
                              setValue(1)
                              handleReview(1, comment)
                            } else {
                              setAlert(true)
                              setErrorAlert(true)
                              setErrorMessage("please login to add review")
                            }
                          }}
                        />
                      ) : reviewAvrg >= 1 ? (
                        <Star
                          className="star pressed"
                          onClick={(e) => {
                            if (
                              currentUser &&
                              currentUser.data &&
                              currentUser.data._id &&
                              currentUser.data._id
                            ) {
                              setValue(1)
                              handleReview(1, comment)
                            } else {
                              setAlert(true)
                              setErrorAlert(true)
                              setErrorMessage("please login to add review")
                            }

                            // handle
                          }}
                        />
                      ) : (
                        <StarOutlineSharp
                          style={{
                            cursor: "pointer",
                          }}
                          className="star"
                          onClick={(e) => {
                            if (
                              currentUser &&
                              currentUser.data &&
                              currentUser.data._id &&
                              currentUser.data._id
                            ) {
                              setValue(1)
                              handleReview(1, comment)
                            } else {
                              setAlert(true)
                              setErrorAlert(true)
                              setErrorMessage("please login to add review")
                            }
                          }}
                        />
                      )}
                      {value >= 2 ? (
                        <Star
                          className="star pressed"
                          onClick={(e) => {
                            if (
                              currentUser &&
                              currentUser.data &&
                              currentUser.data._id &&
                              currentUser.data._id
                            ) {
                              setValue(2)
                              handleReview(2, comment)
                            } else {
                              setAlert(true)
                              setErrorAlert(true)
                              setErrorMessage("please login to add review")
                            }
                          }}
                        />
                      ) : reviewAvrg >= 2 ? (
                        <Star
                          className="star pressed"
                          onClick={(e) => {
                            if (
                              currentUser &&
                              currentUser.data &&
                              currentUser.data._id &&
                              currentUser.data._id
                            ) {
                              setValue(2)
                              handleReview(2, comment)
                            } else {
                              setAlert(true)
                              setErrorAlert(true)
                              setErrorMessage("please login to add review")
                            }
                          }}
                        />
                      ) : (
                        <StarOutlineSharp
                          style={{
                            cursor: "pointer",
                          }}
                          className="star"
                          onClick={(e) => {
                            if (
                              currentUser &&
                              currentUser.data &&
                              currentUser.data._id &&
                              currentUser.data._id
                            ) {
                              setValue(2)
                              handleReview(2, comment)
                            } else {
                              setAlert(true)
                              setErrorAlert(true)
                              setErrorMessage("please login to add review")
                            }
                          }}
                        />
                      )}
                      {value >= 3 ? (
                        <Star
                          className="star pressed"
                          onClick={(e) => {
                            if (
                              currentUser &&
                              currentUser.data &&
                              currentUser.data._id &&
                              currentUser.data._id
                            ) {
                              setValue(3)
                              handleReview(3, comment)
                            } else {
                              setAlert(true)
                              setErrorAlert(true)
                              setErrorMessage("please login to add review")
                            }
                          }}
                        />
                      ) : reviewAvrg >= 3 ? (
                        <Star
                          className="star pressed"
                          onClick={(e) => {
                            if (
                              currentUser &&
                              currentUser.data &&
                              currentUser.data._id &&
                              currentUser.data._id
                            ) {
                              setValue(3)
                              handleReview(3, comment)
                            } else {
                              setAlert(true)
                              setErrorAlert(true)
                              setErrorMessage("please login to add review")
                            }
                          }}
                        />
                      ) : (
                        <StarOutlineSharp
                          style={{
                            cursor: "pointer",
                          }}
                          className="star"
                          onClick={(e) => {
                            if (
                              currentUser &&
                              currentUser.data &&
                              currentUser.data._id &&
                              currentUser.data._id
                            ) {
                              setValue(3)
                              handleReview(3, comment)
                            } else {
                              setAlert(true)
                              setErrorAlert(true)
                              setErrorMessage("please login to add review")
                            }
                          }}
                        />
                      )}
                      {value >= 4 ? (
                        <Star
                          className="star pressed"
                          onClick={(e) => {
                            if (
                              currentUser &&
                              currentUser.data &&
                              currentUser.data._id &&
                              currentUser.data._id
                            ) {
                              setValue(4)
                              handleReview(4, comment)
                            } else {
                              setAlert(true)
                              setErrorAlert(true)
                              setErrorMessage("please login to add review")
                            }
                          }}
                        />
                      ) : reviewAvrg >= 4 ? (
                        <Star
                          className="star pressed"
                          onClick={(e) => {
                            if (
                              currentUser &&
                              currentUser.data &&
                              currentUser.data._id &&
                              currentUser.data._id
                            ) {
                              setValue(4)
                              handleReview(4, comment)
                            } else {
                              setAlert(true)
                              setErrorAlert(true)
                              setErrorMessage("please login to add review")
                            }
                          }}
                        />
                      ) : (
                        <StarOutlineSharp
                          style={{
                            cursor: "pointer",
                          }}
                          className="star"
                          onClick={(e) => {
                            if (
                              currentUser &&
                              currentUser.data &&
                              currentUser.data._id &&
                              currentUser.data._id
                            ) {
                              setValue(4)
                              handleReview(4, comment)
                            } else {
                              setAlert(true)
                              setErrorAlert(true)
                              setErrorMessage("please login to add review")
                            }
                          }}
                        />
                      )}
                      {value >= 5 ? (
                        <Star
                          className="star pressed"
                          onClick={(e) => {
                            if (
                              currentUser &&
                              currentUser.data &&
                              currentUser.data._id &&
                              currentUser.data._id
                            ) {
                              setValue(5)
                              handleReview(5, comment)
                            } else {
                              setAlert(true)
                              setErrorAlert(true)
                              setErrorMessage("please login to add review")
                            }
                          }}
                        />
                      ) : reviewAvrg >= 5 ? (
                        <Star
                          className="star pressed"
                          onClick={(e) => {
                            if (
                              currentUser &&
                              currentUser.data &&
                              currentUser.data._id &&
                              currentUser.data._id
                            ) {
                              setValue(5)
                              handleReview(5, comment)
                            } else {
                              setAlert(true)
                              setErrorAlert(true)
                              setErrorMessage("please login to add review")
                            }
                          }}
                        />
                      ) : (
                        <StarOutlineSharp
                          style={{
                            cursor: "pointer",
                          }}
                          className="star"
                          onClick={(e) => {
                            if (
                              currentUser &&
                              currentUser.data &&
                              currentUser.data._id &&
                              currentUser.data._id
                            ) {
                              setValue(5)
                              handleReview(5, comment)
                            } else {
                              setAlert(true)
                              setErrorAlert(true)
                              setErrorMessage("please login to add review")
                            }
                          }}
                        />
                      )}
                      <span className="ratingspan">
                        {product &&
                          product.reviewRecieved &&
                          product.reviewRecieved.length}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="singleproductproductland">
                <span className="singleproductproductprice">
                  ${product && product.price}
                </span>
                {/* <span>
                  <del
                    style={{ fontSize: "10px", fontFamily: "Bellota-Light" }}
                  >
                    $3,500.00
                  </del>
                </span> */}
              </div>

              <div
                className="singleproductproductdescription"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <span style={{ fontSize: "18px", fontFamily: "Bellota-Bold" }}>
                  Color:{" "}
                </span>
                {product && !product.color && (
                  <div
                    className="circularcolorholder"
                    style={{
                      backgroundColor: product.color,
                      height: "30px",
                      width: "30px",
                      borderRadius: "50%",
                      marginLeft: "10px",
                    }}
                  ></div>
                )}
              </div>
              <div className="singleproductproductdescription">
                {/* TODO After some letters it should display ... */}
                <FormControl sx={{ m: 1, minWidth: "30%" }}>
                  <InputLabel id="demo-simple-select-label">Size</InputLabel>

                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    autoWidth
                    value={size}
                    onChange={(e) => setSize(e.target.value)}
                    size="small"
                    // label={`Qty: ${quantity}`}
                    label="Size"
                  >
                    {product && product.size && product.size.length > 0 ? (
                      <>
                        {" "}
                        <MenuItem value="">{<em>{size}</em>}</MenuItem>
                        {product &&
                          product.size &&
                          product.size.map((item, index) => (
                            <MenuItem
                              value={item}
                              onClick={(e) => setSize(e.target.value)}
                            >
                              <span
                                style={{
                                  fontSize: "11px",
                                  fontFamily: "Bellota-Light",
                                }}
                              >
                                {item}
                              </span>
                            </MenuItem>
                          ))}
                      </>
                    ) : (
                      <MenuItem>
                        {userType} hasn't provided size for item
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
              <div className="singleproductproductspecification">
                <FormControl sx={{ m: 1, minWidth: "30%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Alternative Color
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    autoWidth
                    // value={quantity}
                    onChange={(e) => setColor(e.target.value)}
                    size="small"
                    // label={`Qty: ${quantity}`}
                    label="Color"
                    style={{ display: "flex" }}
                  >
                    {product &&
                    product.associate_color &&
                    product.associate_color.length > 0 ? (
                      <>
                        <MenuItem
                          value={1}
                          onClick={(e) => setColor(e.target.value)}
                        >
                          <div
                            className="color"
                            style={{ display: "flex", width: "100px" }}
                          >
                            <img
                              src=""
                              alt=""
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50%",
                                backgroundColor: "#f7f8f9",
                              }}
                            />
                            <span>blue</span>
                          </div>
                          <span
                            style={{
                              fontSize: "11px",
                              fontFamily: "Bellota-Light",
                            }}
                          >
                            1700
                          </span>
                        </MenuItem>
                        <MenuItem
                          value={2}
                          onClick={(e) => setColor(e.target.value)}
                        >
                          <div className="color" style={{ display: "flex" }}>
                            <img
                              src=""
                              alt=""
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50%",
                                backgroundColor: "#f7f8f9",
                              }}
                            />
                            <span>blue</span>
                          </div>
                          <span
                            style={{
                              fontSize: "11px",
                              fontFamily: "Bellota-Light",
                            }}
                          >
                            1700
                          </span>
                        </MenuItem>
                        <MenuItem
                          value={2}
                          onClick={(e) => setColor(e.target.value)}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <div className="color" style={{ display: "flex" }}>
                            <img
                              src=""
                              alt=""
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50%",
                                backgroundColor: "#f7f8f9",
                              }}
                            />
                            <span>blue</span>
                          </div>
                          <span
                            style={{
                              fontSize: "11px",
                              fontFamily: "Bellota-Light",
                            }}
                          >
                            1700
                          </span>
                        </MenuItem>
                      </>
                    ) : (
                      <MenuItem>
                        Seems like there isn't any variant of item
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>

              <div className="singleproductproductaddtocart">
                <FormControl sx={{ m: 1, minWidth: "30%" }}>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    autoWidth
                    value={quantity}
                    onChange={(e) => {
                      setQuantity(e.target.value)
                    }}
                    size="small"
                    // label={`Qty: ${quantity}`}
                    label="Qty"
                  >
                    <MenuItem value="">{<em>{quantity}</em>}</MenuItem>
                    <MenuItem
                      value="1"
                      onClick={(e) => setQuantity(e.target.value)}
                    >
                      <span
                        style={{
                          fontSize: "11px",
                          fontFamily: "Bellota-Light",
                        }}
                      >
                        Qty: 1
                      </span>
                    </MenuItem>
                    <MenuItem
                      value={2}
                      onClick={(e) => setQuantity(e.target.value)}
                    >
                      <span
                        style={{
                          fontSize: "11px",
                          fontFamily: "Bellota-Light",
                        }}
                      >
                        Qty: 2
                      </span>
                    </MenuItem>
                    <MenuItem
                      value={3}
                      onClick={(e) => setQuantity(e.target.value)}
                    >
                      <span
                        style={{
                          fontSize: "11px",
                          fontFamily: "Bellota-Light",
                        }}
                      >
                        Qty: 3
                      </span>
                    </MenuItem>
                    <MenuItem
                      value={4}
                      onClick={(e) => setQuantity(e.target.value)}
                    >
                      <span
                        style={{
                          fontSize: "11px",
                          fontFamily: "Bellota-Light",
                        }}
                      >
                        Qty: 4
                      </span>
                    </MenuItem>
                    <MenuItem
                      value={5}
                      onClick={(e) => setQuantity(e.target.value)}
                    >
                      <span
                        style={{
                          fontSize: "11px",
                          fontFamily: "Bellota-Light",
                        }}
                      >
                        Qty: 5
                      </span>
                    </MenuItem>
                    <MenuItem
                      value={6}
                      onClick={(e) => setQuantity(e.target.value)}
                    >
                      <span
                        style={{
                          fontSize: "11px",
                          fontFamily: "Bellota-Light",
                        }}
                      >
                        Qty: 6
                      </span>
                    </MenuItem>
                    <MenuItem
                      value={7}
                      onClick={(e) => setQuantity(e.target.value)}
                    >
                      <span
                        style={{
                          fontSize: "11px",
                          fontFamily: "Bellota-Light",
                        }}
                      >
                        Qty: 7
                      </span>
                    </MenuItem>
                    <MenuItem
                      value={8}
                      onClick={(e) => setQuantity(e.target.value)}
                    >
                      <span
                        style={{
                          fontSize: "11px",
                          fontFamily: "Bellota-Light",
                        }}
                      >
                        Qty: 8
                      </span>
                    </MenuItem>
                    <MenuItem
                      value={9}
                      onClick={(e) => setQuantity(e.target.value)}
                    >
                      <span
                        style={{
                          fontSize: "11px",
                          fontFamily: "Bellota-Light",
                        }}
                      >
                        Qty: 9
                      </span>
                    </MenuItem>
                  </Select>
                </FormControl>
                <button
                  onClick={async (e) => {
                    await handleClick()
                  }}
                >
                  Add To Cart
                </button>
              </div>
            </div>
          </div>
          <div className="singleproductdetailedsection">
            <div className="container">
              {nav.map((item, index) => (
                <span
                  onClick={(e) => {
                    setIsLined(index)
                    setSlider(item)
                  }}
                  key={index}
                  style={
                    index === isLined
                      ? {
                          textDecorationLine: "underline",
                          textDecorationColor: "#00aeff",
                          fontFamily: "Bellota-Bold",
                          fontSize: "22px",
                          fontWeight: "Bold",
                          textDecorationThickness: "30%",
                        }
                      : {}
                  }
                >
                  {item}
                </span>
              ))}
            </div>
            {
              slider === "Specification" ? (
                <div
                  className="discriptionorspecification"
                  style={{ marginTop: "2%" }}
                >
                  {product && product._id && (
                    <Box left={"Product ID"} right={product && product._id} />
                  )}
                  {product && product.userInfo.name && (
                    <Box
                      left={"Manufactured By"}
                      right={product && product.userInfo.name}
                    />
                  )}
                  {product && product.weight && (
                    <Box
                      left={"Size/Weight"}
                      right={product && product.weight}
                    />
                  )}
                  {product && product.color && (
                    <Box left={"Color"} right={product && product.color} />
                  )}
                  {product.materials.length > 0 && (
                    <Box
                      left={"Materials"}
                      right={product.materials.map((material, index) => {
                        return material + ","
                      })}
                    />
                  )}
                  <Box left={"Assembly Required"} right={"No"} />
                  <Box left={"Style"} right={"Transitional"} />
                </div>
              ) : (
                <div className="discriptionorspecification">
                  {product &&
                    product.discription &&
                    product.discription.length > 0 &&
                    product.discription.map((item, index) => (
                      <p key={index + 789}>{item}</p>
                    ))}
                </div>
              )
              // </div>
            }
          </div>

          {product && related && related.length > 0 && (
            <div className="singleproductrelatedproducts">
              <span className="singleproductrelatedproductsheader">
                Related Products
              </span>
              <div
                className="slidercontainers"
                style={{ transform: `translateX(${-25 * relatedSlider}%)` }}
              >
                <div className="slider">
                  {findRelatedProduct.isLoading ? (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100dvh",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    product &&
                    related && <RelatedProducts cards={related && related} />
                  )}
                </div>
              </div>
              {product && related && related.length > 3 && (
                <div className="sliderbuttons">
                  <div
                    className="relatedleft"
                    onClick={(e) => {
                      relatedSlider !== 0
                        ? setRelatedSlider(relatedSlider - 1)
                        : setRelatedSlider(0)
                    }}
                  >
                    <NavigateBefore
                      style={{ color: "black", fontSize: "22px" }}
                    />
                  </div>
                  <div
                    className="relatedright"
                    onClick={(e) => {
                      {
                        relatedSlider !== 3
                          ? setRelatedSlider(relatedSlider + 1)
                          : setRelatedSlider(3)
                      }
                    }}
                  >
                    <NavigateNext
                      style={{ color: "black", fontSize: "22px" }}
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="singleproductreviews" id="reviews">
            <span className="singleproductreviewheader">Customer Reviews</span>
            {product ? (
              <Review
                review={product && product.reviewRecieved}
                average={product && Math.round(product.avgRating, 1)}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
      <Footer />
    </div>
  )
}
