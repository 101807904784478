import { useEffect, useState } from "react"
/// scss class
import "../../../../styles/profile/product/update/updateproduct.scss"
/// material ui
import {
  Close,
  FormatQuote,
  Info,
  Inventory,
  PriceChange,
} from "@mui/icons-material"
import FormControl from "@mui/material/FormControl"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import { Button, CircularProgress, TextField } from "@mui/material"
import Alert from "@mui/material/Alert"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select, { SelectChangeEvent } from "@mui/material/Select"

import { DeleteOutline } from "@mui/icons-material"

// api call to login the user
import { login } from "../../../../redux/apiCall"
// state dispacther
import { useDispatch, useSelector } from "react-redux"
// router import
import { useNavigate } from "react-router-dom"

// firebase imports to upload the picture
import app from "../../../../constant/fireBase"
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage"

//
import { useQuery, useMutation } from "@tanstack/react-query"

//
import { userRequest } from "../../../../constant/requestMethods"

export default function UpdateProduct({ setOpenModal, openModal, product }) {
  // mui style for the selsector
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 140,
        width: 250,
      },
    },
  }

  let checked = true
  const navigate = useNavigate()
  // getting the state from the website
  const { currentUser } = useSelector((state) => state.user)

  // controllers to show alets
  const [alert, setAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  // error checkers
  const [fieldError, setFieldError] = useState(false)
  const [errorOn, setErrorOn] = useState("")

  // vars for the form
  const [email, setEmail] = useState("")
  const [userName, setUserName] = useState("")
  const [password, setPassWord] = useState("")
  const [file, setFile] = useState()
  const [fileName, setFileName] = useState([...product.image])
  const [input, setInput] = useState({})
  const [cat, setCat] = useState({})
  const [catagory, setCatagory] = useState([])
  const feature = [
    "Decoration & Accessories",
    "Furniture",
    "Kitchen & Baths",
    "Lighting",
    "Materials",
    "Painting",
    "Roofing & Gutter",
    "Wall & Floor",
  ]

  const [petty, setPetty] = useState([{}])

  //	// trigger for setting if the images failed to load or are success ful
  const [isImageLoading, setIsImageLoading] = useState(false)
  const [hasFailed, setHasFailed] = useState(false)

  // console.lsog(fileName)

  // saves the Edited textfields with their name and values
  const handleInput = (e) => {
    setInput((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

  /// function that saves the inputed properties as arrays
  const handleCat = (e) => {
    setCat((prev) => {
      return { ...prev, [e.target.name]: e.target.value.split(/[\r?\n,]/) }
    })
  }

  const handleDisc = (e) => {
    setCat((prev) => {
      return { ...prev, [e.target.name]: e.target.value.split(/[\r?\n]/) }
    })
  }

  const handleCatagory = (e) => {
    // console.log(e.target.value)
    const {
      target: { value },
    } = e
    setCatagory(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    )
  }

  // endpoint to edit product
  const editProduct = async (data) => {
    const reply = await userRequest.put(
      `/product/update/${currentUser.data._id}/${product._id}`,
      data
    )
    return reply.data
  }
  const { mutate, isLoading } = useMutation(editProduct, {
    onSuccess: (data) => {
      setAlert(true)
      setSuccessAlert(true)
      // console.log(data);s
      setSuccessMessage(data.msg)
    },
    onError: (e) => {
      setAlert(true)
      setErrorAlert(true)
      setErrorMessage(e.response.message)
    },
  })
  const updateHandler = () => {
    let { amount, price } = input
    amount = parseInt(amount)
    price = parseInt(price)
    const inStock = { amount }
    const updates = {
      ...input,
      ...cat,
      image: fileName,
      catagory,
      inStock,
    }
    setAlert(false)
    setErrorAlert(false)
    setSuccessAlert(false)
    setErrorMessage("")
    setSuccessMessage("")
    // console.log(updates)
    mutate(updates)
  }

  // chnages eerytime an image is removed from an array which sets the page to relaoad
  const [deleted, setDeleted] = useState(true)

  // useEffect to reload when a pic is deleted
  useEffect(() => {}, [fileName, deleted])

  // function to upload images
  const handleClick = async (e) => {
    // e.prev""entDefault()
    var streetsofafruce = {}

    streetsofafruce = e.target.files[0]
    setFile(streetsofafruce)

    const fileName = new Date().getTime() + streetsofafruce.name
    const storage = getStorage(app)
    const storageReff = ref(storage, fileName)
    const uploadTask = uploadBytesResumable(storageReff, streetsofafruce)

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log("Upload is " + progress + "% done")
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused")
            break
          case "running":
            console.log("Upload is running")
            break
        }
        setIsImageLoading(true)
      },
      (error) => {
        setIsImageLoading(false)
        setHasFailed(false)
        // Handle unsuccessful uploads
      },
      () => {
        setIsImageLoading(false)
        setHasFailed(false)
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          var lio = downloadURL

          setFileName((oldone) => [...oldone, downloadURL])
        })

        fileName &&
          fileName.length > 0 &&
          fileName.map((item, index) => {
            if (!item) {
              fileName.slice(index, 1)
            }
          })
      }
    )
  }

  return (
    <div
      className="renderupdateproduct"
      style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        left: "50%",
        top: "60%",
        // height: "90%",
        width: "60.4%",
        transform: "translate(-50%, -50%)",
        outline: "none",
        opacity: "1",
        backgroundColor: "white",
        border: "none",
      }}
    >
      <div className="updateproductheader">
        <div className="updateproducttext">
          <span>Update {product && product.title}</span>
        </div>

        {/* <div className='verticallines'></div> */}

        {/* <div className='verticallines'></div> */}
        {/* <div className='verticallines'></div> */}

        <button
          onClick={(e) => {
            setOpenModal(!openModal)
          }}
        >
          <Close />
        </button>
      </div>
      {alert && (
        <div className="alert">
          {errorAlert && <Alert severity="error">{errorMessage}</Alert>}
          {successAlert && <Alert severity="success">{successMessage}</Alert>}
        </div>
      )}
      <div className="updateproductimage">
        <div className="updateproductimageplus">
          <div className="laketitle">
            <span>Image</span>
          </div>
          <div className="ollio">
            <input
              type="file"
              multiple="multiple"
              onChange={
                (e) =>
                  handleClick(e) /*paperback(e)setFile(e.target.files[0]) */
              }
            />
          </div>
        </div>
        <div
          className="fetchspinning"
          style={{ display: "flex", alignItems: "center" }}
        >
          {isImageLoading ? (
            <div style={{ marginRight: "1%" }}>
              <CircularProgress />
            </div>
          ) : (
            <></>
          )}
          {hasFailed ? (
            <span style={{ fontSize: "12px", color: "red" }}>
              {" "}
              Error. Try to upload the file again
            </span>
          ) : (
            <></>
          )}
          <div
            className="iplayedmyfair"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {fileName.length >= 1 ? (
              fileName.map((item, index) => (
                <div
                  style={{ display: "flex", flexDirection: "row" }}
                  key={index + 33}
                >
                  <img
                    src={item}
                    alt=""
                    style={{
                      height: "100px",
                      width: "100px",
                      backgroundColor: "#dce0e0",
                      objectFit: "cover",
                      marginRight: "2%",
                    }}
                  />
                  <DeleteOutline
                    style={{
                      position: "relative",
                      color: "red",
                      fontSize: "25px",
                      left: "-10%",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      // console.log(index)
                      fileName.splice(index, 1)
                      setDeleted(!deleted)
                      // console.log(fileName)
                    }}
                  />
                </div>
              ))
            ) : (
              <span></span>
            )}
          </div>
        </div>
      </div>
      <div className="updateproductmiddle">
        <TextField
          // placeholder={product.title}
          size="small"
          className="updateproducttextfield"
          label="Title"
          variant="outlined"
          placeholder={product && product.title}
          name="title"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FormatQuote />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleInput(e)
          }}
        />
        <TextField
          // placeholder={product.title}
          size="small"
          className="updateproducttextfield"
          label="Size"
          variant="outlined"
          name="size"
          placeholder={
            product &&
            product.size &&
            product.size.length > 0 &&
            product.size.map((item) => {
              return item + ","
            })
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FormatQuote />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleCat(e)
          }}
        />
        <TextField
          // placeholder={product.title}
          size="small"
          className="updateproducttextfield"
          label="Color"
          variant="outlined"
          name="color"
          placeholder={product && product.color && product.color}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FormatQuote />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleInput(e)
          }}
        />
        <TextField
          // placeholder={product.title}
          size="small"
          className="updateproducttextfield"
          label="Weight"
          variant="outlined"
          name="weight"
          placeholder={product && product.weight && product.weight}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FormatQuote />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleInput(e)
          }}
        />
        <TextField
          // placeholder={product.title}
          size="small"
          className="updateproducttextfield"
          label="Price"
          variant="outlined"
          type="number"
          name="price"
          placeholder={product && product.price && product.price}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FormatQuote />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleInput(e)
          }}
        />
        <TextField
          // placeholder={product.title}
          size="small"
          className="updateproducttextfield"
          label="Materials"
          variant="outlined"
          name="materials"
          placeholder={
            product &&
            product.material &&
            product.materials.length > 0 &&
            product.materials.map((item) => {
              return item + ",s"
            })
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FormatQuote />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleCat(e)
          }}
        />

        <TextField
          size="small"
          className="updateproducttextfield"
          label="Stock"
          variant="outlined"
          // placeholder={product.discription && product.discription[0]}
          type="number"
          name="amount"
          placeholder={
            product &&
            product.inStock &&
            product.inStock.amount &&
            product.inStock.amount
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Info />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleInput(e)
          }}
        />
        <TextField
          size="small"
          className="updateproducttextfield"
          label="Discription"
          variant="outlined"
          // placeholder={product.discription && product.discription[0]}
          multiline
          rows={4}
          name="discription"
          placeholder={
            product &&
            product.discription &&
            product.discription.length > 0 &&
            product.discription.map((item) => {
              return item + ",s"
            })
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Info />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleDisc(e)
          }}
        />
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-name-label">Catagory</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={catagory}
            onChange={(e) => {
              handleCatagory(e)
            }}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
          >
            {feature.map((name) => (
              <MenuItem
                key={name}
                value={name}
                // style={getStyles(name, personName, theme)}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="updateproductlast">
        <Button
          className="updateproductbutton"
          onClick={(e) => updateHandler()}
          disabled={isLoading || fileName.length < 1}
        >
          {isLoading ? (
            <CircularProgress
              style={{ color: "white", width: "20px", height: "20px" }}
            />
          ) : (
            <>Update</>
          )}
        </Button>
        {/* <div className="logingoogle">
          <Google style={{ width: "20%", fontSize: "20px", border: " 1px solid #3c8daa", height: "10%" }} />
          <button className="googlebutton"> Sign In with Google</button>
        </div> */}
      </div>
    </div>
  )
}
