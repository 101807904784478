// css class
import "../../styles/display/display.scss"
import Products from "./products/index.js"

import Front from "./front"
import Search from "./search"
import Footer from "./footer"

export default function Display() {
  return (
    <div className="display">
      {/* <Front /> */}
      <Products />
      {/* <div className="adjusting">

      </div> */}
      {/* <div > */}

      {/* </div> */}
      <Footer />
    </div>
  )
}
