import "../../../../../styles/layout/nav/forgot/forgot.scss"
import { CircularProgress, TextField, Alert } from "@mui/material"
import { Close } from "@mui/icons-material"
import Border from "../../../../../example/borderbottom"
import { useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { publicRequest } from "../../../../../constant/requestMethods"
export default function Forgot({ setOpenModal, openModal, setChangeRegister }) {
  const [userCred, setUserCred] = useState("")

  // controllers to show alets
  const [alert, setAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  // endpoint to edit product
  const fotgotRequest = async (data) => {
    const reply = await publicRequest.post(`/user/reset`, data)
    return reply.data
  }
  const { mutate, isLoading } = useMutation(fotgotRequest, {
    onSuccess: (data) => {
      setAlert(true)
      setSuccessAlert(true)
      // console.log(data);s
      setSuccessMessage(data.msg)
    },
    onError: (e) => {
      setAlert(true)
      setErrorAlert(true)
      setErrorMessage(e.response.data.msg)
    },
  })
  const forgotHandler = () => {
    setAlert(false)
    setErrorAlert(false)
    setSuccessAlert(false)
    setErrorMessage("")
    setSuccessMessage("")
    // console.log(updates)
    let user
    if (userCred.indexOf("@") === -1) {
      user = { userName: userCred }
    } else {
      user = { email: userCred }
    }
    mutate(user)
  }

  return (
    <div
      className="renderforgot"
      style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        left: "50%",
        top: "35%",
        // height: "60%",
        width: "33.4%",
        transform: "translate(-50%, -50%)",
        outline: "none",
        opacity: "1",
        backgroundColor: "white",
        border: "none",
      }}
    >
      <div className="forgotheader">
        <div className="forgotsigintext">
          <span>Reset Password</span>
        </div>

        <Close
          style={{ color: "#7E7E7E", fill: "#7E7E7E", cursor: "pointer" }}
          onClick={(e) => {
            setOpenModal(!openModal)
            setChangeRegister("login")
          }}
        />
      </div>
      <Border />
      {alert && (
        <div className="alert">
          {errorAlert && <Alert severity="error">{errorMessage}</Alert>}
          {successAlert && <Alert severity="success">{successMessage}</Alert>}
        </div>
      )}

      <div className="forgotmiddle">
        <span>
          Please enter your username or email address. You will receive a link
          to create a new password via email.
        </span>
        <TextField
          // style={{ ma }}
          size="small"
          onChange={(e) => {
            setUserCred(e.target.value)
          }}
        ></TextField>
      </div>
      <div className="forgotlast">
        <button
          className="forgotsigininbutton"
          // onClick={(e) => handleforgot()}
          disabled={!userCred}
          onClick={(e) => {
            forgotHandler()
          }}
        >
          {isLoading ? (
            <CircularProgress
              style={{ color: "white", width: "20px", height: "20px" }}
            />
          ) : (
            <>Get new password</>
          )}
        </button>
        {/* <div className="logingoogle">
      <Google style={{ width: "20%", fontSize: "20px", border: " 1px solid #3c8daa", height: "10%" }} />
      <button className="googlebutton"> Sign In with Google</button>
    </div> */}
      </div>
    </div>
  )
}
