import "./deleteitem.scss"
import { useState } from "react"
import { Close } from "@mui/icons-material"
import { Alert, CircularProgress, TextField } from "@mui/material"

import { useSelector } from "react-redux"
import { useMutation } from "@tanstack/react-query"
import { userRequest } from "../../constant/requestMethods"
export default function DeleteItem({
  openModal,
  setOpenModal,
  img,
  item,
  type,
}) {
  const currentUser = useSelector((state) => state.user.currentUser)

  // controllers to show alets
  const [alert, setAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  const [itemName, setItemName] = useState("")
  // endpoint to edit product
  const deleteItem = async (data) => {
    const reply = await userRequest.delete(
      `/${type}/delete/${currentUser.data._id}/${item._id}`
    )
    return reply.data
  }
  const { mutate, isLoading } = useMutation(deleteItem, {
    onSuccess: (data) => {
      setAlert(true)
      setSuccessAlert(true)
      // console.log(data);s
      setSuccessMessage(data.msg)
    },
    onError: (e) => {
      setAlert(true)
      setErrorAlert(true)
      setErrorMessage(e.response.msg)
    },
  })
  const deleteHandler = () => {
    setAlert(false)
    setErrorAlert(false)
    setSuccessAlert(false)
    setErrorMessage("")
    setSuccessMessage("")
    // console.log(updates)
    mutate()
  }

  return (
    <div
      className="deleteitem"
      style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        left: "50%",
        top: "35%",
        // height: "60%",
        width: "33.4%",
        transform: "translate(-50%, -50%)",
        outline: "none",
        opacity: "1",
        backgroundColor: "white",
        border: "none",
      }}
    >
      <div className="deleteheader">
        <Close
          style={{ color: "white", cursor: "pointer" }}
          onClick={(e) => {
            setOpenModal(!openModal)
          }}
        />
      </div>
      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : errorAlert || successAlert ? (
        <>
          {successAlert && <Alert severity="success">{successMessage}</Alert>}
          {errorAlert && <Alert severity="error">{errorMessage}</Alert>}
        </>
      ) : (
        <>
          <Alert severity="warning">
            Are you sure you want to do this? This action can't be undone.
          </Alert>
          <span>To confirm, type" {item.title} " in the box below.</span>
          <TextField
            size="small"
            className="deletebox"
            onChange={(e) => {
              setItemName(e.target.value)
            }}
          ></TextField>
          {/* <Rooms icons={<Check />} number="Added To Cart" /> */}
          <div className="deletebuttons">
            <button
              className="keep"
              onClick={(e) => {
                setOpenModal(!openModal)
              }}
            >
              Cancel
            </button>

            <button
              className="delete"
              onClick={(e) => {
                deleteHandler()
              }}
              disabled={item && item.title !== itemName}
            >
              Delete
            </button>
          </div>
        </>
      )}
    </div>
  )
}
