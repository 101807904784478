import { useEffect, useState } from "react"
//csss class
import "../../styles/profile/userprofile.scss"

// mui
import { CircularProgress, TextField } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import Alert from "@mui/material/Alert"
import InputLabel from "@mui/material/InputLabel"

import ProfileSideNav from "./sidenav"

import { userRequest, GetToken } from "../../constant/requestMethods"
import { useSelector } from "react-redux"

//
import { useQuery, useMutation } from "@tanstack/react-query"

//
import { useLocation } from "react-router"

// firebase imports to upload the picture
import app from "../../constant/fireBase"
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage"

export default function UserProfile(navNeeded) {
  const location = useLocation()
  const id = location.pathname.split("/")[1]

  const [navDown, setNavDown] = useState(!navNeeded)
  // for getting the user Id stored when the user logged in
  const { currentUser } = useSelector((state) => state.user)

  // state to use the user
  const [user, setUser] = useState({})

  // state data
  const [userData, setUserData] = useState({})

  // holds the info
  const [userInfo, setUserInfo] = useState({})

  // controllers to show alets
  const [alert, setAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  // user type checker
  const [isType, setIsType] = useState("")

  const address = ["Addis Ababa", "Addama", "Mekele", "Sebeta"]

  const [addressName, setAddressName] = useState([])

  const [file, setFile] = useState()
  const [fileName, setFileName] = useState([])
  const [background, setBackground] = useState([])
  const [input, setInput] = useState({})
  const [cat, setCat] = useState({})
  const [isImageLoading, setIsImageLoading] = useState(false)
  const [isLogoLoading, setLogoLoading] = useState(false)
  const [hasImageFailed, setImageFailed] = useState(false)
  const [hasProfilePicFailed, setProfilePicFailed] = useState(false)
  const [linker, setLinker] = useState("")
  const [catagories, setCatagories] = useState([])
  const [catagoryName, setCatagoryName] = useState([])
  const firmCatagory = [
    "Architecture / Design Firm",
    "Engineering",
    "Finishing",
    "General Contractors",
    "Graphics & Animation",
    "Landscape Architecture",
    "Photography Studio",
    "Planning",
    "Security",
  ]
  const professionalCatagory = [
    "Architecture / Design professional",
    "Engineering",
    "Finishing",
    "General Contractors",
    "Graphics & Animation",
    "Landscape Architecture",
    "Photography Studio",
    "Planning",
    "Security",
    "Decoration & Accessories",
    "Furniture",
    "Kitchen & Baths",
    "Lighting",
    "Material",
    "Painting",
    "Roofing & Gutter",
    "Wall & Floor",
  ]
  const manufacturerCatagory = [
    "Decoration & Accessories",
    "Furniture",
    "Kitchen & Baths",
    "Lighting",
    "Material",
    "Painting",
    "Roofing & Gutter",
    "Wall & Floor",
  ]

  // const [bigInfo, setBigInfo] = useState({})

  //	// trigger for setting if the images failed to load or are success ful
  // const [isLoading, setIsLoading] = useState(false);

  // to check if update button is pressed
  const [isUpdatePressed, setUpdatePressed] = useState(false)

  // saves the Edited textfields with their name and values
  const handleInput = (e) => {
    setInput((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

  /// function that saves the inputed properties as arrays
  const handleCat = (e) => {
    setCat((prev) => {
      return { ...prev, [e.target.name]: e.target.value.split(/[\r?\n,]/) }
    })
  }

  const handleAddress = (e) => {
    const {
      target: { value },
    } = e
    setAddressName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    )
  }

  const handleCatagory = (e) => {
    // console.log(e.target.value)

    const {
      target: { value },
    } = e
    setCatagoryName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    )
  }

  // query to find the user and display the data
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["finduser"],
    queryFn: async () => {
      try {
        setErrorAlert(false)
        setAlert(false)
        setErrorMessage("")

        // const userId = currentUser.data._id
        const tojen = GetToken()
        // console.log(tojen)
        const reply = await userRequest.get(
          `/user/find/${currentUser.data._id}`,
          { headers: { token: `Bearer ${tojen}` } }
        )
        // console.log(reply)
        if (reply.data) {
          // console.log(reply)
          const { firm, manufacturer, professional, ...others } =
            reply.data.data
          setUser(others)
          if (firm) {
            const { firmInfo, ...rest } = firm
            setUserInfo(firmInfo)
            setUserData(rest)
            setIsType("firm")
            setLinker("firm/update/" + currentUser.data._id)
          } else if (manufacturer) {
            const { manufacturerInfo, ...besides } = manufacturer
            setUserInfo(manufacturerInfo)
            setUserData(besides)
            setIsType("manufacturer")
            setLinker("manufacturer/update/" + currentUser.data._id)
          } else if (professional) {
            const { professionalInfo, ...other } = professional
            setUserInfo(professionalInfo)
            setUserData(other)
            setIsType("professional")
            setLinker("professional/update/" + currentUser.data._id)
          }
          return reply
        } else {
          return "please reload the page agian and see if the issue has disappeard"
        }
      } catch (e) {
        setErrorAlert(true)
        setAlert(true)
        setErrorMessage(e.message)
        return e
      }
    },

    cacheTime: Infinity,
    staleTime: Infinity,
  })
  const handleClick = async (e) => {
    // e.prev""entDefault()
    var streetsofafruce = {}

    streetsofafruce = e.target.files[0]

    setFile(streetsofafruce)
    // console.log(streetsofafruce)
    const fileName = new Date().getTime() + streetsofafruce.name
    const storage = getStorage(app)
    const storageReff = ref(storage, fileName)
    const uploadTask = uploadBytesResumable(storageReff, streetsofafruce)

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log("Upload is " + progress + "% done")
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused")
            break
          case "running":
            console.log("Upload is running")
            break
        }
        setIsImageLoading(true)
      },
      (error) => {
        setIsImageLoading(false)
        setImageFailed(true)
        // Handle unsuccessful uploads
      },
      () => {
        setIsImageLoading(false)
        setImageFailed(false)
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          var lio = downloadURL

          setFileName(downloadURL)
          setInput((prev) => {
            return { ...prev, profilepic: downloadURL }
          })
        })
      }
    )
  }

  // for the background image
  const handleBackgroundImage = async (e) => {
    // e.prev""entDefault()
    var streetsofafruce = {}

    streetsofafruce = e.target.files[0]

    setFile(streetsofafruce)
    // console.log(streetsofafruce)
    const fileName = new Date().getTime() + streetsofafruce.name
    const storage = getStorage(app)
    const storageReff = ref(storage, fileName)
    const uploadTask = uploadBytesResumable(storageReff, streetsofafruce)

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log("Upload is " + progress + "% done")
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused")
            break
          case "running":
            console.log("Upload is running")
            break
        }
        setLogoLoading(true)
      },
      (error) => {
        setLogoLoading(false)
        setProfilePicFailed(true)
        // Handle unsuccessful uploads
      },
      () => {
        setLogoLoading(false)
        setProfilePicFailed(false)
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          var lio = downloadURL

          setBackground(downloadURL)
          setInput((prev) => {
            return { ...prev, logo: downloadURL }
          })
        })
      }
    )
  }

  // endpoint to updateUser project
  const updateUser = async (data) => {
    const reply = await userRequest.put(linker, data)
    return reply.data
  }
  const update = useMutation(updateUser, {
    onSuccess: (data) => {
      setAlert(true)
      setSuccessAlert(true)
      // console.log(data)
      setSuccessMessage(data.msg)
    },
    onError: (e) => {
      setAlert(true)
      setErrorAlert(true)
      setErrorMessage(e.message)
    },
  })

  const handleUpdate = (e) => {
    e.preventDefault()
    // console.log("hellowrld")
    if (isType === "firm") {
      let bigInfo = { firmInfo: {} }
      if (input.cellPhone) {
        input.cellPhone = parseInt(input.cellPhone)
      }
      if (
        (cat.facebook && cat.facebook.length > 0) ||
        (cat.linkedIn && cat.linkedIn.length > 0) ||
        (cat.youtube && cat.youtube.length > 0) ||
        (cat.google && cat.google.length > 0) ||
        (cat.twitter && cat.twitter.length > 0) ||
        (cat.youtube && cat.youtube.length > 0) ||
        (cat.pinterest && cat.pinterest.length > 0) ||
        (cat.websiteLink && cat.websiteLink.length > 0)
      ) {
        const { discription, ...others } = cat

        bigInfo = {
          firmInfo: {
            ...bigInfo.firmInfo,
            socialLinks: {
              ...others,
            },
          },
        }
        // console.log()
      } else {
        bigInfo = {}
      }
      if (addressName && addressName.length > 0) {
        bigInfo = {
          firmInfo: {
            ...bigInfo.firmInfo,
            region: [
              {
                city: [
                  address.map((name) => {
                    return { location: name }
                  }),
                ],
              },
            ],
          },
        }
      }
      let ory
      if (catagoryName && catagoryName.length > 0) {
        ory = {
          catagory: catagoryName.map((item) => {
            return item.toLowerCase()
          }),
        }
      }

      const updates = {
        ...input,
        ...cat,
        ...bigInfo,
        ...ory,
      }
      Object.keys(updates).length !== 0 && update.mutate(updates)
      // console.log(updates)
    } else if (isType === "manufacturer") {
      let bigInfo = { manufacturerInfo: {} }
      if (input.cellPhone) {
        input.cellPhone = parseInt(input.cellPhone)
      }
      if (
        (cat.facebook && cat.facebook.length > 0) ||
        (cat.linkedIn && cat.linkedIn.length > 0) ||
        (cat.youtube && cat.youtube.length > 0) ||
        (cat.google && cat.google.length > 0) ||
        (cat.twitter && cat.twitter.length > 0) ||
        (cat.youtube && cat.youtube.length > 0) ||
        (cat.pinterest && cat.pinterest.length > 0) ||
        (cat.websiteLink && cat.websiteLink.length > 0)
      ) {
        const { discription, ...others } = cat

        bigInfo = {
          manufacturerInfo: {
            ...bigInfo.manufacturerInfo,
            socialLinks: {
              ...others,
            },
          },
        }
        // console.log()
      } else {
        bigInfo = {}
      }
      if (addressName && addressName.length > 0) {
        bigInfo = {
          manufacturerInfo: {
            ...bigInfo.manufacturerInfo,
            region: [
              {
                city: [
                  address.map((name) => {
                    return { location: name }
                  }),
                ],
              },
            ],
          },
        }
      }
      let ory
      if (catagoryName && catagoryName.length > 0) {
        ory = {
          catagory: catagoryName.map((item) => {
            return item.toLowerCase()
          }),
        }
      }
      const updates = {
        ...input,
        ...cat,
        ...bigInfo,
        ...ory,
      }

      Object.keys(updates).length !== 0 && update.mutate(updates)
      // console.log(updates)
    } else if (isType === "professional") {
      let bigInfo = { professionalInfo: {} }
      if (input.cellPhone) {
        input.cellPhone = parseInt(input.cellPhone)
      }
      if (
        (cat.facebook && cat.facebook.length > 0) ||
        (cat.linkedIn && cat.linkedIn.length > 0) ||
        (cat.youtube && cat.youtube.length > 0) ||
        (cat.google && cat.google.length > 0) ||
        (cat.twitter && cat.twitter.length > 0) ||
        (cat.youtube && cat.youtube.length > 0) ||
        (cat.pinterest && cat.pinterest.length > 0) ||
        (cat.websiteLink && cat.websiteLink.length > 0)
      ) {
        const { discription, ...others } = cat

        bigInfo = {
          professionalInfo: {
            ...bigInfo.professionalInfo,
            socialLinks: {
              ...others,
            },
          },
        }
        console.log(bigInfo)
      } else {
        bigInfo = {}
      }
      if (addressName && addressName.length > 0) {
        bigInfo = {
          professionalInfo: {
            ...bigInfo.professionalInfo,
            region: [
              {
                city: [
                  address.map((name) => {
                    return { location: name }
                  }),
                ],
              },
            ],
          },
        }
      }

      let ory
      if (catagoryName && catagoryName.length > 0) {
        ory = {
          catagory: catagoryName.map((item) => {
            return item.toLowerCase()
          }),
        }
      }
      const updates = {
        ...input,
        ...cat,
        ...bigInfo,
        ...ory,
      }

      Object.keys(updates).length !== 0 && update.mutate(updates)
      // console.log(Object.keys(updates).length === 0)
    } else {
      setAlert(true)
      setErrorAlert(true)
      setErrorMessage("please reload the page")
    }
  }

  // mui style for the selsector
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 140,
        width: 250,
      },
    },
  }

  useEffect(() => {
    refetch()
  }, [id])

  useEffect(() => {
    refetch()
  }, [update.data])
  // console.log(user, userData, userInfo)
  // console.log(navDown)
  return {
    yetAnotherNavNeeded: navDown,
    displayPage: (
      <div className="userprofile">
        <ProfileSideNav page={"profile"} />
        {isLoading || update.isLoading ? (
          <div
            style={{
              display: "flex",
              height: "500px",
              width: "70%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div className="userprofileinfo">
            {alert && (
              <div className="alert">
                {errorAlert && <Alert severity="error">{errorMessage}</Alert>}
                {successAlert && (
                  <Alert severity="success">{successMessage}</Alert>
                )}
              </div>
            )}

            <div className="userprofileheader">
              <span>My Profile</span>
            </div>
            <div className="userprofileinfoname">
              <span className="userprofileinfospan">Information</span>
            </div>
            <div className="userprofileinformation">
              <div className="userprofilecontainer">
                <div className="userprofileinfoimage">
                  {isImageLoading ? (
                    <div
                      style={{
                        display: "flex",
                        height: "300px",
                        width: "300px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    <img
                      src={
                        fileName.length > 0 ? fileName : user && user.profilepic
                      }
                      alt=""
                    />
                  )}

                  <input
                    className="input"
                    type="file"
                    // multiple="multiple"
                    onChange={
                      (e) =>
                        handleClick(
                          e
                        ) /*paperback(e)setFile(e.target.files[0]) */
                    }
                    width="120"
                    height="40"
                  />
                  <button>Update ProfilePic Picture</button>

                  <span>Minimum size 300 x 300 px</span>
                  {isLogoLoading ? (
                    <div
                      style={{
                        display: "flex",
                        height: "300px",
                        width: "300px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    <img
                      src={
                        background.length > 0
                          ? background
                          : userData && userData.logo && userData.logo
                      }
                      alt=""
                    />
                  )}
                  <input
                    className="input"
                    type="file"
                    // multiple="multiple"
                    onChange={
                      (e) =>
                        handleBackgroundImage(
                          e
                        ) /*paperback(e)setFile(e.target.files[0]) */
                    }
                    width="120"
                    height="40"
                  />
                  <button>Update Background Picture</button>
                  <span>Minimum size 1200 x 300 px</span>
                </div>
                <div className="userprofileinfoupdates">
                  <div className="userprofiletextarea">
                    <div className="userprofiletextcontainer">
                      <span className="label"> User Name</span>
                      <TextField
                        name="userName"
                        size="small"
                        className="userprofiletextfield"
                        // label="Title"
                        variant="outlined"
                        placeholder={user && user.userName && user.userName}
                        onChange={(e) => {
                          handleInput(e)
                        }}
                      />
                    </div>
                    <div className="userprofiletextcontainer">
                      <span className="label"> Email</span>
                      <TextField
                        name="email"
                        size="small"
                        className="userprofiletextfield"
                        // label="Title"
                        variant="outlined"
                        placeholder={user && user.email && user.email}
                        onChange={(e) => {
                          handleInput(e)
                        }}
                      />
                    </div>
                    <div className="userprofiletextcontainer">
                      <span className="label"> First Name</span>
                      <TextField
                        name="name"
                        size="small"
                        className="userprofiletextfield"
                        // label="Title"
                        variant="outlined"
                        placeholder={user && user.name && user.name}
                        onChange={(e) => {
                          handleInput(e)
                        }}
                      />
                    </div>
                    <div className="userprofiletextcontainer">
                      <span className="label">Last Name</span>
                      <TextField
                        name="lastName"
                        size="small"
                        className="userprofiletextfield"
                        // label="Title"
                        variant="outlined"
                        placeholder={user && user.lastName && user.lastName}
                        onChange={(e) => {
                          handleInput(e)
                        }}
                      />
                    </div>

                    <div className="userprofiletextcontainer">
                      <span className="label">Cell Phone</span>
                      <TextField
                        name="cellPhone"
                        size="small"
                        className="userprofiletextfield"
                        type="number"
                        // label="Title"
                        variant="outlined"
                        // placeholder={firm.user ? firm.user.cellPhone : ""}
                        onChange={(e) => {
                          handleInput(e)
                        }}
                      />
                    </div>
                    <div className="userprofiletextcontainer">
                      <span className="label">Address</span>
                      <FormControl sx={{ m: 1, minWidth: 200, height: 10 }}>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          multiple
                          value={addressName}
                          onChange={(e) => {
                            handleAddress(e)
                          }}
                          input={<OutlinedInput label="Name" />}
                          MenuProps={MenuProps}
                        >
                          {address.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              // style={getStyles(name, personName, theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="userprofiletextcontainerfilled">
                    <span className="label">About Me</span>
                    <TextField
                      name="discription"
                      multiline
                      rows={5}
                      // size="small"
                      className="userprofiletextfield"
                      // label="Title"
                      variant="outlined"
                      placeholder={
                        userData &&
                        userData.discription &&
                        userData.discription[0] &&
                        userData.discription[0]
                      }
                      onChange={(e) => {
                        handleCat(e)
                      }}
                    />
                  </div>
                  <div className="userprofiletextcontainerfilled">
                    <button
                      onClick={(e) => {
                        setUpdatePressed(!isUpdatePressed)
                        handleUpdate(e)
                      }}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="userprofileinformation">
              <div className="userprofilecontainer">
                <div className="userprofileinfoimage">
                  <span className="socialmedia">Social Media</span>
                </div>

                <div className="userprofileinfoupdates">
                  <div className="userprofiletextarea">
                    <div className="userprofiletextcontainer">
                      <span className="label"> Facebook</span>
                      <TextField
                        name="facebook"
                        size="small"
                        className="userprofiletextfield"
                        // label="Title"
                        variant="outlined"
                        placeholder={
                          userInfo &&
                          userInfo.socialLinks &&
                          userInfo.socialLinks.facebook &&
                          userInfo.socialLinks.facebook[0]
                        }
                        onChange={(e) => {
                          handleCat(e)
                        }}
                      />
                    </div>
                    <div className="userprofiletextcontainer">
                      <span className="label"> Twitter</span>
                      <TextField
                        name="twitter"
                        size="small"
                        className="userprofiletextfield"
                        // label="Title"
                        variant="outlined"
                        placeholder={
                          userInfo &&
                          userInfo.socialLinks &&
                          userInfo.socialLinks.twitter &&
                          userInfo.socialLinks.twitter[0]
                        }
                        onChange={(e) => {
                          handleCat(e)
                        }}
                      />
                    </div>
                    <div className="userprofiletextcontainer">
                      <span className="label"> Linked in</span>
                      <TextField
                        name="linkedIn"
                        size="small"
                        className="userprofiletextfield"
                        // label="Title"
                        variant="outlined"
                        placeholder={
                          userInfo &&
                          userInfo.socialLinks &&
                          userInfo.socialLinks.linkedIn &&
                          userInfo.socialLinks.linkedIn[0]
                        }
                        onChange={(e) => {
                          handleCat(e)
                        }}
                      />
                    </div>
                    <div className="userprofiletextcontainer">
                      <span className="label"> Instagram</span>
                      <TextField
                        name="instagram"
                        size="small"
                        className="userprofiletextfield"
                        // label="Title"
                        variant="outlined"
                        placeholder={
                          userInfo &&
                          userInfo.socialLinks &&
                          userInfo.socialLinks.instagram &&
                          userInfo.socialLinks.instagram[0]
                        }
                        onChange={(e) => {
                          handleCat(e)
                        }}
                      />
                    </div>
                    <div className="userprofiletextcontainer">
                      <span className="label"> Youtube</span>
                      <TextField
                        name="youtube"
                        size="small"
                        className="userprofiletextfield"
                        // label="Title"
                        variant="outlined"
                        placeholder={
                          userInfo &&
                          userInfo.socialLinks &&
                          userInfo.socialLinks.youtube &&
                          userInfo.socialLinks.youtube[0]
                        }
                        onChange={(e) => {
                          handleCat(e)
                        }}
                      />
                    </div>
                    <div className="userprofiletextcontainer">
                      <span className="label"> Pinterest</span>
                      <TextField
                        name="pinterest"
                        size="small"
                        className="userprofiletextfield"
                        // label="Title"
                        variant="outlined"
                        placeholder={
                          userInfo &&
                          userInfo.socialLinks &&
                          userInfo.socialLinks.pinterest &&
                          userInfo.socialLinks.pinterest[0]
                        }
                        onChange={(e) => {
                          handleCat(e)
                        }}
                      />
                    </div>
                    <div className="userprofiletextcontainer">
                      <span className="label"> Skype</span>
                      <TextField
                        name="skype"
                        size="small"
                        className="userprofiletextfield"
                        // label="Title"
                        variant="outlined"
                        placeholder={
                          userInfo &&
                          userInfo.socialLinks &&
                          userInfo.socialLinks.skype &&
                          userInfo.socialLinks.skype[0]
                        }
                        onChange={(e) => {
                          handleCat(e)
                        }}
                      />
                    </div>
                    <div className="userprofiletextcontainer">
                      <span className="label"> Website</span>
                      <TextField
                        name="websiteLink"
                        size="small"
                        className="userprofiletextfield"
                        // label="Title"
                        variant="outlined"
                        placeholder={
                          userInfo &&
                          userInfo.socialLinks &&
                          userInfo.socialLinks.websiteLink &&
                          userInfo.socialLinks.websiteLink[0]
                        }
                        onChange={(e) => {
                          handleCat(e)
                        }}
                      />
                    </div>
                  </div>
                  <div className="userprofiletextcontainerfilled">
                    <div className="userprofiletextcontainerfilled">
                      <button
                        onClick={(e) => {
                          setUpdatePressed(!isUpdatePressed)
                          handleUpdate(e)
                        }}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="userprofileinformation">
            <div className="userprofilecontainer">
              <div className="userprofileinfoimage">
                <span className="socialmedia">Account Role</span>
              </div>
              <div className="userprofileinfoupdates">
                <div className="userprofiletextcontainerfilled">
                  <FormControl sx={{ m: 1, minWidth: "60%" }}>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      autoWidth
                      value={account}
                      onChange={(e) => setAccount(e.target.value)}
                      size="small"
                    >
                      <MenuItem value="">{<em>{account}</em>}</MenuItem>
                      <MenuItem
                        value="Firm"
                        onClick={(e) => setAccount(e.target.value)}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                            fontFamily: "Bellota-Light",
                          }}
                        >
                          Firm
                        </span>
                      </MenuItem>
                      <MenuItem
                        value="Manufacturer"
                        onClick={(e) => setAccount(e.target.value)}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                            fontFamily: "Bellota-Light",
                          }}
                        >
                          Manufacturer
                        </span>
                      </MenuItem>
                      <MenuItem
                        value="Producer"
                        onClick={(e) => setAccount(e.target.value)}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                            fontFamily: "Bellota-Light",
                          }}
                        >
                          Producer
                        </span>
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <div className="userprofiletextcontainerfilled">
                    <button>Update</button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
            <div className="userprofileinformation">
              <div className="userprofilecontainer">
                <div className="userprofileinfoimage">
                  <span className="socialmedia">
                    {isType === "firm"
                      ? "Firm Type"
                      : isType === "professional"
                      ? "Professional Type"
                      : isType === "manufacturer" && "Manufacturer Type"}
                  </span>
                </div>
                <div className="userprofileinfoupdates">
                  <div className="userprofiletextarea">
                    <div className="userprofiletextcontainer">
                      <span className="label"> Catagory</span>
                      <FormControl
                        sx={{ m: 1, width: 300 }}
                        className="userprofiletextfield"
                      >
                        <InputLabel id="demo-multiple-name-label">
                          Catagory
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          multiple
                          value={catagoryName}
                          onChange={(e) => {
                            handleCatagory(e)
                          }}
                          input={<OutlinedInput label="Name" />}
                          MenuProps={MenuProps}
                        >
                          {isType === "firm" &&
                            firmCatagory.map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                                // style={getStyles(name, personName, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))}
                          {isType === "professional" &&
                            professionalCatagory.map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                                // style={getStyles(name, personName, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))}
                          {isType === "manufacturer" &&
                            manufacturerCatagory.map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                                // style={getStyles(name, personName, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="userprofiletextcontainerfilled">
                    <div className="userprofiletextcontainerfilled">
                      <button
                        onClick={(e) => {
                          catagoryName.map((ie) => {
                            return ie.toLowerCase()
                          })
                          handleUpdate(e)
                        }}
                        disabled={catagoryName && catagoryName.length < 1}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="userprofileinformation">
              <div className="userprofilecontainer">
                <div className="userprofileinfoimage">
                  <span className="socialmedia">Password</span>
                </div>
                <div className="userprofileinfoupdates">
                  <div className="userprofiletextarea">
                    <div className="userprofiletextcontainer">
                      <span className="label"> New Password</span>
                      <TextField
                        name="password"
                        size="small"
                        className="userprofiletextfield"
                        // label="Title"
                        variant="outlined"
                        error={input.password && input.password.length < 8}
                        placeholder="Enter the Facebook URL"
                        // error={input.password !== input.retypepassword}
                        onChange={(e) => {
                          handleInput(e)
                        }}
                      />
                    </div>
                    <div className="userprofiletextcontainer">
                      <span className="label"> Confirm Password</span>
                      <TextField
                        name="retypepassword"
                        size="small"
                        className="userprofiletextfield"
                        // label="Title"
                        variant="outlined"
                        placeholder="Enter the Facebook URL"
                        error={
                          input.retypepassword &&
                          input.password !== input.retypepassword
                        }
                        onChange={(e) => {
                          handleInput(e)
                        }}
                      />
                    </div>
                  </div>
                  <div className="userprofiletextcontainerfilled">
                    <div className="userprofiletextcontainerfilled">
                      <button
                        onClick={(e) => handleUpdate(e)}
                        disabled={
                          input.password !== input.retypepassword ||
                          !input.password ||
                          input.password.length < 8
                        }
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*<div className="userprofileinformation">
            <div className="userprofilecontainer">
              <div className="userprofileinfoimage">
                <span className="socialmedia">Delete User</span>
              </div>
               <div className="userprofileinfoupdates">
                <div className="userprofiletextcontainerfilled">
                  <div className="userprofiletextcontainerfilled">
                    <button className="deleteuserprofile">Delete</button>
                  </div>
                </div>
              </div> 
            </div>
          </div>
          */}
          </div>
        )}
      </div>
    ),
  }
}
