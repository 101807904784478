import { useEffect, useState } from "react"
/// scss class
import "../../../../styles/profile/product/create/createproduct.scss"
/// material ui
import { Close, FormatQuote, Info } from "@mui/icons-material"
import FormControl from "@mui/material/FormControl"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import { Button, CircularProgress, TextField } from "@mui/material"
import Alert from "@mui/material/Alert"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { DeleteOutline } from "@mui/icons-material"

//
import { useMutation } from "@tanstack/react-query"

//
import { userRequest } from "../../../../constant/requestMethods"

// state dispacther
import { useSelector } from "react-redux"
// router import
import { useNavigate } from "react-router-dom"

// firebase imports to upload the picture
import app from "../../../../constant/fireBase"
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage"

export default function CreateBlog({ setOpenModal, openModal }) {
  // mui style for the selsector
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 140,
        width: 250,
      },
    },
  }

  // getting the state from the website
  const { isFetching, currentUser } = useSelector((state) => state.user)

  // controllers to show alets
  const [alert, setAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  // vars for the form
  const [input, setInput] = useState({})
  const [cat, setCat] = useState({})
  const [file, setFile] = useState()
  const [fileName, setFileName] = useState([])

  //	// trigger for setting if the images failed to load or are success ful
  const [isImageLoading, setIsImageLoading] = useState(false)
  const [hasFailed, setHasFailed] = useState(false)

  // chnages eerytime an image is removed from an array which sets the page to relaoad
  const [deleted, setDeleted] = useState(true)

  // saves the Edited textfields with their name and values
  const handleInput = (e) => {
    setInput((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

  /// function that saves the inputed properties as arrays
  const handleCat = (e) => {
    setCat((prev) => {
      return { ...prev, [e.target.name]: e.target.value.split(/[\r?\n]/) }
    })
  }

  // useEffect to reload when a pic is deleted
  useEffect(() => {}, [fileName, deleted])

  // function to upload images
  const handleClick = async (e) => {
    var streetsofafruce = []

    streetsofafruce = [...e.target.files]
    console.log(streetsofafruce)
    streetsofafruce.map(async (item, index) => {
      console.log(index)
      console.log(item)
      const filename = new Date().getTime() + item.name
      const storage = getStorage(app)
      const storageReff = ref(storage, filename)
      const uploadTask = uploadBytesResumable(storageReff, item)
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log("Upload is " + progress + "% done")
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused")
              break
            case "running":
              console.log("Upload is running")
              break
          }
          setIsImageLoading(true)
        },
        (error) => {
          setIsImageLoading(false)
          setHasFailed(false)
          // Handle unsuccessful uploads
        },
        () => {
          setIsImageLoading(false)
          setHasFailed(false)
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            var lio = downloadURL
            setFileName((oldone) => [...oldone, downloadURL])
            // console.log(downloadURL)
          })
          fileName.length > 0 &&
            fileName.map((item, index) => {
              if (!item) {
                fileName.slice(index, 1)
              }
            })
        }
      )
    })
  }

  // endpoint to edit product
  const createBlog = async (data) => {
    const reply = await userRequest.post(
      `/blog/create/${currentUser.data._id}`,
      data
    )
    return reply.data
  }
  const { mutate, isLoading } = useMutation(createBlog, {
    onSuccess: (data) => {
      setAlert(true)
      setSuccessAlert(true)
      // console.log(data);
      setSuccessMessage(data.msg)
    },
    onError: (e) => {
      setAlert(true)
      setErrorAlert(true)
      setErrorMessage(e.message)
    },
  })

  const createHandler = () => {
    // console.log(catagory, features)

    const updates = {
      ...input,
      ...cat,
      image: fileName,
    }
    setAlert(false)
    setErrorAlert(false)
    setSuccessAlert(false)
    setErrorMessage("")
    setSuccessMessage("")
    // console.log(updates)
    mutate(updates)
  }

  return (
    <div
      className="rendercreateproduct"
      style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        left: "50%",
        top: "50%",
        height: "90%",
        width: "60.4%",
        transform: "translate(-50%, -50%)",
        outline: "none",
        opacity: "1",
        backgroundColor: "white",
        border: "none",
      }}
    >
      <div className="createproductheader">
        <div className="createproducttext">
          <span>Create New Blog</span>
        </div>

        {/* <div className='verticallines'></div> */}

        {/* <div className='verticallines'></div> */}
        {/* <div className='verticallines'></div> */}

        <button
          onClick={(e) => {
            setOpenModal(!openModal)
          }}
        >
          <Close />
        </button>
      </div>
      {alert && (
        <div className="alert">
          {errorAlert && <Alert severity="error">{errorMessage}</Alert>}
          {successAlert && <Alert severity="success">{successMessage}</Alert>}
        </div>
      )}
      <div className="createproductimage">
        <div className="createproductimageplus">
          <div className="laketitle">
            <span>Image</span>
          </div>
          <div className="ollio">
            <input
              type="file"
              multiple="multiple"
              onChange={
                (e) =>
                  handleClick(e) /*paperback(e)setFile(e.target.files[0]) */
              }
            />
          </div>
        </div>
        <div
          className="fetchspinning"
          style={{ display: "flex", alignItems: "center" }}
        >
          {isImageLoading ? (
            <div style={{ marginRight: "1%" }}>
              <CircularProgress />
            </div>
          ) : (
            <></>
          )}
          {hasFailed ? (
            <span style={{ fontSize: "12px", color: "red" }}>
              {" "}
              Error. Try to upload the file again
            </span>
          ) : (
            <></>
          )}
          <div
            className="iplayedmyfair"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {fileName.length >= 1 ? (
              fileName.map((item, index) => (
                <div
                  style={{ display: "flex", flexDirection: "row" }}
                  key={index + 33}
                >
                  <img
                    src={item}
                    alt=""
                    style={{
                      height: "100px",
                      width: "100px",
                      backgroundColor: "#dce0e0",
                      obductFit: "cover",
                      marginRight: "2%",
                    }}
                  />
                  <DeleteOutline
                    style={{
                      position: "relative",
                      color: "red",
                      fontSize: "25px",
                      left: "-10%",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      console.log(index)
                      fileName.splice(index, 1)
                      setDeleted(!deleted)
                      console.log(fileName)
                    }}
                  />
                </div>
              ))
            ) : (
              <span></span>
            )}
          </div>
        </div>
      </div>
      <div className="createproductmiddle">
        <TextField
          // placeholder={product.title}
          size="small"
          className="createproducttextfield"
          label="Title"
          variant="outlined"
          name="title"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FormatQuote />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleInput(e)
          }}
        />
        <TextField
          // placeholder={product.title}
          size="small"
          className="createproducttextfield"
          label="Source"
          variant="outlined"
          name="source"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FormatQuote />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleInput(e)
          }}
        />

        <TextField
          size="small"
          className="createproducttextfield"
          label="Discription"
          variant="outlined"
          // placeholder={product.discription && product.discription[0]}
          multiline
          rows={4}
          name="discription"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Info />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleCat(e)
          }}
        />
      </div>
      <div className="createproductlast">
        <Button
          className="createproductbutton"
          onClick={(e) => createHandler()}
          disabled={
            isFetching || isLoading || !input.title || fileName.length < 1
          }
          //
          // }
          // || fileName.length < 1}
        >
          {isFetching || isImageLoading || isLoading ? (
            <CircularProgress
              style={{ color: "white", width: "20px", height: "20px" }}
            />
          ) : (
            <>Create</>
          )}
        </Button>
        {/* <div className="logingoogle">
          <Google style={{ width: "20%", fontSize: "20px", border: " 1px solid #3c8daa", height: "10%" }} />
          <button className="googlebutton"> Sign In with Google</button>
        </div> */}
      </div>
    </div>
  )
}
