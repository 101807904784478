import { useRef, useCallback, useState } from "react"
import "./selling.scss"
import ImageBox from "../imagebox"
import Rooms from "../rooms"
import {
  AttachFile,
  FavoriteBorder,
  UnfoldMore,
  AccountCircleOutlined,
  NavigateNext,
  AddShoppingCart,
} from "@mui/icons-material"
import { Link } from "react-router-dom"
//
import { addProduct } from "../../redux/cartRedux"
import { useDispatch } from "react-redux"

import { format } from "timeago.js"

export default function SellingProductsLayout(cards, isLoading, nextPage) {
  // modaol opener
  const [openModal, setOpenModal] = useState(false)

  const [pageNumber, setPageNumber] = useState(1)
  const observer = useRef()
  //
  const lastBlogElement = useCallback(
    (node) => {
      if (isLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && nextPage) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1)
          // console.log(pageNumber)
        }
      })
      if (node) observer.current.observe(node)
    },
    [
      isLoading,
      nextPage,
      /* Todo setup a state to check it there are any more blogs to be seen
      and make is loading a dependency */
    ]
  )

  /// holds the id of the item when unfoldmore is pressed
  const [individualItem, setIndividualItem] = useState("")

  // adds product into cart
  const dispatch = useDispatch()
  const handleClickBuy = () => {
    dispatch(addProduct(individualItem))
  }

  // console.log(individualItem)
  return {
    pageNumber: pageNumber,
    modalOpen: openModal,
    modalItem: individualItem,
    setModalOpen: setOpenModal,
    sellingItem: individualItem,
    setItem: setIndividualItem,
    display: (
      <div className="sellinglayout">
        {cards.map((item, index) => {
          if (cards.length === index + 1) {
            return (
              <div className="selling" key={index + 232}>
                {/* {renderModal()} */}

                <div className="sellingtop">
                  <Link to={`/product/${item._id}`}>
                    <img
                      src={item && item.image[0]}
                      alt=""
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Link>
                  <div className="sellingfirstinsideimg">
                    <div className="sellingfirst">
                      <span className="first">Featured</span>
                    </div>
                    <div className="sellingsecond">
                      {/* <span>For Rent</span> */}
                      <span>Hot Offer</span>
                    </div>
                  </div>
                  <div className="sellingsecondinsideimg">
                    <div className="sellingfirsttags">
                      <span className="sellingprice">
                        $ {item && item.price}
                      </span>
                      {/* <span className="sellingarea">320sq/m</span> */}
                    </div>
                    <div className="sellingicons">
                      {/* Todo make the fav functional */}
                      {/* <div className="sellingindividual">
                        <div className="sellingshadow">
                          <span>Favorite</span>
                        </div>
                        <FavoriteBorder className="sellinglike" />
                      </div> */}
                      <div className="sellingindividual">
                        <div className="sellingshadow">
                          <span>Preview</span>
                        </div>
                        <UnfoldMore
                          className="sellinglikepreview"
                          onClick={(e) => {
                            setIndividualItem(item._id)
                            // console.log(item._id, individualItem)
                            setOpenModal(!openModal)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sellingbottomhalf">
                  <Link to={`/product/${item._id}`} className="link">
                    <div className="sellingbottomfirst">
                      <span className="productname" ref={lastBlogElement}>
                        {item && item.title}
                      </span>

                      {/* <span>905 Brickell Bay Dr, Miami, FL 33131, USA</span> */}
                    </div>

                    <div className="sellingbottomlast">
                      <div className="sellinguser">
                        <AccountCircleOutlined
                          style={{ color: "#2F2F2F", fontSize: "12px" }}
                        />
                        <span style={{ color: "black" }}>
                          {item && item.userInfo.name}
                        </span>
                      </div>
                      <div className="sellingtimeago">
                        <AttachFile
                          style={{
                            color: "#2F2F2F",
                            fontSize: "12px",
                            transform: "rotate(45deg)",
                          }}
                        />
                        <span>{format(item.createdAt)}</span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            )
          } else {
            return (
              <div className="selling" key={index + 232}>
                {/* {renderModal()} */}

                <div className="sellingtop">
                  <Link to={`/product/${item._id}`}>
                    <img
                      src={item.image && item.image[0]}
                      alt=""
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Link>
                  <div className="sellingfirstinsideimg">
                    <div className="sellingfirst">
                      <span className="first">Featured</span>
                    </div>
                    <div className="sellingsecond">
                      {/* <span>For Rent</span> */}
                      <span>Hot Offer</span>
                    </div>
                  </div>
                  <div className="sellingsecondinsideimg">
                    <div className="sellingfirsttags">
                      <span className="sellingprice">${item.price}</span>
                      {/* <span className="sellingarea">320sq/m</span> */}
                    </div>
                    <div className="sellingicons">
                      {/* Todo make it functional */}
                      {/* <div className="sellingindividual">
                        <div className="sellingshadow">
                          <span>Favorite</span>
                        </div>
                        <FavoriteBorder className="sellinglike" />
                      </div> */}
                      <div
                        className="sellingindividual"
                        onClick={(e) => {
                          setIndividualItem(item._id)
                          // console.log("hello woeld")
                          // console.log(item._id, individualItem)
                          setOpenModal(!openModal)
                        }}
                      >
                        <div className="sellingshadow">
                          <span>Preview</span>
                        </div>
                        <UnfoldMore
                          className="sellinglikepreview"
                          onClick={(e) => {
                            setIndividualItem(item._id)
                            setOpenModal(!openModal)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sellingbottomhalf">
                  <Link to={`/product/${item._id}`} className="link">
                    <div className="sellingbottomfirst">
                      <span className="productname"> {item.title}</span>

                      {/* <span>905 Brickell Bay Dr, Miami, FL 33131, USA</span> */}
                    </div>

                    <div className="sellingbottomlast">
                      <div className="sellinguser">
                        <AccountCircleOutlined
                          style={{ color: "#2F2F2F", fontSize: "12px" }}
                        />
                        <span style={{ color: "black" }}>
                          {item && item.userInfo.name}
                        </span>
                      </div>
                      <div className="sellingtimeago">
                        <AttachFile
                          style={{
                            color: "#2F2F2F",
                            fontSize: "12px",
                            transform: "rotate(45deg)",
                          }}
                        />
                        <span style={{ color: "black" }}>
                          {format(item.createdAt)}
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            )
          }
        })}
      </div>
    ),
  }
}
