// imported css class
import "./related.scss"

//link
import { Link } from "react-router-dom"

//mui
import { Star, StarOutlineSharp } from "@mui/icons-material"

export default function RelatedProducts({ cards }) {
  // TODO should have a function that askes the api
  // this is for the liked and peopel
  const isLiked = 4
  const people = 103
  var peopleholder
  if (people.toString().split("").length > 5) {
    peopleholder =
      people.toString().split("")[0] +
      people.toString().split("")[1] +
      people.toString().split("")[2] +
      "k"
  } else if (people.toString().split("").length > 4) {
    peopleholder =
      people.toString().split("")[0] + people.toString().split("")[1] + "k"
  }

  return (
    <div className="relatedlayout">
      {cards.map((item, index) => {
        return (
          <div className="related" key={index + 232}>
            {/* {renderModal()} */}

            <div className="relatedtop">
              <Link to={`/product/${item._id}`}>
                <img
                  src={item.image[0]}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </Link>
            </div>
            <div className="relatedbottomhalf">
              <Link to={`/product/${item._id}`} className="link">
                <div className="relatedbottomfirst">
                  <span className="productname"> {item.title}</span>

                  <div className="relatedlandstars">
                    <div className="relatedratingstars">
                      {isLiked >= 1 ? (
                        <Star className="relatedstar pressed" />
                      ) : (
                        <StarOutlineSharp className="relatedstar" />
                      )}
                      {isLiked >= 2 ? (
                        <Star className="relatedstar pressed" />
                      ) : (
                        <StarOutlineSharp className="relatedstar" />
                      )}
                      {isLiked >= 3 ? (
                        <Star className="relatedstar pressed" />
                      ) : (
                        <StarOutlineSharp className="relatedstar" />
                      )}
                      {isLiked >= 4 ? (
                        <Star className="relatedstar pressed" />
                      ) : (
                        <StarOutlineSharp className="relatedstar" />
                      )}
                      {isLiked >= 5 ? (
                        <Star className="relatedstar pressed" />
                      ) : (
                        <StarOutlineSharp className="relatedstar" />
                      )}
                      <span className="relatedratingspan">
                        {people > 999 ? peopleholder : people}{" "}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="relatedfirsttags">
                  <span className="relatedprice" style={{ color: "black" }}>
                    $ {item.price}
                  </span>
                  {/* <span className="relatedarea">320sq/m</span> */}
                </div>
              </Link>
            </div>
          </div>
        )
      })}
    </div>
  )
}
