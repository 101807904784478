import "../../../styles/pages/material/material.scss"
import Footer from "../../display/footer"
import { data } from "./data"

export default function Material() {
  const colores = [
    "#c5e3f6",
    "#fccde2",
    "#22d1ee",
    "#17b978",
    "#88bef5",
    "#364f6b",
    "#f3cf7a",
    "#e7b3b3",
    "#ffb174",
    "#fff4e3",
  ]
  return (
    <div className="material">
      <div className="insidematerial">
        <div className="tablecontent">
          <table className="tabling">
            <tbody className="tablebody">
              <tr
                className="tableheader"
                style={{ position: "sticky", top: "0", zIndex: "1" }}
              >
                <th className="headerno">Item NO</th>
                <th className="headerdisc">Description</th>
                <th className="headerunit">Unit</th>
                <th className="headerpr">Price</th>
              </tr>
              {data.map((item, index) => (
                <>
                  <tr className="tablerow" key={index + 2321}>
                    <td className="rowno">
                      <div
                        className="colorgrdient"
                        style={
                          colores[index]
                            ? { backgroundColor: colores[index] }
                            : { backgroundColor: colores[index % 10] }
                        }
                      ></div>
                      <span>{item.item_no}</span>
                    </td>
                    <td className="rowdisc">{item.discription}</td>
                    <td className="rowunit">{item.unit}</td>
                    <td className="rowpr">{item.price}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
        <Footer />
      </div>
    </div>
  )
}
