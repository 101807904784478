import { useState } from "react"

// css class
import "./unfollow.scss"

import { Check, Close } from "@mui/icons-material"
import Alert from "@mui/material/Alert"
import { CircularProgress } from "@mui/material"

import Rooms from "../rooms"

// mutation to un follow organization
import { useQuery, useMutation } from "@tanstack/react-query"

// user request sender module
import { userRequest } from "../../constant/requestMethods"

export default function UnfollowModal(
  openModal,
  setOpenModal,
  name,
  organization,
  id,
  orgId,
  isFollowing
) {
  // controllers to show alets
  const [alert, setAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  // endpoint to rate firm
  const unFollowOrg = async (data) => {
    if (organization === "firm") {
      const reply = await userRequest.put(`firm/unfollowfirm/${id}/${orgId}`)
      return reply.data
    } else if (organization === "manufacturer") {
      const reply = await userRequest.put(
        `manufacturer/unfollowmanufacturer/${id}/${orgId}`
      )
      return reply.data
    } else if (organization === "professional") {
      const reply = await userRequest.put(
        `professional/unfollowprofessional/${id}/${orgId}`
      )
      return reply.data
    }
  }
  const unFollow = useMutation(unFollowOrg, {
    onSuccess: (data) => {
      setAlert(true)
      setSuccessAlert(true)
      // console.log(data)
      setSuccessMessage(data.msg)
      isFollowing(false)
    },
    onError: (e) => {
      setAlert(true)
      setErrorAlert(true)
      setErrorMessage(e.message)
    },
  })

  // create or update review
  const handleUnFollow = () => {
    setAlert(false)
    setErrorAlert(false)
    setSuccessAlert(false)
    setErrorMessage("")
    setSuccessMessage("")
    // console.log(orgId, id, organization)
    unFollow.mutate()
  }
  return {
    unFollowed: successAlert,
    display: (
      <div
        className="unfollowmodal"
        style={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          left: "50%",
          top: "35%",
          // height: "60%",
          width: "33.4%",
          transform: "translate(-50%, -50%)",
          outline: "none",
          opacity: "1",
          backgroundColor: "white",
          border: "none",
        }}
      >
        <div className="unfollowheader">
          <Close
            style={{ color: "white", cursor: "pointer" }}
            onClick={(e) => {
              setOpenModal(!openModal)
            }}
          />
        </div>
        {unFollow.isLoading ? (
          <div
            style={{
              width: "100px",
              height: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {alert && (
              <div className="alert">
                {errorAlert && <Alert severity="error">{errorMessage}</Alert>}
                {successAlert && (
                  <Alert severity="success">{successMessage}</Alert>
                )}
              </div>
            )}
            {successAlert ? (
              <></>
            ) : (
              <Rooms
                icons={
                  <Close
                    style={{ color: "red", fill: "red", cursor: "pointer" }}
                  />
                }
                number={"are you sure you want to do this?"}
              />
            )}

            <div className="buttons">
              <button
                className="keep"
                onClick={(e) => {
                  setOpenModal(!openModal)
                }}
              >
                go back
              </button>
              {successAlert ? (
                <></>
              ) : (
                <button
                  className="unfollow"
                  onClick={(e) => {
                    handleUnFollow()
                  }}
                >
                  {" "}
                  UNfollow {name}
                </button>
              )}
            </div>
          </>
        )}
      </div>
    ),
  }
}
