// css class
import "./layout.scss"

import { useRef, useCallback, useState } from "react"

import TenderCard from "../singletender"

import { format } from "timeago.js"

export default function TenderLayout(cards, isLoading, nextPage) {
  const [pageNumber, setPageNumber] = useState(1)
  const observer = useRef()
  const lastBlogElement = useCallback(
    (node) => {
      if (isLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && nextPage) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1)
          console.log(pageNumber)
        }
      })
      if (node) observer.current.observe(node)
    },
    [
      isLoading,
      nextPage,
      /* Todo setup a state to check it there are any more blogs to be seen
      and make is loading a dependency */
    ]
  )
  // console.log(nextPage)
  return {
    pageNumber: pageNumber,
    display: (
      <div>
        {cards &&
          cards.map((item, index) => {
            if (cards.length === index + 1) {
              return (
                <div
                  className="card"
                  ref={lastBlogElement}
                  key={index + 1242345}
                >
                  <TenderCard
                    title={item.title}
                    tot={item.tot}
                    country={item.country}
                    date={format(item.deadline)}
                    id={item._id}
                  />
                </div>
              )
            } else {
              return (
                <div className="card" key={index + 1242}>
                  <TenderCard
                    title={item.title}
                    tot={item.tot}
                    country={item.country}
                    date={format(item.deadline)}
                    id={item._id}
                  />
                </div>
              )
            }
          })}
      </div>
    ),
  }
}
